import {atom} from 'recoil';

export const loadingState = atom({
  key: 'loadingState',
  default: false,
});

export const loadingZindex = atom({
  key: 'loadingZindex',
  default: 1000,
});
