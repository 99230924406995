import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {SERVER_URL} from '../../../constants';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const filter = createFilterOptions();

export default function AddTagWidget(props: $TSFixMe) {
  const [tagOptions, setTagOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState('action');
  const [dialogValue, setDialogValue] = React.useState({
    title: '',
    year: '',
  });

  const getTagOptions = () => {
    const fbjwt = localStorage.getItem('Authorization');
    //TODO: this is a bit of a hack.
    fetch(`${SERVER_URL}v1/rfps/tags?page=0&pageSize=${100000}`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const tags = res?._embedded?.models || [];
        const options = tags.map((tag: $TSFixMe) => ({
          title: tag.name,
          id: tag.id,
        }));
        setTagOptions(options);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getTagOptions();
  }, []);

  const handleSubmit = (event: $TSFixMe) => {
    event.preventDefault();
    event.stopPropagation();
    console.log('SUBMIT');
    const tag = {
      name: dialogValue.title,
      type: type,
    };
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/rfps/tags`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(tag),
    })
      .then((res) => res.json())
      .then((res) => {
        getTagOptions();
        props.addTag(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const openCreateTagDialog = () => {
    setOpen(true);
  };

  const autoCompleteOnChange = (event: $TSFixMe, newValue: $TSFixMe) => {
    if (typeof newValue === 'string') {
      setValue({
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ title: string; }' is not assig... Remove this comment to see the full error message
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setValue({
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ title: any; }' is not assignab... Remove this comment to see the full error message
        title: newValue.inputValue,
      });
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ title: any; }' is not assignab... Remove this comment to see the full error message
      setDialogValue({
        title: newValue.inputValue,
      });
      openCreateTagDialog();
    } else {
      setValue(newValue);
      props.addTag(newValue);
    }
  };

  const autoCompleteFilterOptions = (options: $TSFixMe, params: $TSFixMe) => {
    const filtered = filter(options, params);
    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        title: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  const autoCompleteGetOptionLabel = (option: $TSFixMe) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.title;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: $TSFixMe) => {
    setType(event.target.value);
  };

  return (
    <>
      <Autocomplete
        id="free-solo-with-text-demo"
        value={value}
        onChange={autoCompleteOnChange}
        filterOptions={autoCompleteFilterOptions}
        options={tagOptions}
        getOptionLabel={autoCompleteGetOptionLabel}
        renderOption={(option) => (option as $TSFixMe).title}
        style={{width: 284, paddingLeft: 10}}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select a tag"
            variant="outlined"
            size="small"
            style={{marginBottom: 16}}
          />
        )}
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Add a new tag</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.title}
              onChange={(event) =>
                setDialogValue({...dialogValue, title: event.target.value})
              }
              label="Tag"
              type="text"
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                onChange={handleChange}>
                <MenuItem value={'action'}>Action</MenuItem>
                <MenuItem value={'object'}>Object</MenuItem>
                <MenuItem value={'context'}>Context</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" color="secondary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
