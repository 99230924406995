import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {SERVER_URL} from '../../../constants';

const styles = (theme: $TSFixMe) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { m... Remove this comment to see the full error message
const DialogTitle = withStyles(styles)((props) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'PropsWi... Remove this comment to see the full error message
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ChangeProjectDialog(props: $TSFixMe) {
  const [open, setOpen] = React.useState(false);
  const [projectList, setProjectList] = React.useState([]);
  const [projectId, setProjectId] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const ComboBox = () => {
    return (
      <Autocomplete
        id="combo-box-demo"
        options={projectList}
        getOptionLabel={(option) => (option as $TSFixMe).title}
        onChange={(event, value) => setProjectId(value)}
        style={{width: 300}}
        renderInput={(params) => (
          <TextField {...params} label="Combo box" variant="outlined" />
        )}
      />
    );
  };

  useEffect(() => {
    if (open) {
      fetchProjectList();
    }
  }, [open]);

  const fetchProjectList = async () => {
    const url = `${SERVER_URL}v1/rfps?page=0&pageSize=100&state=OPEN`;
    const fbjwt = localStorage.getItem('Authorization');
    return await fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ method: string; Authorization: string | nu... Remove this comment to see the full error message
      headers: {
        method: 'GET',
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        setProjectList(responseData._embedded.models);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        style={{color: 'red', float: 'right'}}>
        Change Project
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; id: string; onClose: () ... Remove this comment to see the full error message */}
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Change Project
        </DialogTitle>
        <DialogContent dividers>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ fetchProjectList: () => Promise<void>; }' ... Remove this comment to see the full error message */}
          <ComboBox fetchProjectList={fetchProjectList} />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => props.updateProjectQuote(projectId)}
            style={{color: 'red'}}>
            Submit
          </Button>
          <Button style={{float: 'left'}} autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
