import React, {useState, Fragment} from 'react';
import {SERVER_URL} from '../../constants';
import MaterialTable from 'material-table';
import Container from 'react-bootstrap/Container';
import Grid from '@material-ui/core/Grid';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faEdit, faCheck} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {loadingState} from '../../recoil/loading/loadingAtom';
import ActivityDetails from '../Activity/ActivityDetails';

library.add(faEdit);
library.add(faCheck);

const Activity = (props: $TSFixMe) => {
  const [, setActivity] = useState(false);
  const [, setShowDialog] = useState(false);
  const [, setLoading] = useRecoilState(loadingState);
  const history = useHistory();

  const fetchActivity = (query: $TSFixMe) => {
    setLoading(true);
    let page = 0;
    if (query.page) {
      page = query.page;
    }
    let url = `${SERVER_URL}v1/activities?page=${page}&pageSize=${query.pageSize}`;
    if (query.search) {
      url += `&search=${query.search}`;
    }
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      field: 'Text',
      render: (rowData: $TSFixMe) => rowData.user.name,
    },
    {
      title: 'Title',
      field: 'text',
      render: (rowData: $TSFixMe) => rowData.title,
    },
    {
      title: 'Date',
      field: 'date',
      render: (rowData: $TSFixMe) => rowData.createdDate,
    },
  ];

  const actions = [
    {
      icon: 'arrow_forward',
      tooltip: 'View Project',
      onClick: (event: $TSFixMe, rowData: $TSFixMe) => {
        console.log(rowData, 'Click Activity');
        history.push(rowData.url);
      },
    },
    (rowData: $TSFixMe) => ({
      icon: 'check',
      tooltip: 'Record Payment',
      disabled: rowData.type !== 'PAYOUT' || rowData.state === 'COMPLETE',

      onClick: (event: $TSFixMe, data: $TSFixMe) => {
        setActivity(data);
        setShowDialog(true);
      },
    }),
  ];

  const materialTable = () => {
    return (
      <Fragment>
        <Grid
          container
          justifyContent="center"
          style={{marginTop: 0, marginBottom: 20, paddingTop: 30}}>
          <Grid item xs={12} md={12} key={0}>
            <MaterialTable
              key={0}
              columns={columns}
              title={'Activity'}
              data={(query) =>
                new Promise((resolve, reject) => {
                  fetchActivity(query)
                    .then((res) => res.json())
                    .then((res) => {
                      setLoading(false);
                      if (res.content.length > 0) {
                        resolve({
                          data: res.content,
                          page: query.page,
                          totalCount: res.totalElements,
                        });
                      } else {
                        resolve({
                          data: [],
                          page: 0,
                          totalCount: 0,
                        });
                      }
                    })
                    .catch((error) => {
                      resolve({
                        data: [],
                        page: 0,
                        totalCount: 0,
                      });
                      console.error('Error:', error);
                    });
                })
              }
              actions={actions}
              options={{
                toolbar: true,
                actionsColumnIndex: -1,
                pageSize: 10,
                pageSizeOptions: [10, 15, 20, 25, 30, 50, 75, 100],
                debounceInterval: 500,
                exportButton: true,
              }}
              detailPanel={(rowData) => {
                return (
                  <Container style={{padding: 60}}>
                    <ActivityDetails activity={rowData} />
                  </Container>
                );
              }}
              // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  return (
    <div>
      <Container
        fluid="false"
        style={{backgroundColor: 'light-grey', paddingTop: 0}}>
        {materialTable()}
      </Container>
    </div>
  );
};

export default Activity;
