import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ChatIcon from '@mui/icons-material/Chat';
import MessagesPanel from '../../Messaging/components/MessagesPanel';
export default function QuoteMessagingDialog(props: $TSFixMe) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <Button
        color={'secondary'}
        variant={'contained'}
        onClick={() => setDialogOpen(true)}>
        Messages <ChatIcon />
      </Button>
      <Dialog
        fullWidth={true}
        fullScreen={window.outerWidth < 600}
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogContent>
          <MessagesPanel
            id={props.conversationId}
            hidden={false}
            central={false}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
