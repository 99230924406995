import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {SERVER_URL} from '../../../constants';
import {dateFormat} from '@bidmii/common/lib/util/DateUtils';
import {FormControl, MenuItem, Select, Snackbar} from '@material-ui/core';

export default function EditableQuote(props: $TSFixMe) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editQuotes, setEditQuotes] = useState({
    description: props.proposal.description,
    state: props.proposal.state,
    validUntilDate: props.proposal.validUntilDate,
    proposedDate: props.proposal.proposedDate,
    enabled: props.proposal.enabled,
  });
  const [openState, setOpenState] = React.useState(false);
  const [snackBarVisible, setSnackBarVisible] = useState(false);
  const [materialsAmount, setMaterialsAmount] = useState(
    props.proposal.materialsAmount,
  );
  const [labourAmount, setLabourAmount] = useState(props.proposal.labourAmount);
  const [contingencyAmount, setContingencyAmount] = useState(
    props.proposal.contingencyAmount,
  );

  const [openBoolean, setOpenBoolean] = React.useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleChangeState = (event: $TSFixMe) => {
    setEditQuotes({...editQuotes, state: event.target.value});
  };

  const handleChangeBoolean = (event: $TSFixMe) => {
    setEditQuotes({...editQuotes, enabled: event.target.value});
  };

  const handleChange = (e: $TSFixMe) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    editQuotes[name] = value;
    console.log('values', value);
    setEditQuotes({...editQuotes});
  };

  const saveEditingQuotes = () => {
    const jwt = localStorage.getItem('Authorization');
    let body = JSON.stringify({
      description: editQuotes.description,
      validUntilDate: editQuotes.validUntilDate,
      proposedDate: editQuotes.proposedDate,
      state: editQuotes.state,
      enabled: editQuotes.enabled,
    });

    if (props.proposal?.stages?.length === 0) {
      body = {
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        ...body,
        materialsAmount: materialsAmount,
        labourAmount: labourAmount,
        contingencyAmount: contingencyAmount,
      };
    }

    return fetch(`${SERVER_URL}v1/proposals/${props.proposal.uid}`, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
      body: body,
    })
      .then((res) => {
        setSnackBarVisible(true);
        setTimeout(function () {
          props.updateRequestForProposalState().then(() => {
            handleClose();
          });
        }, 3500);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const hideSnackBar = (event: $TSFixMe) => {
    setSnackBarVisible(false);
  };

  const handleCloseDialog = () => {
    setOpenState(false);
  };

  const handleOpen = () => {
    setOpenState(true);
  };

  const handleOpenBooleanDialog = () => {
    setOpenBoolean(true);
  };
  const handleCloseBooleanDialog = () => {
    setOpenBoolean(false);
  };

  return (
    <div>
      <Button
        disabled={['FUNDED', 'COMPLETE'].includes(props.proposal.state)}
        style={{background: '#e6e6e6'}}
        onClick={() => setDialogOpen(true)}>
        Edit Quote
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        maxWidth={'sm'}
        fullWidth
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">EDIT</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="html"
            name="description"
            fullWidth
            onChange={handleChange}
            color={'secondary'}
            value={editQuotes.description}
          />
          <div>
            <Button style={{marginTop: 6}} onClick={handleOpen}>
              State -
            </Button>
            <FormControl style={{marginTop: 6}}>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={openState}
                onClose={handleCloseDialog}
                onOpen={handleOpen}
                value={editQuotes.state}
                onChange={handleChangeState}>
                <MenuItem value={'DRAFT'}>DRAFT</MenuItem>
                <MenuItem value={'CANCELLED'}>CANCELLED</MenuItem>
                <MenuItem value={'OPEN'}>OPEN</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            inputProps={{color: 'red'}}
            autoFocus
            margin="dense"
            id="validUntilDate"
            label="Expiry date"
            type="date"
            fullWidth
            name="validUntilDate"
            color={'secondary'}
            onChange={handleChange}
            defaultValue={dateFormat(editQuotes.validUntilDate)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="proposedDate"
            label="proposedDate"
            type="date"
            fullWidth
            name="proposedDate"
            color={'secondary'}
            onChange={handleChange}
            defaultValue={dateFormat(editQuotes.proposedDate)}
          />
          <br />
          <div>
            <Button style={{marginTop: 6}} onClick={handleOpen}>
              Enabled -
            </Button>
            <FormControl style={{marginTop: 6}}>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={openBoolean}
                onClose={handleCloseBooleanDialog}
                onOpen={handleOpenBooleanDialog}
                value={editQuotes.enabled}
                onChange={handleChangeBoolean}>
                <MenuItem value={'true'}>True</MenuItem>
                <MenuItem value={'false'}>False</MenuItem>
              </Select>
            </FormControl>
          </div>
          <br />
          {props.proposal.stages.length === 0 && (
            <>
              <TextField
                margin="dense"
                label="material amount"
                id="materialamount"
                type="number"
                fullWidth
                name="materialamount"
                onChange={(event) => setMaterialsAmount(event.target.value)}
                value={materialsAmount}
                color={'secondary'}
              />
              <TextField
                margin="dense"
                label="labour amount"
                id="labouramount"
                type="number"
                fullWidth
                name="labouramount"
                onChange={(event) => setLabourAmount(event.target.value)}
                value={labourAmount}
                color={'secondary'}
              />
              <TextField
                margin="dense"
                label="contingency amount"
                id="contingencyamount"
                type="number"
                fullWidth
                name="contingencyamount"
                onChange={(event) => setContingencyAmount(event.target.value)}
                value={contingencyAmount}
                color={'secondary'}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={saveEditingQuotes}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackBarVisible}
        onClose={hideSnackBar}
        message="Quote has been saved"
      />
    </div>
  );
}
