import React, {useState, useEffect, Fragment} from 'react';
import {SERVER_URL} from '../../constants';
import MaterialTable from 'material-table';
import Container from 'react-bootstrap/Container';
import Grid from '@material-ui/core/Grid';
import {useRecoilState} from 'recoil';
import {loadingState} from '../../recoil/loading/loadingAtom';

const Unsubscribes = (props: $TSFixMe) => {
  const [, setLoading] = useRecoilState(loadingState);
  const [data, setData] = useState([]);

  const fetchUnsubscribes = () => {
    setLoading(true);
    const url = `${SERVER_URL}v1/unsubscribes`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/text',
      },
    })
      .then((res) => res.text())
      .then((res) => {
        const resData = JSON.parse(res);
        if (resData.length > 0) {
          setData(resData);
        }
      });
  };

  useEffect(() => {
    fetchUnsubscribes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Date',
      field: 'date',
      render: (rowData: $TSFixMe) => {
        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        return Date(rowData.created).toLocaleUpperCase('en-US', options);
      },
    },
    {
      title: 'Email',
      field: 'Text',
      render: (rowData: $TSFixMe) => rowData.email,
    },
  ];

  const materialTable = () => {
    return (
      <Fragment>
        <Grid
          container
          justifyContent="center"
          style={{marginTop: 0, marginBottom: 20, paddingTop: 30}}>
          <Grid item xs={12} md={12} key={0}>
            <MaterialTable
              columns={columns}
              title={'Unsubscribes'}
              data={data}
              options={{
                toolbar: true,
                //pageSize:,
                pageSizeOptions: [
                  10, 20, 30, 50, 75, 100, 200, 500, 1000, 100000,
                ],
                exportButton: true,
              }}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  return (
    <div>
      <Container
        fluid="false"
        style={{backgroundColor: 'light-grey', paddingTop: 0}}>
        {materialTable()}
      </Container>
    </div>
  );
};

export default Unsubscribes;
