import React, {useState, useEffect, useCallback} from 'react';
import {SERVER_URL} from '../../constants';
import QuestionRejectDialog from './components/QuestionRejectDialog';
import {questionsCountState} from '../../recoil/questions/questionsAtom';
import {useSetRecoilState} from 'recoil';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {dateAsWords} from '../../util/DateAsWords';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CheckIcon from '@material-ui/icons/Check';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

const CustomToolbar = (props: $TSFixMe) => {
  const apiRef = useGridApiContext();

  const [hasRowsSelected, setHasRowsSelected] = useState(false);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('rowSelectionChange', (params) => {
      const selection = apiRef.current.getSelectedRows();
      setHasRowsSelected(selection.size > 0);
    });
  }, [apiRef]);

  const handleClickApprove = () => {
    const selection = apiRef.current.getSelectedRows();
    const questionIds: $TSFixMe = [];

    selection.forEach((question) => questionIds.push(question.id));

    const url = `${SERVER_URL}v1/questions/approve-all`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ids: questionIds}),
    })
      .then(() => {
        props.getData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleClickDelete = () => {
    const selection = apiRef.current.getSelectedRows();
    const questionIds: $TSFixMe = [];

    selection.forEach((question) => questionIds.push(question.id));

    const url = `${SERVER_URL}v1/questions`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'DELETE',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ids: questionIds}),
    })
      .then(() => {
        props.getData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <GridToolbarContainer style={{paddingTop: 25}}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      {hasRowsSelected && (
        <>
          <Button
            variant="outlined"
            size={'small'}
            color={'secondary'}
            onClick={handleClickApprove}>
            Approve
          </Button>
          <Button
            variant="outlined"
            size={'small'}
            color={'secondary'}
            style={{marginLeft: 30}}
            onClick={handleClickDelete}>
            Delete
          </Button>
        </>
      )}
      <GridToolbarQuickFilter style={{position: 'fixed', right: 50}} />
    </GridToolbarContainer>
  );
};

const Questions = (props: $TSFixMe) => {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const apiRef = useGridApiRef();

  const [questionRejectionDialog, setQuestionRejectionDialog] = useState(false);
  const [questionRejectionID, setQuestionRejectionID] = React.useState('');
  const setQuestionsReviewCount = useSetRecoilState(questionsCountState);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize, props.filter]);

  const getData = useCallback(() => {
    fetch(
      `${SERVER_URL}v1/questions?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setRowCount(res.page.totalElements);
        setRows(res._embedded?.models || []);
        setLoading(false);
      });
  }, [paginationModel.page, paginationModel.pageSize]);

  const getQuestionsToReviewCount = () => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/questions/review-count`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setQuestionsReviewCount(res);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  useEffect(() => {
    if (questionRejectionID) {
      setQuestionRejectionDialog(true);
    }
  }, [questionRejectionID]);

  const handleRejectQuestion = (rejectReasonText: $TSFixMe) => {
    const url = `${SERVER_URL}v1/questions/${questionRejectionID}/reject`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({rejectReason: rejectReasonText}),
    })
      .then((response) => response.text())
      .then((res) => {
        setQuestionRejectionDialog(false);
        getData();
        getQuestionsToReviewCount();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleApprovalQuestion = (questionIDApproval: $TSFixMe) => {
    const url = `${SERVER_URL}v1/questions/${questionIDApproval}/approve`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        setQuestionRejectionDialog(false);
        getData();
        getQuestionsToReviewCount();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteQuestion = (questionID: $TSFixMe) => {
    const url = `${SERVER_URL}v1/questions/${questionID}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'DELETE',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        getData();
        getQuestionsToReviewCount();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns: GridColDef[] = [
    {headerName: 'ID', field: 'id', type: 'number'},
    {
      headerName: 'Project Title',
      field: 'rfpTitle',
      width: 200,
    },
    {
      headerName: 'Question',
      field: 'question',
      width: 450,
    },
    {
      headerName: 'Answer',
      field: 'answer',
      width: 450,
    },
    {
      headerName: 'Rejection Reason',
      field: 'rejectReason',
      width: 450,
    },
    {
      headerName: 'Name',
      field: 'name',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row?.user?.name?.toUpperCase() || 'N/A',
      width: 250,
    },
    {
      headerName: 'AI Label',
      field: 'vertexAiLabel',
      type: 'singleSelect',
      valueOptions: [
        'OK',
        'SITE_VISIT',
        'PROJECT_AVAILABILITY',
        'NOT_A_QUESTION',
      ],
    },
    {
      headerName: 'Confidence',
      field: 'vertexAiConfidence',
    },
    {
      headerName: 'State',
      field: 'state',
      type: 'singleSelect',
      valueOptions: ['REVIEW', 'PENDING', 'REJECTED', 'COMPLETE'],
    },
    {
      headerName: 'Created',
      field: 'createdDate',
      type: 'dateTime',
      valueFormatter: (value: $TSFixMe) =>
        dateAsWords(value, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      width: 120,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          icon={<CheckIcon />}
          onClick={() => {
            handleApprovalQuestion(params.id);
          }}
          label="Approve"
        />,
        <GridActionsCellItem
          icon={<NotInterestedIcon />}
          onClick={() => {
            setQuestionRejectionID(params.id);
          }}
          label="Reject"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => {
            handleDeleteQuestion(params.id);
          }}
          label="Delete"
        />,
        <GridActionsCellItem
          icon={<OpenInNewIcon />}
          onClick={() => {
            window.open(
              `${window.location.origin}/myRfps/${params?.row?.rfpId}`,
              '_blank',
            );
          }}
          label="View Project"
        />,
      ],
      width: 200,
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 30,
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          getRowHeight={() => 'auto'}
          getDetailPanelContent={({row}) => (
            <div
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                textAlign: 'left',
                paddingLeft: window.outerWidth < 600 ? 10 : 40,
              }}>
              {row.question}
            </div>
          )}
          getDetailPanelHeight={() => 'auto'}
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                answer: false,
                rejectReason: false,
                vertexAiLabel: false,
                vertexAiConfidence: false,
              },
            },
            filter: {
              filterModel: {
                items: [{field: 'state', operator: 'is', value: 'REVIEW'}],
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 500},
              getData: getData,
            },
          }}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          checkboxSelection
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50, 100, 150, 200, 300, 400, 500, 750, 1000]}
          pagination
          paginationMode={'server'}
          loading={loading}
        />
      </div>
      <QuestionRejectDialog
        open={questionRejectionDialog}
        onDecline={handleRejectQuestion}
        handleClose={() => setQuestionRejectionDialog(false)}
      />
    </div>
  );
};

export default Questions;
