import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {red} from '@material-ui/core/colors';
import HomeownerAvatar from '../../../util/HomeownerAvatar';
import ContractorAvatar from '../../../util/ContractorAvatar';
import {dateAsWords, processedDate} from '../../../util/DateAsWords';
import {currencyFormat} from '../../../util/CurrencyFormatter';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ProposalContractorViewTable from './ProposalContractorViewTable';
import Grid from '@material-ui/core/Grid';
import parser from 'html-react-parser';
import EditIcon from '@material-ui/icons/Edit';
import {SERVER_URL} from '../../../constants';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  btn_gropus: {
    marginTop: 10,
    '& button:first-child': {
      marginRight: 10,
    },
  },
  flex_item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  edit_icon: {
    cursor: 'pointer',
    marginLeft: 10,
  },
  edit_input: {
    width: '100%',
  },
}));

function QuoteDetail(props: $TSFixMe) {
  const classes = useStyles();
  const [quote, setQuote] = useState(props.quote);
  const [expanded, setExpanded] = useState(false);
  const [editableDescription, setEditableDescription] = useState(false);
  const [showEditQuote, setShowEditQuote] = useState(false);

  const getAvatar = () => {
    if (quote.user.userType === 'REQUESTER') {
      return <HomeownerAvatar user={quote.user} />;
    } else {
      return <ContractorAvatar user={quote.user} />;
    }
  };

  const getAction = () => {
    return (
      <Grid
        container
        direction={'column'}
        spacing={2}
        style={{paddingRight: 10}}>
        <Grid item>
          {quote.validUntilDate ? (
            <Typography
              component="span"
              variant={'body2'}
              color="secondary"
              style={{fontWeight: 500}}>
              Expires:{' '}
              <span style={{color: 'grey'}}>
                {processedDate(quote.validUntilDate, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </span>
            </Typography>
          ) : (
            <Typography
              component="span"
              variant={'body2'}
              color="secondary"
              style={{fontWeight: 500, paddingRight: 5}}>
              No Expiry
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  const saveQuote = () => {
    const url = `${SERVER_URL}v1/proposals/${quote.id}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({description: quote.description}),
    }).then((res) => {
      setEditableDescription(false);
      props.dataFetcher();
    });
  };

  const discardQuote = () => {
    setEditableDescription(false);
  };

  const onChangeHandler = (event: $TSFixMe, editor: $TSFixMe) => {
    const data = editor.getData();
    setQuote({...quote, description: data});
  };

  return (
    <div>
      <Card className={classes.root}>
        <CardHeader
          avatar={getAvatar()}
          title={quote.user.name}
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
          subheader={dateAsWords(quote.validUntilDate)}
          action={getAction()}
        />
        <CardContent style={{paddingBottom: 0}}>
          <Typography variant="h6" noWrap>
            {quote.requestForProposalTitle}
          </Typography>
        </CardContent>
        <CardContent>
          <Typography
            color="secondary"
            component="p"
            variant="body1"
            style={{fontWeight: 500}}>
            Project Description
          </Typography>
          {editableDescription ? (
            <div>
              <CKEditor
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ placeholder: string; editor: typeof Classi... Remove this comment to see the full error message
                placeholder="Description"
                editor={ClassicEditor}
                data={quote.description}
                config={{toolbar: []}}
                onChange={(event, editor) => onChangeHandler(event, editor)}
              />
              <div className={`${classes.btn_gropus} ${classes.flex_item}`}>
                <Button variant="contained" onClick={() => saveQuote()}>
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => discardQuote()}>
                  Discard
                </Button>
              </div>
            </div>
          ) : (
            <div
              onMouseEnter={() => setShowEditQuote(true)}
              onMouseLeave={() => setShowEditQuote(false)}>
              <Typography component="span" variant="body1">
                {parser(quote.description || '')}
              </Typography>
              {showEditQuote ? (
                <EditIcon
                  onClick={() => setEditableDescription(true)}
                  className={classes.edit_icon}
                />
              ) : (
                ''
              )}
            </div>
          )}
        </CardContent>
        <Divider />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <ProposalContractorViewTable proposal={quote} />
          </CardContent>
        </Collapse>
        <CardActions style={{marginBottom: 7, marginLeft: 8}}>
          <Typography
            color="secondary"
            component="p"
            variant="body1"
            style={{fontWeight: 500}}>
            Total{' '}
          </Typography>
          <span color="black">{currencyFormat(quote.amount * 1.13, true)}</span>
          <Button
            size="small"
            color="secondary"
            style={{marginLeft: 'auto'}}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setExpanded(!expanded);
            }}>
            {expanded ? 'Less Details' : 'More Details'}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

export default QuoteDetail;
