import React from 'react';
import videoExtensions from 'video-extensions';

export const isVideo = (url: $TSFixMe) => {
  if (url && url !== '/images/logo.png') {
    try {
      const urlOb = new URL(url);
      const urlParts = urlOb.pathname.split('.');
      return videoExtensions.includes(urlParts[urlParts.length - 1]);
    } catch (e) {
      console.error(e);
    }
  }
  return false;
};

export const renderVideo = (video: $TSFixMe) => {
  return (
    <video
      src={video.url}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; src: any; alt: any; widt... Remove this comment to see the full error message
      alt={video.name}
      width="100%"
      autoPlay
      controls
      muted>
      Sorry, your browser doesn't support embedded videos
    </video>
  );
};
