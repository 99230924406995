import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Grid,
  Card,
  CardHeader,
  TextField,
  List,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CategorySearchWidget from '../../RequestForProposal/components/CategorySearchWidget';
import UserRiskStatusSelectionWidget from './UserRiskStatusSelectionWidget';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Chip from '@material-ui/core/Chip';
import {SERVER_URL} from '../../../constants';
import InputColor from 'react-input-color';
import {signInAnonymously} from 'firebase/auth';
import {firebaseAuth, firestoreDb} from '../../../App';
import {collection, doc, getDoc, setDoc} from 'firebase/firestore';
import {LinkAccountForm} from '../../Developers/LinkAccountForm';

function Alert(props: $TSFixMe) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  input_form: {
    width: '45%',
    margin: '2.5%',
  },
  date_input_form: {
    width: '47%',
    marginTop: '2.5%',
    marginBottom: '2.5%',
    marginLeft: '0.5%',
    marginRight: '2%',
  },
}));

const EditableProfile = forwardRef((props: $TSFixMe, ref) => {
  const classes = useStyles();
  const data = props.data;
  console.log(JSON.stringify(data));
  const [fields, setFields] = useState(props.data);
  const [updatedValues, setUpdatedValues] = useState({});
  const [radioValue, setRadioValue] = useState(data.userType);
  const [userVerificationState, setUserVerificationState] = useState(
    data.userVerificationState,
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [welcomeKitDetails, setWelcomeKitDetails] = useState<any>(false);
  const [approvedForCalls, setApprovedForCalls] = useState<boolean | null>(
    null,
  );

  const editorInstance = useMemo(() => ClassicEditor, []);

  const initFirestoreDoc = () => {
    signInAnonymously(firebaseAuth)
      .catch(function (error) {
        console.error('ANONYMOUS LOGIN FAIL');
        console.error(error.code);
        console.error(error.message);
      })
      .then((res) => {
        // @ts-ignore
        const userDocRef = doc(collection(firestoreDb, 'users'), data.uid);

        setDoc(userDocRef, {});
      });
  };

  useEffect(() => {
    if (data.uid) {
      // @ts-ignore
      const userDocRef = doc(collection(firestoreDb, 'users'), data.uid);

      getDoc(userDocRef)
        .then((doc) => {
          if (doc.exists()) {
            console.log('EXISTS');
            console.log(JSON.stringify(doc.data()));
            setWelcomeKitDetails(doc.data());
            try {
              // @ts-ignore
              setApprovedForCalls(doc.data().phoneEnabled.toString());
            } catch (e) {}
          } else {
            initFirestoreDoc();
            setWelcomeKitDetails({});
          }
        })
        .catch((err: any) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.uid]);

  const handleChange = (e: $TSFixMe) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (name === 'userType') {
      setRadioValue(value);
    }
    if (name === 'userVerificationState') {
      setUserVerificationState(value);
    }

    fields[name] = value;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    updatedValues[name] = value;
    setFields({...fields});
    // @ts-ignore
    setUpdatedValues({...updatedValues});
  };

  const handleChangeCallApproval = (e: $TSFixMe) => {
    const target = e.target;
    const value = target.value;
    setApprovedForCalls(value);
    handleChangeFirestoreDetails(e);
  };

  const handleChangeFirestoreDetails = (e: $TSFixMe) => {
    const target = e.target;
    const value = target.value;
    const fbjwt = localStorage.getItem('Authorization');

    return fetch(`${SERVER_URL}v1/users/${data.uid}/store-vals`, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({[target.name]: value}),
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const handleChangeColor = (color: $TSFixMe) => {
    fields.color = color.hex;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    updatedValues.color = color.hex;
    setFields({...fields});
    // @ts-ignore
    setUpdatedValues({...updatedValues});
  };

  const onChangeHandler = (e: $TSFixMe, editor: $TSFixMe, name: $TSFixMe) => {
    const description = editor.getData();
    fields[name] = description;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    updatedValues[name] = description;
    setFields({...fields});
    // @ts-ignore
    setUpdatedValues({...updatedValues});
  };

  const addCategoryToUser = (category: $TSFixMe) => {
    props
      .updateUserValues({
        categoryId: category.id,
      })
      .then(() => {
        setSnackbarOpen(true);
        setSnackbarMessage('Category successfully added to user');
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 3000);
      });
  };

  const setUserSeverity = (severity: $TSFixMe) => {
    props.updateUserValues({riskStatus: severity}).then(() => {
      setSnackbarOpen(true);
      setSnackbarMessage('User severity has been set');
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    });
  };

  useImperativeHandle(ref, () => ({
    saveProfile() {
      console.log('saveProfile()');
      props.updateUserValues(updatedValues).then(() => {
        setSnackbarOpen(true);
        setSnackbarMessage('User changes have been saved');
        setTimeout(() => {
          props.setMode('view');
          setSnackbarOpen(false);
        }, 1500);
      });
    },
  }));

  const handleCategoryDelete = (category: $TSFixMe) => {
    const rfp = {
      categoryId: category.id,
      userId: data.id,
    };
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/rfps/categories/unsubscribe`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(rfp),
    })
      .then((res) => res.json())
      .then((res) => {
        props.fetchData(`admin/user/${data.id}`).then((res2: $TSFixMe) => {
          props.setData(res2);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <Grid item xs={12} md={12} key={0}>
        <Card style={{margin: '10px', borderRadius: '10px'}}>
          <CardHeader subheader={'Profile Info'} />
          <Grid
            container
            justifyContent="center"
            style={{marginTop: 20, marginBottom: 20}}>
            <UserRiskStatusSelectionWidget
              riskStatus={fields.riskStatus}
              setUserSeverity={setUserSeverity}
            />
          </Grid>
          <Grid
            container
            justifyContent="center"
            style={{marginTop: 20, marginBottom: 20}}>
            <Grid item xs={6} md={6} key={1}>
              <List component="nav">
                <TextField
                  label="FirstName"
                  name="firstName"
                  value={fields.firstName}
                  type="text"
                  className={classes.input_form}
                  onChange={handleChange}
                />
                <TextField
                  label="LastName"
                  name="lastName"
                  value={fields.lastName}
                  type="text"
                  className={classes.input_form}
                  onChange={handleChange}
                />
                {data.userType !== 'ADMIN' && (
                  <TextField
                    label="Title"
                    name="title"
                    value={fields.title}
                    type="text"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                )}
                <TextField
                  label="Email"
                  name="email"
                  value={fields.email}
                  type="email"
                  className={classes.input_form}
                  onChange={handleChange}
                />
                <TextField
                  label="Phone"
                  name="phone"
                  value={fields.phone}
                  className={classes.input_form}
                  onChange={handleChange}
                />

                {data.userType === 'BIDDER' && (
                  <>
                    <TextField
                      label="Billing Email"
                      name="billingEmail"
                      value={fields.billingEmail}
                      type="email"
                      className={classes.input_form}
                      onChange={handleChange}
                    />
                    <TextField
                      label="Work Phone"
                      name="workPhone"
                      value={fields.workPhone}
                      type="number"
                      className={classes.input_form}
                      onChange={handleChange}
                    />
                  </>
                )}

                {data.userType === 'ADMIN' && (
                  <TextField
                    label="Slack ID"
                    name="slackId"
                    value={fields.slackId}
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                )}
                {data.userType !== 'ADMIN' && (
                  <>
                    {data.userType === 'BIDDER' && (
                      <TextField
                        label="Service Area"
                        name="contractorServiceArea"
                        value={fields.contractorServiceArea}
                        type="number"
                        inputProps={{step: '25000'}}
                        className={classes.input_form}
                        onChange={handleChange}
                      />
                    )}

                    <FormControl
                      component="fieldset"
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: -60,
                      }}>
                      <FormLabel style={{display: 'flex'}}>User Type</FormLabel>
                      <RadioGroup
                        aria-label="User type"
                        name="userType"
                        value={radioValue}
                        onChange={handleChange}
                        style={{flexDirection: 'initial'}}>
                        <FormControlLabel
                          value="REQUESTER"
                          control={<Radio />}
                          label="Homeowner "
                        />
                        <FormControlLabel
                          value="BIDDER"
                          control={<Radio />}
                          label="Contractor "
                        />
                      </RadioGroup>
                    </FormControl>

                    <br />

                    {data.userType === 'BIDDER' && (
                      <>
                        <FormControl
                          component="fieldset"
                          style={{
                            marginTop: 20,
                            marginBottom: 10,
                            marginLeft: -60,
                          }}>
                          <FormLabel style={{display: 'flex'}}>
                            Call Feature Enabled
                          </FormLabel>
                          <RadioGroup
                            aria-label="approved for calls"
                            name="phoneEnabled"
                            value={approvedForCalls}
                            onChange={handleChangeCallApproval}
                            style={{flexDirection: 'initial'}}>
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                        <FormControl
                          component="fieldset"
                          style={{marginTop: 20, marginLeft: 10}}>
                          <FormLabel style={{display: 'flex'}}>
                            Verification State
                          </FormLabel>
                          <RadioGroup
                            aria-label="user verification state"
                            name="userVerificationState"
                            value={userVerificationState}
                            onChange={handleChange}
                            style={{flexDirection: 'initial'}}>
                            <FormControlLabel
                              value="UNVERIFIED"
                              control={<Radio />}
                              label="UNVERIFIED"
                            />
                            <FormControlLabel
                              value="PENDING"
                              control={<Radio />}
                              label="PENDING"
                            />
                            <FormControlLabel
                              value="IDENTITY"
                              control={<Radio />}
                              label="IDENTITY"
                            />
                            <FormControlLabel
                              value="IDENTITY_CRIMINAL"
                              control={<Radio />}
                              label="IDENTITY + BACKGROUND"
                            />
                          </RadioGroup>
                        </FormControl>
                        <CategorySearchWidget addCategory={addCategoryToUser} />
                      </>
                    )}
                  </>
                )}
              </List>
            </Grid>
            <Grid item xs={6} md={6} key={3}>
              <List component="nav">
                <TextField
                  label="Street"
                  name="street"
                  value={fields.street}
                  type="text"
                  className={classes.input_form}
                  onChange={handleChange}
                />
                <TextField
                  label="Street Line 2"
                  name="street2"
                  value={fields.street2}
                  type="text"
                  className={classes.input_form}
                  onChange={handleChange}
                />
                <TextField
                  label="City"
                  name="city"
                  value={fields.city}
                  type="text"
                  className={classes.input_form}
                  onChange={handleChange}
                />
                <TextField
                  label="Postal"
                  name="postal"
                  value={fields.postal}
                  type="text"
                  className={classes.input_form}
                  onChange={handleChange}
                />
                <TextField
                  label="Province"
                  name="province"
                  value={fields.province}
                  type="text"
                  className={classes.input_form}
                  onChange={handleChange}
                />
                <TextField
                  label="Country"
                  name="country"
                  value={fields.country}
                  type="text"
                  className={classes.input_form}
                  onChange={handleChange}
                />
                {data.userType !== 'ADMIN' && (
                  <>
                    <TextField
                      label="Latitude"
                      name="lat"
                      value={fields.lat}
                      type="number"
                      className={classes.input_form}
                      onChange={handleChange}
                    />
                    <TextField
                      label="Longitude"
                      name="lng"
                      value={fields.lng}
                      type="number"
                      className={classes.input_form}
                      onChange={handleChange}
                    />
                    <TextField
                      label="How did you hear about us"
                      name="How did you hear about us"
                      value={fields.userSource}
                      type="text"
                      className={classes.input_form}
                      onChange={handleChange}
                      style={{width: '90%'}}
                    />
                    {fields.userType === 'BIDDER' && (
                      <>
                        <span>Custom Colour &nbsp;</span>
                        <InputColor
                          initialValue={fields.color || '#000000'}
                          onChange={handleChangeColor}
                          placement="left"
                        />
                      </>
                    )}
                  </>
                )}
              </List>
            </Grid>
            <Grid item xs={12} md={12} key={4}>
              <FormControl
                component="fieldset"
                style={{marginTop: 20, width: '97%'}}
                key={'staff-notes'}>
                <FormLabel style={{display: 'flex'}}>Staff Notes</FormLabel>
                <CKEditor
                  id={'staff-notes'}
                  editor={editorInstance}
                  data={fields.adminNote}
                  config={{toolbar: []}}
                  onChange={(event, editor) =>
                    onChangeHandler(event, editor, 'adminNote')
                  }
                />
              </FormControl>

              {data.userType !== 'ADMIN' && (
                <FormControl
                  component="fieldset"
                  style={{marginTop: 20, width: '97%'}}
                  key={'user-description'}>
                  <FormLabel style={{display: 'flex'}}>Description</FormLabel>
                  <CKEditor
                    id={'user-description'}
                    editor={editorInstance}
                    data={fields.description}
                    config={{toolbar: []}}
                    onChange={(event, editor) =>
                      onChangeHandler(event, editor, 'description')
                    }
                  />
                </FormControl>
              )}
              <LinkAccountForm
                userId={data.id}
                apigeeAppId={data.apigeeAppId}
                apiKey={data.apiKey}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {data.userType === 'BIDDER' && (
        <>
          {data.categories && (
            <Grid item xs={12} md={12} key={5}>
              <Card style={{margin: '10px', borderRadius: '10px'}}>
                <CardHeader subheader={'Categories'} />

                <Card
                  style={{
                    margin: '10px',
                    borderRadius: '10px',
                    paddingBottom: '10px',
                  }}>
                  <CardHeader subheader={'Categories'} />
                  <div>
                    <Grid container justifyContent={'center'} spacing={2}>
                      <>
                        {data.categories.map((category: $TSFixMe) => {
                          return (
                            <Grid item key={`category_${category.id}`}>
                              <Chip
                                label={category.name}
                                onDelete={() => handleCategoryDelete(category)}
                                size={'small'}
                                color={'primary'}
                              />
                            </Grid>
                          );
                        })}
                      </>
                    </Grid>
                  </div>
                </Card>
              </Card>
            </Grid>
          )}
          <Grid
            container
            justifyContent="center"
            style={{marginTop: 20, marginBottom: 20}}>
            <Grid item xs={6} md={6} key={4}>
              <Card style={{margin: '10px', borderRadius: '10px'}}>
                <CardHeader subheader={'Business Info'} />
                <List component="nav">
                  <TextField
                    label="Company Name"
                    name="companyName"
                    value={fields.companyName}
                    type="text"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                  <TextField
                    label="HST No"
                    name="taxNo"
                    value={fields.taxNo}
                    type="text"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Operating As"
                    name="alias"
                    value={fields.alias}
                    type="text"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Email"
                    name="workEmail"
                    value={fields.workEmail}
                    type="email"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Phone"
                    name="workPhone"
                    value={fields.workPhone}
                    type="number"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Domain"
                    name="domain"
                    value={fields.domain}
                    type="text"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                </List>
              </Card>
            </Grid>
            <Grid item xs={6} md={6} key={5}>
              <Card style={{margin: '10px', borderRadius: '10px'}}>
                <CardHeader subheader={'Business Address'} />
                <List component="nav">
                  <TextField
                    label="Street"
                    name="workStreet"
                    value={fields.workStreet}
                    type="text"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Street Line 2"
                    name="workStreet2"
                    value={fields.workStreet2}
                    type="text"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                  <TextField
                    label="City"
                    name="workCity"
                    value={fields.workCity}
                    type="text"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Postal"
                    name="workPostal"
                    value={fields.workPostal}
                    type="text"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Province"
                    name="workProvince"
                    value={fields.workProvince}
                    type="text"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Country"
                    name="workCountry"
                    value={fields.workCountry}
                    type="text"
                    className={classes.input_form}
                    onChange={handleChange}
                  />
                </List>
              </Card>
            </Grid>
            {welcomeKitDetails && (
              <Grid item xs={6} key={6}>
                <Card
                  style={{
                    margin: '10px',
                    borderRadius: '10px',
                    paddingBottom: '10px',
                  }}>
                  <CardHeader subheader={'Welcome Kit Details'} />
                  <List component="nav">
                    <TextField
                      label="T-shirt Size"
                      name="shirtSize"
                      defaultValue={welcomeKitDetails.shirtSize}
                      type="text"
                      className={classes.input_form}
                      onChange={handleChangeFirestoreDetails}
                    />
                    <TextField
                      label="First Name"
                      name="firstName"
                      defaultValue={welcomeKitDetails.firstName}
                      type="text"
                      className={classes.input_form}
                      onChange={handleChangeFirestoreDetails}
                    />
                    <TextField
                      label="Last Name"
                      name="lastName"
                      defaultValue={welcomeKitDetails.lastName}
                      type="text"
                      className={classes.input_form}
                      onChange={handleChangeFirestoreDetails}
                    />
                    <TextField
                      label="Email"
                      name="email"
                      defaultValue={welcomeKitDetails.email}
                      type="text"
                      className={classes.input_form}
                      onChange={handleChangeFirestoreDetails}
                    />
                    <TextField
                      label="Phone"
                      name="phone"
                      defaultValue={welcomeKitDetails.phone}
                      type="text"
                      className={classes.input_form}
                      onChange={handleChangeFirestoreDetails}
                    />
                    <TextField
                      label="Company Name"
                      name="companyName"
                      defaultValue={welcomeKitDetails.companyName}
                      type="text"
                      className={classes.input_form}
                      onChange={handleChangeFirestoreDetails}
                    />
                    <TextField
                      label="Job Title"
                      name="title"
                      defaultValue={welcomeKitDetails.title}
                      type="text"
                      className={classes.input_form}
                      onChange={handleChangeFirestoreDetails}
                    />
                    <TextField
                      label="Logo Choice"
                      name="logoChoice"
                      defaultValue={welcomeKitDetails.logoChoice}
                      type="text"
                      className={classes.input_form}
                      onChange={handleChangeFirestoreDetails}
                    />
                  </List>
                </Card>
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={4000}>
        <Alert>{snackbarMessage}</Alert>
      </Snackbar>
    </div>
  );
});

export default EditableProfile;
