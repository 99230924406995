import React, {useState, useEffect} from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';

export default function useCropper(props: $TSFixMe) {
  const [image, setImage] = useState(null);
  const [, setCropData] = useState('#');
  const [cropper, setCropper] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(3 / 2);

  const getCropData = function () {
    if (typeof cropper !== 'undefined' && cropper !== null) {
      let cropperData = null;
      try {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        cropperData = cropper.getCroppedCanvas().toDataURL('image/jpeg');
        setCropData(cropperData);
      } catch (err) {
        console.warn(err);
      }
      return cropperData;
    }
    return null;
  };

  useEffect(() => {
    if (props.file) {
      const reader = new FileReader();
      reader.onload = function () {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | ArrayBuffer | null' is ... Remove this comment to see the full error message
        setImage(reader.result);
      };
      try {
        reader.readAsDataURL(props.file);
      } catch (e) {
        props.closeDialog();
      }
    }
    if (props.image) {
      setImage(props.image.src);
    }
    if (props.base64) {
      setImage(props.base64);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, props.file, props.image, props.base64, props.closeDialog]);

  useEffect(() => {
    setAspectRatio(props.aspectRatio);
  }, [props.open, props.aspectRatio]);

  const rotateImage = () => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    cropper.rotate(-90);
  };

  const cropperDialog = (
    <Dialog open={props.open} fullWidth maxWidth="sm">
      <IconButton
        color="primary"
        style={{marginLeft: 'auto'}}
        onClick={rotateImage}>
        <Rotate90DegreesCcwIcon />
      </IconButton>

      <div style={{width: '100%'}}>
        <Cropper
          style={{height: 400, width: '100%'}}
          zoomTo={-0.1}
          initialAspectRatio={aspectRatio}
          preview=".img-preview"
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
          src={image}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'true' is not assignable to type '"" | "anony... Remove this comment to see the full error message
          crossOrigin={true}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Cropper' is not assignable to pa... Remove this comment to see the full error message
            setCropper(instance);
          }}
        />
      </div>

      <div
        style={{
          padding: 16,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Button className="secondary-button" onClick={props.closeDialog}>
          Close
        </Button>
        <Button className="primary-button" onClick={props.handleCropImage}>
          Save
        </Button>
      </div>
    </Dialog>
  );

  return [getCropData, cropperDialog];
}
