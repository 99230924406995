import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import Container from 'react-bootstrap/Container';
import {SERVER_URL} from '../../../constants';
import AddReviewDialog from './AddReviewDialog';

function ReviewsTab(props: $TSFixMe) {
  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(0);
  const [addReviewDialogOpen, setAddReviewDialogOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(undefined);

  const getReviewsDetails = () => {
    const fullUrl = `${SERVER_URL}v1/users/${props.user.id}/reviews?&page=${page}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(fullUrl, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setReviews(res.reviews);
        if (res?._embedded) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
          setReviews([...reviews, ...res._embedded?.models]);
        }
        if (res?.page && page < res.page.totalPages - 1) {
          setPage((currentPage) => currentPage + 1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getReviewsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleAddReviewClick = (reviewToEdit: $TSFixMe) => {
    setSelectedReview(reviewToEdit);
    setAddReviewDialogOpen(true);
  };

  const columns = [
    {
      title: 'Project title',
      field: 'title',
      render: (rowData: $TSFixMe) => {
        if (rowData.title === null) {
          return 'N/A';
        } else {
          return rowData.title;
        }
      },
    },
    {
      title: 'Reviewed By',
      field: ' reviewer.name',
      render: (rowData: $TSFixMe) => {
        if (rowData.reviewer?.name === null) {
          return 'N/A';
        } else {
          return rowData.reviewer?.name;
        }
      },
    },
    {
      title: 'Type',
      field: 'reviewType',
      render: (rowData: $TSFixMe) => {
        if (rowData.reviewType === null) {
          return 'N/A';
        } else {
          return rowData.reviewType;
        }
      },
    },
    {
      title: 'Rating',
      field: 'rating',
      render: (rowData: $TSFixMe) => {
        if (rowData.rating === null) {
          return 'N/A';
        } else {
          return `${((rowData.rating / 1) * 100).toFixed(0)}%`;
        }
      },
    },
  ];

  const getActionsList = () => {
    console.log(props);
    if (props.user.userType !== 'BIDDER') {
      console.log(props);
      return [];
    }
    return [
      {
        icon: 'add',
        tooltip: 'Add Review',
        isFreeAction: true,
        onClick: (review: $TSFixMe) => handleAddReviewClick(review),
      },
    ];
  };

  const closeAddReviewDialog = () => {
    setReviews([]);
    setAddReviewDialogOpen(false);
    setPage(0);
  };

  return (
    <div>
      <MaterialTable
        columns={columns}
        title={'Reviews'}
        data={reviews}
        actions={getActionsList()}
        options={{
          toolbar: true,
          pageSize: 10,
          actionsColumnIndex: -1,
        }}
        detailPanel={(rowData) => {
          return (
            <Container style={{padding: 60}}>
              {/* <QuoteDetail quote={rowData} dataFetcher={getReviewsDetails} /> */}
            </Container>
          );
        }}
      />

      <AddReviewDialog
        user={props.user}
        selectedReview={selectedReview}
        open={addReviewDialogOpen}
        onClose={closeAddReviewDialog}
      />
    </div>
  );
}

export default ReviewsTab;
