import {withStyles} from '@material-ui/core/styles';
import {red} from '@material-ui/core/colors';
import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import {Box, Button, FormControlLabel, Switch} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import '../myRequestStyling.css';
import RfpTabs from './RfpTabs';
import {SERVER_URL} from '../../../constants';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {sortById} from '../../../util/SortingHelpers';
import moment from 'moment';
import RequestForProposalRiskStatusWidget from '../components/RequestForProposalRiskStatusWidget';
import {getBrandColorForState} from '../../../util/BrandUtils';
import ConfirmCoverPhotoDialog from '../components/ConfirmCoverPhotoDialog';
import {startsWithNumber} from '@bidmii/common/lib/util/NumberUtils';

const styles = (theme: $TSFixMe) => ({
  root: {
    maxWidth: '100vw',
    textAlign: 'left',
    borderRadius: '1rem',
    height: '100%',
    width: '100%',
  },

  media: {
    height: 300,
    width: '100 %',
    paddingTop: '56.25%', // 16:9
  },

  avatar: {
    backgroundColor: red[500],
  },

  actions: {
    flex: 'initial',
  },

  question: {
    marginLeft: 'auto',
  },

  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
});

function SnackAlert(props: $TSFixMe) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type MyRequestForProposalLargeCardState = $TSFixMe;

class MyRequestForProposalLargeCard extends Component<
  $TSFixMe,
  MyRequestForProposalLargeCardState
> {
  constructor(props: $TSFixMe) {
    super(props);

    this.state = {
      coverImage: 'https://via.placeholder.com/350x150',
      tabIndex: 0,
      popoverAnchorEl: null,
      popoverOpen: false,
      messageLoading: false,
      user: false,
      confirmCoverImageDialogOpen: false,
    };
  }

  setProfileName = () => {
    const profileName = (this.props as $TSFixMe).requestForProposal
      .requesterName;
    const nameParts = profileName.split(' ');
    let firstName = null;
    let lastName = null;
    if (nameParts.length > 0) {
      firstName = profileName.split(' ')[0];
    }
    if (nameParts.length > 1) {
      lastName = profileName.split(' ')[1];
    }
    const displayName = `${firstName} ${lastName.charAt(0)}`;
    this.setState({profileName: displayName});
  };

  getTitle = () => {
    if (
      (this.props as $TSFixMe).requestForProposal.referenceLabel &&
      !startsWithNumber((this.props as $TSFixMe).requestForProposal.title)
    ) {
      return `Work Order ${(this.props as $TSFixMe).requestForProposal.referenceLabel} ${(this.props as $TSFixMe).requestForProposal.title}`;
    } else if ((this.props as $TSFixMe).requestForProposal.referenceLabel) {
      return `Work Order ${(this.props as $TSFixMe).requestForProposal.title}`;
    }
    return (this.props as $TSFixMe).requestForProposal.title;
  };

  getHeaderBlock = () => {
    return (
      <Box mb={1}>
        <Typography variant="h4" style={{marginRight: 8}}>
          {this.getTitle()}
        </Typography>

        <RequestForProposalRiskStatusWidget
          riskStatus={(this.props as $TSFixMe).requestForProposal?.riskStatus}
        />
        <Chip
          style={{
            backgroundColor: getBrandColorForState(
              (this.props as $TSFixMe).requestForProposal.state,
            ),
          }}
          label={
            (this.props as $TSFixMe).requestForProposal.type === 'BIDMII_DIRECT'
              ? 'DIRECT'
              : (this.props as $TSFixMe).requestForProposal.state
          }
          color={'primary'}
        />

        <Typography
          component="span"
          variant={'subtitle2'}
          style={{marginLeft: 8, marginRight: 16}}>
          CREATED:{' '}
          {moment(
            (this.props as $TSFixMe).requestForProposal.createdDate,
          ).format('MMMM Do')}
        </Typography>
        <Typography component="span" variant={'subtitle2'}>
          COMPLETE BY:{' '}
          {moment(
            (this.props as $TSFixMe).requestForProposal.deadlineDate,
          ).format('MMMM Do')}
        </Typography>
      </Box>
    );
  };

  handleOnClick = () => {
    (this.props as $TSFixMe).history.push(
      `/users/${(this.props as $TSFixMe)?.requestForProposal?.user?.id}`,
    );
  };

  handleCategoryDelete = (category: $TSFixMe) => {
    const rfp = {
      categoryId: category.id,
      requestForProposalId: (this.props as $TSFixMe).requestForProposal.id,
    };
    const fbjwt = localStorage.getItem('Authorization');
    //TODO: implement
    fetch(`${SERVER_URL}v1/rfps/categories/unsubscribe`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(rfp),
    })
      .then((res) => res.json())
      .then((res) => {
        (this.props as $TSFixMe).updateRequestForProposalState();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleTagDelete = (tag: $TSFixMe) => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/rfps/tags/${tag.id}`, {
      method: 'DELETE',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        (this.props as $TSFixMe).updateRequestForProposalState();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  sendRealTimeCategoryNotifications = () => {
    const that = this;
    const rfp = {
      requestForProposalId: (this.props as $TSFixMe).requestForProposal.id,
    };
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/rfps/categories/notify`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(rfp),
    })
      .then((res) => res.json())
      .then((res) => {
        setTimeout(function () {
          that.setState(
            {
              confirmCoverImageDialogOpen: false,
              snackBarOpen: true,
              message: 'Notifications sent',
            },
            () => {
              (that.props as $TSFixMe).updateRequestForProposalState();
            },
          );
        }, 1500);

        setTimeout(function () {
          that.setState({snackBarOpen: false});
        }, 5500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  confirmNotifySubscribers = () => {
    const that = this;
    const rfp = {
      requestForProposalId: (this.props as $TSFixMe).requestForProposal.id,
    };
    const fbjwt = localStorage.getItem('Authorization');

    fetch(`${SERVER_URL}v1/rfps/categories/schedule-notification`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(rfp),
    })
      .then(() => {
        that.sendRealTimeCategoryNotifications();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  notifySubscribers = () => {
    if (
      (this.props as $TSFixMe).requestForProposal.lat === null ||
      (this.props as $TSFixMe).requestForProposal.lng === null
    ) {
      // eslint-disable-next-line no-alert
      alert(
        'This project has no coordinates, we can not notify local contractors',
      );
      return;
    }

    const coverImages = (
      this.props as $TSFixMe
    ).requestForProposal.attachmentReferences.filter(
      (ref: $TSFixMe) => ref.key === 'before_image' && ref.type === 'cover',
    );

    if (!coverImages || coverImages.length === 0) {
      // eslint-disable-next-line no-alert
      alert('Please specify a cover image');
      return;
    }

    this.setState({confirmCoverImageDialogOpen: true});
  };

  toggleIsFeaturedProject = () => {
    const rfp = {
      isFeaturedProject: !(this.props as $TSFixMe).requestForProposal
        .featuredProject,
    };

    const _updateRequestForProposalState = (this.props as $TSFixMe)
      .updateRequestForProposalState;

    const fbjwt = localStorage.getItem('Authorization');
    fetch(
      `${SERVER_URL}v1/rfps/${(this.props as $TSFixMe).requestForProposal.id}`,
      {
        method: 'PATCH',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt,
        },
        body: JSON.stringify(rfp),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        _updateRequestForProposalState(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  createTagChip = (tag: $TSFixMe) => {
    return (
      <Grid item key={tag.name}>
        <Chip
          label={tag.name}
          onDelete={
            (this.props as $TSFixMe).mode === 'edit'
              ? () => this.handleTagDelete(tag)
              : () => {}
          }
          size={'small'}
          color={'primary'}
        />
      </Grid>
    );
  };

  createCategoryChip = (category: $TSFixMe) => {
    return (
      <Grid item key={`category_${category.id}`}>
        <Chip
          label={category.name}
          onDelete={
            (this.props as $TSFixMe).mode === 'edit'
              ? () => this.handleCategoryDelete(category)
              : undefined
          }
          size={'small'}
          color={'primary'}
        />
      </Grid>
    );
  };

  getSubTitle = () => {
    return (
      <>
        <Grid container direction="column" spacing={1} style={{marginLeft: 8}}>
          <Grid item>
            <Typography
              component={'span'}
              variant={'body2'}
              style={{marginBottom: 6}}>
              CALLS:{' '}
              {(this.props as $TSFixMe).requestForProposal.approvedForCalls
                ? 'Approved'
                : 'Disabled'}
            </Typography>

            <Typography
              component={'span'}
              variant={'body2'}
              style={{marginBottom: 6, marginLeft: 25}}>
              ASSIGNED TO:{' '}
              {(this.props as $TSFixMe).requestForProposal.assignedTo.name
                ? (this.props as $TSFixMe).requestForProposal.assignedTo.name
                : 'Unassigned'}
            </Typography>

            <Typography
              component={'span'}
              variant={'body2'}
              style={{marginBottom: 6, marginLeft: 25}}>
              Project Size:{' '}
              {(this.props as $TSFixMe).requestForProposal.size
                ? (this.props as $TSFixMe).requestForProposal.size
                : 'Not Set'}
            </Typography>

            {!(this.props as $TSFixMe).requestForProposal.buyNowPrice ? (
              <Typography
                component={'span'}
                variant={'body2'}
                style={{marginBottom: 6, marginLeft: 25}}>
                Unlock Price:{' '}
                {(this.props as $TSFixMe).requestForProposal.unlockPrice
                  ? `$${(this.props as $TSFixMe).requestForProposal.unlockPrice}`
                  : 'Not Set'}
              </Typography>
            ) : (
              <Typography
                component={'span'}
                variant={'body2'}
                style={{marginBottom: 6, marginLeft: 25}}>
                Property Manager Price:{' $'}
                {(this.props as $TSFixMe).requestForProposal.buyNowPrice}
              </Typography>
            )}
          </Grid>

          {(this.props as $TSFixMe).requestForProposal.tags.length > 0 && (
            <Grid container spacing={1} style={{marginBottom: 6}}>
              <Grid item>
                <Typography component={'span'} variant={'body2'}>
                  TAGS:
                </Typography>
              </Grid>
              {(this.props as $TSFixMe).requestForProposal.tags
                .sort(sortById)
                .map(this.createTagChip)}
            </Grid>
          )}
          {(this.props as $TSFixMe).requestForProposal.categories.length >
            0 && (
            <Grid container spacing={1} style={{marginBottom: 6}}>
              <Grid item>
                <Typography component={'span'} variant={'body2'}>
                  CATEGORIES:
                </Typography>
              </Grid>
              {(this.props as $TSFixMe).requestForProposal.categories
                .sort(sortById)
                .map(this.createCategoryChip)}
              <Grid item key={'send_button'}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.notifySubscribers}
                  endIcon={<Icon>send</Icon>}
                  size="small">
                  Send
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1}>
            {(this.props as $TSFixMe).requestForProposal.state ===
              'COMPLETE' && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        (this.props as $TSFixMe).requestForProposal
                          .featuredProject
                      }
                      onChange={this.toggleIsFeaturedProject}
                    />
                  }
                  label="Featured"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  getAction = () => {
    const {classes} = this.props;
    return (
      <Grid container spacing={1} style={{marginTop: 15, marginRight: 10}}>
        <Grid item key={'requester_name'}>
          <Typography style={{marginTop: 7}}>
            {(this.props as $TSFixMe).requestForProposal.requesterName}
          </Typography>
        </Grid>
        <Grid item key={'avatar'}>
          <Button onClick={() => this.handleOnClick()}>
            <Avatar
              alt={(this.props as $TSFixMe)?.requestForProposal?.user.firstName}
              src={
                (this.props as $TSFixMe)?.requestForProposal?.user
                  ?.imgAttachmentRef?.url
              }
              className={classes.large}
            />
          </Button>
        </Grid>
      </Grid>
    );
  };

  componentDidMount() {
    this.setProfileName();
  }

  render() {
    const {classes} = this.props;

    return (
      <div>
        <Grid
          container
          justifyContent="center"
          style={{marginTop: 20, marginBottom: 20}}>
          <Card className={classes.root} variant={'outlined'}>
            <CardHeader
              action={this.getAction()}
              title={this.getHeaderBlock()}
              subheader={this.getSubTitle()}
            />
            <RfpTabs
              requestForProposal={(this.props as $TSFixMe).requestForProposal}
              mode={(this.props as $TSFixMe).mode}
              updateRequestForProposalState={
                (this.props as $TSFixMe).updateRequestForProposalState
              }
              user={(this.props as $TSFixMe).requestForProposal.user}
              transactionData={(this.props as $TSFixMe).transactionData}
            />
            <Snackbar
              open={this.state.snackBarOpen}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              autoHideDuration={4000}>
              <SnackAlert severity={this.state.severity ? 'error' : 'success'}>
                {this.state.message}
              </SnackAlert>
            </Snackbar>
          </Card>
        </Grid>

        <ConfirmCoverPhotoDialog
          requestForProposal={(this.props as $TSFixMe).requestForProposal}
          open={this.state.confirmCoverImageDialogOpen}
          handleClose={() =>
            this.setState({confirmCoverImageDialogOpen: false})
          }
          confirm={this.confirmNotifySubscribers}
        />
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { m... Remove this comment to see the full error message
export default withStyles(styles)(MyRequestForProposalLargeCard);
