import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const sortingMenuMapping = [
  {
    column: 'id',
    buttonText: 'Id',
  },
  {
    column: 'createdDate',
    buttonText: 'Created Date',
  },
  {
    column: 'lastModifiedDate',
    buttonText: 'Last Modified Date',
  },
  {
    column: 'city',
    buttonText: 'City',
  },
  {
    column: 'deadlineDate',
    buttonText: 'Deadline Date',
  },
];

export default function SortByToggleButton({onMenuClick}: $TSFixMe) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleMenuItemClick = (event: $TSFixMe, index: $TSFixMe) => {
    onMenuClick(sortingMenuMapping[index].column);
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: $TSFixMe) => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <span style={{marginRight: 8}}>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
        style={{height: '100%'}}>
        {/* <label>Sort By</label> */}
        <label
          style={{
            width: 64,
            paddingTop: 9,
            color: 'black',
          }}>
          Sort By
        </label>
        <Button
          onClick={handleToggle}
          style={{width: 180, alignItems: 'left', justifyContent: 'left'}}>
          {sortingMenuMapping[selectedIndex].buttonText}
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'right top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {sortingMenuMapping.map((option, index) => (
                    <MenuItem
                      key={option.column}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}>
                      {option.buttonText}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </span>
  );
}
