import React from 'react';
import Button from '@material-ui/core/Button';
import {SERVER_URL} from '../../../constants';

export default function ResendDirectQuoteWidget(props: $TSFixMe) {
  const [sending, setSending] = React.useState(false);

  const handleClickResend = () => {
    setSending(true);
    const url = `${SERVER_URL}v1/proposals/${props.proposalUid}/resend-notification`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then((response) => response.json())
      .then((responseData) => {
        setSending(false);
      })
      .catch((err) => {
        console.error(err);
        setSending(false);
      });
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClickResend}
        disabled={sending}
        style={{margin: 10}}>
        RESEND
      </Button>
    </div>
  );
}
