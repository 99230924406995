import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import MaterialTable, {Action} from 'material-table';
import {dateAsWords} from '../../../util/DateAsWords';
import Container from 'react-bootstrap/Container';
import QuoteDetail from './QuoteDetail';
import {SERVER_URL} from '../../../constants';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface Quote {
  name: string;
  surname: string;
  birthYear: number;
  birthCity: number; // Using a number for the birthCity key
  requestForProposalTitle: string;
  createdDate: string;
  amount: number;
  labourAmount: number;
  materialsAmount: number;
  contingencyAmount: number;
}

function QuotesTab(props: $TSFixMe) {
  const [quotes, setQuotes] = useState([]);
  const history = useHistory();
  const [page, setPage] = useState(0);

  const getQuoteDetails = () => {
    console.log(props);
    const fullUrl = `${SERVER_URL}v1/users/${props.user.id}/proposals?&page=${page}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(fullUrl, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res?._embedded) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
          setQuotes([...quotes, ...res._embedded?.models]);
        }
        if (res?.page && page < res.page.totalPages - 1) {
          setPage((currentPage) => currentPage + 1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getQuoteDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const columns = [
    {title: 'Title', field: 'requestForProposalTitle'},
    {
      title: 'Created',
      field: 'createdDate',
      render: (rowData: $TSFixMe) => {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        return dateAsWords(rowData.createdDate);
      },
      customSort: (a: $TSFixMe, b: $TSFixMe) =>
        // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
        new Date(a.createdDate) - new Date(b.createdDate),
    },
    {title: 'Amount', field: 'amount'},
    {title: 'Labour Amount', field: 'labourAmount'},
    {title: 'Materials Amount', field: 'materialsAmount'},
    {title: 'Contingency', field: 'contingencyAmount'},
  ];

  const handleOnClick = (rowData: $TSFixMe) => {
    history.push(`/myRfps/${rowData.rfpId}`);
  };

  const actions: Action<Quote>[] = [
    // @ts-ignore
    (rowData: Quote) => ({
      icon: OpenInNewIcon,
      // @ts-ignore
      onClick: (event, rows) => {
        handleOnClick(rowData);
      },
    }),
  ];

  return (
    <div>
      <MaterialTable
        columns={columns}
        title={'Quotes'}
        data={quotes}
        actions={actions}
        options={{
          toolbar: true,
          pageSize: 10,
          actionsColumnIndex: -1,
          exportButton: true,
        }}
        detailPanel={(rowData) => {
          return (
            <Container style={{padding: 60}}>
              <QuoteDetail quote={rowData} dataFetcher={getQuoteDetails} />
            </Container>
          );
        }}
        onRowClick={(event, rowData, togglePanel) =>
          togglePanel && togglePanel()
        }
      />
    </div>
  );
}

export default QuotesTab;
