import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';
// import HomeownerAvatar from '../../util/HomeownerAvatar';
// import ContractorAvatar from '../../util/ContractorAvatar';
import {dateAsWords} from '../../util/DateAsWords';
// import { currencyFormat } from '../../util/CurrencyFormatter';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 450,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function ActivityDetails(props: $TSFixMe) {
  const classes = useStyles();

  const getAvatar = () => {
    if (props.activity.user.userType === 'REQUESTER') {
      return <div>Activity</div>;
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={getAvatar()}
        title={props.activity.user.name}
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        subheader={dateAsWords(props.activity.date)}
      />
      <CardContent>
        <Typography variant="body1" style={{fontWeight: 600, color: '#000000'}}>
          Email:{' '}
          <Typography variant="body2" color="textSecondary" component="span">
            {props.activity.user.email}
          </Typography>
        </Typography>
        <br />
        <Typography variant="body1" style={{fontWeight: 600, color: '#000000'}}>
          Phone:{' '}
          <Typography variant="body2" color="textSecondary" component="span">
            {props.activity.user.phone}
          </Typography>
        </Typography>
      </CardContent>
    </Card>
  );
}
