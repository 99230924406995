export const getBrandColorForState = (state: string) => {
  switch (state.toUpperCase()) {
    case 'DRAFT':
      return '#FFA0F7';
    case 'REQUESTED':
      return '#22ABF7';
    case 'OPEN':
      return '#22ABF7';
    case 'FUNDED':
      return '#906FCE';
    case 'COMPLETE':
      return '#228848';
    case 'CANCELLED':
      return '#F93822';
    default:
      return 'initial';
  }
};
