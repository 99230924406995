import React from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  question_item: {
    padding: 10,
    backgroundColor: '#ddd',
    borderRadius: 10,
    textAlign: 'left',
  },
}));

function QuestionsTab(props: $TSFixMe) {
  const classes = useStyles();
  const questions = props.questions;

  return (
    <div>
      {questions.map((question: $TSFixMe, index: $TSFixMe) => (
        <div key={index} className={classes.question_item}>
          <Typography gutterBottom variant="h5" component="p">
            Question: {question.question}
          </Typography>
          <Typography gutterBottom variant="body2" component="p">
            Answer: {question.answer}
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default QuestionsTab;
