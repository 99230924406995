import React, {useState, useEffect, useCallback} from 'react';
import {SERVER_URL} from '../../constants';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {dateAsWords} from '../../util/DateAsWords';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditCategoryDialog from './components/EditCategoryDialog';

const CustomToolbar = (props: $TSFixMe) => {
  return (
    <GridToolbarContainer style={{paddingTop: 25}}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <GridToolbarQuickFilter style={{position: 'fixed', right: 50}} />
    </GridToolbarContainer>
  );
};

const Categories = (props: $TSFixMe) => {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [categorySelectedForEdit, setCategorySelectedForEdit] = useState(null);

  const apiRef = useGridApiRef();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize, props.filter]);

  const getData = useCallback(() => {
    fetch(
      `${SERVER_URL}v1/categories?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setRowCount(res.page.totalElements);
        setRows(res._embedded?.models || []);
        setLoading(false);
      });
  }, [paginationModel.page, paginationModel.pageSize]);

  const handleDeleteCategory = (categoryId: $TSFixMe) => {
    const url = `${SERVER_URL}v1/categories/${categoryId}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'DELETE',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        getData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleUpdateCategory = (
    categoryId: $TSFixMe,
    categoryName: string,
    notEligibleForInsuranceCoverage: boolean,
  ) => {
    const url = `${SERVER_URL}v1/categories/${categoryId}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: categoryName,
        excludedFromInsuranceCoverage: notEligibleForInsuranceCoverage,
      }),
    })
      .then(() => {
        setCategorySelectedForEdit(null);
        getData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns: GridColDef[] = [
    {headerName: 'ID', field: 'id', type: 'number'},
    {
      headerName: 'Name',
      field: 'name',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row?.name?.toUpperCase() || 'N/A',
      width: 250,
    },
    {
      headerName: 'Created',
      field: 'createdDate',
      type: 'dateTime',
      valueFormatter: (value: $TSFixMe) =>
        dateAsWords(value, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      width: 120,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => {
            setCategorySelectedForEdit(params.row);
          }}
          label="Edit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => {
            handleDeleteCategory(params.id);
          }}
          label="Delete"
        />,
      ],
      width: 200,
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 30,
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          getRowHeight={() => 'auto'}
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                createdDate: false,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 500},
              getData: getData,
            },
          }}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[50, 100, 150, 200, 300, 400, 500, 750, 1000]}
          pagination
          paginationMode={'server'}
          loading={loading}
        />
      </div>
      {categorySelectedForEdit && (
        <EditCategoryDialog
          open={true}
          handleClose={() => setCategorySelectedForEdit(null)}
          updateCategory={handleUpdateCategory}
          category={categorySelectedForEdit}
        />
      )}
    </div>
  );
};

export default Categories;
