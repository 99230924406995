import React from 'react';
import {SERVER_URL} from '../../constants';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const CreateServiceAreaDialog = (props: $TSFixMe) => {
  const handleSaveServiceArea = () => {
    const url = `${SERVER_URL}v1/service-areas`;
    const fbjwt = localStorage.getItem('Authorization');
    const serviceAreaFormEl = document.getElementById('serviceAreaForm');
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
    const data = new FormData(serviceAreaFormEl);
    const body = {};
    data.forEach(function (value, key) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      body[key] = value;
    });
    return fetch(url, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((res) => {
        props.fetchData();
        props.onClose(false);
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="Create Service Area">
      <DialogTitle id="form-dialog-title">Create Service Area</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Make sure you have co-ordinated with the appropriate support staff
          before opening a service area.
        </DialogContentText>
        <form id={'serviceAreaForm'}>
          <TextField
            autoFocus
            margin="dense"
            id="city"
            name="city"
            label="City"
            type="text"
            placeholder={'City'}
            fullWidth
          />
          <TextField
            margin="dense"
            id="region"
            name="region"
            label="Region"
            type="text"
            placeholder={'Region'}
            fullWidth
          />
          <TextField
            margin="dense"
            id="province"
            name="province_state"
            label="Province"
            type="text"
            placeholder={'Province'}
            fullWidth
          />
          <TextField
            margin="dense"
            id="country"
            name="country"
            label="Country"
            type="text"
            placeholder={'Country'}
            fullWidth
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSaveServiceArea} color="secondary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateServiceAreaDialog;
