import React, {useState} from 'react';
import {SERVER_URL} from '../../constants';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Button} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const AccountingTable = (props: $TSFixMe) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [items, setItems] = useState([]);
  const [reportType, setReportType] = useState('payouts');

  const fetchData = () => {
    if (startDate > endDate) {
      // eslint-disable-next-line no-alert
      alert('Start date cannot be larger than end date');
      return;
    }
    setItems([]);
    const url = `${SERVER_URL}v1/transactions/report?startDate=${startDate}&endDate=${endDate}&type=${reportType}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.text())
      .then((res) => {
        const file = new File([res], 'report.csv', {type: 'text/csv'});
        const downloadUrl = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = 'report.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
      });
  };

  const handleStartDateChange = (e: $TSFixMe) => {
    setStartDate(e);
  };

  const startDateSelector = () => {
    return (
      <DatePicker
        style={{margin: 20}}
        value={startDate}
        label="Start Date"
        // @ts-expect-error ts-migrate(2322) FIXME: Type '"week"' is not assignable to type 'DatePicke... Remove this comment to see the full error message
        views={['year', 'month', 'week', 'day']}
        onChange={(e) => handleStartDateChange(e)}
      />
    );
  };

  const handleEndDateChange = (e: $TSFixMe) => {
    setEndDate(e);
  };

  const endDateSelector = () => {
    return (
      <DatePicker
        style={{margin: 20}}
        value={endDate}
        label="End Date"
        // @ts-expect-error ts-migrate(2322) FIXME: Type '"week"' is not assignable to type 'DatePicke... Remove this comment to see the full error message
        views={['year', 'month', 'week', 'day']}
        onChange={(e) => handleEndDateChange(e)}
      />
    );
  };

  const getDataButton = () => {
    return (
      <>
        <FormControl style={{margin: 20}}>
          <InputLabel id="report-type">Type</InputLabel>
          <Select
            labelId="report-type"
            value={reportType}
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            onChange={(e) => setReportType(e.target.value)}>
            <MenuItem value={'payouts'}>Balance Sheet</MenuItem>
            <MenuItem value={'p_and_l'}>P&L</MenuItem>
            <MenuItem value={'account_balances'}>Account Balances</MenuItem>
          </Select>
        </FormControl>
        <Button
          onClick={fetchData}
          style={{margin: 20}}
          variant="contained"
          color="primary">
          Get Data
        </Button>
      </>
    );
  };

  return (
    <div style={{paddingTop: 30}}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; utils: typeof DateFns... Remove this comment to see the full error message */}
      <MuiPickersUtilsProvider utils={DateFnsUtils} style={{display: 'flex'}}>
        {startDateSelector()}
        {endDateSelector()}
        {getDataButton()}
        <Grid container spacing={1}>
          {items}
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default AccountingTable;
