import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function EditCategoryDialog(props: $TSFixMe) {
  const [categoryName, setCategoryName] = useState(props.category.name);
  const [notEligibleForInsuranceCoverage, setNotEligibleForInsuranceCoverage] =
    useState(props.category.excludedFromInsuranceCoverage);

  const handleUpdateCategory = () =>
    props.updateCategory(
      props.category.id,
      categoryName,
      notEligibleForInsuranceCoverage,
    );

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle id="form-dialog-title">Update Category</DialogTitle>
        <DialogContent>
          <TextField
            placeholder={'Category Name'}
            defaultValue={categoryName}
            onChange={(event) => setCategoryName(event.target.value)}
            fullWidth={true}
            style={{marginTop: 15}}
          />
          <FormGroup>
            <FormControlLabel
              onChange={(event, checked) =>
                setNotEligibleForInsuranceCoverage(checked)
              }
              control={<Checkbox checked={notEligibleForInsuranceCoverage} />}
              label="Not eligible for insurance coverage"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdateCategory}
            variant={'contained'}
            color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
