/**
 * Returns array sorted by id ascending
 *
 * @param tuple
 */

export const sortById = (a: $TSFixMe, b: $TSFixMe) => {
  if (a.id > b.id) {
    return 1;
  }
  if (b.id > a.id) {
    return -1;
  }
};

export const sortByIdDesc = (a: $TSFixMe, b: $TSFixMe) => {
  if (a.id > b.id) {
    return -1;
  }
  if (b.id > a.id) {
    return 1;
  }
};
