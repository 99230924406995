import React, {useState} from 'react';
import {Box, Grid, IconButton, Link, Typography} from '@material-ui/core';
import PdfImage from '../assets/images/pdf_icon.png';
import DocImage from '../assets/images/doc_icon.png';
import {sortById} from '../util/SortingHelpers';
import {Delete} from '@material-ui/icons';
import AlertDialog from './AlertDialog';
import {SERVER_URL} from '../constants';

function DocumentsList(props: $TSFixMe) {
  const [deleteDocument, setDeleteDocument] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const truncate = (name: $TSFixMe) =>
    name.length > 16 ? `${name.substring(0, 16)}...` : name;

  const documentItem = (doc: $TSFixMe) => {
    const parts = doc.name.split('.');
    const extension = parts[parts.length - 1];
    let image;
    let title = truncate(doc.name);

    if (
      [
        'fees_invoice',
        'payment_receipt',
        'contractor_payment_receipt',
        'proposal_invoice',
      ].includes(doc.key)
    ) {
      image = PdfImage;
      title = doc.description;
    } else if (extension === 'pdf') {
      image = PdfImage;
    } else {
      image = DocImage;
    }

    const handleClickDeleteDocument = (document: $TSFixMe) => {
      setSelectedFile(document);
      setDeleteDocument(true);
    };

    return (
      <Grid item key={doc.id}>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Grid container row>
          <Grid item xs={2}>
            <Box
              p={1}
              style={{
                display: 'flex',
              }}>
              <Link href={doc.url} target="_blank" rel="noopener">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    textAlign: 'center',
                    width: '120%',
                  }}>
                  <img
                    src={image}
                    alt={'File Icon'}
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      maxWidth: 35,
                    }}
                  />
                  <br />
                  <Typography variant="caption" style={{color: '#000000'}}>
                    {title}
                  </Typography>
                </div>
              </Link>
              <IconButton
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  transform: 'translateX(-2px) translateY(-15px)',
                  marginLeft: 0,
                }}>
                <Delete
                  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                  color="black"
                  fontSize="small"
                  onClick={() => handleClickDeleteDocument(doc)}
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleDeleteFile = (attachmentRef: $TSFixMe) => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const url = `${SERVER_URL}v1/attachments/${selectedFile.id}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'DELETE',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.text())
      .then((res) => {
        // look for index in array where id matches selectedFile id
        const targetIndex = props.attachmentReferences.findIndex(
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          (x: $TSFixMe) => x.id === selectedFile.id,
        );
        // remove entry from attachment array
        props.attachmentReferences.splice(targetIndex, 1);
        setDeleteDocument(false);
        props.updateRequestForProposalState();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Box mb={2} mt={2}>
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Grid container row spacing={1}>
        {props.attachmentReferences
          .filter((doc: $TSFixMe) =>
            [
              'project_document',
              'messaging_attachment',
              'fees_invoice',
              'payment_receipt',
              'contractor_payment_receipt',
              'proposal_invoice',
            ].includes(doc.key),
          )
          .sort(sortById)
          .map((doc: $TSFixMe, index: $TSFixMe) => documentItem(doc))}
      </Grid>
      <AlertDialog
        open={deleteDocument}
        dialogDescription="Are you sure you want to delete this file?"
        confirm="DELETE"
        close="CANCEL"
        onConfirm={handleDeleteFile}
        onClose={() => setDeleteDocument(false)}
        deletion
      />
    </Box>
  );
}

export default DocumentsList;
