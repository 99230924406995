import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TransactionDetails from './components/TransactionDetails';
import RefundTransactionDialog from './RefundTransactionDialog';

export default function TransactionSummaryDialog(props: $TSFixMe) {
  const handleClose = () => {
    props.handleClose();
  };

  console.log(props.transaction);

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'md'}
        fullWidth>
        <DialogTitle id="form-dialog-title">TRANSACTION SUMMARY</DialogTitle>
        <DialogContent>
          <TransactionDetails transaction={props.transaction} />
        </DialogContent>
        <DialogActions>
          {props.transaction.type === 'PAYMENT' && (
            <RefundTransactionDialog transaction={props.transaction} />
          )}
          <Button onClick={handleClose} color="secondary">
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
