import React, {useState} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import DetailsTab from './DetailsTab';
import MessagesTab from './MessagesTab';
import Review from '../../Review';
import InvitesTab from './InvitesTab';
import ViewsTab from './ViewsTab';
import QuestionsTab from './QuestionsTab';
import ProposalsTab from './ProposalsTab';
import SiteVisitsTab from './SiteVisitsTab';
import {makeStyles} from '@material-ui/styles';
import CallsTab from './CallsTab';

type TabPanelProps = {
  children?: React.ReactNode;
  index: $TSFixMe;
  value: $TSFixMe;

  selectedValue: string;
};

function TabPanel(props: TabPanelProps) {
  const {children, value, selectedValue, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== selectedValue}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === selectedValue && (
        <Box p={3} key={value}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: $TSFixMe) => ({
  tabs: {
    backgroundColor: '#f7f7f7',
    borderTop: '1px solid #d9d9d9',
    borderBottom: '1px solid #d9d9d9',

    '& .MuiTab-root': {
      minWidth: window.innerWidth > 600 ? 110 : 'auto',
    },
  },
}));

function RfpTabs(props: $TSFixMe) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState('details');

  const handleTabChange = (event: $TSFixMe, newValue: $TSFixMe) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Grid container>
        <Grid item>
          <div style={{padding: 20}}>
            <div
              style={{
                backgroundColor: '#e6e6e6',
                borderRadius: 10,
                width: 'fit-content',
              }}>
              <Typography
                style={{
                  width: 'fit-content',
                  padding: 12,
                  textTransform: 'uppercase',
                }}>
                {props?.requestForProposal?.user.phone}
                <br />
                {props?.requestForProposal?.user.email}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item>
          {props?.requestForProposal?.street && (
            <div style={{padding: 20}}>
              <div
                style={{
                  backgroundColor: '#e6e6e6',
                  borderRadius: 10,
                  width: 'fit-content',
                }}>
                <Typography
                  style={{
                    width: 'fit-content',
                    padding: 12,
                    textTransform: 'uppercase',
                  }}>
                  {props?.requestForProposal?.street},{' '}
                  {props?.requestForProposal?.city}
                  <br />
                  {props?.requestForProposal?.province},{' '}
                  {props?.requestForProposal?.postal}
                </Typography>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        //indicatorColor="primary"
        textColor="secondary"
        variant="scrollable"
        scrollButtons="on"
        className={classes.tabs}>
        <Tab label="DETAILS" value={'details'} />
        <Tab
          label="SITE VISITS"
          disabled={props.mode === 'edit'}
          value={'visits'}
        />
        <Tab label="BIDS" disabled={props.mode === 'edit'} value={'bids'} />
        <Tab
          label="MESSAGES"
          disabled={['DRAFT', 'CANCELLED'].includes(
            props.requestForProposal.state,
          )}
          value={'messages'}
        />
        <Tab
          label="Questions"
          disabled={props.mode === 'edit'}
          value={'questions'}
        />
        <Tab label={'Calls'} value={'calls'} />
        <Tab label={'Traffic'} value={'views'} />
        {props.requestForProposal.state === 'OPEN' && (
          <Tab label="Invites" value={'invites'} />
        )}
        {props.requestForProposal.state === 'COMPLETE' && (
          <Tab
            label="Reviews"
            disabled={!props.requestForProposal.bidderReview}
            value={'reviews'}
          />
        )}
      </Tabs>
      <TabPanel value={'details'} selectedValue={tabValue} index={0}>
        <DetailsTab
          requestForProposal={props.requestForProposal}
          mode={props.mode}
          updateRequestForProposalState={props.updateRequestForProposalState}
        />
      </TabPanel>
      <TabPanel value={'visits'} selectedValue={tabValue} index={1}>
        <SiteVisitsTab
          requestForProposal={props.requestForProposal}
          updateRequestForProposalState={props.updateRequestForProposalState}
        />
      </TabPanel>
      <TabPanel value={'bids'} selectedValue={tabValue} index={2}>
        <ProposalsTab
          requestForProposal={props.requestForProposal}
          updateRequestForProposalState={props.updateRequestForProposalState}
        />
      </TabPanel>
      <TabPanel value={'messages'} selectedValue={tabValue} index={3}>
        <MessagesTab requestForProposal={props.requestForProposal} />
      </TabPanel>
      <TabPanel value={'questions'} selectedValue={tabValue} index={4}>
        <QuestionsTab requestForProposal={props.requestForProposal} />
      </TabPanel>
      <TabPanel value={'calls'} selectedValue={tabValue} index={5}>
        <CallsTab requestForProposal={props.requestForProposal} />
      </TabPanel>
      <TabPanel value={'views'} selectedValue={tabValue} index={6}>
        <ViewsTab requestForProposal={props.requestForProposal} />
      </TabPanel>
      <TabPanel value={'invites'} selectedValue={tabValue} index={7}>
        <InvitesTab requestForProposal={props.requestForProposal} />
      </TabPanel>

      {['FUNDED', 'COMPLETE'].includes(props.requestForProposal.state) && (
        <>
          <TabPanel value={'reviews'} selectedValue={tabValue} index={8}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={6} key={0} style={{textAlign: 'center'}}>
                <Review
                  review={props.requestForProposal.bidderReview}
                  user="Contractor"
                />
              </Grid>
              <Grid item xs={6} md={6} key={1} style={{textAlign: 'center'}}>
                <Review
                  review={props.requestForProposal.requesterReview}
                  user="Homeowner"
                />
              </Grid>
            </Grid>
          </TabPanel>
        </>
      )}
    </div>
  );
}

export default RfpTabs;
