import React, {useEffect, useState} from 'react';
import {SERVER_URL} from '../../../constants';
import BuyNowQuoteDetailsTable from './BuyNowQuoteDetailsTable';
import StandardQuoteDetailsTable from './StandardQuoteDetailsTable';

export default function QuoteDetailsTable(props: $TSFixMe) {
  const [page, setPage] = useState(0);
  const [lines, setLines] = useState([]);

  const getProposalLineData = () => {
    const jwt = localStorage.getItem('Authorization');
    return fetch(
      `${SERVER_URL}v1/proposals/${props.proposal.uid}/lines?page=${page}&pageSize=100`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwt,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res?._embedded?.models) {
          setLines((prevState) =>
            page === 0
              ? res._embedded.models
              : [...prevState, ...res._embedded.models],
          );
        }

        if (res?.page && page < res.page.totalPages - 1) {
          setPage((currentPage) => currentPage + 1);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  useEffect(() => {
    getProposalLineData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      {props.proposal.type === 'buy_now' ? (
        <BuyNowQuoteDetailsTable lines={lines} proposal={props.proposal} />
      ) : (
        <StandardQuoteDetailsTable lines={lines} proposal={props.proposal} />
      )}
    </>
  );
}
