import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import SortByToggleButton from '../components/SortByToggleButton';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ViewTypeToggleButton from './ViewTypeToggleButton';
import {Grid} from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const menuMapping = [
  {
    filter: 'all enabled',
    buttonText: 'All Enabled',
  },
  {
    filter: 'all',
    buttonText: 'All',
  },
  {
    filter: 'property_management',
    buttonText: 'Property Management',
  },
  {
    filter: 'draft',
    buttonText: 'Draft',
  },
  {
    filter: 'posted',
    buttonText: 'Posted',
  },
  {
    filter: 'bids',
    buttonText: 'Projects with Bids',
    secondFilter: 'hb',
  },
  {
    filter: 'awarded',
    buttonText: 'Awarded',
    secondFilter: 'aw',
  },
  {
    filter: 'completed',
    buttonText: 'Complete',
    secondFilter: 'cp',
  },
  {
    filter: 'cancelled',
    buttonText: 'Cancelled',
  },
  {
    filter: 'requester reviewed',
    buttonText: 'Requester Reviewed',
    secondFilter: 'rr',
  },
  {
    filter: 'bidder reviewed',
    buttonText: 'Bidder Reviewed',
    secondFilter: 'br',
  },
  {
    filter: 'paid out',
    buttonText: 'Projects Paid Out',
    secondFilter: 'po',
  },
  {
    filter: 'my projects',
    buttonText: 'My Projects',
  },
  {
    filter: 'anonymous',
    buttonText: 'Anonymous',
  },
];

export default function TopMenu(props: $TSFixMe) {
  const typeParams = () => {
    let menuIndex = 0;
    if (props.location?.search) {
      menuIndex = menuMapping.findIndex(
        (menuItem) =>
          menuItem.secondFilter ===
          queryString.parse(props.location?.search).filter,
      );
      if (queryString.parse(props.location?.search).filter === 'all') {
        menuIndex = 2;
      }
      if (menuIndex < 0) {
        menuIndex = 0;
      }
    }
    return menuIndex;
  };

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [sortIcon, setSortIcon] = useState(<ArrowDownwardIcon />);
  const [buttonText, setButtonText] = useState(
    menuMapping[typeParams()].buttonText,
  );
  const anchorRef = React.useRef(null);
  const [order, setOrder] = React.useState('desc');

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: $TSFixMe) => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: $TSFixMe) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleChange = (
    event: $TSFixMe,
    newValue: $TSFixMe,
    secondFilter: $TSFixMe,
  ) => {
    const filter = menuMapping[newValue].filter;
    setButtonText(menuMapping[newValue].buttonText);
    props.onChangeFilter(filter, secondFilter);
    handleClose(event);
  };

  const handleUpdateViewType = (viewType: $TSFixMe) => {
    props.setViewType(viewType);
  };

  const toggleSort = () => {
    let newOrderState = '';
    let newIcon = '';
    if (order === 'asc') {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
      newIcon = <ArrowDownwardIcon />;
      newOrderState = 'desc';
    } else {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
      newIcon = <ArrowUpwardIcon />;
      newOrderState = 'asc';
    }
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setSortIcon(newIcon);
    setOrder(newOrderState);
    props.onSortByDate(newOrderState);
  };

  return (
    <div className={classes.root}>
      <AppBar
        style={{
          backgroundColor: '#FFF',
          marginTop: window.innerWidth > 600 ? 64 : 54,
          paddingLeft: window.innerWidth > 600 ? 254 : 16,
          borderBottom: '1px solid #d9d9d9',
        }}
        elevation={0}>
        <Grid
          container
          justifyContent={'flex-start'}
          alignItems="center"
          py={1}>
          <ViewTypeToggleButton updateViewType={handleUpdateViewType} />
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            style={{marginRight: 24}}>
            {buttonText} <ArrowDropDownIcon />
          </Button>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal>
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}>
                      {menuMapping.map((item, i) => {
                        return (
                          <MenuItem
                            onClick={(event) =>
                              handleChange(event, i, item.secondFilter)
                            }>
                            {item.buttonText}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          {props.viewType === 'card' && (
            <div
              style={{
                display: 'flex',
                marginRight: 24,
                marginTop: window.innerWidth <= 600 ? 8 : 0,
              }}>
              <SortByToggleButton onMenuClick={props.onSetColumnName} />
              <Button
                onClick={toggleSort}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  height: 40,
                }}>
                {sortIcon}
              </Button>
            </div>
          )}
        </Grid>
      </AppBar>
    </div>
  );
}
