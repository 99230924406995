import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {SERVER_URL} from '../../constants';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {convertDate} from '../../util/DateAsWords';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function PaymentFormDialog(props: $TSFixMe) {
  const handleClose = () => {
    props.setShowDialog(false);
  };

  const [promocode, setPromoCode] = useState({
    code: '',
    amount: '',
    expiryDate: new Date(),
    type: '',
  });

  const handleChange = (e: $TSFixMe) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    promocode[name] = value;
    setPromoCode({...promocode});
  };

  const handleDateChange = (date: $TSFixMe) => {
    setPromoCode({...promocode, expiryDate: date});
  };

  const addPromoCode = () => {
    const fbjwt = localStorage.getItem('Authorization');
    const body = JSON.stringify({
      code: promocode.code,
      amount: promocode.amount,
      expiryDate: convertDate(promocode.expiryDate),
      type: promocode.type,
    });
    return fetch(`${SERVER_URL}v1/promocodes`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: body,
    })
      .then((res) => {
        props.fetchPromoCodes();
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Promo Code</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="code"
            label="Code"
            type="text"
            name="code"
            fullWidth
            onChange={handleChange}
            value={promocode.code}
          />
          <TextField
            autoFocus
            margin="dense"
            id="amount"
            label="Amount"
            type="number"
            fullWidth
            name="amount"
            onChange={handleChange}
            value={promocode.amount}
          />
          <br />
          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="type"
              onChange={handleChange}>
              <MenuItem value={'FIXED'}>Fixed</MenuItem>
              <MenuItem value={'PERCENT'}>Percent</MenuItem>
            </Select>
          </FormControl>
          <br />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Expiry Date"
            name="expiryDate"
            onChange={handleDateChange}
            value={promocode.expiryDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            autoOk={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" color="secondary" onClick={addPromoCode}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
