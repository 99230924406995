import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {SERVER_URL} from '../../../constants';

const styles = (theme: $TSFixMe) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { m... Remove this comment to see the full error message
const DialogTitle = withStyles(styles)((props) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'PropsWi... Remove this comment to see the full error message
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function BanUserDialog(props: $TSFixMe) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirm = () => {
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(`${SERVER_URL}v1/users/${props.userId}`, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({enabled: !props.user.enabled}),
    })
      .then((res) => res.json())
      .then((res) => {
        props.setUser(res);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  return (
    <>
      <Button
        style={{
          marginLeft: '20px',
          height: 'fit-content',
          color: 'red',
          backgroundColor: '#FFF',
        }}
        variant="contained"
        color="primary"
        component={'span'}
        onClick={handleClickOpen}>
        {!props.user.enabled ? 'Unblock User' : 'Block User'}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="ban-user-dialog"
        open={open}>
        <DialogTitle>Block User</DialogTitle>
        <DialogContent dividers>
          <Typography>{`Are you sure you want to ${
            props.user.enabled ? 'ban' : 'unban'
          } this user?`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button style={{float: 'left'}} autoFocus onClick={handleClose}>
            Close
          </Button>
          <Button autoFocus onClick={confirm} style={{color: 'red'}}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
