import React from 'react';
import Grid from '@material-ui/core/Grid';
import MessagesPanel from '../../Messaging/components/MessagesPanel';
import ConversationsTable from './ConversationsTable';

type IProps = {
  requestForProposal: any;
};
function MessagesTab(props: IProps) {
  if (props.requestForProposal.proposal) {
    return (
      <div>
        <h2 style={{textAlign: 'center'}}>Messages</h2>
        <Grid container spacing={3}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <MessagesPanel
              id={props.requestForProposal.conversationId}
              hidden={false}
              central={false}
            />
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </div>
    );
  }

  return <ConversationsTable requestForProposal={props.requestForProposal} />;
}

export default MessagesTab;
