import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Avatar, Badge, Grid} from '@material-ui/core';
import {createUUID} from '../../../util/UUID';
import {CDN_URL} from '../../../constants';
import {firebaseAuth, firebaseStorage} from '../../../App';
import {ref, uploadBytes} from 'firebase/storage';
import {signInAnonymously} from 'firebase/auth';
import {v4 as uuidv4} from 'uuid';

type IAttachmentRef = {
  id: number | null;
  name: string | null;
  description: string | null;
  resModel: string | null;
  resId: number | null;
  key: string | null;
  url: string | null;
  firebaseRef: string | null;
};

export default function EditBadgeDialog(props: $TSFixMe) {
  const [badgeName, setBadgeName] = useState(props.badge.badgeTitle);
  const [description, setDescription] = useState(props.badge.description);

  const [updatedAwardedImage, setUpdatedAwardedImage] =
    useState<IAttachmentRef | null>(null);
  const [updatedNonAwardedImage, setUpdatedNonAwardedImage] =
    useState<IAttachmentRef | null>(null);

  const awardedImage = props.badge.awardedImage.url.includes('https')
    ? props.badge.awardedImage.url
    : `${CDN_URL}/media/badges/${props.badge.awardedImage.url.replace(
        'images/badges/',
        '',
      )}`;

  const nonAwardedImage = props.badge.nonAwardedImage.url.includes('https')
    ? props.badge.nonAwardedImage.url
    : `${CDN_URL}/media/badges/${props.badge.nonAwardedImage.url.replace(
        'images/badges/',
        '',
      )}`;

  const handleUpdateBadge = async () => {
    // eslint-disable-next-line no-alert
    alert('Editing Badges is temporarily disabled pending platform update');

    /*
    if (updatedAwardedImage) {
      await updateRef(updatedAwardedImage);
    }
    if (updatedNonAwardedImage) {
      await updateRef(updatedNonAwardedImage);
    }
    props.updateBadge(props.badge.id, badgeName, description);
    */
  };

  const handleChangeFile = (file: $TSFixMe, userAttr: $TSFixMe) => {
    if (file) {
      const fileUUID = createUUID();
      const fileParts = file.name.split('.');
      const extension = fileParts[fileParts.length - 1];
      const storePath = `media/badges/${fileUUID}.${extension}`;
      const fileRef = ref(firebaseStorage, storePath);

      signInAnonymously(firebaseAuth)
        .catch(function (error) {
          console.error('ANONYMOUS LOGIN FAIL');
          console.error(error.code);
          console.error(error.message);
        })
        .then((res) => {
          handleUploadAndStoreRef(file, fileRef, storePath, userAttr);
        });
    }
  };

  const handleUploadAndStoreRef = (
    file: $TSFixMe,
    fileRef: $TSFixMe,
    storePath: $TSFixMe,
    badgeAttr: $TSFixMe,
  ) => {
    const metadata = {
      cacheControl: 'max-age=604800',
    };
    return uploadBytes(fileRef, file, metadata).then((snapshot: $TSFixMe) => {
      const path = storePath.replace('/', '%2F');
      const attachmentReference = {
        id:
          badgeAttr === 'awardedImage'
            ? props.badge.awardedImage.id
            : props.badge.nonAwardedImage.id,
        name: file.name,
        description: badgeName,
        resModel: 'Badge',
        resId: props.badge.id,
        key: null,
        url: `${CDN_URL}${path}?alt=media&token=${uuidv4()}`,
        firebaseRef: storePath,
      };
      if (badgeAttr === 'awardedImage') {
        setUpdatedAwardedImage(attachmentReference);
      } else {
        setUpdatedNonAwardedImage(attachmentReference);
      }
    });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle id="form-dialog-title">Update Badge</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item>
              <label style={{cursor: 'pointer'}}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={
                    <input
                      accept="image/*"
                      style={{display: 'none'}}
                      id=""
                      name=""
                      type="file"
                      onChange={(e) => {
                        handleChangeFile(
                          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                          e.target.files[0],
                          'awardedImage',
                        );
                      }}
                    />
                  }>
                  <Avatar
                    alt={`Image for ${badgeName}`}
                    src={
                      updatedAwardedImage
                        ? updatedAwardedImage.url
                        : awardedImage
                    }
                    style={{width: 100, height: 100}}
                  />
                </Badge>
              </label>
            </Grid>
            <Grid item>
              <label style={{cursor: 'pointer'}}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={
                    <input
                      accept="image/*"
                      style={{display: 'none'}}
                      id=""
                      name=""
                      type="file"
                      onChange={(e) => {
                        handleChangeFile(
                          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                          e.target.files[0],
                          'nonAwardedImage',
                        );
                      }}
                    />
                  }>
                  <Avatar
                    alt={`Image for ${badgeName}`}
                    src={
                      updatedNonAwardedImage
                        ? updatedNonAwardedImage.url
                        : nonAwardedImage
                    }
                    style={{width: 100, height: 100}}
                  />
                </Badge>
              </label>
            </Grid>
          </Grid>

          <TextField
            placeholder={'Badge Name'}
            defaultValue={badgeName}
            onChange={(event) => setBadgeName(event.target.value)}
            fullWidth={true}
            style={{marginTop: 15}}
          />
          <TextField
            placeholder={'Description'}
            defaultValue={description}
            onChange={(event) => setDescription(event.target.value)}
            fullWidth={true}
            style={{marginTop: 15}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdateBadge}
            variant={'contained'}
            color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
