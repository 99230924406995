import React, {useState} from 'react';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {SERVER_URL} from '../../../constants';
import CreatePromptDialog from './CreatePromptDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdatePromptDialog from './UpdatePromptDialog';
import Chip from '@material-ui/core/Chip';

const ProjectTemplatesToolbar = (props: $TSFixMe) => {
  return (
    <GridToolbarContainer
      style={{
        padding: 12,
        paddingTop: 24,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #999',
      }}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <CreatePromptDialog
        onUpdatePrompts={props.onUpdatePrompts}
        templateUid={props.templateUid}
      />
    </GridToolbarContainer>
  );
};

const ProjectTemplatePrompts = (props: $TSFixMe) => {
  const [key, setKey] = useState(1);
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState(props.template.prompts);
  const [rowCount] = useState(0);
  const [selectedPrompt, setSelectedPrompt] = useState<$TSFixMe | null>(null);
  const [paginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('rowClick', (params) => {
      setSelectedPrompt(params.row);
    });
  }, [apiRef]);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('cellClick', (params) => {
      switch (params.field) {
        case 'email':
          window.open(`mailto:${params?.row?.email}`, '_blank');
          break;
        case 'phone':
          window.open(`tel:${params?.row?.phone}`, '_blank');
          break;
      }
    });
  }, [apiRef]);

  React.useEffect(() => {
    setRows(props.template.prompts);
  }, [props.template]);

  const handleDeletePrompt = (promptUid: $TSFixMe) => {
    const jwt = localStorage.getItem('Authorization');

    return fetch(`${SERVER_URL}v1/project-templates/prompts/${promptUid}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt || '',
      },
    })
      .then((res) => {
        props.refreshTemplateData();
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  // @ts-ignore
  const columns: GridColDef[] = [
    {
      headerName: 'ID',
      field: 'id',
      width: 30,
    },
    {
      headerName: 'Prompt',
      field: 'prompt',
      width: 425,
      // @ts-ignore
      renderCell: ({value}) => (
        <div
          style={{
            overflow: 'visible',
            height: '100%',
            whiteSpace: 'initial',
            paddingTop: 10,
          }}>
          {value}
        </div>
      ),
    },
    {
      headerName: 'Type',
      field: 'type',
      width: 170,
      // @ts-ignore
      renderCell: ({value}) => (
        <div
          style={{
            overflow: 'visible',
            height: '100%',
            whiteSpace: 'initial',
            paddingTop: 10,
          }}>
          {value}
        </div>
      ),
    },
    {
      headerName: 'Options',
      field: 'options',
      width: 250,
      // @ts-ignore
      renderCell: ({value}) => {
        let tags = null;
        try {
          const parsedOptions = value ? JSON.parse(value) : null;

          tags =
            parsedOptions?.map((op: {label: any}, index: any) => (
              <Chip
                label={`${op.label}`}
                key={`option_${index}`}
                style={{marginRight: 10, marginBottom: 10}}
              />
            )) ?? null;
        } catch (e) {
          console.log(`FAILED TO PROCESS: ${value}`);
        }

        return (
          <div
            style={{
              overflow: 'visible',
              height: '100%',
              whiteSpace: 'initial',
              padding: 10,
            }}>
            {tags}
          </div>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => handleDeletePrompt(params?.row?.uid)}
          label="Delete"
        />,
      ],
      width: 200,
    },
  ];

  /*
  function updateRowPosition(
    initialIndex: number,
    newIndex: number,
    data: Array<GridRowModel>,
  ): Promise<any> {
    return new Promise((resolve) => {
      const rowsClone = [...data];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
    });
  }
   */

  /*
  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows,
    );

    setRows(newRows);
  };
  */

  return (
    <div style={{height: 500}}>
      <DataGridPro
        key={`users_list_${key}`}
        apiRef={apiRef}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        slots={{
          toolbar: ProjectTemplatesToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {debounceMs: 2500},
            setKey: setKey,
            onUpdatePrompts: props.refreshTemplateData,
            templateUid: props.template.uid,
          },
        }}
        rows={rows}
        rowCount={rowCount}
        columns={columns}
        rowHeight={100}
        paginationModel={paginationModel}
        pageSizeOptions={[2, 3, 5, 7, 10, 15, 20]}
        getRowClassName={(params) => {
          return params?.row?.enabled ? 'odd' : 'even';
        }}
        sx={{
          '& .MuiDataGrid-cell': {
            display: 'block',
          },
        }}
      />
      {selectedPrompt && (
        <UpdatePromptDialog
          prompt={selectedPrompt}
          onUpdatePrompts={props.refreshTemplateData}
          templateUid={props.templateUid}
          setSelectedPrompt={setSelectedPrompt}
        />
      )}
    </div>
  );
};

export default ProjectTemplatePrompts;
