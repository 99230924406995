import React from 'react';
import RequestForProposalAdminTable from './RequestForProposalAdminTableView';
import RequestForProposalTableView from './RequestForProposalTableView';

function ProjectViewTab(props: $TSFixMe) {
  return (
    <div>
      <div
        style={{
          maxWidth: '100%',
          backgroundColor: '#efefef',
          paddingTop: props.user.userType !== 'ADMIN' ? 10 : 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}>
        {props.user.userType !== 'ADMIN' ? (
          <RequestForProposalTableView userId={props.user.id} />
        ) : (
          <RequestForProposalAdminTable user={props.user} />
        )}
      </div>
    </div>
  );
}

export default ProjectViewTab;
