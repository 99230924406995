import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {SERVER_URL} from '../../../constants';

const filter = createFilterOptions();

export default function CategorySearchWidget(props: $TSFixMe) {
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);

  const getCategoryOptions = () => {
    const fbjwt = localStorage.getItem('Authorization');
    //TODO: Also a bit of a hack.
    fetch(`${SERVER_URL}v1/rfps/categories?page=0&pageSize=10000`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const options = res?._embedded?.models.map((category: $TSFixMe) => ({
          title: category.name,
          id: category.id,
        }));
        setCategoryOptions(options);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createCategory = (category: $TSFixMe) => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/rfps/categories`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({name: category}),
    })
      .then((res) => res.json())
      .then((res) => {
        getCategoryOptions();
        props.addCategory(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getCategoryOptions();
  }, []);

  const autoCompleteOnChange = (event: $TSFixMe, newValue: $TSFixMe) => {
    if (typeof newValue === 'string') {
      setValue({
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ title: string; }' is not assig... Remove this comment to see the full error message
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setValue({
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ title: any; }' is not assignab... Remove this comment to see the full error message
        title: newValue.inputValue,
      });
      createCategory(newValue.inputValue);
    } else {
      setValue(newValue);
      props.addCategory(newValue);
    }
  };

  const autoCompleteFilterOptions = (options: $TSFixMe, params: $TSFixMe) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        title: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  const autoCompleteGetOptionLabel = (option: $TSFixMe) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.title;
  };

  return (
    <Autocomplete
      id="free-solo-with-text-demo"
      value={value}
      onChange={autoCompleteOnChange}
      filterOptions={autoCompleteFilterOptions}
      options={categoryOptions}
      getOptionLabel={autoCompleteGetOptionLabel}
      renderOption={(option) => (option as $TSFixMe).title}
      style={{width: 284, paddingLeft: 10}}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select a category"
          variant="outlined"
          size="small"
          style={{marginBottom: 16}}
        />
      )}
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
    />
  );
}
