import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme: $TSFixMe) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { m... Remove this comment to see the full error message
const DialogTitle = withStyles(styles)((props) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'PropsWi... Remove this comment to see the full error message
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ConfirmCoverPhotoDialog(props: $TSFixMe) {
  if (!props.open) {
    return null;
  }
  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}>
      <DialogTitle>Confirm Cover Photo</DialogTitle>
      <DialogContent>
        <Typography>
          Please review selected cover photo, this image will be included in
          email notifications
        </Typography>
        <img
          style={{maxWidth: '100%', maxHeight: '50vh', width: 'auto'}}
          src={
            (props as $TSFixMe).requestForProposal.attachmentReferences.filter(
              (ref: $TSFixMe) =>
                ref.key === 'before_image' && ref.type === 'cover',
            )[0].url
          }
          alt={'project cover photo'}
        />
      </DialogContent>
      <DialogActions>
        <Button color={'secondary'} onClick={props.handleClose}>
          Cancel
        </Button>
        <Button
          color={'secondary'}
          variant={'contained'}
          onClick={props.confirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
