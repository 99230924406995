import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Main} from '../index';
import {SERVER_URL} from '../../constants';

type State = $TSFixMe;

class MainRouter extends Component<$TSFixMe, State> {
  state = {
    user: false,
  };

  getUserData = () => {
    const url = `${SERVER_URL}v1/admin/users`;
    const fbjwt = localStorage.getItem('Authorization');
    if (!fbjwt) {
      (this.props as $TSFixMe).history.push('/login');
      return;
    }

    return fetch(url, {
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (
          responseData.hasOwnProperty('error') &&
          responseData.status === 403
        ) {
          (this.props as $TSFixMe).history.push('/login');
        }
        (window as $TSFixMe).bidmii.user = responseData;
        this.setState({user: responseData});
      })
      .catch((err) => {
        console.log(err);
        (this.props as $TSFixMe).history.push('/login');
      });
  };

  componentDidMount() {
    this.getUserData();
  }

  render() {
    return (
      this.state.user && (
        <Switch>
          <Route path="/" component={Main} />
        </Switch>
      )
    );
  }
}

export default MainRouter;
