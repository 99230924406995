import React, {useCallback, useEffect, useState} from 'react';
import {SERVER_URL} from '../../../constants';
import {DataGridPro, GridColDef, useGridApiRef} from '@mui/x-data-grid-pro';
import moment from 'moment/moment';

function SiteVisitsTab(props: $TSFixMe) {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('rowClick', (params) => {
      window.open(
        `${window.location.origin}/myRfps/${params?.row?.rfpId}`,
        '_blank',
      );
    });
  }, [apiRef]);

  const getData = useCallback(() => {
    setLoading(true);
    fetch(
      `${SERVER_URL}v1/users/${props.userId}/sitevisits?enabled=true&page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res._embedded) {
          setRowCount(res.page.totalElements);
          setRows(res?._embedded.models || []);
        }
        setLoading(false);
      });
  }, [paginationModel.page, paginationModel.pageSize, props.userId]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize]);

  const columns: GridColDef[] = [
    {
      headerName: 'Selected Date',
      field: 'visitDateTime',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row.visitDateTime &&
        moment(row.visitDateTime).format('MMM D, YYYY, h:mm A'),
      width: 180,
    },
    {
      headerName: 'Option 1',
      field: 'dateOption1',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        moment(row.dateOption1).format('MMM D, YYYY, h:mm A'),
      width: 180,
    },
    {
      headerName: 'Option 2',
      field: 'dateOption2',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        moment(row.dateOption2).format('MMM D, YYYY, h:mm A'),
      width: 180,
    },
    {
      headerName: 'Option 3',
      field: 'dateOption3',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        moment(row.dateOption3).format('MMM D, YYYY, h:mm A'),
      width: 180,
    },
    {headerName: 'State', field: 'siteVisitState'},
  ];

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '45vh',
        backgroundColor: '#ffffff',
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 500},
              getData: getData,
            },
          }}
          getRowId={(row: $TSFixMe) => row.uid}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          //checkboxSelection
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50, 100, 150, 200]}
          pagination
          paginationMode={'server'}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default SiteVisitsTab;
