import {createTheme} from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#E6E6E6',
      light: '#b4cfd3',
    },
    secondary: {
      main: '#22ABF7',
      light: '#d1e1fc',
    },
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ primary: { main: string; light: string; };... Remove this comment to see the full error message
    black: {
      main: '#000',
    },
  },
});
