import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';
import {Box} from '@mui/material';
import LegacyTransactionDetails from './LegacyTransactionDetails';
import StandardTransactionDetails from './StandardTransactionDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 450,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function TransactionDetails(props: $TSFixMe) {
  const classes = useStyles();

  const getTransactionDetails = () => {
    const data = props.transaction;
    if (data.feeFactor === 0.96 || data.feeFactor === 0.85) {
      return <LegacyTransactionDetails {...props} />;
    } else if (data.product_type === 'RFP_PAYMENT') {
      return <StandardTransactionDetails {...props} />;
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box my={1}>
          <Typography>{`Transaction No: ${props.transaction.transactionNo}`}</Typography>
        </Box>
        <Box my={1}>{getTransactionDetails()}</Box>

        <Typography variant="body1" style={{color: '#000000'}}>
          Email:{' '}
          <Typography variant="body2" color="textSecondary" component="span">
            {props.transaction.user.billingEmail ||
              props.transaction.user.email}
          </Typography>
        </Typography>
        <br />
        <Typography variant="body1" style={{color: '#000000'}}>
          Phone:{' '}
          <Typography variant="body2" color="textSecondary" component="span">
            {props.transaction.user.phone}
          </Typography>
        </Typography>
      </CardContent>
    </Card>
  );
}
