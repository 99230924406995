import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Snackbar} from '@material-ui/core';
import {SERVER_URL} from '../../constants';

export default function RefundTransactionDialog(props: $TSFixMe) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackBarVisible, setSnackBarVisible] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const getMaxRefund = () => {
    const materialAmount = props.transaction.proposal.materialsAmount;
    const labourAmount = props.transaction.proposal.labourAmount;
    const contingencyAmount = props.transaction.proposal.contingencyAmount;
    const subtotal = materialAmount + labourAmount + contingencyAmount;
    return (subtotal * (1 + props.transaction.proposal.taxFactor)).toFixed(2);
  };

  const [amount, setAmount] = useState(getMaxRefund());

  const handleClose = () => {
    setDialogOpen(false);
  };

  const refundTransaction = () => {
    const jwt = localStorage.getItem('Authorization');
    if (amount > getMaxRefund()) {
      setSnackBarMessage("Sorry, you can't refund this much");
      setSnackBarVisible(true);
      return;
    }
    const body = JSON.stringify({
      amount: amount,
    });
    return fetch(
      `${SERVER_URL}v1/transactions/${props.transaction.id}/refund`,
      {
        method: 'POST',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwt,
        },
        body: body,
      },
    )
      .then((res) => {
        if (res.status === 409) {
          return res;
        } else {
          return res.json();
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const hideSnackBar = (event: $TSFixMe) => {
    setSnackBarVisible(false);
  };

  return (
    <div>
      <Button
        style={{background: '#e6e6e6'}}
        onClick={() => setDialogOpen(true)}>
        Refund Transaction
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Refund Transaction</DialogTitle>
        <DialogContent>
          <TextField
            // @ts-expect-error ts-migrate(2322) FIXME: Type '"black"' is not assignable to type '"primary... Remove this comment to see the full error message
            color="black"
            margin="dense"
            label="amount"
            id="amount"
            type="number"
            fullWidth
            name="amount"
            onChange={(event) => setAmount(event.target.value)}
            value={amount}
            inputProps={{
              readonly: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{color: 'black'}}
            color="primary"
            onClick={refundTransaction}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackBarVisible}
        onClose={hideSnackBar}
        message={snackBarMessage}
      />
    </div>
  );
}
