import React, {useRef, useState, useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {LinearProgress, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles} from '@material-ui/core/styles';
import {SERVER_URL} from '../../../constants';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import DetailsPane from './DetailsPane';
import BanUserDialog from './BanUserDialog';
import {useHistory} from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import LinkIcon from '@material-ui/icons/Link';
import LaunchIcon from '@material-ui/icons/Launch';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
    backgroundColor: '#D6EFFF',
    borderBottom: '1px solid #d9d9d9',
  },
}));

function TransitionUp(props: $TSFixMe) {
  return <Slide {...props} direction="up" timeout={0} />;
}

function Details(props: $TSFixMe) {
  const classes = useStyles();
  const history = useHistory();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const userId = props.match.params.uid;
  const [mode, setMode] = useState('view');
  const [user, setUser] = useState<$TSFixMe>(null);
  const [loading, setLoading] = useState(true);
  const detailsPaneRef = useRef(null);

  const handleClose = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  };
  async function fetchData(url: $TSFixMe) {
    const fullUrl = `${SERVER_URL}${url}`;
    const fbjwt = localStorage.getItem('Authorization');
    const response = await fetch(fullUrl, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    return data;
  }

  useEffect(() => {
    async function dataFetcher() {
      try {
        console.info(userId);
        const userData = await fetchData(`v1/users/${userId}`);
        setUser(userData);
        setLoading(false);
      } catch (err: any) {
        console.info(err);
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      }
    }
    dataFetcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserValues = (userData: $TSFixMe) => {
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(`${SERVER_URL}v1/users/${userId}`, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(userData),
    })
      .then((res) => res.json())
      .then((res) => {
        console.info(res);
        setUser(res);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="Job"
        TransitionComponent={TransitionUp}
        fullScreen>
        <AppBar position="fixed" elevation={0}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" />
            {user && (
              <div>
                {mode === 'view' && (
                  <>
                    <Tooltip title={'Copy Link'}>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://bidmii.com/user/${user?.id}`,
                          );
                          setMessage('Copied!');
                          setSnackBarOpen(true);
                          setTimeout(function () {
                            setSnackBarOpen(false);
                          }, 2000);
                        }}>
                        <LinkIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={'View Profile On Bidmii'}>
                      <IconButton
                        color="inherit"
                        onClick={() =>
                          window.open(
                            `https://bidmii.com/user/${user?.id}`,
                            '_blank',
                          )
                        }>
                        <LaunchIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {mode === 'view' ? (
                  <Tooltip title={'Edit'}>
                    <IconButton
                      color="inherit"
                      aria-label="edit project"
                      component="span"
                      onClick={() => setMode('edit')}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        // @ts-ignore
                        detailsPaneRef.current &&
                          // @ts-ignore
                          detailsPaneRef.current.saveProfile();
                      }}>
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setMode('view')}>
                      Cancel
                    </Button>
                  </>
                )}
                {mode === 'view' && (
                  <>
                    {(user as $TSFixMe).locked ? (
                      <Tooltip title={'Unlock Profile'}>
                        <IconButton
                          color="inherit"
                          aria-label="edit project"
                          component="span"
                          onClick={() => updateUserValues({locked: false})}>
                          <LockIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={'Lock Profile'}>
                        <IconButton
                          color="inherit"
                          aria-label="edit project"
                          component="span"
                          onClick={() => updateUserValues({locked: true})}>
                          <LockOpenIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <BanUserDialog
                      userId={userId}
                      user={user}
                      setUser={setUser}
                    />
                  </>
                )}
              </div>
            )}
          </Toolbar>
          {loading && <LinearProgress color={'secondary'} />}
        </AppBar>
        <div
          style={{
            height: '100%',
            alignItems: 'center',
          }}>
          {user ? (
            <DetailsPane
              ref={detailsPaneRef}
              userId={userId}
              user={user}
              mode={mode}
              setMode={setMode}
              updateUserValues={updateUserValues}
              loading={loading}
              setLoading={setLoading}
            />
          ) : null}
        </div>
      </Dialog>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={4000}
        TransitionComponent={TransitionUp}
        message={message}
      />
    </div>
  );
}

export default Details;
