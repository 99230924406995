import {v4 as uuidv4} from 'uuid';
import * as Sentry from '@sentry/react';
import {securedFetchRequest} from '@bidmii/common/lib/util/FetchRequest';
import {SERVER_URL} from '../constants';
import {firebaseAuth, firebaseStorage} from '../App';
import {ref, uploadBytes} from 'firebase/storage';
import {signInAnonymously} from 'firebase/auth';

export const storeRef = (attachmentReference: $TSFixMe) => {
  return securedFetchRequest(`${SERVER_URL}v1/attachments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachmentReference),
  });
};

export const deleteRef = (attachmentReference: $TSFixMe) => {
  return securedFetchRequest(
    `${SERVER_URL}v1/attachments/${attachmentReference.id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const handleFiles = (
  event: $TSFixMe,
  onAttach: $TSFixMe,
  attachmentInfo: $TSFixMe,
  files: $TSFixMe,
) => {
  const uploadPromises = [];

  const loops = event ? event.currentTarget.files.length : files.length;
  for (let i = 0; i < loops; i++) {
    let file: $TSFixMe;
    if (!files) {
      file = event.currentTarget.files.item(i);
    } else {
      file = files[i];
    }
    const uid = sessionStorage.getItem('uid');
    const fileUUID = uuidv4();

    const fileParts = file.name.split('.');
    const extension = fileParts[fileParts.length - 1];
    const storePath = `${uid}/${fileUUID}.${extension}`;
    const fileRef = ref(firebaseStorage, storePath);

    const metadata = {
      cacheControl: 'max-age=604800',
    };

    const uploadPromise = signInAnonymously(firebaseAuth)
      .catch(function (error) {
        console.error('ANONYMOUS LOGIN FAIL');
        console.error(error.code);
        console.error(error.message);
      })
      .then((res) => {
        uploadBytes(fileRef, file, metadata).then(async () => {
          const path = storePath.replace('/', '%2F');
          const attachmentReference = {
            name: file.name,
            url: `${SERVER_URL}${path}?alt=media&token=${uuidv4()}`,
            firebaseRef: `${uid}/${fileUUID}`,
            ...attachmentInfo,
          };

          try {
            const storeFile = await storeRef(attachmentReference);
            const fileJSON = await storeFile.json();
            return fileJSON;
          } catch (err) {
            console.error(err);

            Sentry.withScope(function (scope) {
              scope.setTag('section', 'image_upload');
              Sentry.captureException(err);
            });
          }
        });
      });

    uploadPromises.push(uploadPromise);
    Promise.allSettled(uploadPromises).then((attachRef) => onAttach(attachRef));
  }
};

export const convertBase64 = (
  files: $TSFixMe,
  onConvert: $TSFixMe,
  trim: $TSFixMe,
) => {
  const file = files[0];
  const reader = new FileReader();
  reader.onloadend = () => {
    let base64 = reader.result;
    if (trim) {
      base64 = (base64 as $TSFixMe).substr(
        (base64 as $TSFixMe).indexOf(',') + 1,
      );
    }
    onConvert(base64, file);
  };
  try {
    reader.readAsDataURL(file);
  } catch (e) {
    console.error('Could not use reader.readAsDataURL api');
  }
};
