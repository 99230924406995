import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {Typography} from '@mui/material';

type IProps = {
  call: any;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: 100,
  },
  playerContainer: {
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    marginBottom: 20,
  },
  transcriptContainer: {
    padding: 40,
  },
  pre: {
    whiteSpace: 'pre-wrap',
  },
}));

function CallDetailPanel(props: IProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.playerContainer}>
        <AudioPlayer
          src={props.call.recordingUrl}
          onPlay={(e) => console.log('onPlay')}
          // other props here
        />
      </div>
      <div className={classes.transcriptContainer}>
        <Typography variant={'subtitle2'}>Summary:</Typography>
        <pre className={classes.pre}>{props.call.summary}</pre>
        <Typography variant={'subtitle2'}>Transcript:</Typography>
        <pre className={classes.pre}>{props.call.transcript}</pre>
      </div>
    </div>
  );
}

export default CallDetailPanel;
