import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Snackbar} from '@material-ui/core';
import {SERVER_URL} from '../../../constants';

export default function DeleteQuoteDialog(props: $TSFixMe) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [snackBarVisible, setSnackBarVisible] = useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const hideSnackBar = (event: $TSFixMe) => {
    setSnackBarVisible(false);
  };

  const submit = () => {
    const url = `${SERVER_URL}v1/proposals/${props.proposal.id}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'PUT',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({enabled: false, state: 'CANCELLED'}),
    }).then((res) => {
      setSnackBarVisible(true);
      setTimeout(function () {
        props.updateRequestForProposalState().then(() => {
          setDialogOpen(false);
        });
      }, 3500);
    });
  };

  return (
    <div>
      <Button
        style={{background: '#e6e6e6'}}
        onClick={() => setDialogOpen(true)}>
        DELETE
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Are you sure you want to delete quote?
        </DialogTitle>
        <DialogContent />
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={submit}>
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackBarVisible}
        onClose={hideSnackBar}
        message="Quote has been deleted"
      />
    </div>
  );
}
