import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Main from './Main';

const styles = (theme: $TSFixMe) => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor:
      (window as $TSFixMe).bidmii.user.userType === 'REQUESTER'
        ? '#d5a6bd'
        : '#fff',
    width: '100%',
  },
});

class DashboardItems extends Component {
  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'Readonl... Remove this comment to see the full error message
    const {classes} = this.props;
    return (
      <Grid className={classes.root}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}>
          <Main />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(DashboardItems);
