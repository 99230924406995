import React from 'react';
import {Typography} from '@material-ui/core';

function QuotesCountCell({params}: $TSFixMe) {
  console.log(params);
  const state = params?.row?.state;
  const count = params?.row?.proposalsCount;

  const getBackgroundColor = () => {
    if (state === 'OPEN') {
      if (count === 0) {
        return 'red';
      }
      if (count > 0 && count < 3) {
        return 'yellow';
      }
      if (count > 2) {
        return 'green';
      }
    }
    if (state === 'FUNDED') {
      return 'white';
    }
  };

  const getColor = () => {
    if (state === 'OPEN') {
      if (count === 0) {
        return 'white';
      }
      if (count > 0 && count < 3) {
        return 'black';
      }
      if (count > 2) {
        return 'white';
      }
    }
    if (state === 'FUNDED') {
      return 'black';
    }
  };

  return (
    <div
      style={{
        backgroundColor: getBackgroundColor(),
        color: getColor(),
        width: '100%',
        height: '100%',
        textAlign: 'center',
      }}>
      <Typography component={'span'} style={{verticalAlign: 'middle'}}>
        {count}
      </Typography>
    </div>
  );
}

export default QuotesCountCell;
