import React, {useEffect} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import {makeStyles} from '@material-ui/core/styles';
// import '.BidmiiCustomLoader.css';
import {useRecoilValue, useRecoilState} from 'recoil';
import {loadingZindex} from '../../recoil/BidmiiCustomLoader/bidmiiCustomLoaderAtom';
import {loadingState} from '../../recoil/BidmiiCustomLoader/bidmiiCustomLoaderAtom';

export default function BidmiiCustomLoader(props: $TSFixMe) {
  const loading = useRecoilValue(loadingState);
  const [zIndex, setZindex] = useRecoilState(loadingZindex);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: props.zIndex ? props.zIndex : zIndex,
      color: '#000000',
      backgroundColor: 'rgba(230, 230, 230, 0.7)',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (!loading) {
      setZindex(1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={props.loading ? props.loading : loading}>
        <div
          style={{
            display: 'flex',
            marginRight: window.innerWidth > 600 ? 4 : 0,
          }}>
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </Backdrop>
    </div>
  );
}
