import React from 'react';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import {ListItemText} from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: '#e6e6e6',
    borderBottom: '2px solid #e6e6e6',
    '& .MuiButton-root:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
  listItemTextPrimary: {
    color: (theme.palette as $TSFixMe).black.main,
    fontFamily: 'AeonikProReqular',
    textTransform: 'uppercase',
    fontSize: '20px',
    paddingLeft: '15px',
    paddingTop: '5px',
  },
}));

function NotificationMenuHeader() {
  const classes = useStyles();
  const history = useHistory();

  const handleViewAllNotification = () => {
    history.push('/activity');
  };

  return (
    <Box className={classes.box}>
      <ListItem ContainerComponent="div">
        <ListItemText classes={{primary: classes.listItemTextPrimary}}>
          Activity
        </ListItemText>
        <ListItemSecondaryAction key={'clear_all'}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Button
            className="secondary-button"
            edge="end"
            aria-label="delete"
            style={{fontSize: 12}}
            onClick={handleViewAllNotification}>
            VIEW ALL
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    </Box>
  );
}

export default NotificationMenuHeader;
