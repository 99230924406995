import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import {GMAPS_API_KEY, SERVER_URL} from '../../../constants';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {KeyboardDatePicker} from '@material-ui/pickers';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import CategorySearchWidget from './CategorySearchWidget';
import AddTagWidget from './AddTagWidget';
import AssignToWidget from './AssignToWidget';
import ChangeOwnerWidget from './ChangeOwnershipDialog';
import Grid from '@material-ui/core/Grid';
import RequestForProposalRiskStatusSelectionWidget from './RequestForProposalRiskStatusSelectionWidget';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {Typography} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Button from '@material-ui/core/Button';

function Alert(props: $TSFixMe) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type EditableFormDetailsState = $TSFixMe;

class EditableFormDetails extends Component<
  $TSFixMe,
  EditableFormDetailsState
> {
  city: $TSFixMe;
  lat: $TSFixMe;
  lng: $TSFixMe;
  postal: $TSFixMe;
  province: $TSFixMe;
  street: $TSFixMe;
  constructor(props: $TSFixMe) {
    super(props);
    this.state = {
      requestForProposal: (this.props as $TSFixMe).requestForProposal,
      dirty: false,
      options: [],
      snackbarOpen: false,
      snackbarMessage: '',
    };
  }

  handleChange = (event: $TSFixMe) => {
    const that = this;
    if (this.state.dirty === false) {
      this.setState(
        {[event.target.name]: event.target.value, dirty: true},
        () => {
          setTimeout(function () {
            that.setState({dirty: false}, () => {
              that.handleClickSubmit();
            });
          }, 1000);
        },
      );
    } else {
      this.setState({[event.target.name]: event.target.value});
    }
  };

  handleClickSubmit = () => {
    const rfp = {
      title: (document.getElementById('title') as $TSFixMe).value,
      description: (document.getElementById('description') as $TSFixMe).value,
      street: (document.getElementById('street') as $TSFixMe).value,
      street2: (document.getElementById('street2') as $TSFixMe).value,
      city: (document.getElementById('city') as $TSFixMe).value,
      province: (document.getElementById('province') as $TSFixMe).value,
      postal: (document.getElementById('postal') as $TSFixMe).value,
      lat: (document.getElementById('lat') as $TSFixMe).value,
      lng: (document.getElementById('lng') as $TSFixMe).value,
      privacy: this.state.privacy,
      unlockPrice: (document.getElementById('unlockPrice') as $TSFixMe).value,
      buyNowPrice: (document.getElementById('buyNowPrice') as $TSFixMe).value,
      referenceUid: (document.getElementById('referenceUid') as $TSFixMe).value,
      referenceLabel: (document.getElementById('referenceLabel') as $TSFixMe)
        .value,
    };

    if (this.state.deadlineDate) {
      (rfp as $TSFixMe).deadlineDate = this.state.deadlineDate;
    }

    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/rfps/${this.state.requestForProposal.id}`, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(rfp),
    })
      .then(() => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Project changes have been saved',
        });
        setTimeout(() => {
          this.setState({snackbarOpen: false});
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleDateChange = (event: $TSFixMe, date: $TSFixMe) => {
    this.setState({deadlineDate: date}, () => {
      this.handleClickSubmit();
    });
  };

  handleEditDetail = (event: $TSFixMe) => {
    const that = this;
    const detailId = event.target.name;

    if (this.state.dirty === false) {
      this.setState({dirty: true}, () => {
        setTimeout(function (this: $TSFixMe) {
          that.setState({dirty: false}, () => {
            const fbjwt = localStorage.getItem('Authorization');
            //TODO: implement v1 endpoint
            fetch(`${SERVER_URL}rfp/detail/${detailId}`, {
              method: 'PUT',
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
              headers: {
                'Content-Type': 'application/json',
                Authorization: fbjwt,
              },
              body: JSON.stringify({
                value: (document.getElementById(detailId) as $TSFixMe).value,
              }),
            })
              .then(() => {
                this.setState({
                  snackbarOpen: true,
                  snackbarMessage: 'Pre-packaged detail has been changed',
                });
                setTimeout(() => {
                  this.setState({snackbarOpen: false});
                }, 1500);
              })
              .catch((err) => {
                console.error(err);
              });
          });
        }, 1000);
      });
    }
  };

  handleDeleteDetail = (detailId: $TSFixMe) => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/rfps/details/delete/${detailId}`, {
      method: 'DELETE',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then(() => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Pre-packaged detail has been removed',
        });
        setTimeout(() => {
          this.setState({snackbarOpen: false});
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  componentDidUpdate(prevProps: $TSFixMe) {
    if (
      (prevProps as $TSFixMe).requestForProposal !==
      (this.props as $TSFixMe).requestForProposal
    ) {
      this.setState({
        requestForProposal: (this.props as $TSFixMe).requestForProposal,
      });
    }
  }

  geoLocate = () => {
    const that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const circle = new (window as $TSFixMe).google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        that.setState({
          autocompletionRequest: {
            componentRestrictions: {
              country: ['ca'],
            },
            location: geolocation,
            radius: 4,
            types: ['address'],
            bounds: circle.getBounds(),
          },
        });
      });
    }
  };

  onAddressSelection = (location: $TSFixMe) => {
    geocodeByPlaceId(location.place_id).then((res) => {
      // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
      const lat = res[0].geometry.location.lat();
      // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
      const lng = res[0].geometry.location.lng();
      const postal = (
        res[0].address_components.filter(
          (c) => (c as $TSFixMe).types[0] === 'postal_code',
        )[0] as $TSFixMe
      ).short_name;
      const street = res[0].formatted_address.split(',')[0];
      const city = (
        res[0].address_components.filter(
          (c) => (c as $TSFixMe).types[0] === 'locality',
        )[0] as $TSFixMe
      ).short_name;
      const province = (
        res[0].address_components.filter(
          (c) => (c as $TSFixMe).types[0] === 'administrative_area_level_1',
        )[0] as $TSFixMe
      ).long_name;
      (document.getElementById('postal') as $TSFixMe).value = postal;
      (document.getElementById('street') as $TSFixMe).value = street;
      (document.getElementById('city') as $TSFixMe).value = city;
      (document.getElementById('province') as $TSFixMe).value = province;
      this.postal = postal;
      this.street = street;
      this.city = city;
      this.province = province;
      this.lat = lat;
      this.lng = lng;
      this.handleClickSubmit();
    });
  };

  updateAfterTimeout = () => {
    setTimeout(() => {
      (this.props as $TSFixMe).updateRequestForProposalState();
      this.setState({snackbarOpen: false});
    }, 2000);
  };

  addCategoryToProject = (category: $TSFixMe) => {
    const rfp = {
      categoryId: category.id,
    };
    const fbjwt = localStorage.getItem('Authorization');
    fetch(
      `${SERVER_URL}v1/rfps/${(this.props as $TSFixMe).requestForProposal.id}`,
      {
        method: 'PATCH',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt,
        },
        body: JSON.stringify(rfp),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Category successfully added to project',
        });
        this.updateAfterTimeout();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  setProjectSeverity = (severity: $TSFixMe) => {
    const rfp = {
      riskStatus: severity,
    };
    const fbjwt = localStorage.getItem('Authorization');
    fetch(
      `${SERVER_URL}v1/rfps/${(this.props as $TSFixMe).requestForProposal.id}`,
      {
        method: 'PATCH',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt,
        },
        body: JSON.stringify(rfp),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Project severity has been set',
        });
        this.updateAfterTimeout();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  addTagToProject = (tag: $TSFixMe) => {
    const rfp = {
      tagId: tag.id,
    };
    const fbjwt = localStorage.getItem('Authorization');
    fetch(
      `${SERVER_URL}v1/rfps/${(this.props as $TSFixMe).requestForProposal.id}`,
      {
        method: 'PATCH',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt,
        },
        body: JSON.stringify(rfp),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Tag successfully added to project',
        });
        this.updateAfterTimeout();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  assignProjectToUser = (userId: $TSFixMe) => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(
      `${SERVER_URL}v1/rfps/${(this.props as $TSFixMe).requestForProposal.id}`,
      {
        method: 'PATCH',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt,
        },
        body: JSON.stringify({assigned_user_id: userId}),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'The user assigned to this project has been changed',
        });
        this.updateAfterTimeout();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  componentDidMount() {
    this.geoLocate();
  }

  updateProjectOwner = (userId: $TSFixMe) => {
    const rfp = {
      user_id: userId.id,
    };
    const fbjwt = localStorage.getItem('Authorization');
    fetch(
      `${SERVER_URL}v1/rfps/${(this.props as $TSFixMe).requestForProposal.id}`,
      {
        method: 'PATCH',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt,
        },
        body: JSON.stringify(rfp),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Project owner has been changed',
        });
        this.updateAfterTimeout();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleProjectSizeChange = (event: SelectChangeEvent) => {
    const body = {
      size: event.target.value,
    };
    const fbjwt = localStorage.getItem('Authorization');
    fetch(
      `${SERVER_URL}v1/rfps/${(this.props as $TSFixMe).requestForProposal.id}`,
      {
        method: 'PATCH',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt,
        },
        body: JSON.stringify(body),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Project size has been changed',
        });
        this.updateAfterTimeout();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleProjectStateChange = (event: SelectChangeEvent) => {
    const body = {
      state: event.target.value,
    };
    const fbjwt = localStorage.getItem('Authorization');
    fetch(
      `${SERVER_URL}v1/rfps/${(this.props as $TSFixMe).requestForProposal.id}`,
      {
        method: 'PATCH',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt,
        },
        body: JSON.stringify(body),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Project state has been changed',
        });
        this.updateAfterTimeout();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleProjectApprovedForCallsChange = (event: SelectChangeEvent) => {
    const body = {
      approvedForCalls: event.target.value === 'true',
    };
    const fbjwt = localStorage.getItem('Authorization');
    fetch(
      `${SERVER_URL}v1/rfps/${(this.props as $TSFixMe).requestForProposal.id}`,
      {
        method: 'PATCH',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt,
        },
        body: JSON.stringify(body),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Project calls settings changed',
        });
        this.updateAfterTimeout();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleBoostClick = () => {
    const body = {
      type: 'ELEVATED',
    };
    const fbjwt = localStorage.getItem('Authorization');
    fetch(
      `${SERVER_URL}v1/rfps/${(this.props as $TSFixMe).requestForProposal.id}`,
      {
        method: 'PATCH',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt,
        },
        body: JSON.stringify(body),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Project has been boosted',
        });
        this.updateAfterTimeout();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    return (
      <div style={{paddingTop: 20}}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Button
              onClick={this.handleBoostClick}
              disabled={this.state.requestForProposal.type !== 'DEFAULT'}
              color={'secondary'}
              size={'small'}
              variant={'contained'}>
              BOOST
            </Button>
            <ChangeOwnerWidget updateProjectOwner={this.updateProjectOwner} />
            <CategorySearchWidget addCategory={this.addCategoryToProject} />
            <AddTagWidget addTag={this.addTagToProject} />
            <AssignToWidget assignProject={this.assignProjectToUser} />

            <Box sx={{maxWidth: 120}} style={{paddingLeft: 10, paddingTop: 10}}>
              <FormControl fullWidth>
                <InputLabel id="project-state-label">Project State</InputLabel>
                <Select
                  labelId="project-state-label"
                  id="project-state"
                  defaultValue={this.state.requestForProposal.state}
                  disabled={['FUNDED', 'COMPLETE'].includes(
                    this.state.requestForProposal.state,
                  )}
                  label="State"
                  onChange={this.handleProjectStateChange}
                  style={{height: 35}}>
                  <MenuItem value={'DRAFT'}>DRAFT</MenuItem>
                  <MenuItem value={'OPEN'}>OPEN</MenuItem>
                  <MenuItem value={'FUNDED'}>FUNDED</MenuItem>
                  <MenuItem value={'COMPLETE'}>COMPLETE</MenuItem>
                  <MenuItem value={'CANCELLED'}>CANCELLED</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <RequestForProposalRiskStatusSelectionWidget
              riskStatus={this.state.requestForProposal?.riskStatus}
              setProjectSeverity={this.setProjectSeverity}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item>
            <Box sx={{maxWidth: 120}} style={{paddingLeft: 10, paddingTop: 10}}>
              <FormControl fullWidth>
                <InputLabel id="project-size-label">Project Size</InputLabel>
                <Select
                  labelId="project-size-label"
                  id="project-size"
                  defaultValue={this.state.requestForProposal.size}
                  label="Size"
                  onChange={this.handleProjectSizeChange}
                  style={{height: 35}}>
                  <MenuItem value={'BYPASS'}>BYPASS</MenuItem>
                  <MenuItem value={'XS'}>XS</MenuItem>
                  <MenuItem value={'SM'}>SM</MenuItem>
                  <MenuItem value={'M'}>M</MenuItem>
                  <MenuItem value={'L'}>L</MenuItem>
                  <MenuItem value={'XL'}>XL</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item>
            <Box ml={1.4} mt={1}>
              <TextField
                color={'secondary'}
                margin="dense"
                id="unlockPrice"
                name="unlockPrice"
                label="Unlock Price"
                type="number"
                onChange={this.handleChange}
                data-rfp-field
                defaultValue={this.state.requestForProposal.unlockPrice}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container justifyContent={'flex-start'}>
          <Grid item>
            <TextField
              color={'secondary'}
              margin="dense"
              id="buyNowPrice"
              name="buyNowPrice"
              label="Property Manager Price"
              type="number"
              onChange={this.handleChange}
              data-rfp-field
              defaultValue={this.state.requestForProposal.buyNowPrice}
            />
          </Grid>
          <Grid item>
            <TextField
              color={'secondary'}
              margin="dense"
              id="referenceUid"
              name="referenceUid"
              label="Reference ID"
              type="text"
              onChange={this.handleChange}
              data-rfp-field
              defaultValue={this.state.requestForProposal.referenceUid}
            />
          </Grid>
          <Grid item>
            <TextField
              color={'secondary'}
              margin="dense"
              id="referenceLabel"
              name="referenceLabel"
              label="Reference NO"
              type="text"
              onChange={this.handleChange}
              data-rfp-field
              defaultValue={this.state.requestForProposal.referenceLabel}
            />
          </Grid>
        </Grid>

        <FormControl component="fieldset" style={{marginTop: 15}}>
          <FormLabel component="legend">Who can see?</FormLabel>
          <RadioGroup
            aria-label="privacy"
            name="privacy"
            defaultValue={this.state.requestForProposal.privacy}
            onChange={this.handleChange}>
            <FormControlLabel
              value="anyone"
              control={<Radio color="primary" />}
              label="Contractors and Homeowners"
              // @ts-expect-error ts-migrate(2322) FIXME: Type '"right"' is not assignable to type '"end" | ... Remove this comment to see the full error message
              labelPlacement="right"
            />
            <FormControlLabel
              value="contractors"
              control={<Radio color="primary" />}
              label="Contractors"
              // @ts-expect-error ts-migrate(2322) FIXME: Type '"right"' is not assignable to type '"end" | ... Remove this comment to see the full error message
              labelPlacement="right"
            />
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" style={{marginTop: 15}}>
          <FormLabel component="legend">Approved for calls?</FormLabel>
          <RadioGroup
            aria-label="Approved for calls"
            name="approvedForCalls"
            defaultValue={
              this.state.requestForProposal.approvedForCalls?.toString() ||
              'false'
            }
            onChange={this.handleProjectApprovedForCallsChange}>
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label="Yes"
              // @ts-expect-error ts-migrate(2322) FIXME: Type '"right"' is not assignable to type '"end" | ... Remove this comment to see the full error message
              labelPlacement="right"
            />
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label="No"
              // @ts-expect-error ts-migrate(2322) FIXME: Type '"right"' is not assignable to type '"end" | ... Remove this comment to see the full error message
              labelPlacement="right"
            />
          </RadioGroup>
        </FormControl>

        <TextField
          color={'secondary'}
          margin="dense"
          id="title"
          name="title"
          label="Title"
          type="text"
          onChange={this.handleChange}
          data-rfp-field
          defaultValue={this.state.requestForProposal.title}
          fullWidth
        />

        <TextField
          color={'secondary'}
          margin="dense"
          id="description"
          name="description"
          label="Description"
          type="text"
          onChange={this.handleChange}
          defaultValue={this.state.requestForProposal.description}
          data-rfp-field
          multiline
          fullWidth
        />

        {this.state.requestForProposal.details &&
          this.state.requestForProposal.details.length > 0 &&
          this.state.requestForProposal.details.map((detail: $TSFixMe) => (
            <Grid
              key={detail.id}
              container
              alignItems="center"
              style={{marginTop: 16}}>
              <Typography variant="subtitle1" component="span">
                {detail.prompt}
              </Typography>
              <TextField
                color={'secondary'}
                margin="dense"
                id={detail.id}
                name={detail.id}
                type="text"
                onChange={this.handleEditDetail}
                defaultValue={detail.value}
                data-rfp-field
                style={{marginLeft: 24, marginRight: 8}}
              />
              <DeleteIcon
                style={{cursor: 'pointer'}}
                onClick={() => this.handleDeleteDetail(detail.id)}
              />
            </Grid>
          ))}

        <FormControl>
          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ margin: "no... Remove this comment to see the full error message */}
          <KeyboardDatePicker
            margin="normal"
            id="deadlineDate"
            name="deadlineDate"
            label="Complete By Date"
            format="yyyy-MM-dd"
            defaultValue={
              this.state.requestForProposal.deadlineDate
                ? this.state.requestForProposal.deadlineDate
                : null
            }
            onChange={(event, x) => this.handleDateChange(event, x)}
            KeyboardButtonProps={{
              'aria-label': 'deadline date',
            }}
            autoOk={true}
            style={{marginBottom: 16}}
          />
        </FormControl>
        <GooglePlacesAutocomplete
          apiKey={GMAPS_API_KEY}
          onSelect={(location) => this.onAddressSelection(location)}
          autocompletionRequest={this.state.autocompletionRequest}
          placeholder="Address Lookup"
        />

        <TextField
          color={'secondary'}
          margin="dense"
          id="street"
          name="street"
          label="Street"
          type="text"
          onChange={this.handleChange}
          defaultValue={this.state.requestForProposal.street}
          data-rfp-field
          InputLabelProps={{shrink: true}}
          fullWidth
        />

        <TextField
          color={'secondary'}
          margin="dense"
          id="street2"
          name="street2"
          label="Street 2"
          type="text"
          onChange={this.handleChange}
          defaultValue={this.state.requestForProposal.street2}
          data-rfp-field
          InputLabelProps={{shrink: true}}
          fullWidth
        />

        <TextField
          color={'secondary'}
          margin="dense"
          id="city"
          name="city"
          label="City"
          type="text"
          onChange={this.handleChange}
          defaultValue={this.state.requestForProposal.city}
          data-rfp-field
          InputLabelProps={{shrink: true}}
          fullWidth
        />

        <TextField
          color={'secondary'}
          margin="dense"
          id="province"
          name="province"
          label="Province"
          type="text"
          onChange={this.handleChange}
          defaultValue={this.state.requestForProposal.province}
          data-rfp-field
          InputLabelProps={{shrink: true}}
          fullWidth
        />

        <TextField
          color={'secondary'}
          margin="dense"
          id="postal"
          name="postal"
          label="Postal"
          type="text"
          onKeyDown={this.handleChange}
          defaultValue={this.state.requestForProposal.postal}
          data-rfp-field
          InputLabelProps={{shrink: true}}
          fullWidth
        />
        <TextField
          color={'secondary'}
          margin="dense"
          id="lat"
          name="lat"
          label="Latitude"
          type="number"
          onKeyDown={this.handleChange}
          defaultValue={this.state.requestForProposal.lat}
          data-rfp-field
          InputLabelProps={{shrink: true}}
          placeholder={'Latitude'}
          fullWidth
        />
        <TextField
          color={'secondary'}
          margin="dense"
          id="lng"
          name="lng"
          label="Longitude"
          type="number"
          onKeyDown={this.handleChange}
          defaultValue={this.state.requestForProposal.lng}
          data-rfp-field
          InputLabelProps={{shrink: true}}
          placeholder={'Longitude'}
          fullWidth
        />

        <Snackbar
          open={this.state.snackbarOpen}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          autoHideDuration={4000}>
          <Alert>{this.state.snackbarMessage}</Alert>
        </Snackbar>
      </div>
    );
  }
}

export default EditableFormDetails;
