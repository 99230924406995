import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MetricItems from './components/MetricItems';

const styles = (theme: $TSFixMe) => ({
  root: {
    display: 'flex',
    backgroundColor:
      (window as $TSFixMe).bidmii.user.userType === 'REQUESTER'
        ? '#d5a6bd'
        : '#fff',
    minHeight: '100vh',
    backgroundSize: '100%',
  },
});

class Dashboard extends Component {
  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'Readonl... Remove this comment to see the full error message
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <MetricItems />
      </div>
    );
  }
}

export default withStyles(styles)(Dashboard);
