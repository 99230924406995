import React, {useState, useEffect} from 'react';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

const Transactions = (props: $TSFixMe) => {
  const apiRef = useGridApiRef();

  const [rows, setRows] = useState([]);

  const columnVisibilityModel = {
    newHomeownersChange: false,
    newContractorsChange: false,
    totalNewUsersChange: false,
    totalProposalsPlacedChange: false,
    projectsAwardedChange: false,
    projectsCompletedChange: false,
    stagesCompletedChange: false,
    projectsWithThreeBidsPercentChange: false,
    totalProjectsPostedChange: false,
    gmvChange: false,
    awardedGmvChange: false,
    bidmiiRevenueChange: false,
    avgBidPerProjectChange: false,
    fulfillmentRateChange: false,
    totalBidmiiProsChange: false,
    projectsWithThreeBidsWithin24HoursPercentChange: false,
  };
  if (props.interval === 'month') {
    // @ts-ignore
    columnVisibilityModel.week = false;
  }

  useEffect(() => {
    const data = props.data;
    data.shift();
    setRows(data);
  }, [props.data]);

  const numberToMonth = (n: $TSFixMe) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[n - 1];
  };

  const columns: GridColDef[] = [
    {
      headerName: 'Month',
      field: 'month',
      renderCell: (params: $TSFixMe) => (
        <div>{numberToMonth(Number(params?.row?.month))}</div>
      ),
    },
    {
      headerName: 'Week',
      field: 'week',
      renderCell: (params: $TSFixMe) => (
        <div>{`${numberToMonth(Number(params?.row?.month)).substr(0, 3)} ${
          params?.row?.day
        },${params?.row?.year}`}</div>
      ),
    },
    {
      headerName: 'Year',
      field: 'year',
      type: 'number',
      renderCell: (params: $TSFixMe) => <div>{`${params?.row?.year}`}</div>,
    },
    {headerName: 'New Users', field: 'totalNewUsers', type: 'number'},
    {
      headerName: 'New Users Change',
      field: 'totalNewUsersChange',
      type: 'number',
    },
    {headerName: 'New Homeowners', field: 'newHomeowners', type: 'number'},
    {
      headerName: 'New Homeowners Change',
      field: 'newHomeownersChange',
      type: 'number',
    },
    {headerName: 'New Contractors', field: 'newContractors', type: 'number'},
    {
      headerName: 'New Contractors Change',
      field: 'newContractorsChange',
      type: 'number',
    },
    {headerName: 'Bidmii Pros', field: 'totalBidmiiPros', type: 'number'},
    {headerName: 'Trial Bidmii Pros', field: 'totalTrialPros', type: 'number'},
    {headerName: 'Cancelled Pros', field: 'totalCancelledPros', type: 'number'},
    {
      headerName: 'Proposals Placed',
      field: 'totalProposalsPlaced',
      type: 'number',
    },
    {
      headerName: 'Proposals Placed Change',
      field: 'totalProposalsPlacedChange',
      type: 'number',
    },
    {headerName: 'Stages Completed', field: 'stagesCompleted', type: 'number'},
    {headerName: 'Projects Awarded', field: 'projectsAwarded', type: 'number'},
    {
      headerName: 'Projects Awarded Change',
      field: 'projectsAwardedChange',
      type: 'number',
    },
    {
      headerName: 'Projects Completed',
      field: 'projectsCompleted',
      type: 'number',
    },
    {
      headerName: 'Projects Completed Change',
      field: 'projectsCompletedChange',
      type: 'number',
    },
    {
      headerName: '3 Bid Projects',
      field: 'projectsWithThreeBidsPercent',
      type: 'number',
    },
    {
      headerName: '3 Bid Projects Change',
      field: 'projectsWithThreeBidsPercentChange',
      type: 'number',
    },
    {
      headerName: '3 Bid Projects 24 Hrs',
      field: 'projectsWithThreeBidsWithin24HoursPercent',
      type: 'number',
    },
    {
      headerName: 'Projects Posted',
      field: 'totalProjectsPosted',
      type: 'number',
    },
    {
      headerName: 'Projects Posted Change',
      field: 'totalProjectsPostedChange',
      type: 'number',
    },
    {headerName: 'GMV', field: 'gmv', type: 'number'},
    {headerName: 'GMV Change', field: 'gmvChange', type: 'number'},
    {headerName: 'Awarded GMV', field: 'awardedGmv', type: 'number'},
    {
      headerName: 'Awarded GMV Change',
      field: 'awardedGmvChange',
      type: 'number',
    },
    {headerName: 'Revenue', field: 'bidmiiRevenue', type: 'number'},
    {
      headerName: 'Revenue Change',
      field: 'bidmiiRevenueChange',
      type: 'number',
    },
    {headerName: 'Avg Bids/Project', field: 'avgBidPerProject', type: 'number'},
    {
      headerName: 'Avg Bids/Project Change',
      field: 'avgBidPerProjectChange',
      type: 'number',
    },
    {headerName: 'Fulfillment Rate', field: 'fulfillmentRate', type: 'number'},
    {
      headerName: 'Fulfillment Rate Change',
      field: 'fulfillmentRateChange',
      type: 'number',
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 29,
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: columnVisibilityModel,
            },
          }}
          rows={rows}
          columns={columns}
          slots={{toolbar: GridToolbar}}
          checkboxSelection
        />
      </div>
    </div>
  );
};

export default Transactions;
