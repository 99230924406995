import React, {Component} from 'react';
import {SERVER_URL} from '../constants';

type State = $TSFixMe;

class RfpsMap extends Component<$TSFixMe, State> {
  constructor(props: $TSFixMe) {
    super(props);
    this.state = {
      objectData: [],
      url: '',
      filter: props.filter,
    };
  }

  fetchObjectData = () => {
    const url = `${SERVER_URL}v1/rfps?viewType=map&filter=${this.state.filter}&page=0&pageSize=1000`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        this.setState(
          {
            objectData: responseData._embedded.models,
            url: (this.props as $TSFixMe).dataUrl,
          },
          () => {
            this.initMap();
          },
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  createMapMarker =
    (map: $TSFixMe, bounds: $TSFixMe) => (location: $TSFixMe) => {
      const url = `/myRfps/${location.id}`;
      const content = `<div id="content">
              <div id="siteNotice"></div>
              <h1 id="firstHeading" class="firstHeading">${location.title}</h1>
              <div id="bodyContent">
                <p>${location.description}</p>
                <p><a href="${url}">More Info</a></p>
              </div>
            </div>`;
      const infowindow = new (window as $TSFixMe).google.maps.InfoWindow({
        content: content,
      });

      const marker = new (window as $TSFixMe).google.maps.Marker({
        position: new (window as $TSFixMe).google.maps.LatLng(
          Number(location.lat),
          Number(location.lng),
        ),
        title: location.title,
        map: map,
      });
      marker.desc = content;
      bounds.extend(marker.position);
      marker.addListener('click', () => {
        infowindow.open(map, marker);
      });
    };

  createMapsZoomListener = (map: $TSFixMe) => {
    const zoom = 10;
    const mapsInstance = (window as $TSFixMe).google.maps;
    const listener = mapsInstance.event.addListener(map, 'idle', () => {
      map.setZoom(zoom);
      mapsInstance.event.removeListener(listener);
    });
  };

  initMap = () => {
    const map = new (window as $TSFixMe).google.maps.Map(
      document.getElementById('objects_map'),
      {
        center: {lat: 43.538, lng: -80.255},
        zoom: 15,
      },
    );

    const bounds = new (window as $TSFixMe).google.maps.LatLngBounds();
    const markerFactory = this.createMapMarker(map, bounds);
    this.state.objectData
      .filter((location: $TSFixMe) => location.lat && location.lng)
      .forEach(markerFactory);

    this.createMapsZoomListener(map);

    map.fitBounds(bounds);
  };

  componentDidMount() {
    this.fetchObjectData();
  }
  componentDidUpdate() {
    if ((this.props as $TSFixMe).dataUrl !== this.state.url) {
      this.fetchObjectData();
    }
  }

  render() {
    return <div id="objects_map" style={{width: '100%', height: '100%'}} />;
  }
}

export default RfpsMap;
