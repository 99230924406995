import React from 'react';
import {RecoilRoot} from 'recoil';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {LicenseInfo} from '@mui/x-license';
import {createRoot} from 'react-dom/client';

// @ts-ignore
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENCE_KEY);

const container = document.getElementById('root');
// @ts-ignore
const root = createRoot(container); // createRoot instead of ReactDOM.render
root.render(
  <React.StrictMode>
    <RecoilRoot>{React.Children.only(<App />)}</RecoilRoot>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
