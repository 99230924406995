import React from 'react';
import {currencyFormat} from '../../../util/CurrencyFormatter';

export default function StandardTransactionDetails(props: $TSFixMe) {
  if (props.transaction.type === 'PAYOUT') {
    return <p>{currencyFormat(props.transaction.amount)}</p>;
  }

  let type = 'STANDARD';
  if (props.transaction.proposal.type === 'BIDMII_DIRECT') {
    type = 'BIDMII DIRECT';
  }

  const materialAmount = props.transaction.proposal.materialsAmount;
  const labourAmount = props.transaction.proposal.labourAmount;
  const contingencyAmount = props.transaction.proposal.contingencyAmount;
  const discountAmount = props.transaction.amountDiscount;
  const subtotal = materialAmount + labourAmount + contingencyAmount;
  const fees = props.transaction.amountRevenue;
  const taxOnFees = props.transaction.amountRevenueTax;
  const feesTotal = fees + taxOnFees;
  const taxes = props.transaction.amountTax;
  const total = subtotal + taxes;
  const grandTotal = props.transaction.amount;

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'left'}}>
        <div style={{fontSize: 12}}>
          <p>Type: {type}</p>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'left'}}>
        <div style={{fontSize: 12, marginTop: 10}}>
          <p>Labour: {currencyFormat(labourAmount)}</p>
          <p>Materials: {currencyFormat(materialAmount)}</p>
          <p>Contingency: {currencyFormat(contingencyAmount)}</p>
          <p>Subtotal: {currencyFormat(subtotal)}</p>
          {discountAmount && <p>Discount: {currencyFormat(discountAmount)}</p>}
          <p>Taxes: {currencyFormat(taxes)}</p>
          <p>Total: {currencyFormat(total)}</p>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'left'}}>
        <div style={{fontSize: 12, marginTop: 10}}>
          <p>Fees: {currencyFormat(fees)}</p>
          <p>Taxes: {currencyFormat(taxOnFees)}</p>
          <p>Total: {currencyFormat(feesTotal)}</p>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'left'}}>
        <div style={{fontSize: 12, marginTop: 10}}>
          <p style={{fontWeight: 'bold'}}>
            Total: {currencyFormat(grandTotal)}
          </p>
        </div>
      </div>
    </>
  );
}
