import React, {useState} from 'react';
import {Typography, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import ImageIcon from '@material-ui/icons/ImageOutlined';
import {SERVER_URL} from '../../../constants';
import HorizontalThumbnailImageList from '../../../common/HorizontalThumbnailImageList';
import BidmiiCustomLoader from '../../BidmiiCustomLoader/BidmiiCustomLoader';
import BidmiiLinearProgress from '../../BidmiiCustomLoader/BidmiiLinearProgress';
import {storeRef} from '../../../util/StoreImageRef';
import {createUUID} from '../../../util/UUID';
import {ref, uploadBytes} from 'firebase/storage';
import {signInAnonymously} from 'firebase/auth';
import {firebaseStorage, firebaseAuth} from '../../../App';
import {CDN_URL} from 'web/src/constants';
import {v4 as uuidv4} from 'uuid';

const styles = (theme: $TSFixMe) => ({
  root: {
    flexGrow: 1,
  },

  snackBox: {
    width: '100%',
    backgroundColor: '#22ABF7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000000',
    flexDirection: 'column',
    padding: '10px 0 10px 15px',
  },

  textBox: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
  },

  link: {
    color: '#0B79D0',
    textDecoration: 'revert',
  },

  uploadButton: {
    borderRadius: 50,
    marginTop: 15,
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { f... Remove this comment to see the full error message
const useStyle = makeStyles(styles);

export default function AddReviewDialog(props: $TSFixMe) {
  const imageGalleryRef = React.useRef();
  const [step, setStep] = useState(0);
  const classes = useStyle();
  const [title, setTitle] = useState(undefined);
  const [note, setNote] = useState(undefined);
  const [review, setReview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const getReview = () => {
    const fbjwt = localStorage.getItem('Authorization');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    fetch(`${SERVER_URL}v1/reviews/${review.uid}`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setReview(res);
        setLoading(false);
        setDisabled(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createReview = () => {
    console.log('CREATE REVIEW');
    const reviewPayload = {
      title: title,
      note: note,
      state: 'PENDING',
      userId: props.user.id,
    };

    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/reviews`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(reviewPayload),
    })
      .then((res) => res.json())
      .then((res) => {
        setReview(res);
        setLoading(false);
        setStep(1);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleUploadAndStoreDocument = (
    file: $TSFixMe,
    fileRef: $TSFixMe,
    storePath: $TSFixMe,
  ) => {
    const metadata = {
      cacheControl: 'max-age=604800',
    };

    return uploadBytes(fileRef, file, metadata).then((snapShot: $TSFixMe) => {
      const path = storePath.replace('/', '%2F');
      const attachmentReference = {
        description: 'Example image',
        resModel: 'Review',
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        resId: `${review.id}`,
        key: 'before_image',
        name: 'Example image',
        url: `${CDN_URL}${path}?alt=media&token=${uuidv4()}`,
        firebaseRef: storePath,
      };
      storeRef(attachmentReference)
        .then((res) => res.json())
        .then((res) => {
          getReview();
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const onAttachDocument = (event: $TSFixMe) => {
    const file = event.currentTarget.files[0];
    const uid = props.user.uid;
    const fileUUID = createUUID();
    const ext = file.name.split('.')[file.name.split('.').length - 1];
    const storePath = `${uid}/${fileUUID}.${ext}`;
    const fileRef = ref(firebaseStorage, storePath);

    signInAnonymously(firebaseAuth)
      .catch(function (error) {
        console.error('ANONYMOUS LOGIN FAIL');
        console.error(error.code);
        console.error(error.message);
      })
      .then((res) => {
        handleUploadAndStoreDocument(file, fileRef, storePath);
      });
  };

  const handleClickDone = () => {
    setLoading(true);
    const fbjwt = localStorage.getItem('Authorization');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    fetch(`${SERVER_URL}v1/reviews/${review.uid}`, {
      method: 'PUT',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({state: 'COMPLETE'}),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        props.onClose();
        resetFields();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const resetFields = () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setTitle(null);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setNote(null);
    setReview(null);
    setStep(0);
  };

  return (
    <Dialog
      open={props.open}
      fullScreen={window.outerWidth <= 600}
      maxWidth="sm"
      fullWidth>
      {loading ? (
        <BidmiiLinearProgress />
      ) : (
        <div style={{height: 5, backgroundColor: '#fff'}} />
      )}
      <DialogTitle>Add History</DialogTitle>
      <DialogContent>
        {step === 0 && (
          <>
            <TextField
              placeholder="TITLE"
              value={title || ''}
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              onChange={(e) => setTitle(e.target.value)}
              variant="outlined"
              className="rounded-textfield gutter"
              InputLabelProps={{shrink: true}}
            />
            <InputLabel>
              <Typography variant={'subtitle2'}>DESCRIPTION</Typography>
            </InputLabel>
            <TextField
              fullWidth
              multiline
              rows={5}
              maxRows={10}
              name="note"
              required
              value={note || ''}
              id="outlined-multiline-static"
              variant="outlined"
              className={(classes as $TSFixMe).input_field}
              InputLabelProps={{shrink: true}}
              placeholder="Here's an example of some recent work I completed..."
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              onChange={(e) => setNote(e.target.value)}
            />
          </>
        )}

        {step === 1 && (
          <>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <input
                  accept="image/*, .mp4, .avi, .webm"
                  id="myproject-image-file"
                  type="file"
                  multiple
                  onChange={onAttachDocument}
                  style={{display: 'none'}}
                />
                <label htmlFor="myproject-image-file">
                  <Button
                    aria-label="Upload images"
                    component="span"
                    variant="outlined"
                    className={classes.uploadButton}>
                    <ImageIcon style={{marginRight: 6}} />
                    UPLOAD IMAGE
                  </Button>
                </label>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {(review as $TSFixMe)?.attachmentReferences && (
                <HorizontalThumbnailImageList
                  imageGalleryRef={imageGalleryRef}
                  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                  data={review.attachmentReferences}
                  //onImageCapture={onImageCapture}
                  updateRec={getReview}
                  resModel={'Review'}
                  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                  resId={review.id}
                  setDialogLoading={setLoading}
                />
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions style={{width: '100%'}}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{width: '100%', padding: '16px 20px'}}>
          <Grid item>
            <Button
              className="secondary-button"
              onClick={() => {
                props.onClose();
              }}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            {step === 0 && (
              <Button
                className="primary-button"
                disabled={title === null && note === null}
                onClick={createReview}>
                NEXT
              </Button>
            )}
            {step === 1 && (
              <>
                <Button
                  className="primary-button"
                  disabled={title === null && note === null}
                  onClick={() => setStep(0)}
                  style={{marginRight: 15}}>
                  BACK
                </Button>
                <Button
                  className="primary-button"
                  disabled={disabled}
                  onClick={handleClickDone}>
                  DONE
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </DialogActions>
      <BidmiiCustomLoader />
    </Dialog>
  );
}
