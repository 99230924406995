import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {SERVER_URL} from '../../constants';

export default function PaymentDialog(props: $TSFixMe) {
  const handleClose = () => {
    props.handleClose();
  };

  const confirm = () => {
    const reference = (document.getElementById('reference') as $TSFixMe).value;
    if (!reference) {
      // eslint-disable-next-line no-alert
      alert('PLEASE RECORD REFERENCE');
      return;
    }
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(
      `${SERVER_URL}v1/transactions/${props.transaction.id}/record-payout`,
      {
        method: 'POST',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt,
        },
        body: JSON.stringify({
          reference: reference,
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        props.forceTableReload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Record Contractor Payout
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            After submitting payment via e-transfer or other method record
            confirmation code and submit
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            color={'secondary'}
            id="reference"
            label="Confirmation Code"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" onClick={confirm} color="secondary">
            Confirm Payout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
