import React from 'react';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';

type ILine = {
  id: number;
  name: string;
  noOfUnits: number;
  unit: string;
  unitPrice: number;
  price: number;
};

export default function StandardQuoteDetailsTable(props: $TSFixMe) {
  const {lines, proposal} = props;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>Desc</TableCell>
              <TableCell align="right">Qty.</TableCell>
              <TableCell align="right">Unit</TableCell>
              <TableCell align="right">Unit Price</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lines.map((row: ILine) => (
              <TableRow key={row?.id} hover>
                <TableCell>{row?.name}</TableCell>
                <TableCell align="right">{row?.noOfUnits}</TableCell>
                <TableCell align="right">{row?.unit}</TableCell>
                <TableCell align="right">
                  {row?.unitPrice ? `$${row?.unitPrice}` : ''}
                </TableCell>
                <TableCell align="right">{`$${row?.price || 0.0}`}</TableCell>
              </TableRow>
            ))}

            <TableRow key={'contingency'} hover>
              <TableCell>Contingency</TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right">{`$${proposal.contingencyAmount}`}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2} />
              <TableCell colSpan={2}>Subtotal</TableCell>
              <TableCell
                colSpan={1}
                align="right">{`$${proposal.subtotal}`}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2} />
              <TableCell colSpan={2}>Tax</TableCell>
              <TableCell
                colSpan={1}
                align="right">{`$${proposal.taxAmount}`}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>Total</TableCell>
              <TableCell
                colSpan={3}
                align="right">{`$${proposal.total}`}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2} />
              <TableCell colSpan={2}>Fees (@4%)</TableCell>
              <TableCell align="right">{`$${proposal.feeAmount}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} />
              <TableCell colSpan={2}>Tax</TableCell>
              <TableCell align="right">{`$${proposal.feeTaxAmount}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>Total Fees</TableCell>
              <TableCell
                colSpan={3}
                align="right">{`$${proposal.totalFeeAmount}`}</TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Grand Total</TableCell>
              <TableCell colSpan={3} align="right">
                {`$${proposal.grandTotal}`}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </>
  );
}
