import React from 'react';
import {Box} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import parser from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  messageSenderSide: {
    maxWidth: '100%',
    position: 'relative',
    borderColor: 'transparent',
    borderRadius: 5,
    backgroundColor: '#22ABF7',
    marginLeft: '20%',
    '&.MuiBox-root': {
      padding: '16px !important',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '10%',
    },
  },
  messageReceiverSide: {
    maxWidth: '100%',
    position: 'relative',
    borderColor: 'transparent',
    borderRadius: 5,
    backgroundColor: '#E6E6E6',
    marginRight: '20%',
    '&.MuiBox-root': {
      padding: '16px !important',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '10%',
    },
  },
  messageAfter: {
    width: 12,
    height: 12,
    position: 'absolute',
    bottom: 24,
    transform: 'rotate(45deg)',
  },
  timestampSenderSide: {
    maxWidth: '100%',
    position: 'relative',
    borderColor: 'transparent',
    marginLeft: '20%',
    '&.MuiBox-root': {
      padding: '4px !important',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '10%',
    },
    textAlign: 'right',
    fontSize: 6,
  },
  timestampReceiverSide: {
    maxWidth: '100%',
    position: 'relative',
    borderColor: 'transparent',
    marginRight: '20%',
    '&.MuiBox-root': {
      padding: '4px !important',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '10%',
    },
    textAlign: 'left',
    fontSize: 6,
  },
}));

const Message = (props: $TSFixMe) => {
  const classes = useStyles();

  const getMessageContainerClass = () => {
    return props.message.fromRequestingUser
      ? `${classes.messageSenderSide} `
      : `${classes.messageReceiverSide} `;
  };

  const getMessageTimeStampClass = () => {
    return props.message.fromRequestingUser
      ? `${classes.timestampSenderSide} `
      : `${classes.timestampReceiverSide} `;
  };

  return (
    <>
      <Box p={2} m={2} border={1} className={getMessageContainerClass()}>
        {!props.message.fromRequestingUser && (
          <Box
            className={classes.messageAfter}
            style={{left: -6, backgroundColor: '#E6E6E6'}}
          />
        )}
        <div style={{overflowWrap: 'break-word'}}>
          <Typography align="left" style={{fontSize: 14}}>
            {parser(props.message.body || '')}
          </Typography>
          {props.message.hasBeenRead && props.message.fromRequestingUser && (
            <div style={{float: 'right', marginTop: '-5px', fontSize: 10}}>
              Read
              <CheckIcon fontSize="inherit" />
            </div>
          )}
        </div>
        {props.message.fromRequestingUser && (
          <Box
            className={classes.messageAfter}
            style={{right: -6, backgroundColor: '#22ABF7'}}
          />
        )}
      </Box>
      <Box
        mt={-2}
        mb={0}
        pt={0}
        pb={0}
        ml={2}
        mr={2}
        className={getMessageTimeStampClass()}>
        <Typography variant={'caption'}>
          {moment(props.message.createdDate).format('MMM D, YYYY, h:mm A')}
        </Typography>
      </Box>
    </>
  );
};
export default Message;
