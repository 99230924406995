import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {SERVER_URL} from '../../constants';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function SnackAlert(props: $TSFixMe) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = (theme: $TSFixMe) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    justifyContent: 'space-evenly',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  root: {
    width: '100%',
  },

  actionsContainer: {
    marginBottom: theme.spacing(2),
  },

  card: {
    maxWidth: 345,
    //border: '1px solid #E6E6E6',
    boxShadow: 'none',
  },

  media: {
    height: 140,
  },
});

type LoginState = $TSFixMe;

class Login extends Component<$TSFixMe, LoginState> {
  constructor(props: $TSFixMe) {
    super(props);
    this.state = {loading: false};
  }

  handleChange = (event: $TSFixMe) => {
    this.setState({[event.target.name]: event.target.value});
  };

  handleKeyDown = (event: $TSFixMe) => {
    if (event.key === 'Enter') {
      this.login();
    }
  };

  login = () => {
    const that = this;
    this.setState({loading: true});
    const user = {username: this.state.username, password: this.state.password};
    fetch(`${SERVER_URL}v1/login`, {
      method: 'POST',
      body: JSON.stringify(user),
    })
      .then((res) => {
        const jwtToken = res.headers.get('Authorization');
        if (jwtToken !== null) {
          localStorage.setItem('Authorization', jwtToken);
          (this.props as $TSFixMe).history.push('/');
        } else {
          if (res.status === 403) {
            this.setState({
              open: true,
            });

            setTimeout(function () {
              that.setState({open: false});
            }, 3000);
          }
        }
        this.setState({loading: false});
      })
      .catch((err) => {
        console.error(err);
        this.setState({loading: false});
      });
  };

  render() {
    const {classes} = this.props;

    return (
      <div
        style={{width: '100hw', height: '100vh', backgroundColor: '#FFFFFF'}}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <img
              src={process.env.PUBLIC_URL + '/images/logo.png'}
              alt="BidMii Company Logo"
              id="big-img"
            />
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center">
              <Grid item xs={12}>
                <Card className={classes.card} elevation={0}>
                  <CardContent style={{paddingBottom: 0}}>
                    <TextField
                      name="username"
                      placeholder="Username"
                      onChange={this.handleChange}
                      autoFocus
                    />
                    <br />
                    <TextField
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={this.handleChange}
                      onKeyDown={this.handleKeyDown}
                    />
                    <br />
                    <br />
                  </CardContent>
                  {this.state.loading ? (
                    <div style={{height: 40}}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div style={{height: 40}} />
                  )}
                  <CardActions>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={'primary-button'}
                      onClick={this.login}
                      fullWidth>
                      Login
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Snackbar
          open={this.state.open}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          autoHideDuration={4000}>
          <SnackAlert severity={'error'}>
            Login and password did not match
          </SnackAlert>
        </Snackbar>
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { paper: { ... Remove this comment to see the full error message
export default withStyles(styles)(Login);
