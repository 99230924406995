/**
 * Returns date in words "June 1 2020"
 *
 * @param date {Date} date to format
 */

import moment from 'moment';

export const dateAsWords = (date: any, options?: any) => {
  if (!options) {
    options = 'MMM Do, YYYY';
  }
  try {
    return moment(date).format(options);
  } catch (e) {
    return date;
  }
};

export const dateAtMidnight = (originalDate: any) => {
  const date = moment(originalDate, moment.ISO_8601);
  const nextDay = date.add(1, 'days').format('YYYY-MM-DD');
  return moment(nextDay, moment.ISO_8601).subtract(1, 'seconds');
};

export const dateFormat = (date: any, options?: any) => {
  if (!options) {
    options = 'YYYY-MM-DD';
  }
  try {
    return moment(date).format(options);
  } catch (e) {
    return date;
  }
};

export const processedDate = (date: any, options?: any) => {
  let newDate = dateAsWords(date, options).toString();
  if (newDate === 'Invalid Date') {
    newDate = date.substr(0, 10);
  } else if (newDate.indexOf('+0000') !== -1) {
    newDate = newDate.substr(0, 10);
  }
  return newDate;
};

export const createdDate = (date: any, options?: any) => {
  const d1 = moment(date);
  const today = moment().toDate();
  const diff = moment(today).diff(d1, 'days');

  if (!options) {
    options = 'MMM D, YYYY';
  }

  if (diff > 30) {
    return '30+ days ago';
    // let newDate = dateAsWords(date, options).toString();
    // if (newDate === 'Invalid Date') {
    //   newDate = date.substr(0, 10);
    // } else if (newDate.indexOf('+0000') !== -1) {
    //   newDate = newDate.substr(0, 10);
    // }
    // return newDate;
  } else if (diff > 1) {
    return `${diff} days ago`;
  } else if (diff === 1) {
    return 'yesterday';
  } else {
    const duration = moment.duration(moment(today).diff(d1));
    const hours: number = parseInt(duration.asHours().toString(), 10);
    const minutes: number =
      parseInt(duration.asMinutes().toString(), 10) - hours * 60;

    if (hours > 1) {
      return `${hours} hours ago`;
    } else if (hours === 1) {
      return `${hours} hour ago`;
    } else if (minutes > 0) {
      if (minutes === 1) {
        return `${minutes} min ago`;
      }
      return `${minutes} min ago`;
    } else {
      return 'Now';
    }
  }
};

export const expiryDate = (date: any, options?: any) => {
  const d1 = moment(date);
  const today = moment().toDate();
  const diff = moment(today).diff(d1, 'days');

  if (!options) {
    options = 'MMM D, YYYY';
  }

  if (diff < -2) {
    let newDate = dateAsWords(date, options).toString();
    if (newDate === 'Invalid Date') {
      newDate = date.substr(0, 10);
    } else if (newDate.indexOf('+0000') !== -1) {
      newDate = newDate.substr(0, 10);
    }
    return newDate;
  } else if (diff === -2) {
    return '2 days';
  } else if (diff === -1) {
    return 'tomorrow';
  } else {
    const duration = moment.duration(moment(today).diff(d1));
    const hours = parseInt(duration.asHours().toString(), 10);
    const minutes = parseInt(duration.asMinutes().toString(), 10) - hours * 60;

    if (hours < -1) {
      return `${hours} hours`;
    } else if (hours === 1) {
      return `${hours} hour`;
    } else if (minutes < 0) {
      if (minutes === 1) {
        return `${minutes} minute`;
      }
      return `${minutes} minutes`;
    } else {
      return 'Now';
    }
  }
};

export const datePosted = (date: any) => {
  const d1 = moment(date);
  const today = moment().toDate();
  const diff = moment(today).diff(d1, 'days');
  if (diff <= 7) {
    return 'New Project';
  }
};
