import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {SERVER_URL} from '../../../constants';
import IconButton from '@material-ui/core/IconButton';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment/moment';
import {Grid} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  edit_input: {
    width: '100%',
  },
  flex_item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn_gropus: {
    marginTop: 10,
    '& button:first-child': {
      marginRight: 10,
    },
  },
  edit_icon: {
    cursor: 'pointer',
    marginLeft: 10,
  },
}));

function Note(props: $TSFixMe) {
  const classes = useStyles();
  const [note, setNote] = useState(props.note);
  const [editableNote, setEditableNote] = useState(false);
  const [showEditNote, setShowEditNote] = useState(false);
  const [showSendSms, setShowSendSms] = useState(false);
  const [showSendDialog, setShowSendDialog] = useState(false);

  const saveNote = () => {
    const url = `${SERVER_URL}v1/rfps/notes/${note.id}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'PUT',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({note: note.note}),
    }).then((res) => {
      setEditableNote(false);
    });
  };

  const discardNote = () => {
    setEditableNote(false);
  };

  const handleOnClickSendSms = () => {
    props.handleOnClickSendSms(note).then(() => {
      setShowSendDialog(false);
    });
  };

  return (
    <Card style={{margin: '10px', borderRadius: '10px'}} variant={'outlined'}>
      {editableNote ? (
        <CardContent>
          <TextField
            id="edit-question"
            value={note.note}
            className={classes.edit_input}
            onChange={(e) => setNote({...note, note: e.target.value})}
          />
          <div className={`${classes.btn_gropus} ${classes.flex_item}`}>
            <Button variant="contained" onClick={() => saveNote()}>
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => discardNote()}>
              Discard
            </Button>
          </div>
        </CardContent>
      ) : (
        <CardContent
          onMouseEnter={() => setShowEditNote(true)}
          onMouseLeave={() => setShowEditNote(false)}>
          <Typography gutterBottom variant="h5" component="span">
            {note.note}
          </Typography>
          {showEditNote ? (
            <EditIcon
              onClick={() => setEditableNote(true)}
              className={classes.edit_icon}
            />
          ) : (
            ''
          )}
        </CardContent>
      )}
      <CardActions
        onMouseEnter={() => setShowSendSms(true)}
        onMouseLeave={() => setShowSendSms(false)}>
        <div style={{width: '100%'}}>
          {!editableNote && showSendSms && (
            <IconButton
              aria-label="send sms"
              style={{float: 'left'}}
              onClick={() => setShowSendDialog(true)}>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <PhoneIphoneIcon size={'small'} />
            </IconButton>
          )}
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography
                component="span"
                variant="subtitle2"
                style={{marginLeft: 12}}>
                {moment(note.createDate).format('MMM D, YYYY, h:mm A')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                component="span"
                variant="subtitle2"
                style={{marginRight: 12}}>
                {note.createdBy
                  ? `${note.createdBy.firstName || ''} ${
                      note.createdBy.lastName || ''
                    } `
                  : ''}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </CardActions>
      <Dialog open={showSendDialog} onClose={() => setShowSendDialog(false)}>
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={handleOnClickSendSms}>
          Confirm Send
        </Button>
      </Dialog>
    </Card>
  );
}

export default Note;
