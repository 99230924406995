import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Button} from '@material-ui/core';

const useStyles = (props: $TSFixMe) =>
  makeStyles(() => ({
    primaryButton: {
      borderRadius: '50px',
      color: '#000000',

      '&:hover': {
        backgroundColor: props.hoverColor
          ? `${props.hoverColor} !important`
          : '#000000 !important',
        color: '#ffffff',
      },

      '&:disabled': {
        backgroundColor: '#e6e6e6 !important',
        color: 'rgba(0, 0, 0, 0.25)',
      },
    },
  }));

export default function PrimaryButton(props: $TSFixMe) {
  const classes = useStyles(props)();
  const [disabled, setDisabled] = useState(false);

  const handleClick = (event: $TSFixMe) => {
    setDisabled(true);
    Promise.resolve(props.onClick(event)).then(() => setDisabled(false));
  };

  const size = props.size ? props.size : 'normal';

  const sizes = {
    xsmall: {
      fontSize: 10,
      padding: '0 12px',
      height: 24,
    },
    small: {
      fontSize: 12,
      padding: '2px 14px 0',
      height: 28,
    },
    normal: {
      fontSize: 14,
      padding: '0 16px',
      height: 32,
    },
    large: {
      fontSize: 16,
      padding: '0 18px',
      height: 36,
    },
    xlarge: {
      fontSize: 18,
      padding: '0 20px',
      height: 40,
    },
  };

  const styles = {
    ...props.style,
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    fontSize: sizes[size].fontSize,
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    padding: sizes[size].padding,
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    height: sizes[size].height,
    backgroundColor: props.color ? props.color : '#e6e6e6',
  };

  return (
    <Button
      {...props}
      onClick={props.onClick && handleClick}
      className={`${classes.primaryButton} ${props.className}`}
      style={styles}
      disabled={props.disabled || disabled}
      component={props.component}
      id={props.id || undefined}>
      {props.children}
    </Button>
  );
}
