import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import './App.css';
import Login from './modules/Login/Login';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {MainRouter} from './modules';
import {ThemeProvider} from '@material-ui/core/styles';
import {theme} from './Theme';
import {FIREBASE_CONFIG} from './constants';
import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';

export const firebaseApp = initializeApp(FIREBASE_CONFIG);
export const firebaseAuth = getAuth(firebaseApp);
export const firestoreDb = getFirestore(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);

const App = (props: $TSFixMe) => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route path="/" component={MainRouter} />
              <Route render={() => <h1>Page not found</h1>} />
            </Switch>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
