import React, {useCallback, useEffect, useState} from 'react';
import {
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  List,
  Tooltip,
  Typography,
  TextField,
  MenuItem,
} from '@material-ui/core';
import CKEditor from '@ckeditor/ckeditor5-react';
import {Edit, DeleteForever, Visibility} from '@material-ui/icons';
import MessagesPanel from '../Messaging/components/MessagesPanel';
import moment from 'moment';
import {red} from '@material-ui/core/colors';
import {getProfileImage} from '../../util/Profile';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parser from 'html-react-parser';
import {SERVER_URL} from '../../constants';
import {useHistory} from 'react-router';
import {DateTimePicker} from '@material-ui/pickers';
import Review from '../Review';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    textAlign: 'left',
    borderRadius: '1rem',
    marginBottom: 15,
  },
  media: {
    height: 300,
    width: '100 %',
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
  actions: {
    flex: 'initial',
  },
  edit_input: {
    width: '100%',
  },
  btn_gropus: {
    marginTop: 10,
    '& button:first-child': {
      marginRight: 10,
    },
  },
  flex_item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  edit_icon: {
    cursor: 'pointer',
    marginLeft: 10,
  },
  flex: {
    display: 'flex',
    '& p': {
      margin: 0,
    },
  },
  edit_part: {
    marginTop: 20,
  },
}));

export default function SiteVisitSmallCard(props: $TSFixMe) {
  const classes = useStyles();
  const history = useHistory();
  const [contractor, setContractor] = useState(null);
  const [review, setReview] = useState(null);
  const [description, setDescription] = useState(props.siteVisit.description);
  const [editable, setEditable] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [newDate, setNewDate] = useState(null);

  const chipColors = {
    REQUESTED: '#906FCE',
    APPROVED: '#22ABF7',
    COMPLETE: '#228848',
    REJECTED: '#F93822',
    NO_SHOW: '#F93822',
  };

  const getContractorUser = useCallback(() => {
    const url = `${SERVER_URL}v1/users/${props.siteVisit.contractorId}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        setContractor(res);
      })
      .catch((err) => console.error(err));
  }, [props.siteVisit.contractorId]);

  const getReview = useCallback(() => {
    const url = `${SERVER_URL}v1/reviews/${props.siteVisit.reviewUID}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        setReview(res);
      })
      .catch((err) => console.error(err));
  }, [props.siteVisit.reviewUID]);

  useEffect(() => {
    getContractorUser();
    getReview();
  }, [getContractorUser, getReview]);

  const viewContractorDetails = (userId: $TSFixMe) => {
    history.push(`/users/${userId}`);
  };

  const updateSiteVisit = (key: $TSFixMe, value: $TSFixMe) => {
    const url = `${SERVER_URL}v1/sitevisits/${props.siteVisit.uid}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({[key]: value}),
    }).then((res) => {
      setEditable(false);
      props.getSiteVisits();
    });
  };

  const deleteSiteVisit = () => {
    const url = `${SERVER_URL}v1/sitevisits/${props.siteVisit.uid}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'DELETE',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        setOpenDeleteDialog(false);
        props.getSiteVisits();
      })
      .catch((err) => console.error(err));
  };

  const handleDateChange = (event: $TSFixMe) => {
    const date = event.currentTarget ? event.currentTarget.value : event;
    const calcDate = moment(date, moment.ISO_8601);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Moment' is not assignable to par... Remove this comment to see the full error message
    setNewDate(calcDate);
  };

  const getTitle = () => {
    return (
      <Box mb={1}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            {contractor && (
              <span style={{display: 'flex', alignItems: 'center'}}>
                <Tooltip title="View Profile">
                  {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
                  <Button onClick={() => viewContractorDetails(contractor.id)}>
                    <Avatar
                      aria-label="recipe"
                      className={classes.avatar}
                      src={getProfileImage(contractor, '100x100')}
                    />
                  </Button>
                </Tooltip>
                {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
                <Typography variant="h6">{contractor.name}</Typography>
              </span>
            )}
          </Grid>
          <span>
            {editable ? (
              <Visibility
                style={{cursor: 'pointer', marginRight: 16}}
                onClick={() => setEditable(false)}
              />
            ) : (
              <Edit
                style={{cursor: 'pointer', marginRight: 16}}
                onClick={() => setEditable(true)}
              />
            )}
            <DeleteForever
              style={{cursor: 'pointer'}}
              onClick={() => setOpenDeleteDialog(true)}
            />
            <Dialog
              open={openDeleteDialog}
              aria-labelledby="simple-dialog-title">
              <DialogTitle id="simple-dialog-title">
                Are you sure you want to delete this site visit?
              </DialogTitle>
              <DialogActions
                style={{display: 'flex', justifyContent: 'space-around'}}>
                <Button onClick={() => setOpenDeleteDialog(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={deleteSiteVisit}
                  variant="contained"
                  style={{color: red[700]}}>
                  DELETE
                </Button>
              </DialogActions>
            </Dialog>
          </span>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          {['REQUESTED', 'REJECTED'].includes(
            props.siteVisit.siteVisitState,
          ) && (
            <>
              <Grid item>
                <Chip
                  label={`OPTION 1: ${moment(
                    props.siteVisit.dateOption1,
                  ).format('MMMM Do YYYY, h:mm A')}`}
                />
              </Grid>
              <Grid item>
                <Chip
                  label={`OPTION 2: ${moment(
                    props.siteVisit.dateOption2,
                  ).format('MMMM Do YYYY, h:mm A')}`}
                />
              </Grid>
              <Grid item>
                <Chip
                  label={`OPTION 3: ${moment(
                    props.siteVisit.dateOption3,
                  ).format('MMMM Do YYYY, h:mm A')}`}
                />
              </Grid>
            </>
          )}
          <Grid item>
            {editable ? (
              <TextField
                select
                value={props.siteVisit.siteVisitState}
                onChange={(e) => updateSiteVisit('state', e.target.value)}>
                <MenuItem value="REQUESTED">REQUESTED</MenuItem>
                <MenuItem value="REJECTED">REJECTED</MenuItem>
                <MenuItem value="APPROVED">APPROVED</MenuItem>
                <MenuItem value="NO_SHOW">NO_SHOW</MenuItem>
                <MenuItem value="COMPLETE">COMPLETE</MenuItem>
              </TextField>
            ) : (
              <Chip
                label={props.siteVisit.siteVisitState}
                style={{
                  color: '#fff',
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  backgroundColor: chipColors[props.siteVisit.siteVisitState],
                }}
              />
            )}
          </Grid>
          {!['REQUESTED', 'REJECTED'].includes(
            props.siteVisit.siteVisitState,
          ) && (
            <Grid item>
              {editable ? (
                <DateTimePicker
                  variant="inline"
                  disablePast
                  autoOk
                  value={props.siteVisit.visitDateTime}
                  onChange={handleDateChange}
                  onClose={() => updateSiteVisit('dateTime', newDate)}
                  style={{width: 220, marginLeft: 16}}
                />
              ) : (
                <Chip
                  label={`DATE AND TIME: ${moment(
                    props.siteVisit.visitDateTime,
                  ).format('MMMM Do YYYY, h:mm A')}`}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

  const onChangeHandler = (event: $TSFixMe, editor: $TSFixMe) => {
    const data = editor.getData();
    setDescription(data);
  };

  const getDescription = () => {
    return editable ? (
      <CardContent>
        <div className={classes.edit_part}>
          <CKEditor
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ placeholder: string; editor: typeof Classi... Remove this comment to see the full error message
            placeholder="Description"
            editor={ClassicEditor}
            data={description}
            config={{toolbar: []}}
            onChange={(event, editor) => onChangeHandler(event, editor)}
          />
          <div className={`${classes.btn_gropus} ${classes.flex_item}`}>
            <Button
              variant="contained"
              onClick={() => updateSiteVisit('description', description)}>
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditable(false)}>
              Discard
            </Button>
          </div>
        </div>
      </CardContent>
    ) : (
      <Typography color="textPrimary">
        {parser(props.siteVisit.description || '')}
      </Typography>
    );
  };

  return (
    <Card className={classes.root} variant={'outlined'}>
      <CardHeader title={getTitle()} />
      <CardContent>
        <Grid
          container
          justifyContent="center"
          style={{marginTop: 20, marginBottom: 20}}
          spacing={1}>
          <Grid item xs={6} md={6} key={1}>
            {getDescription()}
            {review && props.siteVisit.siteVisitState === 'COMPLETE' && (
              // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              <Review review={review} user={review.reviewed.name} />
            )}
          </Grid>
          <Grid item xs={6} md={6} key={3}>
            <List component="nav" style={{marginRight: '10px'}}>
              <MessagesPanel
                id={props.siteVisit.conversationId}
                hidden={false}
                central={false}
              />
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
