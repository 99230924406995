import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';
import HomeownerAvatar from '../../../util/HomeownerAvatar';
import ContractorAvatar from '../../../util/ContractorAvatar';
import {dateAsWords} from '../../../util/DateAsWords';
import {currencyFormat} from '../../../util/CurrencyFormatter';
import RefundTransactionDialog from '../RefundTransactionDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 450,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function LegacyTransactionDetails(props: $TSFixMe) {
  const classes = useStyles();

  const getAvatar = () => {
    if (props.transaction.user.userType === 'REQUESTER') {
      return <HomeownerAvatar user={props.transaction.user} />;
    } else {
      return <ContractorAvatar user={props.transaction.user} />;
    }
  };

  const priceBreakdownLegacy = (data: $TSFixMe) => {
    if (data.type === 'PAYOUT') {
      return <p>{currencyFormat(data.amount)}</p>;
    }

    let type = 'STANDARD';
    if (data.proposal.type === 'BIDMII_DIRECT') {
      type = 'BIDMII DIRECT';
    }

    const materialAmount = data.proposal.materialsAmount;
    const labourAmount = data.proposal.labourAmount;
    const contingencyAmount = data.proposal.contingencyAmount;
    const discountAmount = data.amountDiscount;
    const subtotal = materialAmount + labourAmount + contingencyAmount;
    const fees = data.amountRevenue;
    const taxes = data.amountTax;
    const total = data.amount;

    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{fontSize: 12}}>
          <p>Type: {type}</p>
          <p>Labour: {currencyFormat(labourAmount)}</p>
          <p>Materials: {currencyFormat(materialAmount)}</p>
          <p>Contingency: {currencyFormat(contingencyAmount)}</p>
          <p>Subtotal: {currencyFormat(subtotal)}</p>
          {discountAmount && <p>Discount: {currencyFormat(discountAmount)}</p>}
          <p>Fees: {currencyFormat(fees)}</p>
          <p>Taxes: {currencyFormat(taxes)}</p>
          <p>Total: {currencyFormat(total)}</p>
        </div>
      </div>
    );
  };

  const priceBreakdownNoFees = (data: $TSFixMe) => {
    if (data.type === 'PAYOUT') {
      return <p>{currencyFormat(data.amount)}</p>;
    }

    let type = 'STANDARD';
    if (data.proposal.type === 'BIDMII_DIRECT') {
      type = 'BIDMII DIRECT';
    }

    const materialAmount = data.proposal.materialsAmount;
    const labourAmount = data.proposal.labourAmount;
    const contingencyAmount = data.proposal.contingencyAmount;
    const discountAmount = data.amountDiscount;
    const subtotal = materialAmount + labourAmount + contingencyAmount;
    const fees = data.amountRevenue;
    const taxOnFees = data.amountRevenueTax;
    const feesTotal = fees + taxOnFees;
    const taxes = data.amountTax;
    const total = subtotal + taxes;
    const grandTotal = data.amount;

    return (
      <>
        <div style={{display: 'flex', justifyContent: 'left'}}>
          <div style={{fontSize: 12}}>
            <p>Type: {type}</p>
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'left'}}>
          <div style={{fontSize: 12, marginTop: 10}}>
            <p>Labour: {currencyFormat(labourAmount)}</p>
            <p>Materials: {currencyFormat(materialAmount)}</p>
            <p>Contingency: {currencyFormat(contingencyAmount)}</p>
            <p>Subtotal: {currencyFormat(subtotal)}</p>
            {discountAmount && (
              <p>Discount: {currencyFormat(discountAmount)}</p>
            )}
            <p>Taxes: {currencyFormat(taxes)}</p>
            <p>Total: {currencyFormat(total)}</p>
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'left'}}>
          <div style={{fontSize: 12, marginTop: 10}}>
            <p>Fees: {currencyFormat(fees)}</p>
            <p>Taxes: {currencyFormat(taxOnFees)}</p>
            <p>Total: {currencyFormat(feesTotal)}</p>
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'left'}}>
          <div style={{fontSize: 12, marginTop: 10}}>
            <p style={{fontWeight: 'bold'}}>
              Total: {currencyFormat(grandTotal)}
            </p>
          </div>
        </div>
      </>
    );
  };

  const getPriceBreakdown = () => {
    const data = props.transaction;
    if (data.feeFactor === 0.04) {
      return priceBreakdownNoFees(data);
    } else {
      return priceBreakdownLegacy(data);
    }
  };

  return (
    <Card className={classes.root}>
      {props.transaction.type === 'PAYMENT' && (
        <RefundTransactionDialog transaction={props.transaction} />
      )}
      <CardHeader
        avatar={getAvatar()}
        title={props.transaction.user.name}
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        subheader={dateAsWords(props.transaction.date)}
        action={getPriceBreakdown()} //currencyFormat(props.transaction.amount)
      />
      <CardContent>
        <Typography variant="body1" style={{fontWeight: 600, color: '#000000'}}>
          Email:{' '}
          <Typography variant="body2" color="textSecondary" component="span">
            {props.transaction.user.billingEmail ||
              props.transaction.user.email}
          </Typography>
        </Typography>
        <br />
        <Typography variant="body1" style={{fontWeight: 600, color: '#000000'}}>
          Phone:{' '}
          <Typography variant="body2" color="textSecondary" component="span">
            {props.transaction.user.phone}
          </Typography>
        </Typography>
      </CardContent>
    </Card>
  );
}
