import React, {useCallback, useEffect, useState} from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {SERVER_URL} from '../../../constants';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Grid from '@material-ui/core/Grid';
import MessagesPanel from '../../Messaging/components/MessagesPanel';

type IProps = {
  requestForProposal: any;
};

const CustomToolbar = (props: $TSFixMe) => {
  return (
    <GridToolbarContainer style={{paddingTop: 25}}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

function ConversationsTable(props: IProps) {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const apiRef = useGridApiRef();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize]);

  const getData = useCallback(() => {
    fetch(
      `${SERVER_URL}v1/rfps/${props.requestForProposal.id}/conversations?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setRowCount(res.page.totalElements);
        setRows(res._embedded?.models || []);
        setLoading(false);
      });
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    props.requestForProposal.id,
  ]);

  const getConversationParticipant = (rowData: $TSFixMe) => {
    return rowData.members.filter(
      (member: $TSFixMe) => member.id !== props.requestForProposal.user.id,
    )[0];
  };

  const columns: GridColDef[] = [
    {headerName: 'ID', field: 'id', type: 'number'},
    {
      headerName: 'First',
      field: 'firstName',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        getConversationParticipant(row)?.firstName,
      width: 150,
    },
    {
      headerName: 'Last',
      field: 'lastName',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        getConversationParticipant(row)?.lastName,
      width: 150,
    },
    {
      headerName: 'City',
      field: 'city',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        getConversationParticipant(row)?.city,
      width: 100,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="phone user"
            component="span"
            style={{marginRight: 8}}>
            <PhoneIcon style={{fontSize: 17}} />
          </IconButton>
          {getConversationParticipant(params.row)?.phone || ''}
        </span>
      ),
      width: 150,
    },
    {
      headerName: 'Email',
      field: 'email',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="email user"
            component="span"
            style={{marginRight: 8}}>
            <EmailIcon style={{fontSize: 17}} />
          </IconButton>
          {getConversationParticipant(params.row)?.email || ''}
        </span>
      ),
      width: 180,
    },
    {
      headerName: 'Last Active',
      field: 'lastOnline',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        moment(getConversationParticipant(row)?.lastOnline).format(
          'MMM D, YYYY, h:mm A',
        ),
      width: 250,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          icon={<OpenInNewIcon />}
          onClick={() => {
            window.open(
              `${window.location.origin}/users/${
                getConversationParticipant(params.row)?.id
              }`,
              '_blank',
            );
          }}
          label="View User"
        />,
      ],
      width: 100,
    },
  ];

  if (!loading && !rows) {
    return (
      <DialogContent>
        <Typography variant="h6" component="h6">
          No conversations yet.
        </Typography>
      </DialogContent>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 30,
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          apiRef={apiRef}
          getDetailPanelContent={({row}) => (
            <div>
              <h2 style={{textAlign: 'center'}}>Messages</h2>
              <Grid container spacing={3}>
                <Grid item xs={2} />
                <Grid item xs={8}>
                  <MessagesPanel id={row.id} hidden={false} central={false} />
                </Grid>
                <Grid item xs={2} />
              </Grid>
            </div>
          )}
          getDetailPanelHeight={() => 'auto'}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 500},
            },
          }}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50]}
          pagination
          paginationMode={'server'}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default ConversationsTable;
