import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {SERVER_URL} from '../../../constants';

const styles = (theme: $TSFixMe) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { m... Remove this comment to see the full error message
const DialogTitle = withStyles(styles)((props) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'PropsWi... Remove this comment to see the full error message
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PromoteProjectDialog(props: $TSFixMe) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmToggleRfpPromotion = () => {
    const url = `${SERVER_URL}v1/rfps/${props.rfpId}/toggle-promote`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        props.updateRequestForProposalState().then(() => {
          handleClose();
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <span>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        style={{backgroundColor: '#FFF', margin: 10}}>
        {props.promoted ? 'Demote' : 'Promote'}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; id: string; onClose: () ... Remove this comment to see the full error message */}
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.promoted ? 'Remove Promotion' : 'Promote Project'}
        </DialogTitle>
        <DialogContent>
          Are you sure you want to {props.promoted ? 'demote' : 'promote'} this
          project?
        </DialogContent>
        <DialogActions>
          <Button style={{float: 'left'}} autoFocus onClick={handleClose}>
            Close
          </Button>
          <Button
            color={'secondary'}
            variant={'contained'}
            onClick={() => {
              confirmToggleRfpPromotion();
              handleClose();
            }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
