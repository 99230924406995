import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {SERVER_URL} from '../../../constants';
import {useHistory} from 'react-router-dom';
import {Box, InputLabel, MenuItem, Select, Snackbar} from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@material-ui/core/FormControl';

export default function CreateUserDialog(props: $TSFixMe) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const [snackBarVisible, setSnackBarVisible] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: $TSFixMe) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    userData[name] = value;
    setUserData({...userData});
  };

  const addNewUser = () => {
    const jwt = localStorage.getItem('Authorization');
    const body = JSON.stringify({
      username: (userData as $TSFixMe).username,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      password: userData.password,
      userType: (userData as $TSFixMe).userType,
    });
    return fetch(`${SERVER_URL}v1/users`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
      body: body,
    })
      .then((res) => {
        if (res.status === 409) {
          return res;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        if (res && res.id) {
          console.log(res);
          history.push(`/users/${res.id}`);
          handleClose();
        } else {
          setSnackBarVisible(true);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const hideSnackBar = (event: $TSFixMe) => {
    setSnackBarVisible(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} color={'secondary'} size={'small'}>
        <AddIcon fontSize="small" /> Create
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Username"
            type="text"
            name="username"
            fullWidth
            onChange={handleChange}
            value={(userData as $TSFixMe).username}
          />
          <TextField
            autoFocus
            margin="dense"
            id="firstName"
            label="First Name"
            type="text"
            name="firstName"
            fullWidth
            onChange={handleChange}
            value={userData.firstName}
          />
          <TextField
            autoFocus
            margin="dense"
            id="lastName"
            label="Last Name"
            type="text"
            fullWidth
            name="lastName"
            onChange={handleChange}
            value={userData.lastName}
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            name="email"
            onChange={handleChange}
            value={userData.email}
          />
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            name="password"
            onChange={handleChange}
            value={userData.password}
          />
          <Box sx={{minWidth: 120}}>
            <FormControl fullWidth>
              <InputLabel id="user-type-input-label">Type</InputLabel>
              <Select
                labelId="user-type-input-label"
                id="user-type-input-label"
                name={'userType'}
                label="User Type"
                onChange={handleChange}>
                <MenuItem value={'ADMIN'}>ADMIN</MenuItem>
                <MenuItem value={'PARTNER'}>PARTNER</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={addNewUser}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackBarVisible}
        onClose={hideSnackBar}
        message="User already exists"
      />
    </>
  );
}
