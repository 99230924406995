import {SERVER_URL} from '../constants';

export const storeRef = (attachmentReference: $TSFixMe) => {
  const fbjwt = localStorage.getItem('Authorization');
  return fetch(`${SERVER_URL}v1/attachments`, {
    method: 'POST',
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
    headers: {
      'Content-Type': 'application/json',
      Authorization: fbjwt,
    },
    body: JSON.stringify(attachmentReference),
  });
};

export const updateRef = async (attachmentReference: $TSFixMe) => {
  const fbjwt = localStorage.getItem('Authorization');
  return await fetch(`${SERVER_URL}v1/attachments/${attachmentReference.id}`, {
    method: 'PATCH',
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
    headers: {
      'Content-Type': 'application/json',
      Authorization: fbjwt,
    },
    body: JSON.stringify(attachmentReference),
  });
};
