import React, {useCallback, useEffect, useState} from 'react';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {SERVER_URL} from '../../../constants';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import PhoneIcon from '@material-ui/icons/Phone';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CallsDetailPanel from './CallsDetailPanel';

type IProps = {
  requestForProposal: any;
};

const CustomToolbar = (props: $TSFixMe) => {
  return (
    <GridToolbarContainer style={{paddingTop: 25}}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

function CallsTab(props: IProps) {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const apiRef = useGridApiRef();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize]);

  const getData = useCallback(() => {
    fetch(
      `${SERVER_URL}v1/rfps/${props.requestForProposal.id}/scheduled-calls?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (paginationModel.page === 0) {
          setRowCount(res.page.totalElements);
          setRows(res._embedded?.models || []);
        } else {
          // @ts-ignore
          setRows((prevState: any) => [...prevState, res._embedded?.models]);
        }

        setLoading(false);
      });
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    props.requestForProposal.id,
  ]);

  const columns: GridColDef[] = [
    {headerName: 'ID', field: 'id', type: 'number'},
    {
      headerName: 'Created',
      field: 'createdDate',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        moment(row.createdDate).format('MMM D, YYYY, h:mm A'),
      width: 200,
    },
    {
      headerName: 'Name',
      field: 'user',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) => row.contractor.name,
      width: 200,
    },
    {
      headerName: 'Status',
      field: 'state',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) => row.state,
      width: 200,
    },
    {
      headerName: 'Duration',
      field: 'duration',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) => row.duration,
      width: 70,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="phone user"
            component="span"
            style={{marginRight: 8}}>
            <PhoneIcon style={{fontSize: 17}} />
          </IconButton>
          {params?.row?.contractor.phone || ''}
        </span>
      ),
      width: 140,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          icon={<OpenInNewIcon />}
          onClick={() => {
            window.open(
              `${window.location.origin}/users/${params?.row?.contractor.id}`,
              '_blank',
            );
          }}
          label="View User"
        />,
      ],
      width: 100,
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 30,
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 500},
            },
          }}
          getRowId={(row) => row?.uid}
          getDetailPanelContent={({row}) => <CallsDetailPanel call={row} />}
          getDetailPanelHeight={({row}) => 'auto'} // Height based on the content.
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50]}
          pagination
          paginationMode={'server'}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default CallsTab;
