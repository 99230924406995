import React, {useEffect, useState, useCallback} from 'react';
import {Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {SERVER_URL} from '../../../constants';
import Message from './Message';
import useScreenWidth from '../../../common/useScreenWidth';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  MessagetextArea: {
    background: 'white',
    marginBottom: window.outerWidth < 600 ? 4 : 0,

    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
}));

const MessagesPanel = (props: $TSFixMe) => {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const width = useScreenWidth();

  const messageCards = messages
    ? messages.map((message) => {
        return <Message key={(message as $TSFixMe).id} message={message} />;
      })
    : null;

  const adjustMessagesContainerScroll = useCallback(() => {
    setTimeout(function () {
      //TODO: use https://gist.github.com/jamesreggio/142215754ad06f375bd87657c6227ed8
      const messagesContainer = document.getElementById('messagesContainer');
      if (messagesContainer !== null) {
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      }
    }, 100);
  }, []);

  const getMessages = useCallback(() => {
    const fbjwt = localStorage.getItem('Authorization');
    const url = `${SERVER_URL}v1/conversations/${props.id}`;
    fetch(url, {
      method: 'GET',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        setMessages(res);
      })
      .then(() => {
        adjustMessagesContainerScroll();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.id, adjustMessagesContainerScroll]);

  useEffect(() => {
    if (props.id) {
      getMessages();
    }
  }, [getMessages, props.id]);

  return (
    <>
      <Paper
        className={classes.paper}
        style={
          props.central
            ? {
                height:
                  width > 600 ? 'calc(100vh - 232px)' : 'calc(100vh - 294px)',
                overflowY: 'scroll',
                padding: 24,
                borderRadius: 0,
              }
            : {maxHeight: '40vh', overflowY: 'auto', padding: 16}
        }
        id={'messagesContainer'}>
        {messages && messages.length !== 0 && messageCards}
      </Paper>
    </>
  );
};
export default MessagesPanel;
