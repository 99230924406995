import React, {useState, useEffect, useCallback} from 'react';
import {CDN_URL, SERVER_URL} from '../../constants';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import EditBadgeDialog from './components/EditBadgeDialog';

const CustomToolbar = (props: $TSFixMe) => {
  return (
    <GridToolbarContainer style={{paddingTop: 25}}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <GridToolbarQuickFilter style={{position: 'fixed', right: 50}} />
    </GridToolbarContainer>
  );
};

const BadgesList = (props: $TSFixMe) => {
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [badgeSelectedForEdit, setBadgeSelectedForEdit] = useState(null);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });

  const apiRef = useGridApiRef();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize, props.filter]);

  const getData = useCallback(() => {
    fetch(
      `${SERVER_URL}v1/badges?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setRowCount(res.page.totalElements);
        setRows(res._embedded?.models || []);
        setLoading(false);
      });
  }, [paginationModel.page, paginationModel.pageSize]);

  /*
  const handleDeleteBadge = (badgeId: $TSFixMe) => {
    const url = `${SERVER_URL}v1/badges/${badgeId}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'DELETE',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        getData();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  */

  const handleUpdateBadge = (
    badgeId: $TSFixMe,
    badgeName: string,
    description: string,
  ) => {
    const url = `${SERVER_URL}v1/badges/${badgeId}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        badgeName: badgeName,
        description: description,
      }),
    })
      .then(() => {
        setBadgeSelectedForEdit(null);
        getData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns: GridColDef[] = [
    {headerName: 'ID', field: 'id', type: 'number'},
    {
      headerName: 'Image',
      field: 'awardedImage',
      renderCell: (params: $TSFixMe) => {
        const awardedImage = params?.row?.awardedImage.url.includes('https')
          ? params?.row?.awardedImage.url
          : `${CDN_URL}/media/badges/${params?.row?.awardedImage.url.replace(
              'images/badges/',
              '',
            )}`;
        return (
          <>
            <img
              src={awardedImage}
              alt={`${params?.row?.badgeTitle} Badge`}
              style={{width: 55}}
            />
          </>
        );
      },
    },
    {
      headerName: 'Name',
      field: 'badgeTitle',
      width: 250,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => {
            setBadgeSelectedForEdit(params.row);
          }}
          label="Edit"
        />,
        /*
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => {
            handleDeleteBadge(params.id);
          }}
          label="Delete"
        />,*/
      ],
      width: 200,
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 30,
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          getRowHeight={() => 'auto'}
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 500},
              getData: getData,
            },
          }}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[50, 100, 150, 200, 300, 400, 500, 750, 1000]}
          pagination
          paginationMode={'server'}
          loading={loading}
        />
      </div>
      {badgeSelectedForEdit && (
        <EditBadgeDialog
          open={true}
          handleClose={() => setBadgeSelectedForEdit(null)}
          updateBadge={handleUpdateBadge}
          badge={badgeSelectedForEdit}
        />
      )}
    </div>
  );
};

export default BadgesList;
