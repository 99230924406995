/**
 *
 * @param amount
 * @param trim
 */
export const currencyFormat = (amount: $TSFixMe, trim?: boolean) => {
  if (amount === 0 || amount === null || amount === undefined) {
    return '$0.00';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'CAD',
  });

  let formattedAmount = formatter.format(amount);

  if (trim) {
    formattedAmount = formattedAmount.substr(
      amount >= 0 ? 2 : 3,
      formattedAmount.length - 1,
    );
  }

  return formattedAmount;
};
