import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import {SERVER_URL} from '../../constants';
import {dateAsWords} from '../../util/DateAsWords';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import AddPromoDialog from './AddPromoDialog';
import {useRecoilState} from 'recoil';
import {loadingState} from '../../recoil/loading/loadingAtom';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: 60,
    marginBottom: 20,
    textAlign: 'right',
  },
  add_btn: {
    margin: '10px 0',
  },
}));

function PromoCodes() {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [, setLoading] = useRecoilState(loadingState);

  const fetchPromoCodes = () => {
    setLoading(true);
    const url = `${SERVER_URL}v1/promocodes`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        setData(responseData);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPromoCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {title: 'Code', field: 'code'},
    {
      title: 'Expiry Date',
      field: 'expiryDate',
      render: (rowData: $TSFixMe) =>
        dateAsWords(rowData.expiryDate, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
    },
    {title: 'Amount', field: 'amount'},
    {title: 'Created By', field: 'username'},
    {title: 'Type', field: 'type'},
  ];

  const getExportData = (dataToExport: $TSFixMe) => {
    const exportData = dataToExport.map((item: $TSFixMe) => ({
      ...item,
      username: item.user.name,
    }));
    return exportData;
  };

  return (
    <div>
      <Grid container justifyContent="center" className={classes.grid}>
        <Grid item xs={12} md={12} key={0}>
          {['phillip', 'jon', 'james'].indexOf(
            (window as $TSFixMe).bidmii.user.username,
          ) !== -1 && (
            <Button
              variant="contained"
              color="primary"
              className={classes.add_btn}
              onClick={() => setShowDialog(true)}>
              Add
            </Button>
          )}
          <MaterialTable
            columns={columns}
            title={'Promo Codes'}
            data={getExportData(data)}
            options={{
              toolbar: true,
              actionsColumnIndex: -1,
              pageSize: 10,
              exportButton: true,
            }}
          />
        </Grid>
      </Grid>
      {showDialog && (
        <AddPromoDialog
          setShowDialog={setShowDialog}
          fetchPromoCodes={fetchPromoCodes}
        />
      )}
    </div>
  );
}

export default PromoCodes;
