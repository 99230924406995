import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import {SERVER_URL} from '../constants';
import {securedFetchRequest} from '@bidmii/common/lib/util/FetchRequest';
import {isVideo, renderVideo} from '../util/VideoHelpers';
import ImageGallery from 'react-image-gallery';
import video_thumbnail from '../assets/images/video_thumbnail.png';
import {Grid} from '@material-ui/core';
import {storeRef} from '../util/FileUtils';
import {v4 as uuidv4} from 'uuid';
import AlertDialog from './AlertDialog';
import useCropper from '../util/Cropper';
import {firebaseStorage} from '../App';
import {ref} from 'firebase/storage';

const styles = (theme: $TSFixMe) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },

  buttonGroup: {
    marginTop: 8,
  },

  button: {
    color: '#000',
    backgroundColor: '#e6e6e6',
    border: 'none',
    borderRadius: 50,

    '&:hover': {
      color: '#fff',
      backgroundColor: '#000',
    },
  },
});

function ThumbnailImageList(props: $TSFixMe) {
  const [data, setData] = useState(props.data);
  const galleryRef = props.imageGalleryRef;
  const [closeWarningOpen, setCloseWarningOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [cropperOpen, setCropperOpen] = useState(null);

  useEffect(() => {
    if (data !== props.data) {
      setData(props.data);
    }
  }, [data, props.data]);

  const items =
    data?.map((img: $TSFixMe) => {
      const item = {
        original: img.url,
        thumbnail: img.url,
      };
      if (isVideo(img.url)) {
        (item as $TSFixMe).renderItem = renderVideo(img);
        item.thumbnail = video_thumbnail;
      }
      return item;
    }) ?? [];

  const handleRemoveImage = (crop: $TSFixMe) => {
    setCloseWarningOpen(false);
    props.setDialogLoading(true);
    const selectedImageIndex = galleryRef.current.getCurrentIndex();
    const images = data.filter((d: $TSFixMe) =>
      ['before_image', 'after_image'].includes(d.key),
    );

    deleteImage(images[selectedImageIndex], crop);
  };

  const deleteImage = (imageToDelete: $TSFixMe, crop: $TSFixMe) => {
    const url = `${SERVER_URL}v1/attachments/${imageToDelete.id}`;
    securedFetchRequest(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.text())
      .then((res) => {
        setTimeout(function () {
          if (crop) {
            props.setDialogLoading(false);
            props.updateRec();
          } else {
            data.forEach((attachment: $TSFixMe, index: $TSFixMe) => {
              if (attachment.id === imageToDelete.id) {
                data.splice(index, 1);
                const newData = [...data];
                setData(newData);
              }
            });
          }
        }, 100);

        if (!crop) {
          props.setDialogLoading(false);
          props.updateRec();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const openCropper = () => {
    const selectedImageIndex = galleryRef.current.getCurrentIndex();
    const elements = document.getElementsByClassName('image-gallery-image');
    const selectedElement = elements[selectedImageIndex];

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
    setImage(selectedElement);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
    setCropperOpen(true);
  };

  const handleCropImage = () => {
    // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
    const fileUrl = getCropData();
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
    setCropperOpen(false);
    if (!fileUrl) {
      return;
    }
    props.setDialogLoading(true);

    const uid = sessionStorage.getItem('uid');
    const fileUUID = uuidv4();
    const fileParts = (getCropData as $TSFixMe).name.split('.');
    let extension = fileParts[fileParts.length - 1];
    if (extension === 'getCropData') {
      extension = 'png';
    }
    const storePath = `${uid}/${fileUUID}.${extension}`;
    const fileRef = ref(firebaseStorage, storePath);
    handleUploadAndStoreRef(fileUrl, fileRef, storePath);
  };

  const handleUploadAndStoreRef = (
    file: $TSFixMe,
    fileRef: $TSFixMe,
    storePath: $TSFixMe,
  ) => {
    const metadata = {
      cacheControl: 'max-age=604800',
    };

    return fileRef
      .putString(file, 'data_url', metadata)
      .then((snapshot: $TSFixMe) => {
        const attachmentReference = {
          name: file.name,
          description: 'Before image',
          resModel: props.resModel,
          resId: `${props.resId}`,
          key: 'before_image',
          url: `${SERVER_URL}${storePath}`,
          firebaseRef: storePath,
        };

        storeRef(attachmentReference)
          .then(() => {
            handleRemoveImage(true);
          })
          .catch((err) => {
            props.setDialogLoading(false);
            console.error(err);
          });
      });
  };

  const [getCropData, cropperDialog] = useCropper({
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
    onCrop: () => setCropperOpen(false),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
    closeDialog: () => setCropperOpen(false),
    open: cropperOpen,
    image,
    handleCropImage,
  });

  const {classes} = props;

  return (
    <div className="App">
      <ImageGallery
        ref={galleryRef}
        items={items}
        infinite={true}
        showNav={true}
        showBullets={items.length > 1}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
        startIndex={items.length - 1}
      />
      {data.length > 0 && (
        <Grid container justifyContent="center">
          <ButtonGroup disableElevation className={classes.buttonGroup}>
            <Button
              onClick={() => {
                setCloseWarningOpen(true);
              }}
              className={classes.button}>
              <DeleteIcon />
            </Button>
            <Button
              onClick={openCropper}
              className={classes.button}
              style={{borderLeft: '1px solid rgba(0,0,0,0.25)'}}>
              <CropRotateIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      )}

      <AlertDialog
        open={closeWarningOpen}
        title="CONFIRM IMAGE DELETION"
        dialogDescription="Are you sure you want to delete this photo?"
        confirm="DELETE"
        close="CANCEL"
        onConfirm={handleRemoveImage}
        onClose={() => setCloseWarningOpen(false)}
        deletion
      />

      {cropperOpen && cropperDialog}
    </div>
  );
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { d... Remove this comment to see the full error message
export default withStyles(styles)(ThumbnailImageList);
