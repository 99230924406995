import React, {useCallback, useState, useEffect} from 'react';
import {SERVER_URL} from '../../constants';
import UserRiskStatusWidget from './components/UserRiskStatusWidget';
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
  useGridApiContext,
  GridColDef,
  GridFilterModel,
} from '@mui/x-data-grid-pro';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Chip from '@material-ui/core/Chip';
import CreateUserDialog from './components/CreateUserDialog';
import CategoriesSelectWidget from './components/CategoriesSelectWidget';
import moment from 'moment';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Box} from '@mui/material';

const CustomUserToolbar = (props: $TSFixMe) => {
  const apiRef = useGridApiContext();
  return (
    <GridToolbarContainer
      style={{
        padding: 12,
        paddingTop: 24,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #999',
      }}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <CreateUserDialog />
      <IconButton
        onClick={() => props.setKey((prevState: number) => prevState + 1)}
        size="small"
        color="secondary"
        aria-label="refresh"
        component="span"
        style={{marginLeft: 8, marginRight: 16}}>
        <RefreshIcon />
      </IconButton>
      <CategoriesSelectWidget {...props} apiRef={apiRef} />
      <Box
        my={1}
        px={window.innerWidth <= 600 ? 1 : 2}
        width={window.innerWidth <= 600 ? '100%' : 'auto'}>
        <GridToolbarQuickFilter
          style={{width: '100%', marginTop: 2}}
          debounceMs={props.quickFilterProps.debounceMs}
        />
      </Box>
    </GridToolbarContainer>
  );
};

import {alpha, styled} from '@mui/material/styles';
import {gridClasses} from '@mui/x-data-grid';

const ODD_OPACITY = 0.2;
const EVEN_OPACITY = 0.9;

const StyledDataGrid = styled(DataGridPro)(({theme}) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, EVEN_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        EVEN_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          EVEN_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            EVEN_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.disabled`]: {
    backgroundColor: 'rgba(255,0,0,0.3)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        'rgba(255,0,0,0.3)',
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const UserIndex = () => {
  const [key, setKey] = useState(1);
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 200,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [queryOptions, setQueryOptions] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('rowClick', (params) => {
      window.open(
        `${window.location.origin}/users/${params?.row?.id}`,
        '_blank',
      );
    });
  }, [apiRef]);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('cellClick', (params) => {
      switch (params.field) {
        case 'email':
          window.open(`mailto:${params?.row?.email}`, '_blank');
          break;
        case 'phone':
          window.open(`tel:${params?.row?.phone}`, '_blank');
          break;
      }
    });
  }, [apiRef]);

  const getData = useCallback(() => {
    setLoading(true);
    console.log(JSON.stringify(queryOptions));
    const url = `${SERVER_URL}v1/users${
      queryOptions ? '/search' : ''
    }?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      method: queryOptions ? 'POST' : 'GET',
      body: queryOptions ? JSON.stringify(queryOptions) : null,
    })
      .then((res) => res.json())
      .then((res) => {
        setRowCount(res.page.totalElements);
        setLoading(false);
        if (!res._embedded) {
          setRows([]);
        } else if (selectedCategories.length > 0) {
          setRows(
            res._embedded?.models.filter((row: $TSFixMe) =>
              row?.categories.some((category: $TSFixMe) =>
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                selectedCategories.includes(category.name.toUpperCase()),
              ),
            ) || [],
          );
        } else {
          setRows(res._embedded?.models || []);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:', error);
      });
  }, [
    queryOptions,
    paginationModel.page,
    paginationModel.pageSize,
    selectedCategories,
  ]);

  useEffect(() => {
    if (selectedCategories && selectedCategories.length > 0) {
      if (paginationModel.pageSize < 1000) {
        setPaginationModel(() => {
          return {page: 0, pageSize: 1000};
        });
      }
      if (rows) {
        console.log('Categories updated, calling getData');
        getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories]);

  useEffect(() => {
    console.log('Page updated calling getData');
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize]);

  const columns: GridColDef[] = [
    {
      headerName: 'ID',
      field: 'id',
      width: 30,
    },
    {
      headerName: 'Enabled',
      field: 'enabled',
      type: 'boolean',
      width: 40,
    },
    {
      headerName: 'First',
      field: 'firstName',
      width: 150,
    },
    {
      headerName: 'Last',
      field: 'lastName',
      width: 150,
    },
    {
      headerName: 'Email',
      field: 'email',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="email user"
            component="span"
            style={{marginRight: 8}}>
            <EmailIcon style={{fontSize: 17}} />
          </IconButton>
          {params?.row?.email || ''}
        </span>
      ),
      width: 250,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="phone user"
            component="span"
            style={{marginRight: 8}}>
            <PhoneIcon style={{fontSize: 17}} />
          </IconButton>
          {params?.row?.phone || ''}
        </span>
      ),
      width: 140,
    },
    {
      headerName: 'Risk Status',
      field: 'riskStatus',
      renderCell: (params: $TSFixMe) => (
        <div>
          <UserRiskStatusWidget
            small={true}
            riskStatus={params?.row?.riskStatus}
          />
        </div>
      ),
      width: 170,
    },
    {
      headerName: 'Created',
      field: 'createdDate',
      type: 'dateTime',
      valueFormatter: (value: $TSFixMe) => {
        return moment(value, moment.ISO_8601).format('MMM Do, YYYY');
      },
      width: 120,
    },

    {
      headerName: 'Street',
      field: 'street',
      width: 160,
    },
    {
      headerName: 'City',
      field: 'city',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) => row?.city?.toUpperCase(),
      width: 100,
    },
    {
      headerName: 'Province',
      field: 'province',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row?.province?.toUpperCase(),
      width: 100,
    },
    {
      headerName: 'Postal',
      field: 'postal',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row?.postal?.toUpperCase(),
      width: 120,
    },
    {
      headerName: 'Rating',
      field: 'rating',
      valueFormatter: (value: $TSFixMe) =>
        value ? `${value.toFixed(2)}%` : '',
      type: 'number',
    },
    {headerName: 'Title', field: 'title'},
    {
      headerName: 'Categories',
      field: 'categoryNames',
      renderCell: (params: $TSFixMe) => (
        <div>
          {params?.row?.categories
            ? params?.row?.categories.map((category: $TSFixMe) => (
                <Chip
                  label={category.name.toUpperCase()}
                  size="small"
                  style={{marginRight: 5}}
                  key={`user_category_${params?.row?.id}_cat_${category.name}`}
                />
              ))
            : null}
        </div>
      ),
      valueGetter: (value: $TSFixMe, row: $TSFixMe) => row?.categories,
      valueFormatter: (value: $TSFixMe) =>
        value
          ? value.map((category: $TSFixMe) => category.name).join(' :: ')
          : null,
      filterable: false,
      width: 110,
    },
    {
      headerName: 'Type',
      field: 'userType',
      type: 'singleSelect',
      valueOptions: ['BIDDER', 'REQUESTER', 'ADMIN', 'PARTNER'],
    },
    {
      headerName: 'Source',
      field: 'userSource',
    },
    {
      headerName: 'Plan',
      field: 'plan',
      type: 'singleSelect',
      valueOptions: ['FREE', 'BIDMII_PRO_LITE', 'BIDMII_PRO_CORE'],
    },
    {
      headerName: 'Insured',
      field: 'insured',
      type: 'boolean',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row?.insured ? 'Yes' : '',
      width: 80,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          key={'users_table_actions_0'}
          icon={<OpenInNewIcon />}
          onClick={() => {
            window.open(
              `${window.location.origin}/users/${params?.id}`,
              '_blank',
            );
          }}
          label="View User"
        />,
      ],
      width: 100,
    },
  ];

  useEffect(() => {
    if (paginationModel.page === 0) {
      getData();
    } else {
      setPaginationModel((prevState) => {
        return {page: 0, pageSize: prevState.pageSize};
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryOptions]);

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    if (
      filterModel?.items.length === 0 &&
      filterModel?.quickFilterValues?.length === 0
    ) {
      console.log('Skipping update of filter, filter empty');
      return;
    }

    if (filterModel.items.length > 0) {
      const filterEntryNotCompleted = !filterModel?.items
        .filter(
          (item: any) =>
            !['isNotEmpty', 'isEmpty'].includes(item.operatorValue),
        )
        .every(
          (item: $TSFixMe) =>
            Object.prototype.hasOwnProperty.call(item, 'value') &&
            item.value !== undefined,
        );
      if (filterEntryNotCompleted) {
        console.log('Skipping update of filter, filter not finished');
        return;
      }
    }

    const filterEntryCompleted = filterModel?.items
      .filter(
        (item: any) => !['isNotEmpty', 'isEmpty'].includes(item.operatorValue),
      )
      .every((item: $TSFixMe) =>
        Object.prototype.hasOwnProperty.call(item, 'value'),
      );

    if (
      (filterModel?.items.length >= 1 && filterEntryCompleted) ||
      (filterModel &&
        filterModel?.quickFilterValues &&
        filterModel?.quickFilterValues?.length > 0)
    ) {
      console.log('Setting filter');
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ filterModel: any; }' is not as... Remove this comment to see the full error message
      setQueryOptions({filterModel: {...filterModel}});
    } else {
      console.log('Setting filter to null');
      setQueryOptions(null);
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 30,
      }}>
      <div style={{flexGrow: 1}}>
        <StyledDataGrid
          key={`users_list_${key}`}
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                enabled: false,
                uid: false,
                riskStatus: false,
                street: false,
                province: false,
                postal: false,
                userSource: false,
              },
            },
          }}
          slots={{
            toolbar: CustomUserToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 750},
              selectedCategories: selectedCategories,
              setSelectedCategories: setSelectedCategories,
              setKey: setKey,
            },
          }}
          filterDebounceMs={750}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          checkboxSelection
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50, 100, 150, 200, 300, 400, 500, 750, 1000]}
          pagination
          paginationMode={'server'}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          loading={loading}
          getRowClassName={(params) => {
            if (!params?.row?.enabled) {
              return 'disabled';
            } else {
              return params.indexRelativeToCurrentPage % 2 === 0
                ? 'even'
                : 'odd';
            }
          }}
        />
      </div>
    </div>
  );
};

export default UserIndex;
