import React from 'react';
import {
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import {SERVER_URL} from '../../constants';

export default function DataTable(props: $TSFixMe) {
  const currentMonth = props.data.month;
  const currentYear = props.data.year;
  const currentDay = props.data.day;

  const getMonthByMonthChange = (topic: $TSFixMe) => {
    if (props.data[topic] === 92233720368547760) {
      return '-';
    }
    topic += 'Change';
    const change = props.data[topic];
    if (change === 9223372036854776000) {
      return '-';
    }
    return `${props.data[topic]}%`;
  };

  const getAmount = (topic: $TSFixMe) => {
    if (props.data[topic] === 92233720368547760) {
      return '-';
    }
    if (percentage.includes(topic)) {
      return props.data[topic] + '%';
    } else if (dollar.includes(topic)) {
      return '$' + props.data[topic];
    }
    return props.data[topic];
  };

  const numberToMonth = (n: $TSFixMe) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[n - 1];
  };

  const percentage = [
    'fulfillmentRate',
    'projectsWithThreeBidsPercent',
    'projectsWithThreeBidsWithin24HoursPercent',
  ];

  const dollar = ['gmv', 'awardedGmv', 'bidmiiRevenue'];

  const topics = {
    'Total New Users': 'totalNewUsers',
    'New Homeowners': 'newHomeowners',
    'New Contractors': 'newContractors',
    'Projects Posted': 'totalProjectsPosted',
    'Projects Completed': 'projectsCompleted',
    'Stages Completed': 'stagesCompleted',
    'Fulfillment Rate': 'fulfillmentRate',
    'Projects Awarded': 'projectsAwarded',
    'Projects 3 bids %': 'projectsWithThreeBidsPercent',
    'Projects 3 bids % in 24h': 'projectsWithThreeBidsWithin24HoursPercent',
    'Total Bids Placed': 'totalProposalsPlaced',
    'Avg bids/project': 'avgBidPerProject',
    'Awarded GMV': 'awardedGmv',
    GMV: 'gmv',
    'Bidmii Revenue': 'bidmiiRevenue',
    'Pro Subscriptions': 'totalBidmiiPros',
    'Trial Subscriptions': 'totalTrialPros',
    'Cancelled Subscription': 'totalCancelledPros',
  };

  const createData = (
    topic: $TSFixMe,
    amount: $TSFixMe,
    monthByMonth: $TSFixMe,
  ) => {
    return {topic, amount, monthByMonth};
  };

  const getTableHeader = () => {
    if (props.interval === 'month') {
      return `${numberToMonth(currentMonth)}, ${currentYear}`;
    } else if (props.interval === 'week') {
      return `Week of ${numberToMonth(
        currentMonth,
      )} ${currentDay},${currentYear}`;
    }
  };

  const rows: $TSFixMe = [];

  Object.keys(topics).forEach((topic) => {
    rows.push(
      createData(
        topic,
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        getAmount(topics[topic]),
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        getMonthByMonthChange(topics[topic]),
      ),
    );
  });

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 12,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const downloadCsvForRow = (row: $TSFixMe) => {
    const url = `${SERVER_URL}stats/reports/awarded-gmv?startDate=${props.startDate}&endDate=${props.endDate}&interval=${props.interval}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.text())
      .then((response) => {
        const file = new File([response], 'report.csv', {type: 'text/csv'});
        const downloadUrl = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = 'report.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
      });
  };

  return (
    <TableContainer style={{width: window.outerWidth > 1280 ? 415 : 315}}>
      <TableHead>
        <TableRow>
          <StyledTableCell>{getTableHeader()}</StyledTableCell>
          <StyledTableCell>Amount</StyledTableCell>
          <StyledTableCell>Change%</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'row' implicitly has an 'any' type. */}
        {rows.map((row) => (
          <StyledTableRow key={row.topic}>
            <StyledTableCell>
              {row.topic}
              {row.topic === 'Awarded GMV' && (
                <IconButton onClick={() => downloadCsvForRow(row)}>
                  <GetAppIcon />
                </IconButton>
              )}
            </StyledTableCell>
            <StyledTableCell>{row.amount}</StyledTableCell>
            <StyledTableCell>{row.monthByMonth}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </TableContainer>
  );
}
