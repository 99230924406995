import React, {useCallback, useEffect, useRef, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {DialogActions, Snackbar} from '@material-ui/core';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
} from '@mui/material';
import Grid from '@material-ui/core/Grid';
import {SERVER_URL} from '../../../constants';
import MuiAlert from '@material-ui/lab/Alert';

const styles = (theme: $TSFixMe) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { m... Remove this comment to see the full error message
const DialogTitle = withStyles(styles)((props) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'PropsWi... Remove this comment to see the full error message
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dropin = require('braintree-web-drop-in');

const prices = {
  monthly: {
    amount: 119,
    discount: 119,
  },
  annual: {
    amount: 1440,
    discount: 200,
  },
  save: 108,
};

function Alert(props: $TSFixMe) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function BidmiiProCheckoutDialog(props: $TSFixMe) {
  const braintreeInstance = useRef(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [paymentPeriod, setPaymentPeriod] = useState<string | null>(null);
  const [nonce, setNonce] = useState<string | null>(null);
  const [checkoutReady, setCheckoutReady] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [applyReferral, setApplyReferral] = useState<boolean>(false);
  const [freeTrial, setFreeTrial] = useState<boolean>(false);
  const [snackBarVisible, setSnackBarVisible] = useState(false);
  const [message, setMessage] = useState<string | null>('');
  const [severity, setSeverity] = useState<string>('success');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.updateUser({});
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentPeriod((event.target as HTMLInputElement).value);
  };

  const getNonceData = () => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/payments/payment-nonce`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({userId: props.userId}),
    })
      .then((res) => res.text())
      .then((res) => {
        setNonce(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (open) {
      getNonceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getCheckoutOptions = useCallback(() => {
    return {
      authorization: nonce,
      container: '#dropin-container',
      card: {
        cardholderName: {
          required: true,
        },
        cvv: {
          required: true,
        },
        overrides: {
          fields: {
            number: {
              maskInput: {
                showLastFour: true,
              },
            },
            cvv: {
              maskInput: true,
            },
          },
        },
      },
      paypal: {
        flow: 'vault',
        amount: amount,
        currency: 'CAD',
      },
    };
  }, [amount, nonce]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initializePaymentForm = () => {
    const options = getCheckoutOptions();
    dropin.create(options, function (createErr: $TSFixMe, instance: $TSFixMe) {
      if (createErr) {
        console.error(createErr);
        return;
      }
      braintreeInstance.current = instance;
      setLoading(false);
      setCheckoutReady(true);
    });
  };

  useEffect(() => {
    if (amount) {
      initializePaymentForm();
    }
  }, [amount, initializePaymentForm]);

  useEffect(() => {
    if (paymentPeriod) {
      // @ts-ignore
      setAmount(prices[paymentPeriod].amount);
    }
  }, [paymentPeriod]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitNonceForPayment = (token: string) => {
    console.log('SUBMIT');
    console.log(token);

    const body = {
      applyReferralCode: applyReferral,
      nonce: token,
      userId: props.userId,
      productIds:
        paymentPeriod === 'monthly'
          ? 'BIDMII_PRO_1_MONTHLY'
          : 'BIDMII_PRO_1_ANNUAL',
    };

    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/payments`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((res) => {
        if (
          [
            'SUCCESS',
            'SUBMITTED_FOR_SETTLEMENT',
            'SETTLING',
            'AUTHORIZED',
          ].includes(res.status)
        ) {
          setSeverity('success');
          setMessage('Checkout Success!');
          setSnackBarVisible(true);
          setTimeout(function () {
            handleClose();
          }, 4000);
        } else {
          setSeverity('error');
          setMessage(`Something went wrong, ${JSON.stringify(res)}`);
          setSnackBarVisible(true);
          setTimeout(function () {
            setSnackBarVisible(false);
          }, 4000);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const freeTrialSubmit = () => {
    console.log('FREE TRIAL SUBMIT');

    const body = {
      userId: props.userId,
    };

    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/payments/bidmii-pro-trial`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          setSeverity('success');
          setMessage('Checkout Success!');
          setSnackBarVisible(true);
          setTimeout(function () {
            handleClose();
          }, 4000);
        } else {
          setSeverity('error');
          setMessage('Something went wrong');
          setSnackBarVisible(true);
          setTimeout(function () {
            setSnackBarVisible(false);
          }, 4000);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const instance = braintreeInstance.current;
    if (instance) {
      const handler = () => {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        instance.requestPaymentMethod(function (
          requestPaymentMethodErr: $TSFixMe,
          payload: $TSFixMe,
        ) {
          if (requestPaymentMethodErr) {
            console.error(requestPaymentMethodErr);
            //openSnackbar(requestPaymentMethodErr.message);
            return;
          }
          if (payload !== undefined) {
            submitNonceForPayment(payload.nonce);
          } else {
            //openSnackbar('Please select a payment method');
          }
        });
      };
      const button = document.getElementById('paypal-button');
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      button.addEventListener('click', handler);

      return () => {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        button.removeEventListener('click', handler);
      };
    }
  }, [submitNonceForPayment, checkoutReady]);

  return (
    <>
      <Button
        style={{
          marginLeft: 30,
          marginRight: '20px',
          height: 'fit-content',
          color: 'red',
        }}
        variant="contained"
        color="primary"
        component={'span'}
        size={'small'}
        onClick={handleClickOpen}>
        Checkout
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="ban-user-dialog"
        fullWidth
        open={open}>
        {loading && <LinearProgress color={'secondary'} />}
        <DialogTitle>Bidmii Pro Checkout</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Checkbox
                  checked={freeTrial}
                  onChange={(event) => setFreeTrial(event.target.checked)}
                />
              }
              label="Free Trial"
            />
            {freeTrial ? (
              <Button
                color="secondary"
                variant={'contained'}
                onClick={freeTrialSubmit}>
                CONFIRM
              </Button>
            ) : (
              <>
                <FormLabel component="legend">Payment Interval</FormLabel>
                <RadioGroup
                  aria-label="period"
                  name="period"
                  value={paymentPeriod}
                  onChange={handleChange}>
                  <FormControlLabel
                    value="monthly"
                    control={<Radio />}
                    label="Monthly"
                  />
                  <FormControlLabel
                    value="annual"
                    control={<Radio />}
                    label="Annual"
                  />
                </RadioGroup>
              </>
            )}
          </FormControl>
          <div style={{width: '100%'}}>
            <div id="dropin-container" />
            {checkoutReady && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applyReferral}
                      onChange={(event) =>
                        setApplyReferral(event.target.checked)
                      }
                    />
                  }
                  label="Apply Referral Code"
                />
                <Grid
                  container
                  alignItems="center"
                  justifyContent={props.justifyButtons || 'flex-end'}
                  style={{margin: '16px 0'}}>
                  <Button
                    id="paypal-button"
                    color="secondary"
                    variant={'contained'}>
                    CONFIRM PAYMENT
                  </Button>
                </Grid>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button color={'secondary'} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
        <Snackbar
          open={snackBarVisible}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          autoHideDuration={4000}>
          <Alert severity={severity}>{message}</Alert>
        </Snackbar>
      </Dialog>
    </>
  );
}
