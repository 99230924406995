import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import FolderIcon from '@material-ui/icons/Folder';

import {dateAsWords} from '../../../util/DateAsWords';
import Avatar from '@material-ui/core/Avatar';

function BusinessDocumentsBuilder(props: $TSFixMe) {
  const documents = props.documents;
  return documents.map((doc: $TSFixMe, index: $TSFixMe) => (
    <a
      key={`doc_${index}`}
      href={doc.url}
      target="_blank"
      rel="noopener noreferrer">
      <ListItem role={undefined} dense button>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={doc.name}
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
          secondary={`${doc.type?.toUpperCase()}, Expires: ${dateAsWords(
            doc.expiryDate,
          )}`}
        />
      </ListItem>
    </a>
  ));
}

export default BusinessDocumentsBuilder;
