import React, {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {LinearProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {SERVER_URL} from '../../constants';
import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import ProjectTemplatePrompts from './components/ProjectTemplatePrompts';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    borderBottom: '1px solid #d9d9d9',
  },
}));
const ProjectTemplateDetails = (props: $TSFixMe) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const templateUid = props.templateUid;
  const [template, setTemplate] = useState<$TSFixMe>(null);

  const getTemplate = useCallback(() => {
    setLoading(true);
    return fetch(`${SERVER_URL}v1/project-templates/${templateUid}`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: localStorage.getItem('Authorization'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setTemplate(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [templateUid]);

  useEffect(() => {
    getTemplate();
  }, [getTemplate]);

  return (
    <>
      <Dialog
        open={true}
        onClose={() => {}}
        aria-labelledby="Project Template"
        maxWidth={'md'}
        fullWidth
        fullScreen>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.close}
            aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {loading && <LinearProgress color={'secondary'} />}
        {template && (
          <Box>
            <DialogContent>
              <DialogTitle>{template.name}</DialogTitle>
              <ProjectTemplatePrompts
                template={template}
                refreshTemplateData={getTemplate}
              />
            </DialogContent>
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default ProjectTemplateDetails;
