import React, {useState, useEffect, useCallback} from 'react';
import {SERVER_URL} from '../../constants';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {dateAsWords} from '../../util/DateAsWords';
const UserFeedback = (props: $TSFixMe) => {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const apiRef = useGridApiRef();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize, props.filter]);

  const getData = useCallback(() => {
    fetch(
      `${SERVER_URL}v1/users/feedback?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setRowCount(res.page.totalElements);
        setRows(res._embedded?.models || []);
        setLoading(false);
      });
  }, [paginationModel.page, paginationModel.pageSize]);

  const columns: GridColDef[] = [
    {headerName: 'ID', field: 'id', type: 'number'},
    {
      headerName: 'Created',
      field: 'createDate',
      type: 'dateTime',
      valueFormatter: (value: $TSFixMe) =>
        dateAsWords(value, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      width: 120,
    },
    {headerName: 'Feedback', field: 'note', width: 800},
  ];

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 30,
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          getRowHeight={() => 'auto'}
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 500},
              getData: getData,
            },
          }}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50, 100, 150, 200, 300, 400, 500, 750, 1000]}
          pagination
          paginationMode={'server'}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default UserFeedback;
