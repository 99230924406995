import {useEffect, useState} from 'react';

const useScreenWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };
  return width;
};
export default useScreenWidth;
