/**
 * Returns date in words "June 1 2020"
 *
 * @param date {Date} date to format
 */
import moment from 'moment';
export const dateAsWords = (date: $TSFixMe, options: $TSFixMe) => {
  if (!date) {
    return null;
  }
  if (!options) {
    options = {year: 'numeric', month: 'long', day: 'numeric'};
  }

  let formattedDate = date;
  const formatter = new Intl.DateTimeFormat('en-US', options);

  try {
    formattedDate = formatter.format(new Date(date));
  } catch (err) {
    console.error(err);
  }
  return formattedDate;
};

export const processedDate = (date: $TSFixMe, options: $TSFixMe) => {
  let newDate = dateAsWords(date, options).toString();
  if (newDate === 'Invalid Date') {
    newDate = date.substr(0, 10);
  } else if (newDate.indexOf('+0000') !== -1) {
    newDate = newDate.substr(0, 10);
  }
  return newDate;
};

export const formatAMPM = (date: $TSFixMe) => {
  const formattedDate = new Date(date);
  const minutes =
    formattedDate.getMinutes().toString().length === 1
      ? '0' + formattedDate.getMinutes()
      : formattedDate.getMinutes();
  const hours =
    formattedDate.getHours().toString().length === 1
      ? '0' + formattedDate.getHours()
      : formattedDate.getHours();
  const ampm = formattedDate.getHours() >= 12 ? 'pm' : 'am';
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return (
    formattedDate.getDate() +
    ' ' +
    months[formattedDate.getMonth()] +
    ' ' +
    formattedDate.getFullYear() +
    ' ' +
    hours +
    ':' +
    minutes +
    ' ' +
    ampm
  );
};

export const convertDate = (str: $TSFixMe) => {
  const date = new Date(str);
  return JSON.stringify(date).slice(1, 11);
};

export const createdDate = (date: $TSFixMe, options: $TSFixMe) => {
  const d1 = moment(date);
  const today = moment().toDate();
  const diff = moment(today).diff(d1, 'days');

  if (!options) {
    options = {year: 'numeric', month: 'short', day: 'numeric'};
  }

  if (diff > 30) {
    return '30+ days ago';
  } else if (diff > 1) {
    return `${diff} days ago`;
  } else if (diff === 1) {
    return 'yesterday';
  } else {
    const duration = moment.duration(moment(today).diff(d1));
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    const hours = parseInt(duration.asHours(), 10);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    const minutes = parseInt(duration.asMinutes(), 10) - hours * 60;

    if (hours > 1) {
      return `${hours} hours ago`;
    } else if (hours === 1) {
      return `${hours} hour ago`;
    } else if (minutes > 0) {
      if (minutes === 1) {
        return `${minutes} min ago`;
      }
      return `${minutes} min ago`;
    } else {
      return 'Now';
    }
  }
};
