import React, {Fragment, useState} from 'react';
import {SERVER_URL} from '../../constants';
import MaterialTable from 'material-table';
import Container from 'react-bootstrap/Container';
import Grid from '@material-ui/core/Grid';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faEdit, faCheck} from '@fortawesome/free-solid-svg-icons';

import {useRecoilState} from 'recoil';
import {loadingState} from '../../recoil/loading/loadingAtom';
import SendEmailsDialog from './SendEmailsDialog';

library.add(faEdit);
library.add(faCheck);

const Notifications = (props: $TSFixMe) => {
  const [showDialog, setShowDialog] = useState(false);
  const [muiTableKey] = React.useState(0);
  const [, setLoading] = useRecoilState(loadingState);
  const [pageSize, setPageSize] = useState(
    localStorage.getItem('defaultPageSize')
      ? Number(localStorage.getItem('defaultPageSize'))
      : 10,
  );
  const fetchNotifications = (query: $TSFixMe) => {
    let page = 0;
    if (query.page) {
      page = query.page;
    }

    const url = `${SERVER_URL}v1/sendgrid-events?page=${page}&pageSize=${query.pageSize}`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    });
  };

  const getData = (query: $TSFixMe) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      fetchNotifications(query)
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          // console.log(res);
          if (res._embedded.models.length > 0) {
            resolve({
              data: res._embedded.models,
              page: res.page.number,
              totalCount: res.page.totalElements,
            });
          } else {
            resolve({
              data: [],
              page: 0,
              totalCount: 0,
            });
          }
        })
        .catch((error) => {
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
          console.error('Error:', error);
        });
    });
  };

  const columns = [
    {
      title: 'Category',
      field: 'category',
      render: (rowData: $TSFixMe) => {
        if (rowData.category === null) {
          return 'N/A';
        } else {
          return rowData.category;
        }
      },
    },
    {
      title: 'Email',
      field: 'emailRecipient',
      render: (rowData: $TSFixMe) => {
        if (rowData.emailRecipient === null) {
          return 'N/A';
        } else {
          return rowData.emailRecipient;
        }
      },
    },
    {
      title: 'Event',
      field: 'event',
      render: (rowData: $TSFixMe) => {
        if (rowData.event === null) {
          return 'N/A';
        } else {
          return rowData.event;
        }
      },
    },
    {
      title: 'Reason',
      field: 'reason',
      render: (rowData: $TSFixMe) => {
        if (rowData.reason === null) {
          return 'N/A';
        } else {
          return rowData.reason;
        }
      },
    },
    {
      title: 'Event Id',
      field: 'eventId',
      render: (rowData: $TSFixMe) => {
        if (rowData.eventId === null) {
          return 'N/A';
        } else {
          return rowData.eventId;
        }
      },
    },
    // {title: 'User Id', field: 'userUID'},
    {
      title: 'Date',
      field: 'timestamp',
      render: (rowData: $TSFixMe) => {
        const date = new Date(rowData.timestamp);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ year: string; month: string; d... Remove this comment to see the full error message
        return date.toLocaleDateString('en-US', options);
      },
    },
  ];

  // , render: (rowData) => rowData

  const actions = [
    {
      icon: 'add',
      tooltip: 'Send Email',
      isFreeAction: true,
      onClick: (event: $TSFixMe) => setShowDialog(true),
    },
  ];

  const handleOnChangeRowsPerPage = (size: $TSFixMe) => {
    localStorage.setItem('defaultPageSize', size);
    setPageSize(size);
  };
  const materialTable = () => {
    return (
      <Fragment>
        <Grid
          container
          justifyContent="center"
          style={{marginTop: 0, marginBottom: 20, paddingTop: 30}}>
          <Grid item xs={12} md={12} key={0}>
            <MaterialTable
              key={muiTableKey}
              columns={columns}
              title={'Notifications'}
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              data={(query) => getData(query)}
              actions={actions}
              options={{
                toolbar: true,
                actionsColumnIndex: -1,
                pageSize: pageSize,
                pageSizeOptions: [10, 15, 20, 25, 30, 50, 75, 100],
                debounceInterval: 500,
                exportButton: true,
              }}
              detailPanel={(rowData) => {
                return <Container style={{padding: 60}} />;
              }}
              onChangeRowsPerPage={handleOnChangeRowsPerPage}
              // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  return (
    <div>
      <Container
        fluid="false"
        style={{backgroundColor: 'light-grey', paddingTop: 0}}>
        {materialTable()}
      </Container>
      {showDialog && (
        <SendEmailsDialog
          setShowDialog={setShowDialog}
          notifications={Notifications}
        />
      )}
    </div>
  );
};

export default Notifications;
