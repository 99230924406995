import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {DialogActions, Snackbar} from '@material-ui/core';
import {
  FormControl,
  InputLabel,
  LinearProgress,
  OutlinedInput,
} from '@mui/material';
import {SERVER_URL} from '../../../constants';
import MuiAlert from '@material-ui/lab/Alert';

const styles = (theme: $TSFixMe) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { m... Remove this comment to see the full error message
const DialogTitle = withStyles(styles)((props) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'PropsWi... Remove this comment to see the full error message
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function Alert(props: $TSFixMe) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function RegisterDomainDialog(props: $TSFixMe) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackBarVisible, setSnackBarVisible] = useState(false);
  const [message, setMessage] = useState<string | null>('');
  const [domain, setDomain] = useState('');
  const [domainValid, setDomainValid] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.updateUser({});
    setLoading(false);
    setSnackBarVisible(false);
    setMessage('');
    setOpen(false);
  };

  const checkDomainAvailability = () => {
    setLoading(true);
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/domains?query=${domain}`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (domain === res[0].name) {
          if (res[0].status === 'AVAILABLE') {
            setDomainValid(true);
          }
        } else {
          setDomainValid(false);
        }
      });
  };

  useEffect(() => {
    if (domain?.includes('.com')) {
      checkDomainAvailability();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain]);

  const handleSubmit = () => {
    setLoading(true);

    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/domains/register`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({domain: domain, userId: props.userId}),
    }).then(() => {
      props.updateUser({});
      setLoading(false);
      setOpen(false);
    });
  };

  return (
    <>
      <Button
        style={{
          marginLeft: 30,
          marginRight: '20px',
          height: 'fit-content',
          color: 'red',
        }}
        variant="contained"
        color="primary"
        component={'span'}
        size={'small'}
        onClick={handleClickOpen}>
        REGISTER DOMAIN
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="ban-user-dialog"
        fullWidth
        open={open}>
        {loading && <LinearProgress color={'secondary'} />}
        <DialogTitle>Register Domain</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Domain
            </InputLabel>
            <OutlinedInput
              id={'domain'}
              name={'domain'}
              type={'text'}
              value={domain}
              onChange={(event) => setDomain(event.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color={'secondary'} onClick={handleClose}>
            Cancel
          </Button>
          {domainValid && (
            <Button
              color={'secondary'}
              variant={'contained'}
              onClick={handleSubmit}>
              Register
            </Button>
          )}
        </DialogActions>
        <Snackbar
          open={snackBarVisible}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          autoHideDuration={4000}>
          <Alert severity={'success'}>{message}</Alert>
        </Snackbar>
      </Dialog>
    </>
  );
}
