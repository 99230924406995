import React, {useState} from 'react';
import MaterialTable from 'material-table';
import {dateAsWords} from '../../../util/DateAsWords';

function TransactionsTab(props: $TSFixMe) {
  const [transactions] = useState(props.transactions);

  const columns = [
    {title: 'No', field: 'transactionNo'},
    {
      title: 'Date',
      field: 'date',
      render: (rowData: $TSFixMe) => {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        return dateAsWords(rowData.createdDate);
      },
    },
    {title: 'Type', field: 'type'},
    {title: 'Description', field: 'description'},
    {title: 'Amount', field: 'amount'},
  ];

  const handleOnClick = (rowData: $TSFixMe) => {
    (history as $TSFixMe).push(`/users/${rowData.id}`);
  };

  return (
    <div>
      <MaterialTable
        columns={columns}
        title={'Transactions'}
        data={transactions}
        actions={[
          (rowData) => ({
            icon: 'arrow_forward',
            onClick: (event, rows) => {
              handleOnClick(rowData);
            },
          }),
        ]}
        options={{
          toolbar: true,
          pageSize: 10,
          actionsColumnIndex: -1,
          exportButton: true,
        }}
      />
    </div>
  );
}

export default TransactionsTab;
