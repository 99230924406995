import React, {forwardRef, useState, useEffect} from 'react';
import {SERVER_URL} from '../../../constants';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {Avatar, Badge, DialogContentText} from '@material-ui/core';
import ProfileInfoTab from './ProfileInfoTab';
import ProjectViewTab from './ProjectViewTab';
import QuotesTab from './QuotesTab';
import QuestionsTab from './QuestionsTab';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import EditableProfile from './EditableProfile';
import UserNote from './UserNote';
import Typography from '@material-ui/core/Typography';
import TransactionsTab from './TransactionsTab';
import {createUUID} from '../../../util/UUID';
import DeleteIcon from '@material-ui/icons/Delete';
import {Dialog, DialogActions, DialogContent} from '@material-ui/core';
import {storeRef} from '../../../util/StoreImageRef';
import UserRiskStatusWidget from './UserRiskStatusWidget';
import UserSessionsTab from './UserSessionsTab';
import SiteVisitsTab from './SiteVisitsTab';
import NotificationsTab from './NotificationsTab';
import ReviewsTab from './ReviewsTab';
import {AttachFile} from '@material-ui/icons';
import ContactsTab from './ContactsTab';
import ReferralsTab from './ReferralsTab';
import {firebaseStorage, firebaseAuth} from '../../../App';
import {signInAnonymously} from 'firebase/auth';
import {
  ref as firebaseStorageRef,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  tabs: {
    marginTop: 20,
    backgroundColor: '#FFF',
    borderTop: '1px solid #d9d9d9',
    borderBottom: '1px solid #d9d9d9',

    '& .MuiTab-root': {
      minWidth: window.innerWidth > 600 ? 120 : 'auto',
    },
  },
}));

function TabPanel(props: $TSFixMe) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}>
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

const DetailsPane = forwardRef((props: $TSFixMe, ref) => {
  const classes = useStyles();
  const [data, setData] = useState(props.user);
  const [transactions, setTransactions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [badges, setBadges] = useState([]);
  const [value, setValue] = React.useState('profile');
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [confirmDeleteLogo, setConfirmDeleteLogo] = React.useState(false);
  const [notes, setNotes] = React.useState([]);

  const handleChange = (event: $TSFixMe, newValue: $TSFixMe) => {
    setValue(newValue);
  };

  async function fetchData(url: $TSFixMe) {
    const fullUrl = `${SERVER_URL}${url}`;
    const fbjwt = localStorage.getItem('Authorization');
    const response = await fetch(fullUrl, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  }

  // Todo - burn this function to the ground
  useEffect(() => {
    async function dataFetcher() {
      try {
        const userQuestions = await fetchData(
          `v1/users/${props.userId}/questions`,
        );
        setQuestions(userQuestions);

        const userBadges = await fetchData(`v1/users/${props.userId}/badges`);
        setBadges(userBadges);

        const userNotes = await fetchData(`v1/users/${props.userId}/notes`);
        setNotes(userNotes?._embedded?.models || []);

        const userTransactions = await fetchData(
          `v1/users/${props.userId}/transactions?page=0&pageSize=200`,
        );
        setTransactions(userTransactions?._embedded?.models || []);
      } catch (err: any) {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      }
    }
    dataFetcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserValues = (userData: $TSFixMe) => {
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(`${SERVER_URL}v1/users/${props.userId}`, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify(userData),
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const handleRemoveImage = () => {
    //send api request to remove image
    const jwt = localStorage.getItem('Authorization');
    console.info(data);
    fetch(`${SERVER_URL}v1/users/${(data as $TSFixMe).id}`, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({imgAttachmentRefId: null}),
    })
      .then((res) => res.json())
      .then((res) => {
        //setLoading(false);
        setData(res);
      });

    closeConfirmDelete();
  };

  const handleRemoveLogoImage = () => {
    //send api request to remove image
    const jwt = localStorage.getItem('Authorization');
    console.info(data);
    fetch(`${SERVER_URL}v1/users/${(data as $TSFixMe).id}`, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({logoImgRefId: null}),
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      });

    closeConfirmDelete();
  };

  const handleOnClickSendSms = (note: $TSFixMe) => {
    const fbjwt = localStorage.getItem('Authorization');
    console.info(note);
    return fetch(`${SERVER_URL}v1/users/notes/${note.id}/send`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({name: null}),
    })
      .then((res) => res.json())
      .then((res) => {
        fetchData(`admin/user/${(data as $TSFixMe).id}`).then((res2) => {
          setData(res2);
        });
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const submitNote = () => {
    const noteEl = document.getElementById('note_input');
    const noteValue = (noteEl as $TSFixMe).value;
    (noteEl as $TSFixMe).value = null;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(`${SERVER_URL}v1/users/${props.userId}/note`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({
        user_id: (data as $TSFixMe).id,
        note: noteValue,
      }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);
        const userNotes = await fetchData(`v1/users/${props.userId}/notes`);
        setNotes(userNotes?._embedded?.models || []);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const getAction = () => {
    return (
      <Grid container spacing={1} style={{marginTop: 15, marginRight: 10}}>
        <Grid item key={52}>
          <Typography variant="body2" component="p" gutterBottom>
            Balance: ${(data as $TSFixMe)?.balance?.toFixed(2) || 0.0}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const openConfirmDelete = () => {
    setConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setConfirmDelete(false);
  };

  const deleteConfirmationDialog = () => {
    return (
      <Dialog open={confirmDelete} onClose={closeConfirmDelete}>
        <DialogContent>
          <DialogContentText>Remove user profile image?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDelete}>Cancel</Button>

          <Button onClick={handleRemoveImage} color="secondary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const openConfirmDeleteLogo = () => {
    setConfirmDeleteLogo(true);
  };

  const closeConfirmDeleteLogo = () => {
    setConfirmDeleteLogo(false);
  };

  const deleteLogoConfirmationDialog = () => {
    return (
      <Dialog open={confirmDeleteLogo} onClose={closeConfirmDeleteLogo}>
        <DialogContent>
          <DialogContentText>Remove user logo image?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDeleteLogo}>Cancel</Button>

          <Button onClick={handleRemoveLogoImage} color="secondary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleChangeFile = (
    file: $TSFixMe,
    key: $TSFixMe,
    userAttr: $TSFixMe,
  ) => {
    if (file) {
      const uid = (data as $TSFixMe).uid;
      const fileUUID = createUUID();
      const fileParts = file.name.split('.');
      const extension = fileParts[fileParts.length - 1];
      const storePath = `${uid}/${fileUUID}.${extension}`;
      const fileRef = firebaseStorageRef(firebaseStorage, storePath);

      signInAnonymously(firebaseAuth)
        .catch(function (error) {
          console.error('ANONYMOUS LOGIN FAIL');
          console.error(error.code);
          console.error(error.message);
        })
        .then((res) => {
          handleUploadAndStoreRef(file, fileRef, storePath, key, userAttr);
        });
    }
  };

  const handleUploadAndStoreRef = (
    file: $TSFixMe,
    fileRef: $TSFixMe,
    storePath: $TSFixMe,
    key: $TSFixMe,
    userAttr: $TSFixMe,
  ) => {
    const metadata = {
      cacheControl: 'max-age=604800',
    };
    return uploadBytes(fileRef, file, metadata).then(
      async (snapshot: $TSFixMe) => {
        const url = await getDownloadURL(fileRef);
        const attachmentReference = {
          name: file.name,
          description: '',
          resModel: 'User',
          resId: (data as $TSFixMe).id,
          key: key,
          url: url,
          firebaseRef: storePath,
        };
        storeRef(attachmentReference)
          .then((res) => res.json())
          .then((res) => {
            updateUserValues({[userAttr]: res.id}).then(() =>
              props.setLoading(false),
            );
          });
      },
    );
  };

  const getTitle = () => {
    return (
      <Grid container spacing={3}>
        <Grid item>
          <label style={{cursor: 'pointer'}}>
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <input
                  accept="image/*"
                  style={{display: 'none'}}
                  id=""
                  name=""
                  type="file"
                  onChange={(e) => {
                    handleChangeFile(
                      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                      e.target.files[0],
                      'profile_image',
                      'imgAttachmentRefId',
                    );
                  }}
                />
              }>
              <Avatar
                alt={(data as $TSFixMe).firstName}
                src={
                  data && (data as $TSFixMe).imgAttachmentRef
                    ? (data as $TSFixMe).imgAttachmentRef.url
                    : null
                }
                className={classes.large}
              />
            </Badge>
          </label>
          {data && (data as $TSFixMe).imgAttachmentRef && (
            <DeleteIcon
              style={{cursor: 'pointer'}}
              onClick={openConfirmDelete}
            />
          )}
          {deleteConfirmationDialog()}
          <br />
          <span style={{fontSize: 12}}>Profile</span>
        </Grid>
        {data && (data as $TSFixMe).userType === 'BIDDER' && (
          <Grid item>
            <label style={{cursor: 'pointer'}}>
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={
                  <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id=""
                    name=""
                    type="file"
                    onChange={(e) => {
                      handleChangeFile(
                        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                        e.target.files[0],
                        'logo_image',
                        'logoImgRefId',
                      );
                    }}
                  />
                }>
                <Avatar
                  alt={(data as $TSFixMe).firstName}
                  src={
                    data && (data as $TSFixMe).logoImgRefId
                      ? (data as $TSFixMe).logoImgRefId.url
                      : null
                  }
                  className={classes.large}
                />
              </Badge>
            </label>
            {data && (data as $TSFixMe).logoImgRefId && (
              <DeleteIcon
                style={{cursor: 'pointer'}}
                onClick={openConfirmDeleteLogo}
              />
            )}
            {deleteLogoConfirmationDialog()}
            <br />
            <span style={{fontSize: 12}}>Logo</span>
          </Grid>
        )}
      </Grid>
    );
  };

  const onVideoCapture = (event: $TSFixMe) => {
    props.setLoading(true);
    handleChangeFile(
      event.target.files[0],
      'intro_video',
      'videoAttachmentRefId',
    );
  };

  const VideoInput = (
    <Box className={(classes as $TSFixMe).uploadBox}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center">
        <input
          accept="video/*"
          id="intro-video"
          type="file"
          onChange={onVideoCapture}
          style={{display: 'none'}}
          disabled={(data as $TSFixMe).plan === 'FREE'}
        />
        <label htmlFor="intro-video">
          <Button
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            color="#4BB9F8"
            component="span"
            disabled={(data as $TSFixMe).plan === 'FREE'}>
            {(data as $TSFixMe).videoAttachmentRef ? 'CHANGE' : 'UPLOAD'} VIDEO
            <AttachFile fontSize="small" />
          </Button>
        </label>
        <Typography
          // style={{color: displayWarning && 'red'}}
          variant="caption"
          color="textSecondary">
          MOV files excluded (max. 50MB)
        </Typography>
      </Grid>
    </Box>
  );

  return (
    <div
      style={{
        textAlign: 'center',
        padding: window.innerWidth > 600 ? '64px 30px' : '64px 8px',
      }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Card
            variant={'outlined'}
            style={{
              backgroundColor: '#f7f7f7',
              width: '100%',
              alignSelf: 'center',
              margin: 'auto',
              marginTop: '20px',
              height: '100%',
              borderRadius: '1rem',
            }}>
            <CardHeader action={getAction()} title={getTitle()} />
            <div className="user-text">
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{flex: 6, marginLeft: '8vw'}}>
                  <h2>
                    {(data as $TSFixMe).name}{' '}
                    {(data as $TSFixMe).riskStatus && (
                      <UserRiskStatusWidget
                        riskStatus={(data as $TSFixMe).riskStatus}
                      />
                    )}
                  </h2>
                  <p>
                    {(data as $TSFixMe).title
                      ? `${(data as $TSFixMe).title} -`
                      : null}{' '}
                    {(data as $TSFixMe).rating !== null &&
                    (data as $TSFixMe).rating > 1
                      ? ` ${Math.round((data as $TSFixMe).rating)} %`
                      : ''}
                  </p>
                </div>
              </div>
              {(data as $TSFixMe).userType === 'BIDDER' &&
                (data as $TSFixMe).videoAttachmentRef && (
                  <Box
                    m={1}
                    p={2.5}
                    style={{
                      backgroundColor: '#e6e6e6',
                    }}>
                    <Grid container justifyContent="center">
                      <video
                        src={(data as $TSFixMe).videoAttachmentRef.url}
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; src: any; alt: string; w... Remove this comment to see the full error message
                        alt="Intro Video"
                        width="100%"
                        controls
                        muted
                        loop
                        style={{height: '36vh'}}
                        type={'video/mp4;'}>
                        Sorry, your browser doesn't support embedded videos
                      </video>
                    </Grid>
                  </Box>
                )}
              {(data as $TSFixMe).userType === 'BIDDER' && VideoInput}
            </div>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="on"
              className={classes.tabs}>
              <Tab value="profile" label="Profile" />
              {['REQUESTER', 'BIDDER'].includes(
                (data as $TSFixMe).userType,
              ) && <Tab value="projects" label="Projects" />}
              {(data as $TSFixMe).userType === 'BIDDER' && (
                <Tab value="quotes" label="Quotes" />
              )}
              {(data as $TSFixMe).userType === 'BIDDER' && (
                <Tab value="siteVisits" label="Site Visits" />
              )}
              {(data as $TSFixMe).userType === 'BIDDER' && (
                <Tab value="contacts" label="Contacts" />
              )}
              {(data as $TSFixMe).userType === 'BIDDER' &&
                questions.length > 0 && (
                  <Tab value="questions" label="Questions" />
                )}
              {['REQUESTER', 'BIDDER'].includes(
                (data as $TSFixMe).userType,
              ) && [
                <Tab value="transactions" label="Transactions" />,
                <Tab value="sessions" label="Sessions" />,
                <Tab value="notifications" label="Notifications" />,
                <Tab value="reviews" label="Reviews" />,
              ]}
              {(data as $TSFixMe).userType === 'PARTNER' && (
                <Tab value="contacts" label="Leads" />
              )}
              {(data as $TSFixMe).userType === 'PARTNER' && (
                <Tab value="referrals" label="Referrals" />
              )}
            </Tabs>
            <TabPanel value={value} index="profile">
              {props.mode === 'view' ? (
                <ProfileInfoTab
                  data={data}
                  updateUserValues={updateUserValues}
                  badges={badges}
                  userId={props.user.id}
                  parentUser={props.user}
                  fetchData={fetchData}
                  setData={setData}
                />
              ) : (
                <EditableProfile
                  ref={ref}
                  data={data}
                  updateUserValues={props.updateUserValues}
                  setMode={props.setMode}
                  fetchData={fetchData}
                  setData={setData}
                />
              )}
            </TabPanel>
            <TabPanel
              value={value}
              index="projects"
              style={{margin: '0 20px 0 20px'}}>
              <ProjectViewTab user={data} />
            </TabPanel>
            <TabPanel
              value={value}
              index="quotes"
              style={{margin: '0 20px 0 20px'}}>
              <QuotesTab user={props.user} />
            </TabPanel>
            <TabPanel
              value={value}
              index="siteVisits"
              style={{margin: '0 20px 0 20px'}}>
              <SiteVisitsTab userId={props.userId} />
            </TabPanel>
            <TabPanel
              value={value}
              index="contacts"
              style={{margin: '0 20px 0 20px'}}>
              <ContactsTab userId={props.userId} />
            </TabPanel>
            {questions.length > 0 && (
              <TabPanel
                value={value}
                index="questions"
                style={{margin: '0 20px 0 20px'}}>
                <QuestionsTab questions={questions} />
              </TabPanel>
            )}
            <TabPanel
              value={value}
              index="transactions"
              style={{margin: '0 20px 0 20px'}}>
              <TransactionsTab transactions={transactions} />
            </TabPanel>
            <TabPanel
              value={value}
              index="notifications"
              style={{margin: '0 20px 0 20px'}}>
              <NotificationsTab
                notifications={(data as $TSFixMe).notifications}
                userId={props.user.id}
              />
            </TabPanel>
            <TabPanel
              value={value}
              index="sessions"
              style={{margin: '0 20px 0 20px'}}>
              <UserSessionsTab userId={(data as $TSFixMe).id} />
            </TabPanel>
            <TabPanel
              value={value}
              index="reviews"
              style={{margin: '0 20px 0 20px'}}>
              <ReviewsTab user={props.user} />
            </TabPanel>
            <TabPanel
              value={value}
              index="referrals"
              style={{margin: '0 20px 0 20px'}}>
              <ReferralsTab user={props.user} />
            </TabPanel>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            variant="outlined"
            style={{
              backgroundColor: '#f7f7f7',
              width: '100%',
              alignSelf: 'center',
              marginTop: '20px',
              borderRadius: 10,
            }}>
            <div
              style={{
                padding: 10,
                textAlign: 'left',
                width: '100%',
                marginBottom: 50,
              }}>
              <TextField
                id="note_input"
                multiline
                maxRows={6}
                placeholder="Record a note..."
                variant="outlined"
                style={{
                  backgroundColor: '#fff',
                  marginBottom: 10,
                  borderRadius: 10,
                  width: '100%',
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={submitNote}
                style={{float: 'right'}}>
                Submit
              </Button>
            </div>
            <div
              style={{
                padding: 10,
                textAlign: 'left',
                height: '100%',
                maxHeight: 700,
                overflowY: 'scroll',
              }}>
              {notes &&
                notes.map((note: any, index) => (
                  <UserNote
                    key={`${note?.id}_${index}`}
                    note={note}
                    handleOnClickSendSms={handleOnClickSendSms}
                  />
                ))}
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
});

export default DetailsPane;
