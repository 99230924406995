import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {SERVER_URL} from '../../../constants';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import {DialogActions} from '@material-ui/core';
import BidmiiProCheckoutDialog from './BidmiiProCheckoutDialog';
import {Checkbox, FormControlLabel} from '@mui/material';

const styles = (theme: $TSFixMe) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { m... Remove this comment to see the full error message
const DialogTitle = withStyles(styles)((props) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'PropsWi... Remove this comment to see the full error message
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function SubscriptionDetailsDialog(props: $TSFixMe) {
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState<any>(null);
  const [removeAccess, setRemoveAccess] = React.useState<boolean>(false);

  const handleChangeRemoveAccess = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRemoveAccess(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmCancellation = () => {
    const fbjwt = localStorage.getItem('Authorization');
    let uri = `${SERVER_URL}v1/subscriptions/user/${props.userId}/cancel`;
    if (details?.id) {
      uri = `${SERVER_URL}v1/subscriptions/${details.id}/cancel`;
    }
    return fetch(uri, {
      method: 'DELETE',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({removeAccess}),
    })
      .then((res) => res.json())
      .then((res) => {
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const getSubscriptionDetails = () => {
    const fbjwt = localStorage.getItem('Authorization');
    console.log(`${SERVER_URL}v1/users/${props.userId}/subscription-details`);
    return fetch(`${SERVER_URL}v1/users/${props.userId}/subscription-details`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setDetails(res);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  useEffect(() => {
    getSubscriptionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {details?.status !== 'CANCELED' ? (
        <Button
          style={{
            marginRight: '20px',
            height: 'fit-content',
            color: 'red',
          }}
          variant="contained"
          color="primary"
          component={'span'}
          size={'small'}
          onClick={handleClickOpen}>
          DETAILS
        </Button>
      ) : (
        <>
          <div
            style={{
              color: 'red',
              fontWeight: 'bold',
              marginTop: -8,
              marginBottom: 12,
            }}>
            (CANCELED)
          </div>
          <BidmiiProCheckoutDialog
            userId={props.userId}
            updateUser={props.updateUser}
          />
        </>
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="ban-user-dialog"
        fullWidth
        open={open}>
        <DialogTitle>Subscription Details</DialogTitle>
        <DialogContent>
          {details && (
            <div style={{paddingLeft: 40, paddingRight: 40}}>
              <Grid container justifyContent={'space-between'}>
                <Grid item sm={6}>
                  STATUS
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    color: details?.status === 'CANCELED' ? 'red' : 'black',
                  }}>
                  {details.status}
                </Grid>
                <Grid item sm={6}>
                  START DATE
                </Grid>
                <Grid item sm={6}>
                  {moment(details.createdAt).format('MMM Do YYYY')}
                </Grid>
                <Grid item sm={6}>
                  PAID UNTIL
                </Grid>
                <Grid item sm={6}>
                  {moment(details.paidThroughDate).format('MMM Do YYYY')}
                </Grid>
                <Grid item sm={6}>
                  PLAN
                </Grid>
                <Grid item sm={6}>
                  {details.planId}
                </Grid>
                <Grid item sm={6}>
                  PRICE
                </Grid>
                <Grid item sm={6}>
                  {details.price}
                </Grid>
              </Grid>
            </div>
          )}
        </DialogContent>
        {details?.status !== 'CANCELED' && (
          <DialogActions>
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  checked={removeAccess}
                  onChange={handleChangeRemoveAccess}
                />
              }
              label="Remove Access"
              labelPlacement="start"
            />
            <Button
              style={{marginLeft: 15}}
              variant={'contained'}
              onClick={() => {
                // eslint-disable-next-line no-alert
                if (confirm('Are you sure?')) {
                  confirmCancellation();
                }
              }}
              color={'secondary'}>
              Cancel Subscription
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
