import React, {useState, useEffect} from 'react';
import {SERVER_URL} from '../../../../../constants';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {makeStyles} from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import moment from 'moment';

import {useRecoilState} from 'recoil';
import {loadingState} from '../../../../../recoil/loading/loadingAtom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  mapPaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    height: '425px',
    paddingRight: '35px',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function UsersCharts(props: $TSFixMe) {
  const [newUserCountDaily, setNewUserCountDaily] = useState([]);
  const [startDate, setStartDate] = useState(Date.now());
  const [, setLoading] = useRecoilState(loadingState);

  const options = {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
    },
    tooltip: {
      pointFormat: 'Time to First Job: {point.y:,.2f} minutes',
    },
    title: {
      text: 'Time to First Job',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value:%Y-%m-%d}',
        rotation: 45,
        align: 'left',
      },
    },
    yAxis: {
      title: {
        text: 'Minutes to First Job',
      },
    },

    series: [
      {
        name: 'Time to First Job',
        color: 'rgba(223, 83, 83, .8)',
        data: newUserCountDaily.map((point) => {
          return [
            (point as $TSFixMe).userSignupTime,
            (point as $TSFixMe).timeToProject / 60,
          ];
        }),
        pointStart: startDate,
        pointInterval: 24 * 36e5,
      },
    ],
  };

  useEffect(() => {
    async function fetchData(url: $TSFixMe) {
      setLoading(true);
      const fullUrl = `${SERVER_URL}${url}`;
      const fbjwt = localStorage.getItem('Authorization');
      const response = await fetch(fullUrl, {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: fbjwt,
          'Content-Type': 'application/json',
        },
      });
      setLoading(false);
      const data = await response.json();
      return data;
    }

    async function dataFetcher() {
      try {
        const responseData = await fetchData('users');
        const projects = await fetchData('admin/rfps?filter=all');

        let dailyArray = dailyArrayGenerator(responseData, projects);

        //exclude projects over 10 minutes
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'point' implicitly has an 'any' type.
        dailyArray = dailyArray.filter((point) => {
          return point.timeToProject < 60 * 10;
        });

        setNewUserCountDaily(dailyArray);
        setStartDate(new Date(dailyArray[0].userSignupTime).getTime());
      } catch (err: $TSFixMe) {
        console.error(err);
        if (err?.status === 403) {
          window.location.href = '/login';
        }
      }
    }
    dataFetcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dailyArrayGenerator = (responseData: $TSFixMe, projects: $TSFixMe) => {
    let dailyArray: $TSFixMe = [];
    responseData.forEach((user: $TSFixMe) => {
      const creationTime = moment(user.createDate);
      const momentDay = creationTime.format('M/D/YYYY');
      const firstUserProject = firstUserProjectCreationTime(projects, user);

      if (firstUserProject) {
        dailyArray.push({
          description: momentDay,
          count: 1,
          timeToProject:
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            firstUserProjectCreationTime(projects, user).diff(creationTime) /
            1000,
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          userSignupTime: new Date(creationTime).getTime(),
        });
      }
    });

    dailyArray = dailyArray.sort(
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
      (a, b) => new Date(a.userSignupTime) - new Date(b.userSignupTime),
    );

    return dailyArray;
  };

  const firstUserProjectCreationTime = (projects: $TSFixMe, user: $TSFixMe) => {
    let uprojects = userProjects(projects, user);
    if (uprojects.length > 0) {
      uprojects = uprojects.sort(
        (a: $TSFixMe, b: $TSFixMe) =>
          // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
          new Date(a.createdDate) - new Date(b.createdDate),
      );
      return moment(uprojects[0].createdDate);
    } else {
      return null;
    }
  };

  //Probably temporary, but I'm going to grab specific user projects without an external request because I don't want make a million of them
  const userProjects = (projects: $TSFixMe, user: $TSFixMe) => {
    return projects.filter((proj: $TSFixMe) => proj.user.id === user.id);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.mapPaper}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          containerProps={{
            style: {height: '100%', width: 'calc(90vw - 240px)'},
          }}
        />
      </Paper>
    </div>
  );
}
