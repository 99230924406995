import React, {useState, useEffect} from 'react';
import {Switch, Route} from 'react-router-dom';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import DrawerMenuOptions from './DrawerMenuOptions';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MyRequestForProposalsList from '../RequestForProposal/MyRequestForProposalsList';
import MyRequestForProposalForm from '../RequestForProposal/MyRequestForProposalForm';
import Users from '../Users';
import Activity from '../Activity/Activity';
import Details from '../Users/components/Details';
import {Metrics} from '../index';
import Transactions from '../Transactions/Index';
import PromoCodes from '../Promocodes';
import Stats from '../Stats';
import Accounting from '../Accounting';
import ServiceArea from '../ServiceArea';
import {useRecoilValue} from 'recoil';
import {loadingState} from '../../recoil/loading/loadingAtom';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import {Dialog, Slide} from '@material-ui/core';
import Notification from './Notification/Notfication';
import NotificationMenuHeader from './Notification/NotificationMenuHeader';
import {SERVER_URL} from '../../constants';
import Unsubscribes from '../Unsubscribes/Unsubscribes';
import Notifications from '../Notifications/Notifications';
import Questions from '../Questions';
import UserFeedback from '../UserFeedback';
import {Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
import Categories from '../Categories';
import Badges from '../BadgesList';
import ProjectTemplates from '../ProjectTemplates';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const drawerWidth = 240;

const styles = (theme: $TSFixMe) => ({
  root: {
    display: 'flex',
  },

  appBar: {
    backgroundColor: '#D6EFFF',
    borderBottom: '1px solid #d9d9d9',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarShift: {
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  hide: {
    display: 'none',
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },

  content: {
    flexGrow: 1,
    paddingTop: 30,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //marginLeft: -drawerWidth,
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  title: {
    flexGrow: 1,
  },

  extendedIcon: {
    marginRight: theme.spacing(1),
  },

  notificationMenu: {
    width: '100%',
    position: 'fixed',
    top: -32,
    bottom: 'auto',
    right: window.outerWidth > 600 ? 60 : undefined,
    borderBottom: '1px solid #e6e6e6',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    maxWidth: 475,
  },
});

type MainProps = {
  classes: $TSFixMe;
};

const Main = (props: MainProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);
  const loading = useRecoilValue(loadingState);
  const [notifications, setNotifications] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const toggleMenuOpen = () => {
    setNotificationMenuOpen(!notificationMenuOpen);
  };

  const getNotifications = () => {
    const url = `${SERVER_URL}v1/activities?page=0&pageSize=10`;
    const fbjwt = localStorage.getItem('Authorization');

    fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            `Server error: [${res.status}] [${res.statusText}] [${res.url}]`,
          );
        }
        return res.json();
      })
      .then((res) => {
        if (res.content) {
          setNotifications(res.content);
        }
      })
      .catch((error) => {
        setNotifications([]);
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const {classes} = props;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
        elevation={0}>
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              {isSmallScreen ? (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(drawerOpen && classes.hide)} // Conditional class for drawer open/close
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <Grid mt={1} container alignItems="flex-start">
                  <img
                    src={'https://cdn.bidmii.com/media/web_assets/logo.png'}
                    style={{width: 100, marginRight: 10}}
                    alt="Bidmii Logo" // Added alt text for accessibility
                  />
                  <Typography variant="h5">ADMIN</Typography>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open notifications"
                onClick={toggleMenuOpen}>
                <NotificationsNoneIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <Dialog
          open={notificationMenuOpen}
          onClose={toggleMenuOpen}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'ForwardRefExoticComponent<RefAttributes<unkn... Remove this comment to see the full error message
          TransitionComponent={Transition}
          PaperProps={{className: classes.notificationMenu}}
          BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.05)'}}}>
          <NotificationMenuHeader />
          <Notification
            notifications={notifications}
            setNotifications={setNotifications}
            onClose={toggleMenuOpen}
          />
        </Dialog>
      </AppBar>
      {isSmallScreen ? ( // Conditional rendering based on screen size
        <Drawer
          className={classes.drawer}
          variant="temporary" // Temporary for small screens
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <DrawerMenuOptions />
        </Drawer>
      ) : (
        <Drawer
          className={classes.drawer}
          variant="permanent" // Permanent for larger screens
          anchor="left"
          classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.drawerHeader} />
          <DrawerMenuOptions />
        </Drawer>
      )}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
        style={{height: '100vh', backgroundColor: '#fafafa'}}>
        {loading ? (
          <LinearProgress color="secondary" style={{zIndex: 1200}} />
        ) : (
          <div style={{height: 4}} />
        )}
        <Switch>
          <Route exact path="/" component={MyRequestForProposalsList} />
          <Route exact path="/my/dashboard" component={Stats} />
          <Route exact path="/metrics" component={Metrics} />
          <Route exact path="/myRfps" component={MyRequestForProposalsList} />
          <Route
            exact
            path="/myRfps/:id"
            component={MyRequestForProposalForm}
          />
          <Route exact path="/users" component={Users} />
          <Route exact path="/users/:uid" component={Details} />
          <Route exact path="/transactions" component={Transactions} />
          <Route exact path="/project-templates" component={ProjectTemplates} />
          <Route exact path="/promocodes" component={PromoCodes} />
          <Route exact path="/accounting" component={Accounting} />
          <Route exact path="/servicearea" component={ServiceArea} />
          <Route exact path="/unsubscribes" component={Unsubscribes} />
          <Route exact path="/activity" component={Activity} />
          <Route exact path="/questions" component={Questions} />
          <Route exact path="/feedback" component={UserFeedback} />
          <Route exact path="/categories" component={Categories} />
          <Route exact path="/badges" component={Badges} />
          <Route exact path="/notifications" component={Notifications} />
        </Switch>
      </main>
    </div>
  );
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: $TSFixMe) => { root: { d... Remove this comment to see the full error message
export default withStyles(styles)(Main);
