import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {SERVER_URL} from '../../constants';

export default function SendEmailsDialog({setShowDialog}: $TSFixMe) {
  const [target, setTarget] = useState('');
  const [templateID, setTemplateID] = useState('');
  const [emailErrors, setEmailErrors] = useState([]);

  const options = [
    {label: 'Homeowners', value: 'homeowners'},
    {label: 'Contractors', value: 'contractors'},
    {label: 'All', value: 'all'},
    {label: 'Myself', value: 'test'},
    {label: 'Category Notifications', value: 'category'},
  ];

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleSelection = (event: $TSFixMe) => {
    setTarget(event.target.value);
  };

  const handleChange = (event: $TSFixMe) => {
    setTemplateID(event.target.value);
  };

  const generateMenuItems = () => {
    const menuItems: $TSFixMe = [];
    options.forEach((option) => {
      menuItems.push(<MenuItem value={option.value}>{option.label}</MenuItem>);
    });
    return menuItems;
  };

  const generateEmailErrorItems = (body: $TSFixMe) => {
    console.log(body);
    setEmailErrors([]);
  };

  const sendEmails = () => {
    const fbjwt = localStorage.getItem('Authorization');
    let url = `${SERVER_URL}v1/email-campaign/send`;
    let body = JSON.stringify({
      target: target,
      templateID: templateID,
    });

    if (target === 'category') {
      url = `${SERVER_URL}v1/email-campaign/category-notifications`;
      body = JSON.stringify({});
    }

    return (
      fetch(url, {
        method: 'POST',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
        headers: {
          'Content-Type': 'application/json',
          Authorization: fbjwt,
        },
        body: body,
      })
        .then(
          (res) => {
            if (res && res.status === 200) {
              return res.json();
            }
          },
          (error) => {
            console.error(error);
          },
        )
        .then((res) => {
          if (target !== 'category') {
            generateEmailErrorItems(res);
          } else {
            handleClose();
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          handleClose();
        })
    );
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Send Emails</DialogTitle>
        <DialogContent style={{minWidth: 300}}>
          <FormControl fullWidth>
            <InputLabel id="send-to-label">Send To</InputLabel>
            <Select
              labelId="send-to-label"
              id="send-to-select"
              value={target}
              label="Send To"
              onChange={handleSelection}>
              {generateMenuItems()}
            </Select>
          </FormControl>
          <br />
          {['homeowners', 'contractors', 'test', 'all'].includes(target) && (
            <TextField
              margin="dense"
              id="templateId"
              label="Sendgrid Template ID"
              type="textInput"
              fullWidth
              name="templateID"
              onChange={handleChange}
              value={templateID}
            />
          )}
          <br />
          <div>{emailErrors}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={sendEmails} variant="contained" color="primary">
            SEND
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
