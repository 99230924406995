export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const GMAPS_API_KEY = process.env.REACT_APP_GMAPS_API_KEY;
export const PAYPAL_AUTHORIZATION = process.env.REACT_APP_PAYPAL_AUTHORIZATION;
export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
