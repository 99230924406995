import React from 'react';
import {SERVER_URL} from '../../constants';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DeleteServiceAreaDialog = (props: $TSFixMe) => {
  const handleDeleteServiceArea = () => {
    const url = `${SERVER_URL}v1/service-areas${props.rowData.id}`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      method: 'DELETE',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      props.fetchData();
      props.onClose();
    });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="Delete Service Area">
      <DialogTitle id="form-dialog-title">Delete Service Area</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {props.rowData?.city?.toUpperCase()}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleDeleteServiceArea} color="secondary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteServiceAreaDialog;
