import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Proposal from '../Proposal';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {DialogContent} from '@mui/material';

export default function ProposalDialog(props: $TSFixMe) {
  const handleClose = () => {
    props.setSelectedProposalUid(null);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        fullScreen={true}
        open={props.proposalUid}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <AppBar position={'fixed'}>
          <Toolbar>
            <Button
              style={{marginLeft: 'auto', marginRight: 40}}
              variant="contained"
              color="secondary"
              onClick={handleClose}>
              CLOSE
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{marginTop: 60}}>
            <Proposal
              proposalUid={props.proposalUid}
              requestForProposal={props.requestForProposal}
              updateRequestForProposalState={
                props.updateRequestForProposalState
              }
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
