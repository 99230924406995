import React, {useCallback, useEffect, useState} from 'react';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {SERVER_URL} from '../../../constants';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {Switch} from '@material-ui/core';
import {FormControlLabel} from '@mui/material';

type IProps = {
  requestForProposal: any;
};

const CustomToolbar = (props: $TSFixMe) => {
  return (
    <GridToolbarContainer style={{paddingTop: 25}}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <FormControlLabel
        control={
          <Switch
            color={'secondary'}
            checked={props.type === 'summary'}
            onChange={props.toggleReportType}
            inputProps={{'aria-label': 'controlled'}}
          />
        }
        label="Summary"
      />

      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

function ViewsTab(props: IProps) {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState('summary');

  const apiRef = useGridApiRef();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    if (paginationModel.page === 0) {
      setRows([]);
      getData();
    } else {
      setRows([]);
      setPaginationModel({page: 0, pageSize: 50});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const getData = useCallback(() => {
    fetch(
      `${SERVER_URL}v1/rfps/${props.requestForProposal.id}/page-views?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}&type=${type}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (paginationModel.page === 0) {
          setRowCount(res.page.totalElements);
          setRows(res._embedded?.models || []);
        } else {
          // @ts-ignore
          setRows((prevState: any) => [...prevState, res._embedded?.models]);
        }

        setLoading(false);
      });
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    props.requestForProposal.id,
    type,
  ]);

  const summaryColumns: GridColDef[] = [
    {
      headerName: 'Name',
      field: 'user',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) => row?.user?.name,
      width: 200,
    },
    {
      headerName: 'Email',
      field: 'email',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="email user"
            component="span"
            style={{marginRight: 8}}>
            <EmailIcon style={{fontSize: 17}} />
          </IconButton>
          {params?.row?.user.email || ''}
        </span>
      ),
      width: 250,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="phone user"
            component="span"
            style={{marginRight: 8}}>
            <PhoneIcon style={{fontSize: 17}} />
          </IconButton>
          {params?.row?.user?.phone || ''}
        </span>
      ),
      width: 140,
    },
    {
      headerName: 'Visits',
      field: 'count',
      type: 'number',
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          key={'ViewsTab_actions_00'}
          icon={<OpenInNewIcon />}
          onClick={() => {
            window.open(
              `${window.location.origin}/users/${params?.row?.user?.id}`,
              '_blank',
            );
          }}
          label="View User"
        />,
      ],
      width: 100,
    },
  ];

  const columns: GridColDef[] = [
    {headerName: 'ID', field: 'id', type: 'number'},
    {
      headerName: 'Created',
      field: 'createdDate',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        moment(row?.createdDate).format('MMM D, YYYY, h:mm A'),
      width: 200,
    },
    {
      headerName: 'Name',
      field: 'user',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) => row?.user.name,
      width: 200,
    },
    {
      headerName: 'Email',
      field: 'email',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="email user"
            component="span"
            style={{marginRight: 8}}>
            <EmailIcon style={{fontSize: 17}} />
          </IconButton>
          {params?.row?.user?.email || ''}
        </span>
      ),
      width: 250,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="phone user"
            component="span"
            style={{marginRight: 8}}>
            <PhoneIcon style={{fontSize: 17}} />
          </IconButton>
          {params?.row?.user.phone || ''}
        </span>
      ),
      width: 140,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          key={'ViewsTab_actions_0'}
          icon={<OpenInNewIcon />}
          onClick={() => {
            window.open(
              `${window.location.origin}/users/${params?.row?.user.id}`,
              '_blank',
            );
          }}
          label="View User"
        />,
      ],
      width: 100,
    },
  ];

  const toggleReportType = () => {
    setType((curState) => (curState === 'details' ? 'summary' : 'details'));
  };

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 30,
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          key={type}
          getRowId={(row) => (row?.user.id ? row.user.id : row.id)}
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 500},
              toggleReportType: toggleReportType,
              type: type,
            },
          }}
          rows={rows}
          rowCount={rowCount}
          columns={type === 'details' ? columns : summaryColumns}
          rowHeight={38}
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50]}
          pagination
          paginationMode={'server'}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default ViewsTab;
