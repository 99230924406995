import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {SERVER_URL} from '../../../constants';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
    cursor: 'pointer',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function PageViewsPanel({data}: $TSFixMe) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [views, setViews] = useState([]);

  const getData = () => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/sessions/${data.id}/page-views?page=${page}`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const responseData = [...views, ...res?._embedded?.models];
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
        setViews(responseData || []);

        if (res?.page && page < res.page.totalPages - 1) {
          setPage((currentPage) => currentPage + 1);
        }
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Timeline align="alternate">
      {views.map((view) => (
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {`${moment((view as $TSFixMe).createdDate).format('hh:mm a')}`}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper
              elevation={3}
              className={classes.paper}
              onClick={() => window.open((view as $TSFixMe).ctaUrl, '_blank')}>
              <Typography variant="h6" component="h1">
                {(view as $TSFixMe).title}
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
