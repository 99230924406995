import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import StandardPayoutViewTable from './StandardPayoutViewTable';
import Button from '@material-ui/core/Button';
import BuyNowPayoutViewTable from './BuyNowPayoutViewTable';

const PayoutReport = (props: $TSFixMe) => {
  const [payoutDetailDialogOpen, setPayoutDetailDialogOpen] = useState(false);

  if (props.stage?.state !== 'COMPLETE') {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => setPayoutDetailDialogOpen(true)}
        variant={props.isStage ? 'text' : 'contained'}
        color={'secondary'}
        size={'small'}>
        {props.isStage ? 'Report' : 'Payout Report'}
      </Button>

      <Dialog
        open={payoutDetailDialogOpen}
        onClose={() => setPayoutDetailDialogOpen(false)}>
        <DialogTitle id="customized-dialog-title">
          Payout Details{' '}
          {props.proposal.type === 'BIDMII_DIRECT' && '(BIDMII PRO)'}
        </DialogTitle>
        <DialogContent dividers style={{width: 600}}>
          {props.proposal.type === 'buy_now' ? (
            <BuyNowPayoutViewTable
              proposal={props.proposal}
              stage={props.stage}
            />
          ) : (
            <StandardPayoutViewTable
              proposal={props.proposal}
              stage={props.stage}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PayoutReport;
