import React, {useEffect, useState, useRef} from 'react';
import {SERVER_URL} from '../../constants';
import MaterialTable from 'material-table';
import Container from 'react-bootstrap/Container';
import Grid from '@material-ui/core/Grid';
import CreateServiceAreaDialog from './CreateServiceAreaDialog';
import DeleteServiceAreaDialog from './DeleteServiceAreaDialog';

//TODO: implement paging for this table.
const ServiceAreaTable = (props: $TSFixMe) => {
  const [data, setData] = useState([]);
  const [regionToDelete, setRegionToDelete] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const tableRef = useRef();
  const [pageSize, setPageSize] = useState(
    localStorage.getItem('defaultPageSize')
      ? localStorage.getItem('defaultPageSize')
      : 10,
  );

  const fetchData = () => {
    const url = `${SERVER_URL}v1/service-areas?page=0&pageSize=100`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      });
  };

  const actions = [
    {
      icon: 'add',
      tooltip: 'Add Service Area',
      isFreeAction: true,
      onClick: (event: $TSFixMe) => setCreateDialogOpen(true),
    },
    {
      icon: 'delete',
      tooltip: 'Delete Service Area',
      onClick: (event: $TSFixMe, rowData: $TSFixMe) => {
        setRegionToDelete(rowData);
        setDeleteDialogOpen(true);
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: 'City',
      field: 'city',
      render: (rowData: $TSFixMe) => rowData.city?.toUpperCase(),
    },
    {
      title: 'Region',
      field: 'region',
    },
    {
      title: 'Province/State',
      field: 'province_state',
    },
    {
      title: 'Canada',
      field: 'country',
    },
  ];

  const handleOnChangeRowsPerPage = (size: $TSFixMe) => {
    localStorage.setItem('defaultPageSize', size);
    setPageSize(size);
  };

  return (
    <div>
      <Container
        fluid="false"
        style={{backgroundColor: 'light-grey', paddingTop: 30}}>
        <Grid container justifyContent="center" style={{marginBottom: 20}}>
          <Grid item xs={12} md={12} key={0}>
            {data && data.length > 0 && (
              <MaterialTable
                columns={columns}
                title={'Service Areas'}
                data={data}
                actions={actions}
                options={{
                  toolbar: true,
                  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                  pageSize: pageSize,
                  pageSizeOptions: [
                    10, 20, 30, 50, 75, 100, 200, 500, 1000, 100000,
                  ],
                  exportButton: true,
                  debounceInterval: 500,
                  actionsColumnIndex: -1,
                }}
                onRowsPerPageChange={handleOnChangeRowsPerPage}
                tableRef={tableRef}
              />
            )}
          </Grid>
        </Grid>

        <CreateServiceAreaDialog
          fetchData={fetchData}
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
        />

        <DeleteServiceAreaDialog
          rowData={regionToDelete}
          fetchData={fetchData}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        />
      </Container>
    </div>
  );
};

export default ServiceAreaTable;
