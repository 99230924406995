import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import {getProfileImage} from './Profile';
import {useHistory} from 'react-router-dom';

export default function ContractorAvatar(props: $TSFixMe) {
  const history = useHistory();

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClick={() => history.push(`/users/${props.user.id}`)}
      style={{cursor: 'pointer'}}>
      <Avatar
        alt={props.user.firstName}
        src={getProfileImage(props.user, '100x100')}
      />
    </Badge>
  );
}
