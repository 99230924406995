import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Rating from '@material-ui/lab/Rating';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import React from 'react';

export default function Review(props: $TSFixMe) {
  if (!props.review) {
    return null;
  }

  const reviewQuestions = props.review.reviewQuestions.map(
    (question: $TSFixMe) => {
      return (
        <Grid key={question.id} container item spacing={1} direction="row">
          <Grid item sm={12} md={12}>
            <ListItem style={{display: 'flex'}}>
              <ListItemText
                style={{flex: 2.6, fontWeight: 'bold'}}
                primary={question.question}
              />
              <ListItemText
                style={{flex: 1.6}}
                primary={
                  <Rating
                    name="read-only"
                    value={question.rating}
                    precision={0.5}
                    readOnly
                  />
                }
              />
            </ListItem>
          </Grid>
        </Grid>
      );
    },
  );
  return (
    <Card style={{margin: '10px', borderRadius: '10px', background: '#eee'}}>
      <CardHeader subheader={`Review of ${props.user}`} />
      <ListItem style={{display: 'flex'}}>
        <ListItemText
          style={{flex: 2.6, fontWeight: 'bold'}}
          primary={'Rating:'}
        />
        <ListItemText style={{flex: 1.6}} primary={props.review.rating * 100} />
      </ListItem>
      {reviewQuestions}
      {props.review.note && (
        <ListItem style={{display: 'flex'}}>
          <ListItemText
            style={{flex: 2.6, fontWeight: 'bold'}}
            primary={'Note:'}
          />
          <ListItemText style={{flex: 1.6}} primary={props.review.note} />
        </ListItem>
      )}
      {props.review.privateFeedback && (
        <ListItem style={{display: 'flex'}}>
          <ListItemText
            style={{flex: 2.6, fontWeight: 'bold'}}
            primary={'Private Feedback:'}
          />
          <ListItemText
            style={{flex: 1.6}}
            primary={props.review.privateFeedback}
          />
        </ListItem>
      )}
      {props?.review?.recomendationScore && (
        <>
          <CardHeader subheader={'Would recommend this user'} />
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: any; max: number; readonly: true; }... Remove this comment to see the full error message */}
          <Rating value={props.review.recomendationScore} max={10} readonly />
        </>
      )}
    </Card>
  );
}
