import React from 'react';
import {Box, Grid, Link, Typography} from '@material-ui/core';
//import PdfImage from './pdf_icon.png';
import DocImage from './doc_icon.png';
//import ReceiptImage from './receipt_icon.png';
import NewPdfImage from '../../assets/images/pdf_icon.png';

function DocumentsList(props: $TSFixMe) {
  const truncate = (name: $TSFixMe) =>
    name.length > 16 ? `${name.substring(0, 16)}...` : name;

  const documentItem = (doc: $TSFixMe) => {
    const parts = doc.name.split('.');
    const extension = parts[parts.length - 1];
    let image;
    let title = truncate(doc.name);

    if (
      [
        'fees_invoice',
        'payment_receipt',
        'contractor_payment_receipt',
      ].includes(doc.key)
    ) {
      image = NewPdfImage;
      title = doc.description;
    } else if (extension === 'pdf') {
      image = NewPdfImage;
    } else {
      image = DocImage;
    }
    return (
      <Grid item key={doc.id}>
        <Link href={doc.url} target="_blank" rel="noopener">
          <div style={{textAlign: 'center'}}>
            <img
              src={image}
              alt={'File Icon'}
              style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: 30}}
            />
            <br />
            <Typography variant="caption" style={{color: 'black'}}>
              {title}
            </Typography>
          </div>
        </Link>
      </Grid>
    );
  };

  return (
    <Box mb={1}>
      <Grid container spacing={3}>
        {props.attachmentReferences
          .filter((doc: $TSFixMe) =>
            [
              'project_document',
              'fees_invoice',
              'payment_receipt',
              'contractor_payment_receipt',
            ].includes(doc.key),
          )
          .map((doc: $TSFixMe, index: $TSFixMe) => documentItem(doc))}
      </Grid>
    </Box>
  );
}

export default DocumentsList;
