import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {SERVER_URL} from '../../../constants';
import {Snackbar} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import {FormControl, InputLabel} from '@mui/material';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 40,
    marginBottom: 40,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 40,
  },
}));

export default function TransactionDialog(props: $TSFixMe) {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [feeFactor, setFeeFactor] = useState(0.01);
  const [date, setDate] = useState(null);
  const [selectedStage, setSelectedStage] = useState<any>('none');
  const [selectedStageObject, setSelectedStageObject] = useState<any>(null);
  const [snackBarVisible, setSnackBarVisible] = useState(false);

  useEffect(() => {
    try {
      let s = props.proposal.stages.filter(
        (stage: $TSFixMe) => stage.id === Number(selectedStage),
      )[0];

      if (!s) {
        props.proposal.addons?.forEach((addon: any) => {
          if (addon && addon?.stages && addon.stages.length > 0) {
            const addonStage = addon.stages[0];
            if (addonStage.id === Number(selectedStage)) {
              s = addonStage;
            }
          }
        });
      }

      setSelectedStageObject(s);
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStage]);

  const getTaxFactor = () => {
    if (props.proposal.taxFactor !== -1) {
      return props.proposal.taxFactor;
    }
    const contractor = props.proposal.user;
    return contractor.taxNo && contractor.taxNo.length > 8 ? 0.13 : 0;
  };

  const taxFactor = getTaxFactor();

  const getTax = () => {
    return selectedStageObject.subtotal * taxFactor;
  };

  const getTotalInvoice = () => {
    return selectedStageObject.subtotal + getTax();
  };

  const getTotal = () => {
    return Number(
      (selectedStageObject.subtotal + getTax() + getTotalFees()).toFixed(2),
    );
  };

  const getFees = () => {
    return getTotalInvoice() * feeFactor;
  };

  const getTotalFees = () => {
    return getFees() * 1.13;
  };

  useEffect(() => {
    if (selectedStage !== 'none' && selectedStageObject) {
      setAmount(getTotal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeFactor, selectedStageObject]);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const recordTransaction = () => {
    const jwt = localStorage.getItem('Authorization');
    const body = JSON.stringify({
      date: date,
      amount: amount,
      feeFactor: feeFactor,
      rfp_id: props.rfp_id,
      stage_id: selectedStageObject.id,
    });
    return fetch(`${SERVER_URL}v1/transactions`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
      body: body,
    })
      .then((res) => {
        if (res.status === 409) {
          return res;
        } else {
          setSnackBarVisible(true);
          setTimeout(function () {
            setSnackBarVisible(false);
            setDialogOpen(false);
            props.updateRequestForProposalState();
          }, 4000);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const hideSnackBar = (event: $TSFixMe) => {
    setSnackBarVisible(false);
  };

  if (!props.proposal) {
    return null;
  }

  const stages = props.proposal.stages.filter(
    (stage: any) => stage.state === 'OPEN',
  );

  try {
    props.proposal.addons?.forEach((addon: any) => {
      if (addon && addon.stages && addon.stages.length > 0) {
        const addonStage = addon.stages[0];
        if (addonStage.state === 'OPEN') {
          stages.push(addonStage);
        }
      }
    });
  } catch (e) {}

  return (
    <div>
      <Button
        style={{background: '#e6e6e6'}}
        onClick={() => setDialogOpen(true)}>
        Apply Payment
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Apply Payment</DialogTitle>
        <DialogContent style={{height: 400, width: 400}}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="stage-select-label" style={{margin: 40}}>
              Stage
            </InputLabel>
            <Select
              labelId="stage-select-label"
              id="stage-select"
              value={selectedStage}
              onChange={(event) => setSelectedStage(event.target.value)}
              style={{marginBottom: 40}}>
              <MenuItem value="none">Select Stage</MenuItem>
              {stages.map((stage: $TSFixMe) => (
                <MenuItem value={stage.id}>{stage.name || 'Stage 1'}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedStage !== 'none' && (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="date"
                type="date"
                name="date"
                fullWidth
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                onChange={(event) => setDate(event.target.value)}
                value={date}
              />
              <TextField
                margin="dense"
                label="amount"
                id="amount"
                type="number"
                fullWidth
                name="amount"
                value={amount}
                inputProps={{
                  readonly: true,
                }}
              />
              <TextField
                margin="dense"
                label="Fee %"
                id="feefactor"
                type="number"
                fullWidth
                name="feeFactor"
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                onChange={(event) => setFeeFactor(event.target.value)}
                defaultValue={0.01}
                value={feeFactor}
                inputProps={{
                  max: 0.04,
                  min: 0,
                  step: 0.01,
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button
            color={'secondary'}
            variant="contained"
            style={{color: 'black'}}
            disabled={selectedStage === 'none'}
            onClick={recordTransaction}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackBarVisible}
        onClose={hideSnackBar}
        message="Transaction has been recorded"
      />
    </div>
  );
}
