import React, {useState, useEffect} from 'react';
import {SERVER_URL} from '../../../constants';
import {TextField} from '@material-ui/core';
import {Autocomplete, Skeleton} from '@material-ui/lab';

const CategoriesSelectWidget = (props: $TSFixMe) => {
  const [categoryOptions, setCategoryOptions] = useState(null);
  const [loading, setLoading] = useState(true);

  const getCategoryOptions = () => {
    setLoading(true);
    fetch(`${SERVER_URL}v1/rfps/categories?page=0&pageSize=10000`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization'),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const options = res?._embedded?.models.map((category: $TSFixMe) =>
          category.name.toUpperCase(),
        );
        setCategoryOptions(options);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onChange = (event: $TSFixMe, value: $TSFixMe, reason: $TSFixMe) => {
    props.setSelectedCategories([...value]);
  };

  useEffect(() => {
    getCategoryOptions();
  }, []);

  if (loading) {
    return <Skeleton variant="text" width={200} height={55} />;
  }

  return (
    <Autocomplete
      multiple
      fullWidth
      id="tags-outlined"
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'any[]'.
      options={categoryOptions}
      size={'small'}
      style={{width: 200}}
      onChange={onChange}
      //getOptionLabel={(option) => option.title}
      //defaultValue={[top100Films[13]]}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Categories"
          placeholder="PAINTING"
        />
      )}
    />
  );
};

export default CategoriesSelectWidget;
