/**
 *
 * @param num
 * @param decPlaces
 * @returns {number}
 */
export const abbrNum = (num: number, decPlaces: number): string => {
  // https://stackoverflow.com/questions/2685911/is-there-a-way-to-round-numbers-into-a-reader-friendly-format-e-g-1-1k
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  const abbrev = ['K', 'M', 'B', 'T'];
  let value = num.toString();

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= num) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      num = Math.round((num * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (num === 1000 && i < abbrev.length - 1) {
        num = 1;
        i++;
      }

      // Add the letter for the abbreviation
      value = num + abbrev[i];

      // We are done... stop
      break;
    }
  }

  return value;
};

export const startsWithNumber = (str: string): boolean => {
  return /^\d/.test(str);
};
