import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import {Typography} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {SERVER_URL} from '../../constants';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import LargeCard from './LargeCard';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import {createUUID} from '../../util/UUID';
import {storeRef} from '../../util/StoreImageRef';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import {sortByIdDesc} from '../../util/SortingHelpers';
import Note from './components/Note';
import PromoteProjectDialog from './components/PromoteProjectDialog';
import LaunchIcon from '@material-ui/icons/Launch';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import {useHistory} from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import CloseoutProjectDialog from './components/CloseoutProjectDialog';
import ResendDirectQuoteWidget from './components/ResendDirectQuoteWidget';
import {firebaseStorage, firebaseAuth} from '../../App';
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import {signInAnonymously} from 'firebase/auth';
import {v4 as uuidv4} from 'uuid';

const styles = (theme: $TSFixMe) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  root: {
    width: '100%',
    alignItems: 'center',
  },

  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  actionsContainer: {
    marginBottom: theme.spacing(2),
  },

  resetContainer: {
    padding: theme.spacing(3),
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});

function TransitionUp(props: $TSFixMe) {
  return <Slide {...props} direction="up" />;
}
function MyRequestForProposalForm(props: $TSFixMe) {
  const history = useHistory();
  const [mode, setMode] = useState('view');
  const [requestForProposal, setRequestForProposal] = useState<$TSFixMe>(null);
  const [transactionData, setTransactionData] = useState(null);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [customReason, setCustomReason] = useState<string | null>(null);
  const [cancelReason, setCancelReason] = useState<string | null>(null);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [message, setMessage] = useState('');

  const processRfpResults = (res: $TSFixMe) => {
    setRequestForProposal(res);
    if (!res.proposal) {
      return null;
    }
    const fbjwt = localStorage.getItem('Authorization');
    const url = `${SERVER_URL}v1/transactions?rfp_id=${res.id}`;
    fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res2) => {
        setTransactionData(res2);
      });
  };

  const getData = (rfp?: $TSFixMe) => {
    if (rfp) {
      processRfpResults(rfp);
    }
    const url = `${SERVER_URL}v1/rfps/${(props as $TSFixMe).match.params.id}`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        return processRfpResults(res);
      });
  };

  const handleUploadAndStoreRef = (
    file: $TSFixMe,
    fileRef: $TSFixMe,
    storePath: $TSFixMe,
  ) => {
    const metadata = {
      cacheControl: 'max-age=604800',
    };

    return uploadBytes(fileRef, file, metadata).then(
      async (snapshot: $TSFixMe) => {
        const url = await getDownloadURL(fileRef);
        const attachmentReference = {
          name:
            requestForProposal.state === 'OPEN'
              ? 'Before image'
              : 'After image',
          description: '',
          resModel: 'RequestForProposal',
          resId: `${requestForProposal?.id}`,
          key: ['FUNDED', 'COMPLETE'].includes(requestForProposal?.state)
            ? 'after_image'
            : 'before_image',
          url: url,
          firebaseRef: storePath,
        };
        storeRef(attachmentReference)
          .then((res) => res.json())
          .then((res) => {
            getData();
          })
          .catch((err) => {
            console.error(err);
          });
      },
    );
  };

  const onImageCapture = (event: $TSFixMe) => {
    const file = event.currentTarget.files[0];
    const uid = uuidv4();
    const fileUUID = createUUID();
    const storePath = `${uid}/${fileUUID}.jpg`;
    const fileRef = ref(firebaseStorage, storePath);

    signInAnonymously(firebaseAuth)
      .catch(function (error) {
        console.error('ANONYMOUS LOGIN FAIL');
        console.error(error.code);
        console.error(error.message);
      })
      .then((res) => {
        handleUploadAndStoreRef(file, fileRef, storePath);
      });
  };

  const handleClose = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  };

  const handleEdit = () => {
    setMode('edit');
  };

  const handleSave = () => {
    getData();
    setMode('view');
  };

  const toggleEnable = () => {
    const state = requestForProposal?.state;
    if (['OPEN', 'DRAFT'].includes(state)) {
      //currently project is open and needs to be disabled
      //open the disable confirmation dialog
      setCancelOpen(true);
    } else {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      enableProject();
    }
  };

  const getToggleEnabledButtonText = () => {
    const state = requestForProposal?.state;
    if (state === 'CANCELLED') {
      return 'ENABLE';
    } else {
      return 'DISABLE';
    }
  };

  const handleUploadAndStoreDocument = (
    file: $TSFixMe,
    fileRef: $TSFixMe,
    storePath: $TSFixMe,
  ) => {
    const metadata = {
      cacheControl: 'max-age=604800',
    };

    return uploadBytes(fileRef, file, metadata).then(
      async (snapshot: $TSFixMe) => {
        const url = await getDownloadURL(fileRef);
        debugger;
        const attachmentReference = {
          name: 'Project Document',
          description: '',
          resModel: 'RequestForProposal',
          resId: `${requestForProposal?.id}`,
          key: 'project_document',
          url: url,
          firebaseRef: storePath,
        };
        storeRef(attachmentReference)
          .then((res) => res.json())
          .then((res) => {
            getData();
          })
          .catch((err) => {
            console.error(err);
          });
      },
    );
  };

  const onAttachDocument = (event: $TSFixMe) => {
    const file = event.currentTarget.files[0];
    const uid = sessionStorage.getItem('uid');
    const fileUUID = createUUID();
    const storePath = `${uid}/${fileUUID}.pdf`;
    const fileRef = ref(firebaseStorage, storePath);

    signInAnonymously(firebaseAuth)
      .catch(function (error) {
        console.error('ANONYMOUS LOGIN FAIL');
        console.error(error.code);
        console.error(error.message);
      })
      .then((res) => {
        handleUploadAndStoreDocument(file, fileRef, storePath);
      });
  };

  const confirmDisableProject = (event: $TSFixMe) => {
    const url = `${SERVER_URL}v1/rfps/${requestForProposal?.id}`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reviewed: true,
        enabled: false,
        state: 'CANCELLED',
        reason: customReason || cancelReason,
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        setCancelOpen(false);
        setRequestForProposal(responseData);

        setTimeout(function () {
          setMessage('Project has been disabled');
          setSnackBarOpen(true);
        }, 1500);

        setTimeout(function () {
          setSnackBarOpen(false);
        }, 5500);

        setTimeout(function () {
          history.push('/myRfps');
        }, 6250);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const enableProject = (event: $TSFixMe) => {
    const url = `${SERVER_URL}v1/rfps/${requestForProposal?.id}`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reviewed: true,
        enabled: true,
        state: 'OPEN',
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        setCancelOpen(false);
        setRequestForProposal(responseData);

        setTimeout(function () {
          setMessage('Project has been enabled');
          setSnackBarOpen(true);
        }, 1500);

        setTimeout(function () {
          setSnackBarOpen(false);
        }, 5500);

        setTimeout(function () {
          history.push('/myRfps');
        }, 6250);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const getDialogActions = () => {
    const actions = (
      <div style={{display: 'flex', alignItems: 'center'}}>
        {mode === 'view' ? (
          <>
            <Tooltip title={'Copy Link'}>
              <IconButton
                color="inherit"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://bidmii.com/rfps/${requestForProposal?.uid}`,
                  );
                  setMessage('Copied!');
                  setSnackBarOpen(true);
                  setTimeout(function () {
                    setSnackBarOpen(false);
                  }, 2000);
                }}>
                <LinkIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'View Project On Bidmii'}>
              <IconButton
                color="inherit"
                onClick={() =>
                  window.open(
                    `https://bidmii.com/rfps/${requestForProposal?.uid}`,
                    '_blank',
                  )
                }>
                <LaunchIcon />
              </IconButton>
            </Tooltip>
            <input
              accept=".pdf"
              style={{display: 'none'}}
              id="myproject-upload-file"
              type="file"
              capture="user"
              onChange={onAttachDocument}
            />
            <Tooltip title={'Upload document'}>
              <label htmlFor="myproject-upload-file">
                <IconButton
                  color="inherit"
                  aria-label="attach document"
                  component="span">
                  <AttachFileIcon />
                </IconButton>
              </label>
            </Tooltip>
            <input
              accept="image/*, .mp4, .avi, .webm"
              style={{display: 'none'}}
              id="myproject-image-file"
              type="file"
              capture="user"
              onChange={onImageCapture}
            />
            <label htmlFor="myproject-image-file">
              <Tooltip title={'Upload Image'}>
                <IconButton
                  color="inherit"
                  aria-label="upload picture"
                  component="span">
                  <PhotoCameraIcon />
                </IconButton>
              </Tooltip>
            </label>
            <Tooltip title={'Edit'}>
              <IconButton
                color="inherit"
                aria-label="edit project"
                component="span"
                onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title={'Save'}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave}>
                Save
              </Button>
            </Tooltip>
            <Tooltip title={'Cancel'}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setMode('view')}>
                Cancel
              </Button>
            </Tooltip>
          </>
        )}
        {mode === 'view' &&
          ['OPEN', 'REQUESTED'].includes(requestForProposal.state) &&
          requestForProposal.type !== 'BIDMII_DIRECT' && (
            <>
              <PromoteProjectDialog
                rfpId={requestForProposal.id}
                promoted={requestForProposal.type === 'PROMOTED'}
                updateRequestForProposalState={getData}
              />
              <Button
                variant="contained"
                style={{backgroundColor: '#FFF', color: 'red', marginLeft: 16}}
                onClick={toggleEnable}>
                {getToggleEnabledButtonText()}
              </Button>
            </>
          )}
        {requestForProposal.type === 'BIDMII_DIRECT' && (
          <ResendDirectQuoteWidget
            proposalUid={
              requestForProposal.proposals.length > 0
                ? requestForProposal.proposals[0].uid
                : ''
            }
          />
        )}
        {requestForProposal.state === 'FUNDED' && (
          <CloseoutProjectDialog
            rfpId={requestForProposal.id}
            updateRequestForProposalState={getData}
          />
        )}
      </div>
    );

    return <DialogActions>{actions}</DialogActions>;
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickSendSms = (note: $TSFixMe) => {
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(`${SERVER_URL}v1/rfps/notes/${note.id}/send`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const submitNote = () => {
    const noteEl = document.getElementById('note_input');
    const value = (noteEl as $TSFixMe).value;
    (noteEl as $TSFixMe).value = null;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(`${SERVER_URL}v1/rfps/notes`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({
        rfp_id: requestForProposal.id,
        note: value,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  if (!requestForProposal) {
    return null;
  }

  const {classes} = props;

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="Job"
        TransitionComponent={TransitionUp}
        fullScreen>
        <AppBar
          position="fixed"
          elevation={0}
          style={{
            overflowX: 'auto',
            backgroundColor: '#D6EFFF',
            borderBottom: '1px solid #d9d9d9',
          }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title} />
            {getDialogActions()}
          </Toolbar>
        </AppBar>
        <div
          style={{
            height: '100vh',
            alignItems: 'center',
            padding: window.innerWidth > 600 ? '64px 30px 0' : '64px 8px 0',
          }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              <LargeCard
                requestForProposal={requestForProposal}
                updateRequestForProposalState={getData}
                mode={mode}
                history={history}
                transactionData={transactionData}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Card
                variant={'outlined'}
                style={{
                  backgroundColor: '#f7f7f7',
                  width: '100%',
                  alignSelf: 'center',
                  marginTop: '20px',
                  maxHeight: 'calc(100% - 40px)',
                  borderRadius: 10,
                }}>
                <div style={{padding: 10, textAlign: 'left'}}>
                  <TextField
                    id="note_input"
                    multiline
                    maxRows={6}
                    placeholder="Record a note..."
                    variant="outlined"
                    style={{
                      backgroundColor: '#fff',
                      marginBottom: 10,
                      borderRadius: 10,
                      width: '100%',
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={submitNote}
                    color="secondary"
                    style={{float: 'right'}}>
                    Submit
                  </Button>
                  <br />
                  <br />
                  <br />
                </div>
                <div
                  style={{
                    padding: 10,
                    textAlign: 'left',
                    height: '100%',
                    //maxHeight: 700,
                    overflowY: 'scroll',
                  }}>
                  {requestForProposal &&
                    requestForProposal.notes &&
                    requestForProposal.notes
                      .sort(sortByIdDesc)
                      .map((note: $TSFixMe) => {
                        return (
                          <Note
                            key={note.id}
                            note={note}
                            handleOnClickSendSms={handleOnClickSendSms}
                          />
                        );
                      })}
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Dialog open={cancelOpen} onClose={() => setCancelOpen(false)}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <CardContent style={{width: '100%', textAlign: 'centre'}}>
            <TextField
              id="Select-a-reason"
              select
              value={cancelReason}
              onChange={(event) => setCancelReason(event.target.value)}
              SelectProps={{
                native: true,
              }}>
              <option value="" disabled>
                SELECT A REASON
              </option>
              <option value="Homeowner unresponsive - Processed Followed">
                Homeowner unresponsive - Processed Followed
              </option>
              <option value="Financial issues/reasons">
                Financial issues/reasons
              </option>
              <option value="Not enough bids">Not enough bids</option>
              <option value="Bids were too high">Bids were too high</option>
              <option value="Unresponsive contractors">
                Unresponsive contractors
              </option>
              <option value="Unprofessional contractors">
                Unprofessional contractors
              </option>
              <option value="Deal went off platform">
                Deal went off platform
              </option>
              <option value="Off-Platform Contractor">
                Off-Platform Contractor
              </option>
              <option value="Other">Other</option>
            </TextField>
            <br />
            <br />
            {cancelReason === 'Other' && (
              <TextField
                id="note_reason_input"
                multiline
                maxRows={6}
                placeholder="Reason for disabling project."
                variant="outlined"
                style={{
                  backgroundColor: '#fff',
                  marginBottom: 10,
                  borderRadius: 10,
                  width: '100%',
                }}
                fullWidth={true}
                onChange={(event) => setCustomReason(event.target.value)}
              />
            )}
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              style={{marginRight: 'auto', marginLeft: 'auto'}}
              onClick={confirmDisableProject}
              disabled={!cancelReason}>
              Confirm Disable Project
            </Button>
          </CardContent>
        </Dialog>
      </Dialog>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={4000}
        TransitionComponent={TransitionUp}
        message={message}
      />
    </div>
  );
}

export default withStyles(styles)(MyRequestForProposalForm);
