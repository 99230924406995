import React from 'react';
import {
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

export default function DataTableTotal(props: $TSFixMe) {
  const topics = {
    'Total New Users': 'totalNewUsersTotal',
    'Total New Homeowners': 'newHomeownersTotal',
    'Total New Contractors': 'newContractorsTotal',
    'Total Projects': 'totalProjectsPostedTotal',
    'Projects Completed Total': 'projectsCompletedTotal',
    'Total Stages Completed': 'stagesCompletedTotal',
    'Fulfillment Rate': 'fulfillmentRateTotal',
    'Projects Awarded Total': 'projectsAwardedTotal',
    'Projects With 3 bids': 'projectsWithThreeBidsPercentTotal',
    'Projects with 3 bids in 24h':
      'projectsWithThreeBidsWithin24HourPercentTotal',
    'Bids Placed': 'totalProposalsPlacedTotal',
    'Avg Bids/Projects': 'avgBidPerProjectTotal',
    'Awarded GMV': 'awardedGmvTotal',
    GMV: 'gmvTotal',
    'Bidmii Revenue': 'bidmiiRevenueTotal',
    'Pro Subscriptions': 'totalBidmiiProsTotal',
    'Trial Pro Subscriptions': 'totalTrialProsTotal',
    'Cancelled Pro Subscriptions': 'totalCancelledProsTotal',
  };

  const totalTopics = {
    'Total Homeowners no filter': 'totalHomeownersNoFilter',
    'Total Contractors no filter': 'totalContractorsNoFilter',
    'Total Homeowners with filter': 'totalHomeownersWithFilter',
    'Total Contractors with filter': 'totalContractorsWithFilter',
  };

  const percentage = [
    'fulfillmentRateTotal',
    'projectsWithThreeBidsPercentTotal',
    'projectsWithThreeBidsWithin24HourPercentTotal',
  ];

  const dollar = ['gmvTotal', 'awardedGmvTotal', 'bidmiiRevenueTotal'];

  const getAmount = (topic: $TSFixMe) => {
    if (props.data[topic] === 92233720368547760) {
      return '-';
    }
    if (percentage.includes(topic)) {
      return props.data[topic] + '%';
    } else if (dollar.includes(topic)) {
      return '$' + props.data[topic];
    }
    return props.data[topic];
  };

  const getTotalAmount = (topic: $TSFixMe) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (props.data[totalTopics[topic]] === 92233720368547760) {
      return '-';
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return props.data[totalTopics[topic]];
  };

  function createData(topic: $TSFixMe, amount: $TSFixMe) {
    return {topic, amount};
  }

  const totalRows: $TSFixMe = [];
  Object.keys(totalTopics).forEach((topic) => {
    totalRows.push(createData(topic, getTotalAmount(topic)));
  });

  const rows: $TSFixMe = [];
  Object.keys(topics).forEach((topic) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    rows.push(createData(topic, getAmount(topics[topic])));
  });

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 12,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  return (
    <TableContainer style={{width: 315}}>
      <TableHead /* onClick={() => props.clickFunction(currentMonth)} style={{ cursor: 'pointer' }} */
      >
        <TableRow>
          <StyledTableCell>Total Stats</StyledTableCell>
          <StyledTableCell>Amount</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'row' implicitly has an 'any' type. */}
        {totalRows.map((row) => (
          <StyledTableRow key={row.topic}>
            <StyledTableCell>{row.topic}</StyledTableCell>
            <StyledTableCell>{row.amount}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
      <TableHead>
        <TableRow>
          <StyledTableCell>Total stats within interval</StyledTableCell>
          <StyledTableCell>Amount</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'row' implicitly has an 'any' type. */}
        {rows.map((row) => (
          <StyledTableRow key={row.topic}>
            <StyledTableCell>{row.topic}</StyledTableCell>
            <StyledTableCell>{row.amount}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </TableContainer>
  );
}
