import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const ProfileItem = (props: $TSFixMe) => {
  return (
    <ListItem style={{display: 'flex', alignItems: 'flex-start'}}>
      <ListItemText
        primaryTypographyProps={{style: {fontWeight: 'bold'}}}
        style={{flex: 1}}
        primary={`${props.itemDescription}:`}
      />
      <ListItemText
        style={{
          flex: props.flex || 1,
          overflowWrap: 'anywhere',
          fontStyle: props.item ? 'normal' : 'italic',
        }}
        primary={`${props.item || 'Unknown'}`}
      />
    </ListItem>
  );
};

export default ProfileItem;
