import React, {useState, useEffect} from 'react';
import {Puff} from 'react-loader-spinner';
import InfiniteScroll from 'react-infinite-scroller';
import {SERVER_URL} from '../../constants';
import Grid from '@material-ui/core/Grid';
import MyRequestForProposalSmallCard from './components/SmallCard';
import TopMenu from './components/TopMenu';
import RequestForProposalTableView from './components/RequestForProposalTableView';
import {useRecoilState} from 'recoil';
import {loadingState} from '../../recoil/loading/loadingAtom';
import RfpsMap from '../../util/RfpsMap';
import {chunkArray} from '../../util/ChunkArray';

const MyRequestForProposalsList = (props: $TSFixMe) => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('all enabled');
  const [viewType, setViewType] = useState('card');
  const [sortOrder, setSortOrder] = useState('desc');
  const [column, setColumn] = useState('createdDate');
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const [loading, setLoading] = useRecoilState(loadingState);

  useEffect(() => {
    if (page !== 0 && data.length > 0 && items.length > 0) {
      setPage(0);
      setData([]);
      setItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder, filter, column]);

  const getData = () => {
    setLoading(true);
    const url = `v1/rfps?filter=${filter}&page=${page}&column=${column}&sort=${sortOrder}`;
    const fullUrl = `${SERVER_URL}${url}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(fullUrl, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res._embedded && res._embedded.models.length > 0) {
          const response = res._embedded.models;
          if (response.length > 0) {
            setData((prevState) => {
              if (page === 0) {
                return response;
              } else {
                return [...prevState, ...response];
              }
            });
            setHasMore(true);
          }
        } else {
          setHasMore(false);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleRfpClick = (event: $TSFixMe, rowData: $TSFixMe) => {
    window.open(`${window.location.origin}/myRfps/${rowData.id}`, '_blank');
  };

  const onChangeFilter = (updatedFilter: $TSFixMe, secondFilter: $TSFixMe) => {
    setFilter(updatedFilter);
  };

  const onSortByDate = (order: $TSFixMe) => {
    if (order === 'desc') {
      setSortOrder('desc');
    } else {
      setSortOrder('asc');
    }
  };

  const loadItems = (newPage: $TSFixMe) => {
    if (!loading) {
      setPage((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    const chunkArray1 = chunkArray(data, 3);
    let pagedItems = (page + 1) * 5;
    if (pagedItems >= chunkArray1.length) {
      pagedItems = chunkArray1.length;
      setHasMore(false);
    }

    const itemsToMap = chunkArray1.slice(0, pagedItems);
    const updatedItems = itemsToMap.map((chunk, index) => {
      return (
        <Grid
          container
          key={`aaa_${index}_${page}`}
          justifyContent="flex-start">
          {chunk.map((requestForProposal: $TSFixMe, indexNo: $TSFixMe) => {
            return (
              <Grid item xs={12} md={4} key={`b_${requestForProposal.id}`}>
                <MyRequestForProposalSmallCard
                  requestForProposal={requestForProposal}
                  onRfpClick={(event: $TSFixMe) =>
                    handleRfpClick(event, requestForProposal)
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      );
    });

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element[]' is not assignable to ... Remove this comment to see the full error message
    setItems(updatedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <TopMenu
        onChangeFilter={onChangeFilter}
        viewType={viewType}
        setViewType={setViewType}
        {...props}
        onSortByDate={onSortByDate}
        onSetColumnName={setColumn}
      />
      {viewType === 'map' && (
        <Grid container justifyContent="center">
          <div
            style={{
              minHeight: 'calc(100vh - 100px)',
              marginTop: 65,
              width: '100%',
            }}>
            <RfpsMap filter={filter} />
          </div>
        </Grid>
      )}
      {viewType === 'card' ? (
        <div
          style={{
            maxWidth: '100%',
            backgroundColor: '#e6e6e6',
            paddingTop: window.innerWidth > 600 ? 90 : 130,
            overflowX: 'hidden',
            height: 'calc(100vh - 60px)',
            maxHeight: 'calc(100vh - 60px)',
          }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadItems}
            //initialLoad={true}
            hasMore={hasMore}
            useWindow={false}
            threshold={400}
            loader={<Puff color="#00BFFF" height={100} width={100} />}>
            <Grid container>{items}</Grid>
          </InfiniteScroll>
        </div>
      ) : (
        <RequestForProposalTableView filter={filter} sortOrder={sortOrder} />
      )}
    </div>
  );
};

export default MyRequestForProposalsList;
