import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PrimaryButton from './PrimaryButton';

// Dialog for when user needs to confirm a permanent action
export default function AlertDialog(props: $TSFixMe) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        disableBackdropClick
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <Box m={1} style={{border: props.deletion && '2px solid #F93822'}}>
          <DialogTitle id="alert-dialog-title" style={{paddingBottom: 0}}>
            {props.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{marginBottom: 8}}>
              {props.dialogDescription}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={props.onClose}
              className="secondary-button"
              style={{marginRight: 8}}>
              {props.close}
            </Button>
            <PrimaryButton
              onClick={props.onConfirm}
              style={{
                margin: '0 16px',
              }}>
              {props.confirm}
            </PrimaryButton>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
