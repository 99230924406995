import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {SERVER_URL} from '../../../constants';

const filter = createFilterOptions();

export default function AssignToWidget(props: $TSFixMe) {
  const [adminUsers, setAdminUsers] = React.useState([]);
  const [value, setValue] = React.useState(null);

  const getAdminUsers = () => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/users?userType=ADMIN&page=0&pageSize=100000`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setAdminUsers(res._embedded.models);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getAdminUsers();
  }, []);

  const autoCompleteOnChange = (event: $TSFixMe, newValue: $TSFixMe) => {
    setValue(newValue);
    props.assignProject(newValue.id);
  };

  const autoCompleteFilterOptions = (options: $TSFixMe, params: $TSFixMe) => {
    const filtered = filter(options, params);
    return filtered;
  };

  return (
    <Autocomplete
      id="assign-to-autocomplete"
      value={value}
      onChange={autoCompleteOnChange}
      filterOptions={autoCompleteFilterOptions}
      options={adminUsers}
      getOptionLabel={(option) => (option as $TSFixMe).name}
      renderOption={(option) => (option as $TSFixMe).name}
      style={{width: 284, paddingLeft: 10}}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Assign to an admin"
          variant="outlined"
          size="small"
        />
      )}
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
    />
  );
}
