import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ProfileItem from './profileItem';
import Button from '@material-ui/core/Button';
import BusinessDocumentsBuilder from './BusinessDocumentsBuilder';
import CheckIcon from '@material-ui/icons/Check';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Badges from './Badges/Badges';
import {SERVER_URL} from '../../../constants';
import {createUUID} from '../../../util/UUID';
import Chip from '@material-ui/core/Chip';
import SubscriptionDetailsDialog from './SubscriptionDetailsDialog';
import BidmiiProCheckoutDialog from './BidmiiProCheckoutDialog';
import RegisterDomainDialog from './RegisterDomainDialog';
import moment from 'moment/moment';
import {firestoreDb} from '../../../App';
import {collection, doc, getDoc} from 'firebase/firestore';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const parse = require('html-react-parser');

function ProfileInfoTab(props: $TSFixMe) {
  const data = props.data;

  const [firestoreData, setFirestoreData] = useState<any>(null);
  const [phoneVerified, setPhoneVerified] = useState(data.phoneVerified);
  const badgesRef = React.useRef();

  useEffect(() => {
    if (data.uid) {
      // @ts-ignore
      const userDocRef = doc(collection(firestoreDb, 'users'), data.uid);

      getDoc(userDocRef).then((doc) => {
        if (doc.exists()) {
          setFirestoreData(doc.data());
        }
      });
    }
  }, [data.uid]);

  useEffect(() => {
    setPhoneVerified(props.data.phoneVerified);
  }, [props.data.phoneVerified]);

  const description = parse(`${data.description || ''}`);
  const staffNotes = parse(`${data.adminNote || ''}`);

  function verifyUserPhoneNumber() {
    console.info(props.parentUser);
    const fbjwt = localStorage.getItem('Authorization');
    console.log(props);
    return fetch(`${SERVER_URL}v1/users/${props.userId}`, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({phoneVerified: true}),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.phoneVerified);
        setPhoneVerified(res.phoneVerified);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const applyBadgeToUser = (badgeId: any) => {
    // eslint-disable-next-line no-debugger
    debugger;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(`${SERVER_URL}v1/users/${props.userId}/badges`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({badgeId: badgeId}),
    })
      .then((res) => res.json())
      .then((res) => {
        props.setData(res);
        // @ts-ignore
        badgesRef.current && badgesRef.current.refreshBadges();
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  return (
    <div>
      <Grid item xs={12} key={0}>
        <Card style={{margin: '10px', borderRadius: '10px'}}>
          <CardHeader subheader={'Profile Info'} />
          <Grid container justifyContent="center" style={{marginBottom: 20}}>
            <Grid item xs={12} sm={6} key={1}>
              <List component="nav">
                <ListItem style={{display: 'flex', alignItems: 'flex-start'}}>
                  <ListItemText
                    primaryTypographyProps={{style: {fontWeight: 'bold'}}}
                    style={{flex: 1}}
                    primary={'Created Date:'}
                  />
                  <ListItemText
                    style={{
                      flex: 1.5,
                      overflowWrap: 'anywhere',
                      fontStyle: 'normal',
                    }}
                    primary={moment(data.createDate).format(
                      'MMM D, YYYY, h:mm A',
                    )}
                  />
                </ListItem>
                <ProfileItem
                  itemDescription="First Name"
                  item={data.firstName}
                  flex={1.5}
                />
                <ProfileItem
                  itemDescription="Last Name"
                  item={data.lastName}
                  flex={1.5}
                />
                <ListItem style={{display: 'flex', alignItems: 'flex-start'}}>
                  <ListItemText
                    primaryTypographyProps={{style: {fontWeight: 'bold'}}}
                    style={{flex: 1}}
                    primary={'UID:'}
                  />
                  <ListItemText
                    style={{
                      flex: 1.5,
                      overflowWrap: 'anywhere',
                      fontStyle: 'normal',
                    }}
                    primary={data.uid}
                  />
                </ListItem>
                <ProfileItem
                  itemDescription="Title"
                  item={data.title}
                  flex={1.5}
                />
                <ProfileItem
                  itemDescription="Email"
                  item={data.email}
                  flex={1.5}
                />
                {data.userType === 'BIDDER' && (
                  <ProfileItem
                    itemDescription="Billing Email"
                    item={data.billingEmail}
                    flex={1.5}
                  />
                )}
                <ListItem style={{display: 'flex'}}>
                  <ListItemText
                    primaryTypographyProps={{style: {fontWeight: 'bold'}}}
                    style={{flex: 1}}
                    primary={'Phone'}
                  />
                  <ListItemText
                    style={{flex: 1.5}}
                    primary={
                      <>
                        {data.phone}{' '}
                        {(phoneVerified && (
                          <Tooltip title={'Verified'}>
                            <CheckIcon color={'secondary'} />
                          </Tooltip>
                        )) || (
                          <Button
                            style={{marginLeft: 1}}
                            variant="contained"
                            onClick={verifyUserPhoneNumber}>
                            Verify
                          </Button>
                        )}
                      </>
                    }
                  />
                </ListItem>
                {data.userType === 'BIDDER' && (
                  <ProfileItem
                    itemDescription="Service Area"
                    item={data.contractorServiceArea}
                    flex={1.5}
                  />
                )}
                <ProfileItem
                  itemDescription="How you heard"
                  item={data.userSource}
                  flex={1.5}
                />
                {data.userType === 'BIDDER' && (
                  <>
                    <ListItem style={{display: 'flex'}}>
                      <ListItemText
                        primaryTypographyProps={{style: {fontWeight: 'bold'}}}
                        style={{flex: 1}}
                        primary={'Plan'}
                      />
                      <ListItemText
                        style={{flex: 1.5}}
                        primary={
                          <>
                            {!data?.plan || data?.plan === 'FREE' ? (
                              <>
                                <span>FREE</span>
                                <BidmiiProCheckoutDialog
                                  userId={props.userId}
                                  updateUser={props.updateUserValues}
                                />
                              </>
                            ) : (
                              <>
                                <span>{data?.plan.replaceAll('_', ' ')}</span>
                              </>
                            )}
                          </>
                        }
                      />
                    </ListItem>
                    {data?.plan &&
                      data.plan.indexOf('BIDMII') !== -1 &&
                      props.userId && (
                        <SubscriptionDetailsDialog
                          userId={props.userId}
                          updateUser={props.updateUserValues}
                        />
                      )}
                    {data?.plan && data?.plan !== 'FREE' && (
                      <ProfileItem
                        itemDescription="Payment Interval"
                        item={data.planPaymentInterval}
                        flex={1.5}
                      />
                    )}
                  </>
                )}
              </List>
            </Grid>
            <Grid item xs={12} sm={6} key={2}>
              <List component="nav">
                <ListItem style={{display: 'flex', alignItems: 'flex-start'}}>
                  <ListItemText
                    primaryTypographyProps={{style: {fontWeight: 'bold'}}}
                    style={{flex: 1}}
                    primary={'Last Active:'}
                  />
                  <ListItemText
                    style={{
                      flex: 1.5,
                      overflowWrap: 'anywhere',
                      fontStyle: 'normal',
                    }}
                    primary={moment(data.lastOnline).format(
                      'MMM D, YYYY, h:mm A',
                    )}
                  />
                </ListItem>
                <ProfileItem
                  itemDescription="Street"
                  item={data.street}
                  flex={1.5}
                />
                <ProfileItem
                  itemDescription="Street Line 2"
                  item={data.street2}
                  flex={1.5}
                />
                <ProfileItem
                  itemDescription="City"
                  item={data.city}
                  flex={1.5}
                />
                <ProfileItem
                  itemDescription="Postal"
                  item={data.postal}
                  flex={1.5}
                />
                <ProfileItem
                  itemDescription="Province"
                  item={data.province}
                  flex={1.5}
                />
                <ProfileItem
                  itemDescription="Country"
                  item={data.country}
                  flex={1.5}
                />
                <ProfileItem
                  itemDescription="Latitude"
                  item={data.lat}
                  flex={1.5}
                />
                <ProfileItem
                  itemDescription="Longitude"
                  item={data.lng}
                  flex={1.5}
                />
                <ListItem style={{display: 'flex'}}>
                  <ListItemText
                    primaryTypographyProps={{style: {fontWeight: 'bold'}}}
                    style={{flex: 1}}
                    primary={'Insured'}
                  />
                  <ListItemText
                    style={{flex: 1.5}}
                    primary={
                      <>
                        {data.insured ? (
                          <Tooltip title={'Insured by covermii'}>
                            <>
                              <span>Yes</span> <CheckIcon color={'secondary'} />{' '}
                            </>
                          </Tooltip>
                        ) : (
                          <span>No</span>
                        )}
                      </>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {data.userType === 'BIDDER' && (
        <>
          <Grid container justifyContent="center">
            {data.categories && (
              <Grid item xs={firestoreData ? 6 : 12} key={3}>
                <Card
                  style={{
                    margin: '10px',
                    borderRadius: '10px',
                    paddingBottom: '20px',
                  }}>
                  <CardHeader subheader={'Categories'} />
                  <div>
                    <Grid container justifyContent={'center'} spacing={2}>
                      <>
                        {data.categories.map((category: $TSFixMe) => {
                          return (
                            <Grid item key={createUUID()}>
                              <Chip
                                label={category.name}
                                size={'small'}
                                color={'primary'}
                              />
                            </Grid>
                          );
                        })}
                      </>
                    </Grid>
                  </div>
                </Card>
              </Grid>
            )}

            {firestoreData && (
              <Grid item xs={6} key={4}>
                <Card
                  style={{
                    margin: '10px',
                    borderRadius: '10px',
                    paddingBottom: '10px',
                  }}>
                  <CardHeader subheader={'Welcome Kit Details'} />
                  <List component="nav">
                    <ProfileItem
                      itemDescription="T-shirt Size"
                      item={firestoreData.shirtSize}
                    />
                    <ProfileItem
                      itemDescription="First Name"
                      item={firestoreData.firstName}
                    />
                    <ProfileItem
                      itemDescription="Last Name"
                      item={firestoreData.lastName}
                    />
                    <ProfileItem
                      itemDescription="Email"
                      item={firestoreData.email}
                    />
                    <ProfileItem
                      itemDescription="Phone"
                      item={firestoreData.phone}
                    />
                    <ProfileItem
                      itemDescription="Company Name"
                      item={firestoreData.companyName}
                    />
                    <ProfileItem
                      itemDescription="Job Title"
                      item={firestoreData.title}
                    />
                    <ProfileItem
                      itemDescription="Logo Choice"
                      item={firestoreData.logoChoice}
                    />
                    {firestoreData.logo && (
                      <ListItem style={{display: 'flex'}}>
                        <ListItemText
                          style={{flex: 1, fontWeight: 'bold'}}
                          primary={'Logo URL:'}
                        />
                        <a
                          href={firestoreData.logo.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            flex: 1.5,
                            overflowWrap: 'anywhere',
                          }}>
                          {firestoreData.logo.name}
                        </a>
                      </ListItem>
                    )}
                  </List>
                </Card>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            justifyContent="center"
            style={{marginTop: 20, marginBottom: 20}}>
            <Grid item xs={6} key={5}>
              <Card style={{margin: '10px', borderRadius: '10px'}}>
                <CardHeader subheader={'Business Info'} />
                <List component="nav">
                  <ProfileItem
                    itemDescription="Company Name"
                    item={data.companyName}
                  />
                  <ProfileItem itemDescription="HST No" item={data.taxNo} />
                  <ProfileItem
                    itemDescription="Operating As"
                    item={data.alias}
                  />
                  <ProfileItem itemDescription="Email" item={data.email} />
                  <ProfileItem itemDescription="Phone" item={data.phone} />
                  {data.domain && (
                    <ProfileItem itemDescription="Domain" item={data.domain} />
                  )}
                  {data.plan === 'FREE' && firestoreData && (
                    <>
                      <ProfileItem
                        itemDescription="Preferred Contact Method"
                        item={firestoreData.preferredContactMethod}
                      />
                      <ProfileItem
                        itemDescription="Filed taxes in last 12 months"
                        item={firestoreData.filedTaxes}
                      />
                      <ProfileItem
                        itemDescription="Has 1 employee OR $30k revenue"
                        item={firestoreData.companyQualified}
                      />
                    </>
                  )}
                  {data.plan !== 'FREE' && (
                    <ListItem style={{display: 'flex'}}>
                      <ListItemText
                        style={{flex: 1, fontWeight: 'bold'}}
                        primary={'Domain'}
                      />
                      <ListItemText
                        style={{flex: 1.5}}
                        primary={
                          <>
                            {data.domain ? (
                              data.domain
                            ) : (
                              <RegisterDomainDialog
                                userId={props.userId}
                                updateUser={props.updateUserValues}
                              />
                            )}
                          </>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </Card>
            </Grid>
            <Grid item xs={6} key={6}>
              <Card style={{margin: '10px', borderRadius: '10px'}}>
                <CardHeader subheader={'Business Address'} />
                <List component="nav">
                  <ProfileItem itemDescription="Street" item={data.street} />
                  <ProfileItem
                    itemDescription="Street Line 2"
                    item={data.street2}
                  />
                  <ProfileItem itemDescription="City" item={data.city} />
                  <ProfileItem itemDescription="Postal" item={data.postal} />
                  <ProfileItem
                    itemDescription="Province"
                    item={data.province}
                  />
                  <ProfileItem itemDescription="Country" item={data.country} />
                </List>
              </Card>
            </Grid>
          </Grid>
        </>
      )}

      {data.userType === 'BIDDER' && (
        <>
          <Card
            style={{
              margin: '10px',
              borderRadius: '10px',
              paddingBottom: '10px',
            }}>
            <CardHeader subheader={'Business Documents'} />
            <List dense>
              {data.businessDocuments && (
                <BusinessDocumentsBuilder documents={data.businessDocuments} />
              )}
            </List>
          </Card>
        </>
      )}

      <Grid item xs={12} md={12} key={5}>
        <Card style={{margin: '10px', borderRadius: '10px'}}>
          <CardHeader subheader={'Notes'} />
          <List component="nav" style={{padding: '0 24px'}}>
            {staffNotes}
          </List>
        </Card>
      </Grid>

      {description && (
        <Grid item xs={12} md={12} key={6}>
          <Card style={{margin: '10px', borderRadius: '10px'}}>
            <CardHeader subheader={'Profile Bio'} />
            <List component="nav" style={{padding: '0 24px'}}>
              {description}
            </List>
          </Card>
        </Grid>
      )}

      <Badges
        ref={badgesRef}
        userId={props.userId}
        applyBadgeToUser={applyBadgeToUser}
      />
    </div>
  );
}

export default ProfileInfoTab;
