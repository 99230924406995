import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  red: {
    backgroundColor: '#F93822 !important',
  },
  orange: {
    backgroundColor: '#FFA0F7 !important',
  },
  yellow: {
    backgroundColor: '#875DC7 !important',
  },
  green: {
    backgroundColor: '#228848 !important',
  },
  blue: {
    backgroundColor: '#22ABF7 !important',
  },
  white: {
    border: '1px solid #000000 !important',
    borderColor: '#000000 !important',
    backgroundColor: '#FFFFFF !important',
    color: '#000000 !important',
  },
}));

export default function UserRiskStatusWidget(props: $TSFixMe) {
  const classes = useStyles();

  const chipData = [
    {
      key: 0,
      label: 'SEVERE RISK',
      class: classes.red,
      value: 'SEVERE',
      selected: props.riskStatus === 'SEVERE',
    },
    {
      key: 1,
      label: 'HIGH RISK',
      class: classes.orange,
      value: 'HIGH',
      selected: props.riskStatus === 'HIGH',
    },
    {
      key: 2,
      label: 'SOME RISK',
      class: classes.yellow,
      value: 'MEDIUM',
      selected: props.riskStatus === 'MEDIUM',
    },
    {
      key: 3,
      label: 'FAIRLY SATISFIED',
      class: classes.green,
      value: 'LOW',
      selected: props.riskStatus === 'LOW',
    },
    {
      key: 4,
      label: 'VERY SATISFIED',
      class: classes.blue,
      value: 'LOWEST',
      selected: props.riskStatus === 'LOWEST',
    },
    {
      key: 5,
      label: 'EXTREMELY SATISFIED',
      class: classes.white,
      value: 'NONE',
      selected: props.riskStatus === 'NONE',
    },
  ];

  return (
    <>
      {chipData
        .filter((status) => status.selected)
        .map((data) => (
          <Chip
            label={data.label}
            className={`${classes.chip} ${data.class}`}
            size={props.small ? 'small' : undefined}
          />
        ))}
    </>
  );
}
