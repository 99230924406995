import React, {useCallback, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {SERVER_URL} from '../../../constants';
import {Box, InputLabel, MenuItem, Select} from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@material-ui/core/FormControl';
import {InputAdornment, OutlinedInput, Typography} from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';

export default function CreatePromptDialog(props: $TSFixMe) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    prompt: '',
    type: 'SHORT_FORM_TEXT',
    options: [],
  });
  const [options, setOptions] = useState<string[]>([]);
  const [option, setOption] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: $TSFixMe) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    // @ts-ignore
    data[name] = value;
    setData({...data});
  };

  const addPrompt = () => {
    const jwt = localStorage.getItem('Authorization');
    const body = data;
    if (body.type === 'MULTIPLE_CHOICE' && options.length > 0) {
      // [{"label": "Part of a larger bathroom renovation project that might require design services", "value": "larger_renovation_with_design"}, {"label": "Standalone LED Bathroom Mirror Installation project", "value": "standalone_project"}]

      const structuredOptions: {label: never; value: never}[] = [];
      options.forEach((op) => {
        // @ts-ignore
        structuredOptions.push({label: op, value: op});
      });

      // @ts-ignore
      body.options = JSON.stringify(structuredOptions);

      setOptions([]);
      setData({
        prompt: '',
        type: 'SHORT_FORM_TEXT',
        options: [],
      });
    }

    return fetch(
      `${SERVER_URL}v1/project-templates/${props.templateUid}/prompts`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwt || '',
        },
        body: JSON.stringify(body),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        props.onUpdatePrompts();
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const handleChangeOption = (event: any) => {
    const target = event.target;
    const value = target.value;
    setOption(value);
  };

  const focusOptionsField = useCallback(() => {
    const optionsEl = document.getElementById('options-input');
    if (optionsEl) {
      optionsEl.focus();
    }
  }, []);

  const submitOption = () => {
    setOption('');
    setOptions((prevState: string[]) => [...prevState, option]);
    focusOptionsField();
  };

  useEffect(() => {
    if (data.type === 'MULTIPLE_CHOICE') {
      setTimeout(function () {
        focusOptionsField();
      }, 250);
    }
  }, [data.type, focusOptionsField]);

  const handleDelete = (text: string, index: number) => {
    setOptions((prevState) => prevState.filter((o) => o !== text));
    focusOptionsField();
  };

  const submitDisabledState = (() => {
    if (!data.prompt) {
      return true;
    } else if (data.type === 'MULTIPLE_CHOICE' && options.length === 0) {
      return true;
    }
    return false;
  })();

  return (
    <>
      <Button onClick={() => setOpen(true)} color={'secondary'} size={'small'}>
        <AddIcon fontSize="small" /> Create
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={'md'}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Prompt</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="prompt"
            label="Prompt"
            type="text"
            name="prompt"
            fullWidth
            onChange={handleChange}
            value={(data as $TSFixMe).prompt}
          />
          <Box sx={{minWidth: 120}}>
            <FormControl fullWidth>
              <InputLabel id="user-type-input-label">Type</InputLabel>
              <Select
                labelId="type-input-label"
                id="type-input-label"
                name={'type'}
                label="Type"
                onChange={handleChange}>
                <MenuItem value={'SHORT_FORM_TEXT'}>Short form text</MenuItem>
                <MenuItem value={'LONG_FORM_TEXT'}>Long form text</MenuItem>
                <MenuItem value={'BOOLEAN'}>Yes / No</MenuItem>
                <MenuItem value={'MULTIPLE_CHOICE'}>Multiple Choice</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {data.type === 'MULTIPLE_CHOICE' && (
            <Box mt={2}>
              <Typography>Options</Typography>
              <FormControl variant="outlined">
                <OutlinedInput
                  id="options-input"
                  value={option}
                  onChange={handleChangeOption}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={submitOption}
                        edge="end">
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Option"
                />
              </FormControl>
              {options.map((option1, index) => (
                <Box mt={2}>
                  <Chip
                    label={`${option1}`}
                    onDelete={() => handleDelete(option1, index)}
                    key={`option_${option1}_${index}`}
                  />
                </Box>
              ))}
            </Box>
          )}
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={addPrompt}
            disabled={submitDisabledState}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
