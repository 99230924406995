import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {DialogContent, Grid, Tooltip, Typography} from '@material-ui/core';
import {SERVER_URL} from '../../../../constants';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import {Skeleton} from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';

const Badges = React.forwardRef((props: $TSFixMe, ref) => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [badges, setBadges] = useState([]);
  const [availableBadgesPage, setAvailableBadgesPage] = useState(0);
  const [availableBadgesloading, setAvailableBadgesLoading] = useState(true);
  const [addBadgesOpen, setAddBadgesOpen] = useState(false);
  const [availableBadges, setAvailableBadges] = useState([]);

  const getData = () => {
    if (page === 0) {
      setLoading(true);
    }
    fetch(
      `${SERVER_URL}v1/users/${props.userId}/badges?page=${page}&pageSize=50`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res._embedded) {
          // @ts-ignore
          setBadges((prevState) => [
            ...(page === 0 ? [] : prevState),
            ...res._embedded.models,
          ]);
        }
        if (res?.page && page < res.page.totalPages - 1) {
          setPage((currentPage) => currentPage + 1);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getAvailableBadgesData = useCallback(() => {
    if (availableBadges.length === 0) {
      setAvailableBadgesLoading(true);
    }
    fetch(`${SERVER_URL}v1/badges?page=${availableBadgesPage}&pageSize=50`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: localStorage.getItem('Authorization'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res._embedded) {
          // @ts-ignore
          setAvailableBadges((prevState) => [
            ...prevState,
            ...res._embedded.models,
          ]);
        }
        if (res?.page && availableBadgesPage < res.page.totalPages - 1) {
          setAvailableBadgesPage((currentPage) => currentPage + 1);
        }
        setAvailableBadgesLoading(false);
      });
  }, [availableBadges.length, availableBadgesPage]);

  useEffect(() => {
    if (addBadgesOpen && availableBadges.length === 0) {
      getAvailableBadgesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addBadgesOpen]);

  useImperativeHandle(ref, () => ({
    refreshBadges() {
      if (page > 0) {
        setPage(0);
      } else {
        getData();
      }
    },
  }));

  return (
    <Grid item xs={12} md={12} key={6}>
      <Card style={{margin: '10px', borderRadius: '10px'}}>
        <CardHeader
          subheader={'Badges'}
          action={
            <IconButton
              aria-label="apply badge"
              onClick={() => setAddBadgesOpen(true)}>
              <AddIcon />
            </IconButton>
          }
        />
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          style={{marginLeft: 24}}>
          <>
            {loading && <Skeleton variant="circle" width={56} height={56} />}
            {badges?.length > 1 && (
              <>
                {badges.map((badge: $TSFixMe) => (
                  <div
                    key={badge.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginRight: 12,
                      marginBottom: 4,
                    }}>
                    <Tooltip arrow title={badge.description}>
                      <img
                        src={badge.awardedImage.url}
                        alt={`${badge.badgeTitle} Badge`}
                        style={{width: 55}}
                      />
                    </Tooltip>
                    <Typography variant="caption">
                      {badge.badgeTitle}
                    </Typography>
                  </div>
                ))}
              </>
            )}
          </>
        </Grid>
      </Card>
      <Dialog
        open={addBadgesOpen}
        onClose={() => setAddBadgesOpen(false)}
        fullWidth={true}>
        <DialogContent style={{marginTop: 15, marginBottom: 15}}>
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="flex-start"
            alignContent={'flex-start'}
            direction={'row'}
            style={{marginLeft: 24, display: 'flex', flexWrap: 'wrap'}}>
            <>
              {availableBadgesloading && (
                <>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                    <Skeleton variant="circle" width={56} height={56} />
                  ))}
                </>
              )}
              {availableBadges
                .filter((ab) => {
                  // @ts-ignore
                  return !badges.map((b) => b.id).includes(ab.id);
                })
                .map((badge: $TSFixMe) => (
                  <Grid
                    container
                    item
                    justifyContent={'center'}
                    alignItems="center"
                    direction={'column'}
                    style={{width: 140}}>
                    <Grid item>
                      <Tooltip arrow title={badge.description}>
                        <img
                          src={badge.awardedImage.url}
                          alt={`${badge.badgeTitle} Badge`}
                          style={{width: 55}}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip arrow title={'Apply Badge'}>
                        <IconButton
                          onClick={() => {
                            props.applyBadgeToUser(badge.id);
                          }}>
                          <Typography variant="caption">
                            {badge.badgeTitle}{' '}
                          </Typography>
                          <AddIcon
                            style={{
                              cursor: 'pointer',
                              color: 'blue',
                              fontSize: 17,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ))}
            </>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
});

export default Badges;
