import React, {useCallback, useState, useEffect} from 'react';
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
  useGridApiContext,
  GridColDef,
  GridFilterModel,
} from '@mui/x-data-grid-pro';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {Box} from '@mui/material';
import {alpha, styled} from '@mui/material/styles';
import {gridClasses} from '@mui/x-data-grid';
import CategoriesSelectWidget from '../Users/components/CategoriesSelectWidget';
import {SERVER_URL} from '../../constants';
import ProjectTemplateDetails from './ProjectTemplateDetails';
import moment from 'moment/moment';

const ProjectTemplatesToolbar = (props: $TSFixMe) => {
  const apiRef = useGridApiContext();
  return (
    <GridToolbarContainer
      style={{
        padding: 12,
        paddingTop: 24,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #999',
      }}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <CategoriesSelectWidget {...props} apiRef={apiRef} />
      <Box
        my={1}
        px={window.innerWidth <= 600 ? 1 : 2}
        width={window.innerWidth <= 600 ? '100%' : 'auto'}>
        <GridToolbarQuickFilter style={{width: '100%', marginTop: 2}} />
      </Box>
    </GridToolbarContainer>
  );
};

const ODD_OPACITY = 0.2;

const StyledDataGrid = styled(DataGridPro)(({theme}) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const ProjectTemplatesTable = () => {
  const [key, setKey] = useState(1);
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [queryOptions, setQueryOptions] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('rowClick', (params) => {
      setSelectedTemplate(params?.row?.uid);
    });
  }, [apiRef]);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('cellClick', (params) => {
      switch (params.field) {
        case 'email':
          window.open(`mailto:${params?.row?.email}`, '_blank');
          break;
        case 'phone':
          window.open(`tel:${params?.row?.phone}`, '_blank');
          break;
      }
    });
  }, [apiRef]);

  const getData = useCallback(() => {
    setLoading(true);
    console.log(JSON.stringify(queryOptions));
    const url = `${SERVER_URL}v1/project-templates${
      queryOptions ? '/search' : ''
    }?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      method: queryOptions ? 'POST' : 'GET',
      body: queryOptions ? JSON.stringify(queryOptions) : null,
    })
      .then((res) => res.json())
      .then((res) => {
        setRowCount(res.page.totalElements);
        setLoading(false);
        if (!res._embedded) {
          setRows([]);
        } else if (selectedCategories.length > 0) {
          setRows(
            res._embedded?.models.filter((row: $TSFixMe) =>
              row.categories.some((category: $TSFixMe) =>
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                selectedCategories.includes(category.name.toUpperCase()),
              ),
            ) || [],
          );
        } else {
          setRows(res._embedded?.models || []);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:', error);
      });
  }, [
    queryOptions,
    paginationModel.page,
    paginationModel.pageSize,
    selectedCategories,
  ]);

  useEffect(() => {
    if (selectedCategories && selectedCategories.length > 0) {
      if (rows) {
        console.log('Categories updated, calling getData');
        getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories]);

  useEffect(() => {
    console.log('Page updated calling getData');
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize]);

  const columns: GridColDef[] = [
    {
      headerName: 'ID',
      field: 'id',
      width: 30,
    },
    {
      headerName: 'Enabled',
      field: 'enabled',
      type: 'boolean',
      width: 30,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 450,
    },
    {
      headerName: 'Updated',
      field: 'lastModified',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row.lastModified
          ? moment(row.lastModified).format('MMM D, YYYY, h:mm A')
          : '',
      width: 200,
    },
    {
      headerName: 'Modified By',
      field: 'lastModifiedBy',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row.lastModifiedBy ? row.lastModifiedBy.toUpperCase() : '',
      width: 200,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          key={`project_templates_action_0`}
          icon={<OpenInNewIcon />}
          onClick={() => {
            setSelectedTemplate(params.uid);
          }}
          label="View User"
        />,
      ],
      width: 100,
    },
  ];

  useEffect(() => {
    if (paginationModel.page === 0) {
      getData();
    } else {
      setPaginationModel((prevState) => {
        return {page: 0, pageSize: prevState.pageSize};
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryOptions]);

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    if (
      filterModel?.items.length === 0 &&
      filterModel?.quickFilterValues?.length === 0
    ) {
      console.log('Skipping update of filter, filter empty');
      return;
    }

    if (filterModel.items.length > 0) {
      const filterEntryNotCompleted = !filterModel?.items
        .filter(
          (item: any) =>
            !['isNotEmpty', 'isEmpty'].includes(item.operatorValue),
        )
        .every(
          (item: $TSFixMe) =>
            Object.prototype.hasOwnProperty.call(item, 'value') &&
            item.value !== undefined,
        );
      if (filterEntryNotCompleted) {
        console.log('Skipping update of filter, filter not finished');
        return;
      }
    }

    const filterEntryCompleted = filterModel?.items
      .filter(
        (item: any) => !['isNotEmpty', 'isEmpty'].includes(item.operatorValue),
      )
      .every((item: $TSFixMe) =>
        Object.prototype.hasOwnProperty.call(item, 'value'),
      );

    if (
      (filterModel?.items.length >= 1 && filterEntryCompleted) ||
      (filterModel &&
        filterModel?.quickFilterValues &&
        filterModel?.quickFilterValues?.length > 0)
    ) {
      console.log('Setting filter');
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ filterModel: any; }' is not as... Remove this comment to see the full error message
      setQueryOptions({filterModel: {...filterModel}});
    } else {
      console.log('Setting filter to null');
      setQueryOptions(null);
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 30,
      }}>
      <div style={{flexGrow: 1}}>
        <StyledDataGrid
          key={`users_list_${key}`}
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                enabled: false,
                uid: false,
              },
            },
          }}
          slots={{
            toolbar: ProjectTemplatesToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 2500},
              selectedCategories: selectedCategories,
              setSelectedCategories: setSelectedCategories,
              setKey: setKey,
            },
          }}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          checkboxSelection
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50, 100, 150, 200, 300, 400, 500, 750, 1000]}
          pagination
          paginationMode={'server'}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          loading={loading}
          getRowClassName={(params) => {
            return params?.row?.enabled ? 'odd' : 'even';
          }}
        />
      </div>
      {selectedTemplate && (
        <ProjectTemplateDetails
          templateUid={selectedTemplate}
          close={() => setSelectedTemplate(null)}
        />
      )}
    </div>
  );
};

export default ProjectTemplatesTable;
