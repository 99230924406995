import React, {useState, useEffect} from 'react';
import {SERVER_URL} from '../../constants';
import {currencyFormat} from '../../util/CurrencyFormatter';
import {dateAsWords} from '../../util/DateAsWords';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PaymentDialog from './PaymentDialog';
import TransactionSummaryDialog from './TransactionSummaryDialog';

const Transactions = (props: $TSFixMe) => {
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('rowClick', (params) => {
      setSelectedTransaction(params.row);
    });
  }, [apiRef]);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('cellClick', (params) => {
      switch (params.field) {
        case 'firstName':
          window.open(
            `${window.location.origin}/users/${params?.row?.userId}`,
            '_blank',
          );
          break;
      }
    });
  }, [apiRef]);

  const getData = () => {
    const url = `${SERVER_URL}v1/transactions?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setRowCount(res.page.totalElements);
        setRows(res._embedded.models || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const columns: GridColDef[] = [
    {headerName: 'No', field: 'transactionNo', width: 175},
    {
      headerName: 'Date',
      field: 'date',
      type: 'dateTime',
      valueFormatter: (value: $TSFixMe) =>
        dateAsWords(value, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      width: 120,
    },
    {headerName: 'Reference', field: 'reference'},
    {
      headerName: 'User',
      field: 'username',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="view user profile"
            component="span"
            style={{marginRight: 8}}>
            <OpenInNewIcon style={{fontSize: 17}} />
          </IconButton>
          {`${params?.row?.user.name || ''}`}
        </span>
      ),
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row?.user?.name?.toUpperCase() || 'N/A',
      width: 200,
    },
    {headerName: 'Project ID', field: 'rfpId'},
    {headerName: 'Description', field: 'description', width: 200},
    {headerName: 'Type', field: 'type'},
    {headerName: 'State', field: 'state'},
    {
      headerName: 'Amount',
      field: 'amount',
      type: 'number',
      valueFormatter: (value: $TSFixMe) => {
        if (value) {
          return currencyFormat(value as number);
        } else {
          return null;
        }
      },
      width: 200,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => {
        const actions = [
          <GridActionsCellItem
            icon={<OpenInNewIcon />}
            onClick={() => {
              let url = '';
              if (params?.row?.rfpId) {
                url = `${window.location.origin}/myRfps/${params?.row?.rfpId}`;
              } else {
                url = `${window.location.origin}/users/${params?.row?.user.id}`;
              }
              window.open(url, '_blank');
            }}
            label="View"
          />,
          <GridActionsCellItem
            icon={<InfoOutlinedIcon />}
            onClick={() => {
              setSelectedTransaction(params.row);
            }}
            label="Details"
          />,
          params?.row?.type === 'PAYOUT' && params?.row?.state === 'PENDING' ? (
            <GridActionsCellItem
              icon={<CheckIcon />}
              onClick={() => {
                setTransaction(params.row);
              }}
              label="Record Payout"
            />
          ) : (
            <div style={{width: 30}} />
          ),
        ];

        return actions;
      },
      width: 200,
    },
  ];

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize]);

  const resetPaymentDialog = () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setTransaction(null);
    getData();
  };

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 29,
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 500},
            },
          }}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          checkboxSelection
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50, 100, 150, 200, 300, 400, 500, 750, 1000]}
          pagination
          paginationMode={'server'}
          loading={loading}
        />
      </div>
      {transaction && (
        <PaymentDialog
          transaction={transaction}
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
          handleClose={() => setTransaction(null)}
          forceTableReload={resetPaymentDialog}
        />
      )}
      {selectedTransaction && (
        <TransactionSummaryDialog
          transaction={selectedTransaction}
          handleClose={() => setSelectedTransaction(null)}
        />
      )}
    </div>
  );
};

export default Transactions;
