import React, {useState, useEffect, useCallback} from 'react';
import {SERVER_URL} from '../../../constants';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {dateAsWords} from '../../../util/DateAsWords';
import IconButton from '@material-ui/core/IconButton';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import moment from 'moment/moment';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ProposalDialog from '../../Proposal/components/ProposalDialog';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

const CustomToolbar = () => {
  return (
    <GridToolbarContainer style={{paddingTop: 25}}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

type IProps = {
  requestForProposal: any;
  updateRequestForProposalState: any;
};
const ProposalsTab = (props: IProps) => {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedProposalUid, setSelectedProposalUid] = useState(null);

  const apiRef = useGridApiRef();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize]);

  const getData = useCallback(() => {
    fetch(
      `${SERVER_URL}v1/rfps/${props.requestForProposal.id}/proposals?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setRowCount(res.page.totalElements);
        setRows(res._embedded?.models || []);
        setLoading(false);
      });
  }, [
    props.requestForProposal.id,
    paginationModel.page,
    paginationModel.pageSize,
  ]);

  const columns: GridColDef[] = [
    {headerName: 'ID', field: 'id', type: 'number'},
    {headerName: 'UID', field: 'uid', type: 'string'},
    {
      headerName: 'Created',
      field: 'createdDate',
      type: 'dateTime',
      valueFormatter: (value: $TSFixMe) =>
        dateAsWords(value, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      width: 120,
    },
    {
      headerName: 'State',
      field: 'state',
      type: 'singleSelect',
      valueOptions: ['REVIEW', 'PENDING', 'REJECTED', 'COMPLETE'],
    },
    {
      headerName: 'Name',
      field: 'name',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row.user?.name?.toUpperCase() || 'N/A',
      width: 150,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      type: 'number',
      width: 80,
    },
    {
      headerName: 'Contingency',
      field: 'contingencyAmount',
      type: 'number',
      width: 90,
    },
    {
      headerName: 'Subtotal',
      field: 'subtotal',
      type: 'number',
      width: 80,
    },
    {
      headerName: 'Tax',
      field: 'taxAmount',
      type: 'number',
      width: 60,
    },
    {
      headerName: 'Total',
      field: 'total',
      type: 'number',
      width: 80,
    },
    {
      headerName: 'Fees',
      field: 'feeAmount',
      type: 'number',
      width: 60,
    },
    {
      headerName: 'Fee Tax',
      field: 'feeTaxAmount',
      type: 'number',
      width: 60,
    },
    {
      headerName: 'Total Fees',
      field: 'totalFeeAmount',
      type: 'number',
      width: 60,
    },
    {
      headerName: 'Grand Total',
      field: 'grandTotal',
      type: 'number',
      width: 100,
    },

    {
      headerName: 'Phone',
      field: 'phone',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="phone user"
            component="span"
            style={{marginRight: 8}}>
            <PhoneIcon style={{fontSize: 17}} />
          </IconButton>
          {params?.row?.user.phone || ''}
        </span>
      ),
      width: 150,
    },
    {
      headerName: 'Email',
      field: 'email',
      renderCell: (params: $TSFixMe) => (
        <span style={{cursor: 'pointer'}}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="email user"
            component="span"
            style={{marginRight: 8}}>
            <EmailIcon style={{fontSize: 17}} />
          </IconButton>
          {params?.row?.user.email || ''}
        </span>
      ),
      width: 180,
    },
    {
      headerName: 'Last Active',
      field: 'lastOnline',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        moment(row.user.lastOnline).format('MMM D, YYYY, h:mm A'),
      width: 250,
    },
    {
      headerName: 'Viewed',
      field: 'hasBeenViewed',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row?.hasBeenViewed ? 'Yes' : '',
      width: 80,
    },
    {
      headerName: 'Insured',
      field: 'insured',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row?.insured ? 'Yes' : '',
      width: 80,
    },
    {
      headerName: 'Decline Reason',
      field: 'declineReason',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row?.declineReason || 'N/A',
      width: 80,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          key={'ProposalTab_actions_0'}
          icon={<FullscreenIcon />}
          onClick={() => {
            setSelectedProposalUid(params?.row?.uid);
          }}
          label="View Proposal"
        />,
        <GridActionsCellItem
          key={'ProposalTab_actions_1'}
          icon={<OpenInNewIcon />}
          onClick={() => {
            window.open(
              `${window.location.origin}/users/${params?.row?.user.id}`,
              '_blank',
            );
          }}
          label="View User"
        />,
      ],
      width: 100,
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        paddingTop: 30,
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          getRowHeight={() => 'auto'}
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                uid: false,
                email: false,
                phone: false,
                lastOnline: false,
                amount: false,
                contingencyAmount: false,
                taxAmount: false,
                total: false,
                subtotal: false,
                feeAmount: false,
                feeTaxAmount: false,
                totalFeeAmount: false,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 500},
              getData: getData,
            },
          }}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50, 100, 150, 200, 300, 400, 500, 750, 1000]}
          pagination
          paginationMode={'server'}
          loading={loading}
        />
      </div>
      {selectedProposalUid && (
        <ProposalDialog
          proposalUid={selectedProposalUid}
          requestForProposal={props.requestForProposal}
          updateRequestForProposalState={props.updateRequestForProposalState}
          setSelectedProposalUid={setSelectedProposalUid}
        />
      )}
    </div>
  );
};

export default ProposalsTab;
