import React from 'react';
import parser from 'html-react-parser';
import EditableForm from '../components/EditableForm';
import ProjectCarousel from './ProjectCarousel';
import DocumentsList from '../../../common/DocumentsList/DocumentsList';
import {Box, Grid, Typography} from '@material-ui/core';

function DetailsTab(props: $TSFixMe) {
  return props.mode === 'view' ? (
    <div>
      <Box
        style={{
          paddingLeft: window.outerWidth < 500 ? 0 : 20,
          paddingRight: window.outerWidth < 500 ? 0 : 20,
        }}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{paddingBottom: 20, maxWidth: 700, fontSize: 16}}>
          {parser(props.requestForProposal.description)}
        </Typography>
      </Box>
      {props.requestForProposal.details &&
        props.requestForProposal.details.length > 0 && (
          <Box mt={2} mb={3}>
            {props.requestForProposal.details.map((detail: $TSFixMe) => (
              <Grid key={detail.id}>
                <Typography variant="body2" component="span">
                  {detail.prompt}
                </Typography>
                <Typography
                  variant="body2"
                  component="span"
                  style={{marginLeft: 24, fontWeight: 'bolder'}}>
                  {detail.value}
                </Typography>
              </Grid>
            ))}
          </Box>
        )}
      <DocumentsList
        attachmentReferences={props.requestForProposal.attachmentReferences.filter(
          (ref: $TSFixMe) =>
            ['payment_receipt', 'project_document'].includes(ref.key),
        )}
      />
      <Box
        style={{
          paddingLeft: window.outerWidth < 500 ? 0 : 20,
          paddingRight: window.outerWidth < 500 ? 0 : 20,
        }}>
        <ProjectCarousel requestForProposal={props.requestForProposal} />
      </Box>
    </div>
  ) : (
    <EditableForm
      requestForProposal={props.requestForProposal}
      updateRequestForProposalState={props.updateRequestForProposalState}
    />
  );
}

export default DetailsTab;
