import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function QuestionRejectDialog(props: $TSFixMe) {
  const [questionRejectReasons, setQuestionRejectReasons] = useState('');
  const [customRejectReason, setCustomRejectReason] = useState('');

  const handleDeclineQuestion = () => {
    if (questionRejectReasons === 'Other') {
      props.onDecline(customRejectReason);
    } else {
      props.onDecline(questionRejectReasons);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle id="form-dialog-title">Question Rejection</DialogTitle>
        <DialogContent>
          <TextField
            id="Select-a-reason"
            select
            value={questionRejectReasons}
            onChange={(event) => setQuestionRejectReasons(event.target.value)}
            SelectProps={{
              native: true,
            }}>
            <option value="" disabled>
              SELECT A REASON
            </option>
            <option value="Not a question">Not a question</option>
            <option value="Inappropriate question">
              Inappropriate question
            </option>
            <option value="Requesting a site visit">
              Requesting a site visit
            </option>
            <option value="Asking if project is available">
              Asking if project is available
            </option>
            <option value="Contact information provided">
              Contact information provided
            </option>
            <option value="Other">Other</option>
          </TextField>
          <br />
          {questionRejectReasons === 'Other' && (
            <TextField
              placeholder={'Enter a reason'}
              onChange={(event) => setCustomRejectReason(event.target.value)}
              fullWidth={true}
              style={{marginTop: 15}}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleDeclineQuestion}
            variant={'contained'}
            color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
