import React, {useEffect, useState, useMemo} from 'react';
import {SERVER_URL} from '../../../constants';
import MaterialTable, {MTableToolbar} from 'material-table';
import Container from 'react-bootstrap/Container';
import Grid from '@material-ui/core/Grid';
import {useHistory} from 'react-router-dom';
import {dateAsWords} from '../../../util/DateAsWords';
import RequestForProposalRiskStatusWidget from '../../RequestForProposal/components/RequestForProposalRiskStatusWidget';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

function Alert(props: $TSFixMe) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const RequestForProposalAdminTable = (props: $TSFixMe) => {
  const tableRef = React.createRef();
  const [muiTableKey, setMuiTableKey] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [pageSize, setPageSize] = useState(
    localStorage.getItem('defaultPageSize')
      ? Number(localStorage.getItem('defaultPageSize'))
      : 10,
  );

  const filterKeys = useMemo(
    () => ({
      state: 'state',
    }),
    [],
  );

  const getData = (query: $TSFixMe) => {
    let filterParams = '';
    if (query.filters.length > 0) {
      query.filters.forEach((filter: $TSFixMe) => {
        const queryParam = `${
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          filterKeys[filter.column.field]
        }=${filter.value.join(',')}`;
        filterParams += queryParam;
      });
    }

    let searchParams = filterParams || '';
    if (query.search) {
      searchParams = `&search=${query.search}`;
      sessionStorage.setItem('rfpsTableSearchParams', searchParams);
    }

    const url = `v1/rfps/admin/user/${props.user.id}?page=${query.page}&pageSize=${query.pageSize}&${searchParams}`;
    const fullUrl = `${SERVER_URL}${url}`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(fullUrl, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    });
  };

  useEffect(() => {
    tableRef.current && (tableRef as $TSFixMe).current.onQueryChange();
  }, [tableRef]);

  const columns = [
    {title: 'Title', field: 'title', filtering: false},
    {
      title: 'Risk Status',
      field: 'riskStatus',
      render: (rowData: $TSFixMe) => (
        <div>
          <RequestForProposalRiskStatusWidget
            small={true}
            riskStatus={rowData.riskStatus}
          />
        </div>
      ),
      filtering: false,
    },
    {
      title: 'User',
      field: 'firstName',
      filtering: false,
      render: (rowData: $TSFixMe) => (
        <span>{`${rowData.firstName || ''} ${rowData.lastName || ''}`}</span>
      ),
    },
    {
      title: 'Created',
      field: 'createdDate',
      render: (rowData: $TSFixMe) =>
        dateAsWords(rowData.createdDate, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      filtering: false,
    },
    {
      title: 'Target',
      field: 'deadlineDate',
      render: (rowData: $TSFixMe) =>
        dateAsWords(rowData.deadlineDate, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
      filtering: false,
    },
    {
      title: 'City',
      field: 'city',
      render: (rowData: $TSFixMe) => rowData.city?.toUpperCase(),
      filtering: false,
    },
    {
      title: 'State',
      field: 'state',
      lookup: {
        OPEN: 'OPEN',
        FUNDED: 'FUNDED',
        COMPLETE: 'COMPLETE',
        DRAFT: 'DRAFT',
        CANCELED: 'CANCELLED',
      },
    },
    {title: 'Quotes', field: 'proposalsCount', filtering: false},
  ];

  const handleOnClickRow = (event: $TSFixMe, rowData: $TSFixMe) => {
    history.push(`/myRfps/${rowData.id}`);
  };

  const handleOnChangeRowsPerPage = (size: $TSFixMe) => {
    localStorage.setItem('defaultPageSize', size);
    setPageSize(size);
  };

  const alertNoResultsAndTableReset = () => {
    setOpen(true);
    setTimeout(function () {
      setOpen(false);
    }, 1150);
  };

  const handleOnClickResetButton = () => {
    sessionStorage.removeItem('rfpsTableFilterParams');
    sessionStorage.removeItem('rfpsTableSearchParams');
    setMuiTableKey(muiTableKey + 1);
  };

  const exportData = (columnDetails: $TSFixMe, data: $TSFixMe) => {
    let csvData =
      'id,title,state,name,phone,email,city,created,deadline,quotes\n';
    data.forEach((row: $TSFixMe) => {
      csvData += `${row.id},${row.title},${row.state},${row.firstName} ${
        row.lastName || ''
      },${row.phone},${row.email},${row?.city?.toUpperCase()},${
        row.createdDate
      },${row.deadlineDate},${row.proposalsCount}\n`;
    });
    const file = new File([csvData], 'report.csv', {type: 'text/csv'});
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'report.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <div>
      <Container
        fluid="false"
        style={{backgroundColor: 'light-grey', paddingTop: 30}}>
        <Grid
          container
          justifyContent="center"
          style={{marginTop: 60, marginBottom: 20}}>
          <Grid item xs={12} md={12} key={0}>
            <MaterialTable
              columns={columns}
              title={''}
              key={muiTableKey}
              data={(query) =>
                new Promise((resolve, reject) => {
                  getData(query)
                    .then((res) => res.json())
                    .then((res) => {
                      if (res.page.size > 0) {
                        resolve({
                          data: res._embedded.models,
                          page: query.page,
                          totalCount: res.page.totalElements,
                        });
                      } else {
                        resolve({
                          data: [],
                          page: 0,
                          totalCount: 0,
                        });
                        alertNoResultsAndTableReset();
                        handleOnClickResetButton();
                      }
                    })
                    .catch((error) => {
                      resolve({
                        data: [],
                        page: 0,
                        totalCount: 0,
                      });
                      alertNoResultsAndTableReset();
                      handleOnChangeRowsPerPage(10);
                      handleOnClickResetButton();
                      console.error('Error:', error);
                    });
                })
              }
              options={{
                search: false,
                toolbar: true,
                actionsColumnIndex: -1,
                pageSize: pageSize,
                pageSizeOptions: [
                  10, 15, 20, 25, 30, 50, 75, 100, 200, 500, 1000, 10000,
                ],
                debounceInterval: 500,
                filtering: true,
                exportButton: true,
                exportCsv: (c, data) => {
                  exportData(c, data);
                },
              }}
              components={{
                Toolbar: (toolbarProps) => (
                  <div>
                    <MTableToolbar {...toolbarProps} />
                    {(sessionStorage.getItem('rfpsTableFilterParams') ||
                      sessionStorage.getItem('rfpsTableSearchParams')) && (
                      <div style={{padding: '0px 10px', textAlign: 'right'}}>
                        <Button
                          variant="contained"
                          onClick={handleOnClickResetButton}
                          color={'secondary'}
                          style={{marginLeft: 'auto', marginRight: 40}}>
                          RESET
                        </Button>
                      </div>
                    )}
                  </div>
                ),
              }}
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              onRowsPerPageChange={handleOnChangeRowsPerPage}
              onRowClick={handleOnClickRow}
              tableRef={tableRef}
            />
          </Grid>
        </Grid>

        <Snackbar
          open={open}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
          <Alert severity="info">No results. Resetting...</Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default RequestForProposalAdminTable;
