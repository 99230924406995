import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {withStyles} from '@material-ui/core/styles';

export default function BidmiiLinearProgress(props: $TSFixMe) {
  const Progress = withStyles((theme) => ({
    root: {
      height: 5,
    },
    colorPrimary: {
      backgroundColor: '#FFFFFF',
    },
    bar: {
      backgroundColor: props.color || '#22ABF7',
      borderRadius: 50,
      opacity: '80%',
    },
  }))(LinearProgress);

  return (
    <Progress
      variant={props.progress ? 'determinate' : 'indeterminate'}
      value={props.progress}
    />
  );
}
