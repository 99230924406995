import React from 'react';
import DataTable from './DataTable';
import {SERVER_URL} from '../../constants';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {Button, LinearProgress} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import DataTableTotal from './DataTableTotal';
import moment from 'moment';
import DashboardTableView from './DashboardTableView';

type State = $TSFixMe;

class Stats extends React.Component<$TSFixMe, State> {
  constructor(props: $TSFixMe) {
    super(props);
    this.state = {
      currentDate: new Date(),
      viewYear: new Date().getFullYear(),
      viewMonth: new Date().getMonth() + 1,
      open: false,
      startDate: null,
      endDate: null,
      interval: 'month',
      items: [],
      view: 'card',
      loading: false,
    };
  }

  fetchData() {
    if (this.state.startDate > this.state.endDate) {
      // eslint-disable-next-line no-alert
      alert('Start date cannot be larger than end date');
      return;
    }
    this.setState({items: []});
    const url = `${SERVER_URL}stats`;
    const fbjwt = localStorage.getItem('Authorization');
    this.setState({loading: true});
    fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        startDate: moment(this.state.submitStartDate, moment.ISO_8601),
        endDate: moment(this.state.submitEndDate, moment.ISO_8601),
        interval: this.state.interval,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({data: response, loading: false});
        const dataTables = [];
        dataTables.push(
          <Grid item xs={12} sm={6} xl={3} md={4}>
            <DataTableTotal data={response[0]} interval={this.state.interval} />
          </Grid>,
        );
        for (let i = 1; i < response.length; i += 1) {
          dataTables.push(
            <Grid item xs={12} sm={6} xl={3} md={4}>
              <DataTable
                data={response[i]}
                interval={this.state.interval}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
            </Grid>,
          );
        }
        this.setState({items: dataTables});
      });
  }

  handleStartDateChange(inputDate: $TSFixMe) {
    let date = inputDate;
    const offsetHours = new Date().getTimezoneOffset() / 60;
    const inputDateHours = date.getHours();

    if (24 - inputDateHours < offsetHours) {
      date = moment(date).subtract(offsetHours, 'hours');
    }

    this.setState({
      startDate: inputDate,
      submitStartDate: date,
    });
  }

  startDateSelector() {
    return (
      <KeyboardDatePicker
        margin="normal"
        id="date-picker-dialog"
        label="Start Date"
        format="yyyy-MM-dd"
        color={'secondary'}
        value={this.state.startDate}
        onChange={(e) => this.handleStartDateChange(e)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    );
  }

  handleEndDateChange(inputDate: $TSFixMe) {
    let date = inputDate;
    const offsetHours = new Date().getTimezoneOffset() / 60;
    const inputDateHours = date.getHours();

    if (24 - inputDateHours < offsetHours) {
      date = moment(date).subtract(offsetHours, 'hours');
    }
    this.setState({
      endDate: inputDate,
      submitEndDate: date,
    });
  }

  endDateSelector() {
    return (
      <KeyboardDatePicker
        margin="normal"
        id="date-picker-dialog"
        label="End Date"
        format="yyyy-MM-dd"
        color={'secondary'}
        value={this.state.endDate}
        onChange={(e) => this.handleEndDateChange(e)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    );
  }

  intervalSelectionDropDown() {
    return (
      <FormControl style={{margin: 20}}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.interval}
          onChange={(e) => this.handleIntervalChange(e)}>
          <MenuItem value={'month'}>Monthly</MenuItem>
          <MenuItem value={'week'}>Weekly</MenuItem>
        </Select>
      </FormControl>
    );
  }

  handleIntervalChange(e: $TSFixMe) {
    this.setState({interval: e.target.value});
  }

  handleViewChange(e: $TSFixMe) {
    this.setState({view: e.target.value});
  }

  getDataButton() {
    return (
      <>
        {this.state.startDate && this.state.endDate && (
          <Button
            onClick={() => this.fetchData()}
            style={{margin: 20}}
            variant="contained"
            color="primary">
            Get Data
          </Button>
        )}
        {this.state.data && (
          <FormControl style={{margin: 20}}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.view}
              onChange={(e) => this.handleViewChange(e)}>
              <MenuItem value={'card'}>Card</MenuItem>
              <MenuItem value={'table'}>List</MenuItem>
            </Select>
          </FormControl>
        )}
      </>
    );
  }
  //turns the data into csv format and downloads it
  exportData(year: $TSFixMe) {
    // let csvContent = 'data:text/csv;charset=utf-8,';
    // csvContent += header.join(',');
    // let encodedUri = encodeURI(csvContent);
    // let link = document.createElement('a');
    // link.setAttribute('href', encodedUri);
    // link.setAttribute('download', `bidmii_${year}${months[latestMonth - 1]}.csv`);
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  }

  render() {
    return (
      <div style={{paddingTop: 30}}>
        {this.state.loading && <LinearProgress color={'secondary'} />}
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; utils: typeof DateFns... Remove this comment to see the full error message */}
        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{display: 'flex'}}>
          {this.intervalSelectionDropDown()}
          {this.startDateSelector()}
          {this.endDateSelector()}
          {this.getDataButton()}
          {this.state.view === 'card' ? (
            <Grid container spacing={1} style={{width: '100%'}}>
              {this.state.items}
            </Grid>
          ) : (
            <>
              {this.state.data && (
                <DashboardTableView
                  data={this.state.data}
                  interval={this.state.interval}
                />
              )}
            </>
          )}
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default Stats;
