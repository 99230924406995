import React from 'react';
import Typography from '@material-ui/core/Typography';
import {currencyFormat} from '../../../util/CurrencyFormatter';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

function ProposalContractorViewTable(props: $TSFixMe) {
  return (
    <div>
      <TableContainer component={Paper} style={{marginTop: 20}}>
        <Table aria-label="spanning table" size="small" style={{width: '100%'}}>
          <TableBody>
            <TableRow key={'TEST'} component="th" scope="row">
              <TableCell>
                <Typography component="p" variant="subtitle1">
                  Materials
                </Typography>
              </TableCell>
              <TableCell align="right">
                {currencyFormat(props.proposal.materialsAmount, true)}
              </TableCell>
            </TableRow>

            <TableRow key={'TEST1'} component="th" scope="row">
              <TableCell>
                <Typography component="p" variant="subtitle1">
                  Labour
                </Typography>
              </TableCell>
              <TableCell align="right">
                {currencyFormat(props.proposal.labourAmount, true)}
              </TableCell>
            </TableRow>

            <TableRow key={'TEST1'} component="th" scope="row">
              <TableCell>
                <Typography component="p" variant="subtitle1">
                  Contingency
                </Typography>
              </TableCell>
              <TableCell align="right">
                {currencyFormat(props.proposal.contingencyAmount, true)}
              </TableCell>
            </TableRow>

            <TableRow key={'TEST2'} component="th" scope="row">
              <TableCell align="right">
                <Typography
                  component="p"
                  variant="subtitle1"
                  style={{fontWeight: 600}}>
                  Subtotal
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  component="p"
                  variant="subtitle1"
                  style={{fontWeight: 600}}>
                  {currencyFormat(props.proposal.amount, true)}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow key={'TEST3'} component="th" scope="row">
              <TableCell>
                <Typography component="p" variant="subtitle1">
                  Tax
                </Typography>
              </TableCell>
              <TableCell align="right">
                {currencyFormat(props.proposal.amount * 0.13, true)}
              </TableCell>
            </TableRow>

            <TableRow key={'TEST4'} component="th" scope="row">
              <TableCell align="right">
                <Typography
                  component="p"
                  variant="subtitle1"
                  style={{fontWeight: 600}}>
                  Total
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  component="p"
                  variant="subtitle1"
                  style={{fontWeight: 600}}>
                  {currencyFormat(props.proposal.amount * 1.13, true)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ProposalContractorViewTable;
