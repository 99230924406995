import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ListIcon from '@material-ui/icons/List';
import ImageIcon from '@material-ui/icons/Image';
import RequestForProposalEditableFormDetails from './EditableFormDetails';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import {Carousel} from 'react-responsive-carousel';
import {SERVER_URL} from '../../../constants';
import {isVideo, renderVideo} from '../../../util/VideoHelpers';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = (theme: $TSFixMe) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

type MyRequestForProposalEditableFormState = $TSFixMe;

class MyRequestForProposalEditableForm extends Component<
  $TSFixMe,
  MyRequestForProposalEditableFormState
> {
  constructor(props: $TSFixMe) {
    super(props);

    const beforeImages = (
      this.props as $TSFixMe
    ).requestForProposal.attachmentReferences.filter(
      (ref: $TSFixMe) => ref.key === 'before_image',
    );

    let selectedImage = null;
    let selectedImageId = null;
    if (beforeImages.length > 0) {
      selectedImage = beforeImages[0];
      selectedImageId = selectedImage.id;
    }

    this.state = {
      tabIndex: 0,
      selectedFocus: 'details',
      selectedImageId: selectedImageId,
      selectedImage: selectedImage,
      requestForProposal: (this.props as $TSFixMe).requestForProposal,
    };
  }

  onChangeCarouselImage = (index: $TSFixMe, item: $TSFixMe) => {
    const key = item.key;
    const imageId = key.substr(2);
    const beforeImages = (
      this.props as $TSFixMe
    ).requestForProposal.attachmentReferences.filter(
      (ref: $TSFixMe) => ref.key === 'before_image',
    );
    this.setState({
      selectedImageId: imageId,
      selectedImage: beforeImages[index],
    });
  };

  deleteImage = (imageId: $TSFixMe) => {
    const that = this;
    const url = `${SERVER_URL}v1/attachments/${imageId}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'DELETE',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.text())
      .then((res) => {
        const requestForProposal = that.state.requestForProposal;
        requestForProposal.attachmentReferences.forEach(
          (attachment: $TSFixMe, index: $TSFixMe) => {
            if (attachment.id === imageId) {
              requestForProposal.attachmentReferences.splice(index, 1);
              that.setState({requestForProposal: requestForProposal});
            }
          },
        );
      });
  };

  deleteSelectedImage = () => {
    let selectedImageId = this.state.selectedImageId;
    if (selectedImageId === null) {
      const beforeImages = (
        this.props as $TSFixMe
      ).requestForProposal.attachmentReferences.filter(
        (ref: $TSFixMe) => ref.key === 'before_image',
      );
      if (beforeImages.length > 0) {
        selectedImageId = beforeImages[0].id;
      }
    }
    if (selectedImageId !== null) {
      this.deleteImage(selectedImageId);
    }
  };

  setSelectedImageAsCover = () => {
    const url = `${SERVER_URL}v1/attachments/${this.state.selectedImageId}/set-cover`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'GET',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.text())
      .then((res) => {
        (this.props as $TSFixMe).updateRequestForProposalState();
      });
  };

  handleChangeFocus = (event: $TSFixMe, focus: $TSFixMe) => {
    this.setState({selectedFocus: focus});
  };

  getCarousel = () => {
    const beforeImages =
      this.state.requestForProposal.attachmentReferences.filter(
        (ref: $TSFixMe) => ref.key === 'before_image',
      );
    return (
      beforeImages && (
        <CardContent>
          <Carousel
            onChange={(index, item) => {
              this.onChangeCarouselImage(index, item);
            }}>
            {beforeImages.map((image: $TSFixMe) => {
              return (
                <div key={image.id}>
                  {isVideo(image.url) ? (
                    renderVideo(image)
                  ) : (
                    <img src={image.url} alt={image.name} />
                  )}
                </div>
              );
            })}
          </Carousel>

          <Button
            variant="contained"
            size="small"
            color="primary"
            aria-label="delete image"
            onClick={this.deleteSelectedImage}>
            <DeleteIcon />
          </Button>
          {this.state.selectedImage &&
          this.state.selectedImage.type !== 'cover' ? (
            <Button
              style={{marginLeft: 10}}
              variant="contained"
              size="small"
              color="secondary"
              aria-label="set as cover"
              onClick={this.setSelectedImageAsCover}>
              Set As Cover
            </Button>
          ) : (
            <Typography component={'span'} style={{marginLeft: 20}}>
              Cover Photo <CheckCircleIcon />
            </Typography>
          )}
        </CardContent>
      )
    );
  };

  componentDidUpdate(prevProps: $TSFixMe) {
    if (
      (prevProps as $TSFixMe).requestForProposal !==
      (this.props as $TSFixMe).requestForProposal
    ) {
      this.setState({
        requestForProposal: (this.props as $TSFixMe).requestForProposal,
      });
      const beforeImages = (
        this.props as $TSFixMe
      ).requestForProposal.attachmentReferences.filter(
        (ref: $TSFixMe) => ref.key === 'before_image',
      );
      if (beforeImages.length > 0) {
        const selectedImage = beforeImages[0];
        const selectedImageId = selectedImage.id;
        this.setState({
          selectedImage: selectedImage,
          selectedImageId: selectedImageId,
        });
      }
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <ToggleButtonGroup
          value={this.state.selectedFocus}
          exclusive
          onChange={this.handleChangeFocus}
          aria-label="edit focus">
          <ToggleButton value="details" aria-label="project details">
            <ListIcon />
          </ToggleButton>
          <ToggleButton value="media" aria-label="media">
            <ImageIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        {this.state.selectedFocus === 'details' ? (
          <RequestForProposalEditableFormDetails
            requestForProposal={this.state.requestForProposal}
            updateRequestForProposalState={
              (this.props as $TSFixMe).updateRequestForProposalState
            }
          />
        ) : (
          this.getCarousel()
        )}
      </div>
    );
  }
}

export default withStyles(styles)(MyRequestForProposalEditableForm);
