import React, {useCallback, useEffect, useState} from 'react';
import {SERVER_URL} from '../../../constants';
import {DataGridPro, GridColDef, useGridApiRef} from '@mui/x-data-grid-pro';
import {Typography} from '@mui/material';

function ReferralsTab(props: $TSFixMe) {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const apiRef = useGridApiRef();

  const getData = useCallback(() => {
    setLoading(true);
    fetch(
      `${SERVER_URL}v1/users/${props.user.id}/referrals?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res._embedded) {
          setRowCount(res.page.totalElements);
          setRows(res?._embedded.models || []);
        }
        setLoading(false);
      });
  }, [paginationModel.page, paginationModel.pageSize, props.user.id]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize]);

  const columns: GridColDef[] = [
    {
      headerName: 'Id',
      field: 'id',
      width: 180,
    },
    {
      field: 'name',
      headerName: 'Name',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row?.firstName || row?.lastName
          ? `${row?.firstName} ${row?.lastName}`
          : 'Unknown',
      flex: 1,
      minWidth: 150,
      type: 'string',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1.25,
      minWidth: 200,
      type: 'string',
      renderCell: (params: $TSFixMe) => (
        <a href={`mailto:${params?.row?.email}`}>
          <Typography variant="subtitle2">{params?.row?.email}</Typography>
        </a>
      ),
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      flex: 0.75,
      minWidth: 125,
      type: 'string',
      renderCell: (params: $TSFixMe) => (
        <a href={`tel:${params?.row?.phone}`}>
          <Typography variant="subtitle2">{params?.row?.phone}</Typography>
        </a>
      ),
    },
    {
      field: 'address',
      headerName: 'Address',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) => row.street,
      flex: 1,
      minWidth: 150,
      type: 'string',
    },
    {
      field: 'city',
      headerName: 'City',
      valueGetter: (value: $TSFixMe, row: $TSFixMe) =>
        row.city || row.province
          ? `${row.city || 'Unknown'} , ${row.province || 'Unknown'}`
          : '',
      flex: 0.75,
      minWidth: 100,
      type: 'string',
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '45vh',
        backgroundColor: '#ffffff',
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 500},
              getData: getData,
            },
          }}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50, 100, 150, 200]}
          pagination
          paginationMode={'server'}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default ReferralsTab;
