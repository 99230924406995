import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import TTFJChart from './Charts/TTFJChart';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    padding: '10px',
    flex: '1',
    color: 'black',
    fontSize: '20px',
  },
  root: {
    flexGrow: 1,
    background: '#EEEEEE',
    width: '100%',
    padding: '30px',
    display: 'flex',
    paddingRight: '20px',
    flexDirection: 'column',
    paddingTop: '80px',
  },
  doublePaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    height: '382px',
    paddingRight: '35px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mapPaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    height: '422px',
    paddingRight: '35px',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const MainDashboard = (props: $TSFixMe) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        color="default"
        style={{marginTop: 63, height: 49}}
        elevation={0}
      />
      <Grid container spacing={3} className={classes.main}>
        <Grid item lg={12} xs={12}>
          <TTFJChart />
        </Grid>
      </Grid>
    </div>
  );
};

export default MainDashboard;
