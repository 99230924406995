import React from 'react';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import {ListItemAvatar, ListItemText} from '@material-ui/core';
import nullNotificationImage from '../../../assets/images/nullNotificationImage.png';
import {createdDate} from '../../../util/DateAsWords';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    color: (theme.palette as $TSFixMe).black.main,
  },
  listItemTextPrimary: {
    color: (theme.palette as $TSFixMe).black.main,
    fontSize: '18px',
    fontFamily: 'TiemposTextRegular',
    paddingBottom: '3px',
  },
  listItemTextSecondary: {
    fontSize: '12px',
    fontFamily: 'AeonikProReqular',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    cursor: 'pointer',
  },
  box: {
    borderBottom: '2px solid #e6e6e6',
    '&:hover': {
      background: '#E6E6E6',
    },
  },
  red: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    backgroundColor: 'red',
    color: 'red',
    marginRight: '10px',
  },
  invisible: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(0, 0, 0, 0)',
    marginRight: '10px',
  },
}));

function Notification(props: $TSFixMe) {
  const history = useHistory();
  const classes = useStyles();

  const handleClearNotification = (notification: $TSFixMe) => {
    const items = [...props.notifications];
    const index = items.findIndex((item) => item.id === notification.id);
    items.splice(index, 1);
    props.setNotifications(items);
  };

  const handleClickItem = (notification: $TSFixMe) => {
    const items = [...props.notifications];
    const index = items.findIndex((item) => item.id === notification.id);
    if (index > 0) {
      try {
        const currentNotification = items[index];
        if (!currentNotification.hasBeenRead) {
          items[index].hasBeenRead = true;
        }
      } catch (e) {}
    }
    props.onClose();
    props.setNotifications(items);
    if (notification.url != null) {
      history.push(`${notification.url}`);
    } else {
      history.push('/');
    }
  };

  return (
    <div style={{overflowY: 'scroll', maxHeight: '45vh'}}>
      {props.notifications && props.notifications.length > 0 ? (
        props.notifications.map((notification: $TSFixMe) => {
          return (
            <Box className={classes.box} key={Date.now()}>
              <ListItem ContainerComponent="div">
                {notification.hasBeenRead ? (
                  <Avatar className={classes.invisible} />
                ) : (
                  <Avatar className={classes.red} />
                )}
                <ListItemAvatar>
                  <Avatar
                    className={classes.large}
                    src={
                      notification.imgUrl === null
                        ? nullNotificationImage
                        : notification.imgUrl
                    }
                    alt="notification rfp"
                    variant="square"
                    onClick={() => handleClickItem(notification)}
                  />
                </ListItemAvatar>
                <ListItemText
                  classes={{
                    primary: classes.listItemTextPrimary,
                    secondary: classes.listItemTextSecondary,
                  }}
                  primary={notification.title}
                  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
                  secondary={createdDate(notification.createdDate)}
                  style={{cursor: 'pointer', paddingLeft: '15px'}}
                  onClick={() => handleClickItem(notification)}
                />
                <ListItemSecondaryAction>
                  <Tooltip
                    title={
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'h7' does not exist on type 'JSX.Intrinsi... Remove this comment to see the full error message
                      <h7 style={{fontSize: '14px'}}> Clear Notification </h7>
                    }
                    arrow>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleClearNotification(notification)}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            </Box>
          );
        })
      ) : (
        <ListItem style={{padding: '20px', textAlign: 'center'}}>
          <ListItemText
            classes={{
              primary: classes.listItemTextPrimary,
            }}
            primary="You're all caught up!"
          />
        </ListItem>
      )}
    </div>
  );
}

export default Notification;
