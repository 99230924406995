import React, {useCallback, useEffect, useState} from 'react';

import {SERVER_URL} from '../../../constants';
import moment from 'moment';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  gridExpandedSortedRowEntriesSelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const CustomToolbar = (props: $TSFixMe) => {
  return (
    <GridToolbarContainer style={{paddingTop: 25}}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <GridToolbarQuickFilter style={{position: 'fixed', right: 50}} />
    </GridToolbarContainer>
  );
};

function NotificationsTab(props: $TSFixMe) {
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('rowClick', (params) => {
      window.open(
        `${window.location.origin}/myRfps/${params?.row?.id}`,
        '_blank',
      );
    });
  }, [apiRef]);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('cellClick', (params) => {
      switch (params.field) {
        case 'firstName':
          window.open(
            `${window.location.origin}/users/${params?.row?.userId}`,
            '_blank',
          );
          break;
        case 'email':
          window.open(`mailto:${params?.row?.email}`, '_blank');
          break;
        case 'phone':
          window.open(`tel:${params?.row?.phone}`, '_blank');
          break;
      }
    });
  }, [apiRef]);

  const getData = useCallback(() => {
    setLoading(true);
    fetch(
      `${SERVER_URL}v1/users/${props.userId}/notifications?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setRowCount(res.page.totalElements);
        setRows(res._embedded?.models || []);
        setLoading(false);
      });
  }, [paginationModel.page, paginationModel.pageSize, props.userId]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize, props.filter]);

  const columns: GridColDef[] = [
    {headerName: 'ID', field: 'id', type: 'number'},
    {
      headerName: 'Created',
      field: 'createdDate',
      type: 'dateTime',
      valueFormatter: (value: $TSFixMe) =>
        moment(value).format('MMM D, YYYY, h:mm A'),
      width: 120,
    },
    {
      headerName: 'Notification',
      field: 'name',
    },
    {
      headerName: 'Project',
      field: 'requestForProposal',
      valueFormatter: (value: $TSFixMe) => value?.title || '',
      width: 120,
    },
    {
      headerName: 'Channel',
      field: 'channel',
      type: 'singleSelect',
      valueOptions: ['EMAIL', 'SMS', 'PUSH'],
    },
    {
      headerName: 'Status',
      field: 'status',
      type: 'singleSelect',
      valueOptions: ['PENDING', 'SENT', 'READ', 'CLICKED', 'ERROR'],
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: $TSFixMe) => [
        <GridActionsCellItem
          icon={<OpenInNewIcon />}
          onClick={() => {
            window.open(
              `${window.location.origin}/myRfps/${params.id}`,
              '_blank',
            );
          }}
          label="View Project"
        />,
      ],
      width: 100,
    },
  ];

  const onFilterModelChange = () => {
    //const r = apiRef.current;
    const rm = gridExpandedSortedRowEntriesSelector(apiRef);
    const visibleRows = rm.length;
    setRowCount(visibleRows);
  };

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '50vh',
        paddingTop: 0,
      }}>
      <div style={{flexGrow: 1}}>
        <DataGridPro
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {debounceMs: 500},
              getData: getData,
            },
          }}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          rowHeight={38}
          checkboxSelection
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 20, 50]}
          pagination
          paginationMode={'server'}
          onFilterModelChange={onFilterModelChange}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default NotificationsTab;
