import React, {useState} from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import SimpleReactValidator from 'simple-react-validator';
import {Grid} from '@mui/material';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {SERVER_URL} from '../../constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children?: ReactNode; direction: "left"; r... Remove this comment to see the full error message
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  userId: number;
  apigeeAppId: string;
  apiKey: string;
}

export const LinkAccountForm = (props: IProps) => {
  const [open, setOpen] = React.useState(false);
  const [appId, setAppId] = React.useState<string>(props.apigeeAppId);
  const [apiKey, setApiKey] = React.useState<string>(props.apiKey);
  const [validator] = useState(new SimpleReactValidator());

  const handleClickCloseButton = () => {
    setOpen(false);
  };

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleClickSubmit = () => {
    const fbjwt = localStorage.getItem('Authorization');

    return fetch(`${SERVER_URL}v1/users/${props.userId}`, {
      method: 'PATCH',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({
        apigeeAppId: appId,
        apiKey: apiKey,
      }),
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  return (
    <>
      <Box my={4} style={{width: 'fit-content', marginLeft: 20}}>
        <Button
          onClick={handleClickOpenDialog}
          color={'secondary'}
          variant={'contained'}>
          Link Developer Account
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClickCloseButton}
        aria-labelledby="Link Account Form"
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'ForwardRefExoticComponent<RefAttributes<unkn... Remove this comment to see the full error message
        TransitionComponent={Transition}
        PaperProps={{
          style: {overflowX: 'hidden'},
          elevation: 3,
        }}>
        <IconButton
          onClick={handleClickCloseButton}
          style={{position: 'absolute', right: 12, top: 12}}
          size="large">
          <Close color="primary" />
        </IconButton>
        <DialogContent>
          <Box px={2}>
            <div style={{maxWidth: 350}}>
              <Typography variant="h6" fontSize={22} mb={3} fontWeight="bold">
                Link Developer Account
              </Typography>
              <Typography>
                In order to authenticate and properly associate a company's
                records with their App we need to link their Bidmii Account with
                their App's credentials.
              </Typography>
              <Box my={2}>
                <Box my={2}>
                  <TextField
                    color={'secondary'}
                    autoFocus
                    fullWidth
                    id="app-id"
                    name="app-id"
                    type="text"
                    label={'App ID'}
                    placeholder={`e.g. 9f0f3561-a51b-47cb-8f9e-e2ed7b617d7c`}
                    value={appId}
                    onChange={(event) => setAppId(event.target.value)}
                    helperText={validator.message('app-id', appId, 'required', {
                      className: 'text-danger',
                    })}
                  />
                </Box>
                <Box my={2}>
                  <TextField
                    color={'secondary'}
                    fullWidth
                    id="api-key"
                    name="api-key"
                    type="password"
                    label={'API Key'}
                    placeholder={`e.g. 9f0f3561-a51b-47cb-8f9e-e2cd7b617d7b`}
                    value={apiKey}
                    onChange={(event) => setApiKey(event.target.value)}
                    helperText={validator.message(
                      'api-key',
                      apiKey,
                      'required',
                      {
                        className: 'text-danger',
                      },
                    )}
                  />
                </Box>
              </Box>
              <Box mt={4}>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}>
                  <Grid item>
                    <Button
                      onClick={handleClickCloseButton}
                      color={'secondary'}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleClickSubmit}
                      variant={'contained'}
                      color={'secondary'}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
