import React from 'react';
import MaterialTable from 'material-table';
import {dateAsWords} from '../../../util/DateAsWords';
import {SERVER_URL} from '../../../constants';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import PageViewsPanel from './PageViewsPanel';

const columns = [
  {
    title: 'Date',
    field: 'date',
    render: (rowData: $TSFixMe) => {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      return dateAsWords(rowData.sessionStart);
    },
  },
  {
    title: 'Device Type',
    field: 'deviceType',
  },
  {
    title: 'Device Name',
    field: 'deviceName',
  },
  {
    title: 'Version',
    field: 'appVersion',
  },
  {
    title: 'Source',
    field: 'utmSource',
  },
  {
    title: 'Medium',
    field: 'utmMedium',
  },
  {
    title: 'Campaign',
    field: 'utmCampaign',
  },
  {
    title: 'Term',
    field: 'utmTerm',
  },
  {
    title: 'Content',
    field: 'utmContent',
  },
  {
    title: 'Timezone',
    field: 'timezone',
  },
  {
    title: 'LogRocket',
    field: 'sessionRecordingUrl',
    render: (rowData: $TSFixMe) =>
      rowData.sessionRecordingUrl && (
        <IconButton
          onClick={() => window.open(rowData.sessionRecordingUrl, '_blank')}>
          <LaunchIcon />
        </IconButton>
      ),
  },
];

function UserSessionsTab(props: $TSFixMe) {
  const getData = (query: $TSFixMe) => {
    return fetch(
      `${SERVER_URL}v1/users/${props.userId}/sessions?page=${query.page}&pageSize=${query.pageSize}`,
      {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      },
    );
  };

  const renderDetailPanel = (rowData: $TSFixMe) => {
    return <PageViewsPanel data={rowData} />;
  };

  return (
    <div>
      <MaterialTable
        columns={columns}
        title={'Sessions'}
        data={(query) =>
          new Promise((resolve, reject) => {
            getData(query)
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  data: [...(res?._embedded?.models || [])],
                  page: res.page.number,
                  totalCount: res.page.totalElements,
                });
              })
              .catch((error) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
                console.error('Error:', error);
              });
          })
        }
        options={{
          toolbar: true,
          pageSize: 10,
          actionsColumnIndex: -1,
          exportButton: true,
        }}
        detailPanel={renderDetailPanel}
      />
    </div>
  );
}

export default UserSessionsTab;
