import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {Carousel} from 'react-responsive-carousel';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Location from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {SERVER_URL} from '../../../constants';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {isVideo, renderVideo} from '../../../util/VideoHelpers';

function ProjectCarousel(props: $TSFixMe) {
  const [imageIndex, setImageIndex] = useState<number>(0);
  const [imageType, setImageType] = useState(false); //true for beforeImages, false for afterImages
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  let beforeImages = props.requestForProposal.attachmentReferences.filter(
    (ref: $TSFixMe) => ref.key === 'before_image',
  );
  const afterImages = props.requestForProposal.attachmentReferences.filter(
    (ref: $TSFixMe) => ref.key === 'after_image',
  );

  const flexStyles = {display: 'flex', alignItems: 'center'};

  const getContractorInfo = () => {
    if (props.requestForProposal.proposal) {
      return (
        <div
          style={{
            backgroundColor: '#E6E6E6',
            padding: 15,
            marginBottom: 15,
            maxWidth: '100%',
          }}>
          <Grid
            container
            justifyContent="space-between"
            style={{paddingBottom: 20}}>
            <Grid item>
              <Typography variant="h6" component="span">
                Contractor:
              </Typography>
              <Typography
                variant="body2"
                component="span"
                style={{paddingLeft: 7}}>
                {`${props.requestForProposal.proposal.user.firstName} ${props.requestForProposal.proposal.user.lastName}`}
              </Typography>
            </Grid>
            <Grid item style={{flexDirection: 'column', ...flexStyles}}>
              <Grid item style={{textAlign: 'right', ...flexStyles}}>
                <Location />
                <Typography variant="body2" component="p">
                  {props.requestForProposal.proposal.user.workCity}
                </Typography>
              </Grid>
              <Typography variant="body2" component="p">
                {props.requestForProposal.proposal.user?.alias}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <div style={{paddingTop: 15}}>
            <Grid container justifyContent="space-between">
              <Grid item style={flexStyles}>
                <PhoneIcon style={{marginRight: 4}} />
                {props.requestForProposal.proposal.user.phone}
              </Grid>
              <Grid item style={flexStyles}>
                <EmailIcon style={{marginRight: 4}} />
                {props.requestForProposal.proposal.user.email}
              </Grid>
            </Grid>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
  const deleteImage = () => {
    let id = null;
    if (imageType) {
      id = beforeImages[imageIndex].id;
    } else {
      id = afterImages[imageIndex].id;
    }
    const url = `${SERVER_URL}v1/attachments/${id}`;
    const fbjwt = localStorage.getItem('Authorization');
    fetch(url, {
      method: 'DELETE',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then((response) => {
        beforeImages = beforeImages.splice(imageIndex, 1);
        console.log(beforeImages);
        setDeleteOpen(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleImageClick = (index: $TSFixMe, type: $TSFixMe) => {
    setImageIndex(index);
    setImageType(type);
    setOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteOpen(false);
    setOpen(false);
  };

  return (
    beforeImages && (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} key={0} style={{textAlign: 'center'}}>
          <h2>Before Pictures</h2>
          <Carousel
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; style: { background: string... Remove this comment to see the full error message
            style={{background: 'white'}}
            onClickItem={(index) => handleImageClick(index, true)}>
            {beforeImages.map((image: $TSFixMe) => {
              return (
                <div
                  key={`image_before_${image.id}`}
                  style={{cursor: 'pointer'}}>
                  {isVideo(image.url) ? (
                    renderVideo(image)
                  ) : (
                    <img src={image.url} alt={image.name} />
                  )}
                </div>
              );
            })}
          </Carousel>
        </Grid>
        {afterImages.length > 0 && (
          <Grid item xs={12} sm={6} key={1} style={{textAlign: 'center'}}>
            {getContractorInfo()}
            <h2>After Completion Pictures</h2>
            <Carousel onClickItem={(e) => handleImageClick(e, false)}>
              {afterImages.map((image: $TSFixMe) => {
                return (
                  <div
                    key={`image_after_${image.id}`}
                    style={{cursor: 'pointer'}}>
                    {isVideo(image.url) ? (
                      renderVideo(image.url)
                    ) : (
                      <img src={image.url} alt={image.name} />
                    )}
                  </div>
                );
              })}
            </Carousel>
          </Grid>
        )}

        <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
          <div>
            <Grid
              container
              justifyContent="space-between"
              style={{padding: '8px 24px'}}>
              <IconButton onClick={() => setOpen(false)} style={{padding: 6}}>
                <CloseIcon />
              </IconButton>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setDeleteOpen(true)}
                startIcon={<DeleteIcon />}>
                DELETE IMAGE
              </Button>
            </Grid>
            <img
              src={
                imageType
                  ? beforeImages[imageIndex]?.url
                  : afterImages[imageIndex]?.url
              }
              alt={`${
                imageType
                  ? beforeImages[imageIndex]?.name
                  : afterImages[imageIndex]?.name
              } full screen`}
              style={{maxHeight: '100vh', maxWidth: '100vw'}}
            />
          </div>
        </Dialog>

        <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
          <DialogTitle>Delete image?</DialogTitle>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary">
              Cancel
            </Button>
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <Button onClick={deleteImage} color="black">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    )
  );
}

export default ProjectCarousel;
